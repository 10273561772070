import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import axios from "../support-components/axios";

function EmailConfirmation(props) {
  useEffect(() => {
    setEmailConfirmationLoader(true);
    axios
      .get(`user/update/${props.match.params.token}`)
      .then((res) => {
        setEmailConfirmationLoader(false);
        setEmailConfirmation(true);
      })
      .catch((err) => {
        setEmailConfirmationLoader(false);
        setEmailConfirmation(false);
      });
  }, [props.match.params.token]);

  const [emailConfirmationLoader, setEmailConfirmationLoader] = useState(false);
  const [emailConfirmation, setEmailConfirmation] = useState(false);

  return (
    <div className="email-otr">
      <div className="email-inr">
        <div className="header-main">
          {emailConfirmationLoader === true ? (
            <Spinner className="mb-4" animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : emailConfirmation === true ? (
            <h1 className="heading">Email Verified!</h1>
          ) : (
            <h1 className="heading">Sorry , Could Not Verify Email</h1>
          )}
        </div>
      </div>
    </div>
  );
}

export default EmailConfirmation;
