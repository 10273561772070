import React from "react";

const LandingPage = () => {
  return (
    <iframe
      src="./landing.html"
      title="landing"
      style={{
        border: "none",
        width: "100%",
        height: "100vh",
        position: "absolute",
        top: 0,
        left: 0,
      }}
    />
  );
};

export default LandingPage;
