const chart = [
  {
    tokenId: 0,
    Tier: "Rare",
  },
  {
    tokenId: 1,
    Tier: "Common",
  },
  {
    tokenId: 2,
    Tier: "Basic",
  },
  {
    tokenId: 3,
    Tier: "Common",
  },
  {
    tokenId: 4,
    Tier: "Uncommon",
  },
  {
    tokenId: 5,
    Tier: "Common",
  },
  {
    tokenId: 6,
    Tier: "Basic",
  },
  {
    tokenId: 7,
    Tier: "Common",
  },
  {
    tokenId: 8,
    Tier: "Uncommon",
  },
  {
    tokenId: 9,
    Tier: "Common",
  },
  {
    tokenId: 10,
    Tier: "Basic",
  },
  {
    tokenId: 11,
    Tier: "Mythic",
  },
  {
    tokenId: 12,
    Tier: "Legendary",
  },
  {
    tokenId: 13,
    Tier: "Legendary",
  },
  {
    tokenId: 14,
    Tier: "Rare",
  },
  {
    tokenId: 15,
    Tier: "Rare",
  },
  {
    tokenId: 16,
    Tier: "Common",
  },
  {
    tokenId: 17,
    Tier: "Basic",
  },
  {
    tokenId: 18,
    Tier: "Basic",
  },
  {
    tokenId: 19,
    Tier: "Uncommon",
  },
  {
    tokenId: 20,
    Tier: "Basic",
  },
  {
    tokenId: 21,
    Tier: "Epic",
  },
  {
    tokenId: 22,
    Tier: "Uncommon",
  },
  {
    tokenId: 23,
    Tier: "Exotic",
  },
  {
    tokenId: 24,
    Tier: "Uncommon",
  },
  {
    tokenId: 25,
    Tier: "Basic",
  },
  {
    tokenId: 26,
    Tier: "Uncommon",
  },
  {
    tokenId: 27,
    Tier: "Basic",
  },
  {
    tokenId: 28,
    Tier: "Basic",
  },
  {
    tokenId: 29,
    Tier: "Basic",
  },
  {
    tokenId: 30,
    Tier: "Basic",
  },
  {
    tokenId: 31,
    Tier: "Basic",
  },
  {
    tokenId: 32,
    Tier: "Basic",
  },
  {
    tokenId: 33,
    Tier: "Common",
  },
  {
    tokenId: 34,
    Tier: "Basic",
  },
  {
    tokenId: 35,
    Tier: "Rare",
  },
  {
    tokenId: 36,
    Tier: "Basic",
  },
  {
    tokenId: 37,
    Tier: "Uncommon",
  },
  {
    tokenId: 38,
    Tier: "Basic",
  },
  {
    tokenId: 39,
    Tier: "Basic",
  },
  {
    tokenId: 40,
    Tier: "Basic",
  },
  {
    tokenId: 41,
    Tier: "Basic",
  },
  {
    tokenId: 42,
    Tier: "Basic",
  },
  {
    tokenId: 43,
    Tier: "Basic",
  },
  {
    tokenId: 44,
    Tier: "Epic",
  },
  {
    tokenId: 45,
    Tier: "Common",
  },
  {
    tokenId: 46,
    Tier: "Rare",
  },
  {
    tokenId: 47,
    Tier: "Basic",
  },
  {
    tokenId: 48,
    Tier: "Basic",
  },
  {
    tokenId: 49,
    Tier: "Mythic",
  },
  {
    tokenId: 50,
    Tier: "Basic",
  },
  {
    tokenId: 51,
    Tier: "Basic",
  },
  {
    tokenId: 52,
    Tier: "Basic",
  },
  {
    tokenId: 53,
    Tier: "Legendary",
  },
  {
    tokenId: 54,
    Tier: "Basic",
  },
  {
    tokenId: 55,
    Tier: "Basic",
  },
  {
    tokenId: 56,
    Tier: "Legendary",
  },
  {
    tokenId: 57,
    Tier: "Basic",
  },
  {
    tokenId: 58,
    Tier: "Rare",
  },
  {
    tokenId: 59,
    Tier: "Epic",
  },
  {
    tokenId: 60,
    Tier: "Epic",
  },
  {
    tokenId: 61,
    Tier: "Legendary",
  },
  {
    tokenId: 62,
    Tier: "Basic",
  },
  {
    tokenId: 63,
    Tier: "Common",
  },
  {
    tokenId: 64,
    Tier: "Basic",
  },
  {
    tokenId: 65,
    Tier: "Basic",
  },
  {
    tokenId: 66,
    Tier: "Uncommon",
  },
  {
    tokenId: 67,
    Tier: "Basic",
  },
  {
    tokenId: 68,
    Tier: "Basic",
  },
  {
    tokenId: 69,
    Tier: "Common",
  },
  {
    tokenId: 70,
    Tier: "Legendary",
  },
  {
    tokenId: 71,
    Tier: "Uncommon",
  },
  {
    tokenId: 72,
    Tier: "Common",
  },
  {
    tokenId: 73,
    Tier: "Common",
  },
  {
    tokenId: 74,
    Tier: "Basic",
  },
  {
    tokenId: 75,
    Tier: "Basic",
  },
  {
    tokenId: 76,
    Tier: "Uncommon",
  },
  {
    tokenId: 77,
    Tier: "Basic",
  },
  {
    tokenId: 78,
    Tier: "Basic",
  },
  {
    tokenId: 79,
    Tier: "Basic",
  },
  {
    tokenId: 80,
    Tier: "Basic",
  },
  {
    tokenId: 81,
    Tier: "Basic",
  },
  {
    tokenId: 82,
    Tier: "Basic",
  },
  {
    tokenId: 83,
    Tier: "Common",
  },
  {
    tokenId: 84,
    Tier: "Common",
  },
  {
    tokenId: 85,
    Tier: "Basic",
  },
  {
    tokenId: 86,
    Tier: "Common",
  },
  {
    tokenId: 87,
    Tier: "Epic",
  },
  {
    tokenId: 88,
    Tier: "Common",
  },
  {
    tokenId: 89,
    Tier: "Basic",
  },
  {
    tokenId: 90,
    Tier: "Uncommon",
  },
  {
    tokenId: 91,
    Tier: "Basic",
  },
  {
    tokenId: 92,
    Tier: "Basic",
  },
  {
    tokenId: 93,
    Tier: "Basic",
  },
  {
    tokenId: 94,
    Tier: "Rare",
  },
  {
    tokenId: 95,
    Tier: "Mythic",
  },
  {
    tokenId: 96,
    Tier: "Common",
  },
  {
    tokenId: 97,
    Tier: "Basic",
  },
  {
    tokenId: 98,
    Tier: "Basic",
  },
  {
    tokenId: 99,
    Tier: "Epic",
  },
  {
    tokenId: 100,
    Tier: "Basic",
  },
  {
    tokenId: 101,
    Tier: "Uncommon",
  },
  {
    tokenId: 102,
    Tier: "Uncommon",
  },
  {
    tokenId: 103,
    Tier: "Mythic",
  },
  {
    tokenId: 104,
    Tier: "Mythic",
  },
  {
    tokenId: 105,
    Tier: "Mythic",
  },
  {
    tokenId: 106,
    Tier: "Uncommon",
  },
  {
    tokenId: 107,
    Tier: "Basic",
  },
  {
    tokenId: 108,
    Tier: "Legendary",
  },
  {
    tokenId: 109,
    Tier: "Basic",
  },
  {
    tokenId: 110,
    Tier: "Mythic",
  },
  {
    tokenId: 111,
    Tier: "Basic",
  },
  {
    tokenId: 112,
    Tier: "Basic",
  },
  {
    tokenId: 113,
    Tier: "Exotic",
  },
  {
    tokenId: 114,
    Tier: "Common",
  },
  {
    tokenId: 115,
    Tier: "Basic",
  },
  {
    tokenId: 116,
    Tier: "Mythic",
  },
  {
    tokenId: 117,
    Tier: "Mythic",
  },
  {
    tokenId: 118,
    Tier: "Uncommon",
  },
  {
    tokenId: 119,
    Tier: "Epic",
  },
  {
    tokenId: 120,
    Tier: "Basic",
  },
  {
    tokenId: 121,
    Tier: "Basic",
  },
  {
    tokenId: 122,
    Tier: "Basic",
  },
  {
    tokenId: 123,
    Tier: "Basic",
  },
  {
    tokenId: 124,
    Tier: "Basic",
  },
  {
    tokenId: 125,
    Tier: "Common",
  },
  {
    tokenId: 126,
    Tier: "Legendary",
  },
  {
    tokenId: 127,
    Tier: "Legendary",
  },
  {
    tokenId: 128,
    Tier: "Basic",
  },
  {
    tokenId: 129,
    Tier: "Common",
  },
  {
    tokenId: 130,
    Tier: "Basic",
  },
  {
    tokenId: 131,
    Tier: "Rare",
  },
  {
    tokenId: 132,
    Tier: "Rare",
  },
  {
    tokenId: 133,
    Tier: "Rare",
  },
  {
    tokenId: 134,
    Tier: "Mythic",
  },
  {
    tokenId: 135,
    Tier: "Common",
  },
  {
    tokenId: 136,
    Tier: "Basic",
  },
  {
    tokenId: 137,
    Tier: "Basic",
  },
  {
    tokenId: 138,
    Tier: "Basic",
  },
  {
    tokenId: 139,
    Tier: "Common",
  },
  {
    tokenId: 140,
    Tier: "Basic",
  },
  {
    tokenId: 141,
    Tier: "Uncommon",
  },
  {
    tokenId: 142,
    Tier: "Uncommon",
  },
  {
    tokenId: 143,
    Tier: "Basic",
  },
  {
    tokenId: 144,
    Tier: "Epic",
  },
  {
    tokenId: 145,
    Tier: "Basic",
  },
  {
    tokenId: 146,
    Tier: "Basic",
  },
  {
    tokenId: 147,
    Tier: "Epic",
  },
  {
    tokenId: 148,
    Tier: "Mythic",
  },
  {
    tokenId: 149,
    Tier: "Basic",
  },
  {
    tokenId: 150,
    Tier: "Basic",
  },
  {
    tokenId: 151,
    Tier: "Basic",
  },
  {
    tokenId: 152,
    Tier: "Legendary",
  },
  {
    tokenId: 153,
    Tier: "Basic",
  },
  {
    tokenId: 154,
    Tier: "Uncommon",
  },
  {
    tokenId: 155,
    Tier: "Basic",
  },
  {
    tokenId: 156,
    Tier: "Rare",
  },
  {
    tokenId: 157,
    Tier: "Basic",
  },
  {
    tokenId: 158,
    Tier: "Rare",
  },
  {
    tokenId: 159,
    Tier: "Rare",
  },
  {
    tokenId: 160,
    Tier: "Common",
  },
  {
    tokenId: 161,
    Tier: "Common",
  },
  {
    tokenId: 162,
    Tier: "Basic",
  },
  {
    tokenId: 163,
    Tier: "Rare",
  },
  {
    tokenId: 164,
    Tier: "Basic",
  },
  {
    tokenId: 165,
    Tier: "Uncommon",
  },
  {
    tokenId: 166,
    Tier: "Rare",
  },
  {
    tokenId: 167,
    Tier: "Basic",
  },
  {
    tokenId: 168,
    Tier: "Rare",
  },
  {
    tokenId: 169,
    Tier: "Basic",
  },
  {
    tokenId: 170,
    Tier: "Epic",
  },
  {
    tokenId: 171,
    Tier: "Basic",
  },
  {
    tokenId: 172,
    Tier: "Basic",
  },
  {
    tokenId: 173,
    Tier: "Basic",
  },
  {
    tokenId: 174,
    Tier: "Basic",
  },
  {
    tokenId: 175,
    Tier: "Common",
  },
  {
    tokenId: 176,
    Tier: "Basic",
  },
  {
    tokenId: 177,
    Tier: "Common",
  },
  {
    tokenId: 178,
    Tier: "Uncommon",
  },
  {
    tokenId: 179,
    Tier: "Basic",
  },
  {
    tokenId: 180,
    Tier: "Basic",
  },
  {
    tokenId: 181,
    Tier: "Legendary",
  },
  {
    tokenId: 182,
    Tier: "Basic",
  },
  {
    tokenId: 183,
    Tier: "Basic",
  },
  {
    tokenId: 184,
    Tier: "Uncommon",
  },
  {
    tokenId: 185,
    Tier: "Basic",
  },
  {
    tokenId: 186,
    Tier: "Legendary",
  },
  {
    tokenId: 187,
    Tier: "Basic",
  },
  {
    tokenId: 188,
    Tier: "Uncommon",
  },
  {
    tokenId: 189,
    Tier: "Basic",
  },
  {
    tokenId: 190,
    Tier: "Legendary",
  },
  {
    tokenId: 191,
    Tier: "Uncommon",
  },
  {
    tokenId: 192,
    Tier: "Common",
  },
  {
    tokenId: 193,
    Tier: "Basic",
  },
  {
    tokenId: 194,
    Tier: "Basic",
  },
  {
    tokenId: 195,
    Tier: "Uncommon",
  },
  {
    tokenId: 196,
    Tier: "Mythic",
  },
  {
    tokenId: 197,
    Tier: "Basic",
  },
  {
    tokenId: 198,
    Tier: "Basic",
  },
  {
    tokenId: 199,
    Tier: "Basic",
  },
  {
    tokenId: 200,
    Tier: "Legendary",
  },
  {
    tokenId: 201,
    Tier: "Basic",
  },
  {
    tokenId: 202,
    Tier: "Common",
  },
  {
    tokenId: 203,
    Tier: "Uncommon",
  },
  {
    tokenId: 204,
    Tier: "Basic",
  },
  {
    tokenId: 205,
    Tier: "Basic",
  },
  {
    tokenId: 206,
    Tier: "Basic",
  },
  {
    tokenId: 207,
    Tier: "Basic",
  },
  {
    tokenId: 208,
    Tier: "Basic",
  },
  {
    tokenId: 209,
    Tier: "Uncommon",
  },
  {
    tokenId: 210,
    Tier: "Common",
  },
  {
    tokenId: 211,
    Tier: "Common",
  },
  {
    tokenId: 212,
    Tier: "Basic",
  },
  {
    tokenId: 213,
    Tier: "Common",
  },
  {
    tokenId: 214,
    Tier: "Uncommon",
  },
  {
    tokenId: 215,
    Tier: "Epic",
  },
  {
    tokenId: 216,
    Tier: "Basic",
  },
  {
    tokenId: 217,
    Tier: "Basic",
  },
  {
    tokenId: 218,
    Tier: "Rare",
  },
  {
    tokenId: 219,
    Tier: "Basic",
  },
  {
    tokenId: 220,
    Tier: "Basic",
  },
  {
    tokenId: 221,
    Tier: "Mythic",
  },
  {
    tokenId: 222,
    Tier: "Common",
  },
  {
    tokenId: 223,
    Tier: "Basic",
  },
  {
    tokenId: 224,
    Tier: "Basic",
  },
  {
    tokenId: 225,
    Tier: "Basic",
  },
  {
    tokenId: 226,
    Tier: "Basic",
  },
  {
    tokenId: 227,
    Tier: "Basic",
  },
  {
    tokenId: 228,
    Tier: "Basic",
  },
  {
    tokenId: 229,
    Tier: "Basic",
  },
  {
    tokenId: 230,
    Tier: "Basic",
  },
  {
    tokenId: 231,
    Tier: "Rare",
  },
  {
    tokenId: 232,
    Tier: "Common",
  },
  {
    tokenId: 233,
    Tier: "Legendary",
  },
  {
    tokenId: 234,
    Tier: "Common",
  },
  {
    tokenId: 235,
    Tier: "Uncommon",
  },
  {
    tokenId: 236,
    Tier: "Basic",
  },
  {
    tokenId: 237,
    Tier: "Basic",
  },
  {
    tokenId: 238,
    Tier: "Basic",
  },
  {
    tokenId: 239,
    Tier: "Common",
  },
  {
    tokenId: 240,
    Tier: "Basic",
  },
  {
    tokenId: 241,
    Tier: "Common",
  },
  {
    tokenId: 242,
    Tier: "Epic",
  },
  {
    tokenId: 243,
    Tier: "Basic",
  },
  {
    tokenId: 244,
    Tier: "Rare",
  },
  {
    tokenId: 245,
    Tier: "Common",
  },
  {
    tokenId: 246,
    Tier: "Uncommon",
  },
  {
    tokenId: 247,
    Tier: "Legendary",
  },
  {
    tokenId: 248,
    Tier: "Basic",
  },
  {
    tokenId: 249,
    Tier: "Basic",
  },
  {
    tokenId: 250,
    Tier: "Common",
  },
  {
    tokenId: 251,
    Tier: "Basic",
  },
  {
    tokenId: 252,
    Tier: "Basic",
  },
  {
    tokenId: 253,
    Tier: "Common",
  },
  {
    tokenId: 254,
    Tier: "Epic",
  },
  {
    tokenId: 255,
    Tier: "Mythic",
  },
  {
    tokenId: 256,
    Tier: "Uncommon",
  },
  {
    tokenId: 257,
    Tier: "Uncommon",
  },
  {
    tokenId: 258,
    Tier: "Uncommon",
  },
  {
    tokenId: 259,
    Tier: "Basic",
  },
  {
    tokenId: 260,
    Tier: "Common",
  },
  {
    tokenId: 261,
    Tier: "Basic",
  },
  {
    tokenId: 262,
    Tier: "Uncommon",
  },
  {
    tokenId: 263,
    Tier: "Rare",
  },
  {
    tokenId: 264,
    Tier: "Common",
  },
  {
    tokenId: 265,
    Tier: "Basic",
  },
  {
    tokenId: 266,
    Tier: "Legendary",
  },
  {
    tokenId: 267,
    Tier: "Common",
  },
  {
    tokenId: 268,
    Tier: "Common",
  },
  {
    tokenId: 269,
    Tier: "Uncommon",
  },
  {
    tokenId: 270,
    Tier: "Basic",
  },
  {
    tokenId: 271,
    Tier: "Basic",
  },
  {
    tokenId: 272,
    Tier: "Basic",
  },
  {
    tokenId: 273,
    Tier: "Common",
  },
  {
    tokenId: 274,
    Tier: "Basic",
  },
  {
    tokenId: 275,
    Tier: "Basic",
  },
  {
    tokenId: 276,
    Tier: "Rare",
  },
  {
    tokenId: 277,
    Tier: "Rare",
  },
  {
    tokenId: 278,
    Tier: "Basic",
  },
  {
    tokenId: 279,
    Tier: "Basic",
  },
  {
    tokenId: 280,
    Tier: "Basic",
  },
  {
    tokenId: 281,
    Tier: "Common",
  },
  {
    tokenId: 282,
    Tier: "Basic",
  },
  {
    tokenId: 283,
    Tier: "Mythic",
  },
  {
    tokenId: 284,
    Tier: "Basic",
  },
  {
    tokenId: 285,
    Tier: "Basic",
  },
  {
    tokenId: 286,
    Tier: "Basic",
  },
  {
    tokenId: 287,
    Tier: "Epic",
  },
  {
    tokenId: 288,
    Tier: "Mythic",
  },
  {
    tokenId: 289,
    Tier: "Common",
  },
  {
    tokenId: 290,
    Tier: "Exotic",
  },
  {
    tokenId: 291,
    Tier: "Common",
  },
  {
    tokenId: 292,
    Tier: "Basic",
  },
  {
    tokenId: 293,
    Tier: "Uncommon",
  },
  {
    tokenId: 294,
    Tier: "Basic",
  },
  {
    tokenId: 295,
    Tier: "Legendary",
  },
  {
    tokenId: 296,
    Tier: "Rare",
  },
  {
    tokenId: 297,
    Tier: "Basic",
  },
  {
    tokenId: 298,
    Tier: "Rare",
  },
  {
    tokenId: 299,
    Tier: "Common",
  },
  {
    tokenId: 300,
    Tier: "Basic",
  },
  {
    tokenId: 301,
    Tier: "Rare",
  },
  {
    tokenId: 302,
    Tier: "Basic",
  },
  {
    tokenId: 303,
    Tier: "Mythic",
  },
  {
    tokenId: 304,
    Tier: "Basic",
  },
  {
    tokenId: 305,
    Tier: "Basic",
  },
  {
    tokenId: 306,
    Tier: "Uncommon",
  },
  {
    tokenId: 307,
    Tier: "Common",
  },
  {
    tokenId: 308,
    Tier: "Common",
  },
  {
    tokenId: 309,
    Tier: "Epic",
  },
  {
    tokenId: 310,
    Tier: "Rare",
  },
  {
    tokenId: 311,
    Tier: "Rare",
  },
  {
    tokenId: 312,
    Tier: "Mythic",
  },
  {
    tokenId: 313,
    Tier: "Common",
  },
  {
    tokenId: 314,
    Tier: "Basic",
  },
  {
    tokenId: 315,
    Tier: "Uncommon",
  },
  {
    tokenId: 316,
    Tier: "Basic",
  },
  {
    tokenId: 317,
    Tier: "Basic",
  },
  {
    tokenId: 318,
    Tier: "Uncommon",
  },
  {
    tokenId: 319,
    Tier: "Exotic",
  },
  {
    tokenId: 320,
    Tier: "Epic",
  },
  {
    tokenId: 321,
    Tier: "Basic",
  },
  {
    tokenId: 322,
    Tier: "Basic",
  },
  {
    tokenId: 323,
    Tier: "Basic",
  },
  {
    tokenId: 324,
    Tier: "Uncommon",
  },
  {
    tokenId: 325,
    Tier: "Common",
  },
  {
    tokenId: 326,
    Tier: "Uncommon",
  },
  {
    tokenId: 327,
    Tier: "Common",
  },
  {
    tokenId: 328,
    Tier: "Mythic",
  },
  {
    tokenId: 329,
    Tier: "Rare",
  },
  {
    tokenId: 330,
    Tier: "Legendary",
  },
  {
    tokenId: 331,
    Tier: "Basic",
  },
  {
    tokenId: 332,
    Tier: "Legendary",
  },
  {
    tokenId: 333,
    Tier: "Epic",
  },
  {
    tokenId: 334,
    Tier: "Rare",
  },
  {
    tokenId: 335,
    Tier: "Uncommon",
  },
  {
    tokenId: 336,
    Tier: "Legendary",
  },
  {
    tokenId: 337,
    Tier: "Legendary",
  },
  {
    tokenId: 338,
    Tier: "Basic",
  },
  {
    tokenId: 339,
    Tier: "Uncommon",
  },
  {
    tokenId: 340,
    Tier: "Rare",
  },
  {
    tokenId: 341,
    Tier: "Common",
  },
  {
    tokenId: 342,
    Tier: "Basic",
  },
  {
    tokenId: 343,
    Tier: "Rare",
  },
  {
    tokenId: 344,
    Tier: "Basic",
  },
  {
    tokenId: 345,
    Tier: "Basic",
  },
  {
    tokenId: 346,
    Tier: "Legendary",
  },
  {
    tokenId: 347,
    Tier: "Uncommon",
  },
  {
    tokenId: 348,
    Tier: "Basic",
  },
  {
    tokenId: 349,
    Tier: "Epic",
  },
  {
    tokenId: 350,
    Tier: "Basic",
  },
  {
    tokenId: 351,
    Tier: "Basic",
  },
  {
    tokenId: 352,
    Tier: "Basic",
  },
  {
    tokenId: 353,
    Tier: "Rare",
  },
  {
    tokenId: 354,
    Tier: "Basic",
  },
  {
    tokenId: 355,
    Tier: "Common",
  },
  {
    tokenId: 356,
    Tier: "Common",
  },
  {
    tokenId: 357,
    Tier: "Rare",
  },
  {
    tokenId: 358,
    Tier: "Uncommon",
  },
  {
    tokenId: 359,
    Tier: "Basic",
  },
  {
    tokenId: 360,
    Tier: "Basic",
  },
  {
    tokenId: 361,
    Tier: "Epic",
  },
  {
    tokenId: 362,
    Tier: "Common",
  },
  {
    tokenId: 363,
    Tier: "Common",
  },
  {
    tokenId: 364,
    Tier: "Basic",
  },
  {
    tokenId: 365,
    Tier: "Mythic",
  },
  {
    tokenId: 366,
    Tier: "Uncommon",
  },
  {
    tokenId: 367,
    Tier: "Common",
  },
  {
    tokenId: 368,
    Tier: "Basic",
  },
  {
    tokenId: 369,
    Tier: "Epic",
  },
  {
    tokenId: 370,
    Tier: "Basic",
  },
  {
    tokenId: 371,
    Tier: "Basic",
  },
  {
    tokenId: 372,
    Tier: "Basic",
  },
  {
    tokenId: 373,
    Tier: "Basic",
  },
  {
    tokenId: 374,
    Tier: "Basic",
  },
  {
    tokenId: 375,
    Tier: "Basic",
  },
  {
    tokenId: 376,
    Tier: "Basic",
  },
  {
    tokenId: 377,
    Tier: "Common",
  },
  {
    tokenId: 378,
    Tier: "Epic",
  },
  {
    tokenId: 379,
    Tier: "Epic",
  },
  {
    tokenId: 380,
    Tier: "Common",
  },
  {
    tokenId: 381,
    Tier: "Basic",
  },
  {
    tokenId: 382,
    Tier: "Basic",
  },
  {
    tokenId: 383,
    Tier: "Basic",
  },
  {
    tokenId: 384,
    Tier: "Rare",
  },
  {
    tokenId: 385,
    Tier: "Basic",
  },
  {
    tokenId: 386,
    Tier: "Common",
  },
  {
    tokenId: 387,
    Tier: "Rare",
  },
  {
    tokenId: 388,
    Tier: "Basic",
  },
  {
    tokenId: 389,
    Tier: "Basic",
  },
  {
    tokenId: 390,
    Tier: "Basic",
  },
  {
    tokenId: 391,
    Tier: "Basic",
  },
  {
    tokenId: 392,
    Tier: "Basic",
  },
  {
    tokenId: 393,
    Tier: "Basic",
  },
  {
    tokenId: 394,
    Tier: "Basic",
  },
  {
    tokenId: 395,
    Tier: "Basic",
  },
  {
    tokenId: 396,
    Tier: "Basic",
  },
  {
    tokenId: 397,
    Tier: "Basic",
  },
  {
    tokenId: 398,
    Tier: "Basic",
  },
  {
    tokenId: 399,
    Tier: "Basic",
  },
  {
    tokenId: 400,
    Tier: "Basic",
  },
  {
    tokenId: 401,
    Tier: "Common",
  },
  {
    tokenId: 402,
    Tier: "Uncommon",
  },
  {
    tokenId: 403,
    Tier: "Rare",
  },
  {
    tokenId: 404,
    Tier: "Basic",
  },
  {
    tokenId: 405,
    Tier: "Epic",
  },
  {
    tokenId: 406,
    Tier: "Basic",
  },
  {
    tokenId: 407,
    Tier: "Basic",
  },
  {
    tokenId: 408,
    Tier: "Basic",
  },
  {
    tokenId: 409,
    Tier: "Legendary",
  },
  {
    tokenId: 410,
    Tier: "Uncommon",
  },
  {
    tokenId: 411,
    Tier: "Basic",
  },
  {
    tokenId: 412,
    Tier: "Basic",
  },
  {
    tokenId: 413,
    Tier: "Basic",
  },
  {
    tokenId: 414,
    Tier: "Uncommon",
  },
  {
    tokenId: 415,
    Tier: "Basic",
  },
  {
    tokenId: 416,
    Tier: "Basic",
  },
  {
    tokenId: 417,
    Tier: "Basic",
  },
  {
    tokenId: 418,
    Tier: "Exotic",
  },
  {
    tokenId: 419,
    Tier: "Epic",
  },
  {
    tokenId: 420,
    Tier: "Basic",
  },
  {
    tokenId: 421,
    Tier: "Basic",
  },
  {
    tokenId: 422,
    Tier: "Common",
  },
  {
    tokenId: 423,
    Tier: "Uncommon",
  },
  {
    tokenId: 424,
    Tier: "Basic",
  },
  {
    tokenId: 425,
    Tier: "Basic",
  },
  {
    tokenId: 426,
    Tier: "Basic",
  },
  {
    tokenId: 427,
    Tier: "Common",
  },
  {
    tokenId: 428,
    Tier: "Rare",
  },
  {
    tokenId: 429,
    Tier: "Epic",
  },
  {
    tokenId: 430,
    Tier: "Rare",
  },
  {
    tokenId: 431,
    Tier: "Basic",
  },
  {
    tokenId: 432,
    Tier: "Basic",
  },
  {
    tokenId: 433,
    Tier: "Basic",
  },
  {
    tokenId: 434,
    Tier: "Basic",
  },
  {
    tokenId: 435,
    Tier: "Rare",
  },
  {
    tokenId: 436,
    Tier: "Basic",
  },
  {
    tokenId: 437,
    Tier: "Basic",
  },
  {
    tokenId: 438,
    Tier: "Mythic",
  },
  {
    tokenId: 439,
    Tier: "Basic",
  },
  {
    tokenId: 440,
    Tier: "Uncommon",
  },
  {
    tokenId: 441,
    Tier: "Mythic",
  },
  {
    tokenId: 442,
    Tier: "Epic",
  },
  {
    tokenId: 443,
    Tier: "Basic",
  },
  {
    tokenId: 444,
    Tier: "Epic",
  },
  {
    tokenId: 445,
    Tier: "Basic",
  },
  {
    tokenId: 446,
    Tier: "Basic",
  },
  {
    tokenId: 447,
    Tier: "Basic",
  },
  {
    tokenId: 448,
    Tier: "Basic",
  },
  {
    tokenId: 449,
    Tier: "Basic",
  },
  {
    tokenId: 450,
    Tier: "Basic",
  },
  {
    tokenId: 451,
    Tier: "Common",
  },
  {
    tokenId: 452,
    Tier: "Basic",
  },
  {
    tokenId: 453,
    Tier: "Basic",
  },
  {
    tokenId: 454,
    Tier: "Basic",
  },
  {
    tokenId: 455,
    Tier: "Basic",
  },
  {
    tokenId: 456,
    Tier: "Basic",
  },
  {
    tokenId: 457,
    Tier: "Epic",
  },
  {
    tokenId: 458,
    Tier: "Basic",
  },
  {
    tokenId: 459,
    Tier: "Mythic",
  },
  {
    tokenId: 460,
    Tier: "Common",
  },
  {
    tokenId: 461,
    Tier: "Basic",
  },
  {
    tokenId: 462,
    Tier: "Common",
  },
  {
    tokenId: 463,
    Tier: "Common",
  },
  {
    tokenId: 464,
    Tier: "Epic",
  },
  {
    tokenId: 465,
    Tier: "Epic",
  },
  {
    tokenId: 466,
    Tier: "Epic",
  },
  {
    tokenId: 467,
    Tier: "Common",
  },
  {
    tokenId: 468,
    Tier: "Basic",
  },
  {
    tokenId: 469,
    Tier: "Basic",
  },
  {
    tokenId: 470,
    Tier: "Rare",
  },
  {
    tokenId: 471,
    Tier: "Legendary",
  },
  {
    tokenId: 472,
    Tier: "Basic",
  },
  {
    tokenId: 473,
    Tier: "Basic",
  },
  {
    tokenId: 474,
    Tier: "Common",
  },
  {
    tokenId: 475,
    Tier: "Basic",
  },
  {
    tokenId: 476,
    Tier: "Common",
  },
  {
    tokenId: 477,
    Tier: "Legendary",
  },
  {
    tokenId: 478,
    Tier: "Basic",
  },
  {
    tokenId: 479,
    Tier: "Legendary",
  },
  {
    tokenId: 480,
    Tier: "Basic",
  },
  {
    tokenId: 481,
    Tier: "Basic",
  },
  {
    tokenId: 482,
    Tier: "Uncommon",
  },
  {
    tokenId: 483,
    Tier: "Uncommon",
  },
  {
    tokenId: 484,
    Tier: "Uncommon",
  },
  {
    tokenId: 485,
    Tier: "Rare",
  },
  {
    tokenId: 486,
    Tier: "Uncommon",
  },
  {
    tokenId: 487,
    Tier: "Basic",
  },
  {
    tokenId: 488,
    Tier: "Common",
  },
  {
    tokenId: 489,
    Tier: "Exotic",
  },
  {
    tokenId: 490,
    Tier: "Basic",
  },
  {
    tokenId: 491,
    Tier: "Epic",
  },
  {
    tokenId: 492,
    Tier: "Basic",
  },
  {
    tokenId: 493,
    Tier: "Uncommon",
  },
  {
    tokenId: 494,
    Tier: "Basic",
  },
  {
    tokenId: 495,
    Tier: "Mythic",
  },
  {
    tokenId: 496,
    Tier: "Basic",
  },
  {
    tokenId: 497,
    Tier: "Epic",
  },
  {
    tokenId: 498,
    Tier: "Uncommon",
  },
  {
    tokenId: 499,
    Tier: "Basic",
  },
  {
    tokenId: 500,
    Tier: "Basic",
  },
  {
    tokenId: 501,
    Tier: "Common",
  },
  {
    tokenId: 502,
    Tier: "Epic",
  },
  {
    tokenId: 503,
    Tier: "Common",
  },
  {
    tokenId: 504,
    Tier: "Basic",
  },
  {
    tokenId: 505,
    Tier: "Basic",
  },
  {
    tokenId: 506,
    Tier: "Basic",
  },
  {
    tokenId: 507,
    Tier: "Basic",
  },
  {
    tokenId: 508,
    Tier: "Basic",
  },
  {
    tokenId: 509,
    Tier: "Mythic",
  },
  {
    tokenId: 510,
    Tier: "Basic",
  },
  {
    tokenId: 511,
    Tier: "Uncommon",
  },
  {
    tokenId: 512,
    Tier: "Basic",
  },
  {
    tokenId: 513,
    Tier: "Basic",
  },
  {
    tokenId: 514,
    Tier: "Basic",
  },
  {
    tokenId: 515,
    Tier: "Basic",
  },
  {
    tokenId: 516,
    Tier: "Rare",
  },
  {
    tokenId: 517,
    Tier: "Legendary",
  },
  {
    tokenId: 518,
    Tier: "Epic",
  },
  {
    tokenId: 519,
    Tier: "Basic",
  },
  {
    tokenId: 520,
    Tier: "Basic",
  },
  {
    tokenId: 521,
    Tier: "Rare",
  },
  {
    tokenId: 522,
    Tier: "Basic",
  },
  {
    tokenId: 523,
    Tier: "Basic",
  },
  {
    tokenId: 524,
    Tier: "Basic",
  },
  {
    tokenId: 525,
    Tier: "Basic",
  },
  {
    tokenId: 526,
    Tier: "Basic",
  },
  {
    tokenId: 527,
    Tier: "Basic",
  },
  {
    tokenId: 528,
    Tier: "Uncommon",
  },
  {
    tokenId: 529,
    Tier: "Uncommon",
  },
  {
    tokenId: 530,
    Tier: "Exotic",
  },
  {
    tokenId: 531,
    Tier: "Basic",
  },
  {
    tokenId: 532,
    Tier: "Common",
  },
  {
    tokenId: 533,
    Tier: "Common",
  },
  {
    tokenId: 534,
    Tier: "Legendary",
  },
  {
    tokenId: 535,
    Tier: "Common",
  },
  {
    tokenId: 536,
    Tier: "Basic",
  },
  {
    tokenId: 537,
    Tier: "Basic",
  },
  {
    tokenId: 538,
    Tier: "Basic",
  },
  {
    tokenId: 539,
    Tier: "Basic",
  },
  {
    tokenId: 540,
    Tier: "Basic",
  },
  {
    tokenId: 541,
    Tier: "Legendary",
  },
  {
    tokenId: 542,
    Tier: "Common",
  },
  {
    tokenId: 543,
    Tier: "Basic",
  },
  {
    tokenId: 544,
    Tier: "Exotic",
  },
  {
    tokenId: 545,
    Tier: "Legendary",
  },
  {
    tokenId: 546,
    Tier: "Basic",
  },
  {
    tokenId: 547,
    Tier: "Basic",
  },
  {
    tokenId: 548,
    Tier: "Basic",
  },
  {
    tokenId: 549,
    Tier: "Rare",
  },
  {
    tokenId: 550,
    Tier: "Basic",
  },
  {
    tokenId: 551,
    Tier: "Uncommon",
  },
  {
    tokenId: 552,
    Tier: "Mythic",
  },
  {
    tokenId: 553,
    Tier: "Common",
  },
  {
    tokenId: 554,
    Tier: "Legendary",
  },
  {
    tokenId: 555,
    Tier: "Basic",
  },
  {
    tokenId: 556,
    Tier: "Basic",
  },
  {
    tokenId: 557,
    Tier: "Epic",
  },
  {
    tokenId: 558,
    Tier: "Basic",
  },
  {
    tokenId: 559,
    Tier: "Mythic",
  },
  {
    tokenId: 560,
    Tier: "Common",
  },
  {
    tokenId: 561,
    Tier: "Uncommon",
  },
  {
    tokenId: 562,
    Tier: "Epic",
  },
  {
    tokenId: 563,
    Tier: "Basic",
  },
  {
    tokenId: 564,
    Tier: "Basic",
  },
  {
    tokenId: 565,
    Tier: "Uncommon",
  },
  {
    tokenId: 566,
    Tier: "Rare",
  },
  {
    tokenId: 567,
    Tier: "Epic",
  },
  {
    tokenId: 568,
    Tier: "Common",
  },
  {
    tokenId: 569,
    Tier: "Basic",
  },
  {
    tokenId: 570,
    Tier: "Rare",
  },
  {
    tokenId: 571,
    Tier: "Basic",
  },
  {
    tokenId: 572,
    Tier: "Basic",
  },
  {
    tokenId: 573,
    Tier: "Basic",
  },
  {
    tokenId: 574,
    Tier: "Legendary",
  },
  {
    tokenId: 575,
    Tier: "Common",
  },
  {
    tokenId: 576,
    Tier: "Basic",
  },
  {
    tokenId: 577,
    Tier: "Basic",
  },
  {
    tokenId: 578,
    Tier: "Uncommon",
  },
  {
    tokenId: 579,
    Tier: "Basic",
  },
  {
    tokenId: 580,
    Tier: "Basic",
  },
  {
    tokenId: 581,
    Tier: "Rare",
  },
  {
    tokenId: 582,
    Tier: "Common",
  },
  {
    tokenId: 583,
    Tier: "Basic",
  },
  {
    tokenId: 584,
    Tier: "Basic",
  },
  {
    tokenId: 585,
    Tier: "Basic",
  },
  {
    tokenId: 586,
    Tier: "Basic",
  },
  {
    tokenId: 587,
    Tier: "Uncommon",
  },
  {
    tokenId: 588,
    Tier: "Basic",
  },
  {
    tokenId: 589,
    Tier: "Rare",
  },
  {
    tokenId: 590,
    Tier: "Basic",
  },
  {
    tokenId: 591,
    Tier: "Basic",
  },
  {
    tokenId: 592,
    Tier: "Uncommon",
  },
  {
    tokenId: 593,
    Tier: "Basic",
  },
  {
    tokenId: 594,
    Tier: "Common",
  },
  {
    tokenId: 595,
    Tier: "Legendary",
  },
  {
    tokenId: 596,
    Tier: "Uncommon",
  },
  {
    tokenId: 597,
    Tier: "Rare",
  },
  {
    tokenId: 598,
    Tier: "Basic",
  },
  {
    tokenId: 599,
    Tier: "Uncommon",
  },
  {
    tokenId: 600,
    Tier: "Mythic",
  },
  {
    tokenId: 601,
    Tier: "Basic",
  },
  {
    tokenId: 602,
    Tier: "Basic",
  },
  {
    tokenId: 603,
    Tier: "Rare",
  },
  {
    tokenId: 604,
    Tier: "Common",
  },
  {
    tokenId: 605,
    Tier: "Uncommon",
  },
  {
    tokenId: 606,
    Tier: "Rare",
  },
  {
    tokenId: 607,
    Tier: "Epic",
  },
  {
    tokenId: 608,
    Tier: "Basic",
  },
  {
    tokenId: 609,
    Tier: "Uncommon",
  },
  {
    tokenId: 610,
    Tier: "Uncommon",
  },
  {
    tokenId: 611,
    Tier: "Common",
  },
  {
    tokenId: 612,
    Tier: "Epic",
  },
  {
    tokenId: 613,
    Tier: "Basic",
  },
  {
    tokenId: 614,
    Tier: "Basic",
  },
  {
    tokenId: 615,
    Tier: "Basic",
  },
  {
    tokenId: 616,
    Tier: "Epic",
  },
  {
    tokenId: 617,
    Tier: "Common",
  },
  {
    tokenId: 618,
    Tier: "Common",
  },
  {
    tokenId: 619,
    Tier: "Common",
  },
  {
    tokenId: 620,
    Tier: "Basic",
  },
  {
    tokenId: 621,
    Tier: "Common",
  },
  {
    tokenId: 622,
    Tier: "Common",
  },
  {
    tokenId: 623,
    Tier: "Basic",
  },
  {
    tokenId: 624,
    Tier: "Common",
  },
  {
    tokenId: 625,
    Tier: "Mythic",
  },
  {
    tokenId: 626,
    Tier: "Epic",
  },
  {
    tokenId: 627,
    Tier: "Common",
  },
  {
    tokenId: 628,
    Tier: "Basic",
  },
  {
    tokenId: 629,
    Tier: "Legendary",
  },
  {
    tokenId: 630,
    Tier: "Legendary",
  },
  {
    tokenId: 631,
    Tier: "Basic",
  },
  {
    tokenId: 632,
    Tier: "Common",
  },
  {
    tokenId: 633,
    Tier: "Uncommon",
  },
  {
    tokenId: 634,
    Tier: "Basic",
  },
  {
    tokenId: 635,
    Tier: "Uncommon",
  },
  {
    tokenId: 636,
    Tier: "Basic",
  },
  {
    tokenId: 637,
    Tier: "Basic",
  },
  {
    tokenId: 638,
    Tier: "Basic",
  },
  {
    tokenId: 639,
    Tier: "Common",
  },
  {
    tokenId: 640,
    Tier: "Uncommon",
  },
  {
    tokenId: 641,
    Tier: "Rare",
  },
  {
    tokenId: 642,
    Tier: "Legendary",
  },
  {
    tokenId: 643,
    Tier: "Basic",
  },
  {
    tokenId: 644,
    Tier: "Uncommon",
  },
  {
    tokenId: 645,
    Tier: "Epic",
  },
  {
    tokenId: 646,
    Tier: "Common",
  },
  {
    tokenId: 647,
    Tier: "Basic",
  },
  {
    tokenId: 648,
    Tier: "Basic",
  },
  {
    tokenId: 649,
    Tier: "Common",
  },
  {
    tokenId: 650,
    Tier: "Basic",
  },
  {
    tokenId: 651,
    Tier: "Legendary",
  },
  {
    tokenId: 652,
    Tier: "Uncommon",
  },
  {
    tokenId: 653,
    Tier: "Rare",
  },
  {
    tokenId: 654,
    Tier: "Uncommon",
  },
  {
    tokenId: 655,
    Tier: "Epic",
  },
  {
    tokenId: 656,
    Tier: "Basic",
  },
  {
    tokenId: 657,
    Tier: "Basic",
  },
  {
    tokenId: 658,
    Tier: "Basic",
  },
  {
    tokenId: 659,
    Tier: "Epic",
  },
  {
    tokenId: 660,
    Tier: "Uncommon",
  },
  {
    tokenId: 661,
    Tier: "Exotic",
  },
  {
    tokenId: 662,
    Tier: "Basic",
  },
  {
    tokenId: 663,
    Tier: "Basic",
  },
  {
    tokenId: 664,
    Tier: "Basic",
  },
  {
    tokenId: 665,
    Tier: "Basic",
  },
  {
    tokenId: 666,
    Tier: "Common",
  },
  {
    tokenId: 667,
    Tier: "Uncommon",
  },
  {
    tokenId: 668,
    Tier: "Rare",
  },
  {
    tokenId: 669,
    Tier: "Epic",
  },
  {
    tokenId: 670,
    Tier: "Basic",
  },
  {
    tokenId: 671,
    Tier: "Basic",
  },
  {
    tokenId: 672,
    Tier: "Basic",
  },
  {
    tokenId: 673,
    Tier: "Basic",
  },
  {
    tokenId: 674,
    Tier: "Legendary",
  },
  {
    tokenId: 675,
    Tier: "Basic",
  },
  {
    tokenId: 676,
    Tier: "Mythic",
  },
  {
    tokenId: 677,
    Tier: "Common",
  },
  {
    tokenId: 678,
    Tier: "Basic",
  },
  {
    tokenId: 679,
    Tier: "Basic",
  },
  {
    tokenId: 680,
    Tier: "Uncommon",
  },
  {
    tokenId: 681,
    Tier: "Basic",
  },
  {
    tokenId: 682,
    Tier: "Basic",
  },
  {
    tokenId: 683,
    Tier: "Mythic",
  },
  {
    tokenId: 684,
    Tier: "Basic",
  },
  {
    tokenId: 685,
    Tier: "Rare",
  },
  {
    tokenId: 686,
    Tier: "Basic",
  },
  {
    tokenId: 687,
    Tier: "Basic",
  },
  {
    tokenId: 688,
    Tier: "Basic",
  },
  {
    tokenId: 689,
    Tier: "Epic",
  },
  {
    tokenId: 690,
    Tier: "Legendary",
  },
  {
    tokenId: 691,
    Tier: "Uncommon",
  },
  {
    tokenId: 692,
    Tier: "Basic",
  },
  {
    tokenId: 693,
    Tier: "Basic",
  },
  {
    tokenId: 694,
    Tier: "Basic",
  },
  {
    tokenId: 695,
    Tier: "Rare",
  },
  {
    tokenId: 696,
    Tier: "Mythic",
  },
  {
    tokenId: 697,
    Tier: "Uncommon",
  },
  {
    tokenId: 698,
    Tier: "Rare",
  },
  {
    tokenId: 699,
    Tier: "Epic",
  },
  {
    tokenId: 700,
    Tier: "Epic",
  },
  {
    tokenId: 701,
    Tier: "Basic",
  },
  {
    tokenId: 702,
    Tier: "Basic",
  },
  {
    tokenId: 703,
    Tier: "Basic",
  },
  {
    tokenId: 704,
    Tier: "Basic",
  },
  {
    tokenId: 705,
    Tier: "Uncommon",
  },
  {
    tokenId: 706,
    Tier: "Basic",
  },
  {
    tokenId: 707,
    Tier: "Rare",
  },
  {
    tokenId: 708,
    Tier: "Legendary",
  },
  {
    tokenId: 709,
    Tier: "Epic",
  },
  {
    tokenId: 710,
    Tier: "Epic",
  },
  {
    tokenId: 711,
    Tier: "Basic",
  },
  {
    tokenId: 712,
    Tier: "Basic",
  },
  {
    tokenId: 713,
    Tier: "Epic",
  },
  {
    tokenId: 714,
    Tier: "Basic",
  },
  {
    tokenId: 715,
    Tier: "Basic",
  },
  {
    tokenId: 716,
    Tier: "Uncommon",
  },
  {
    tokenId: 717,
    Tier: "Basic",
  },
  {
    tokenId: 718,
    Tier: "Basic",
  },
  {
    tokenId: 719,
    Tier: "Basic",
  },
  {
    tokenId: 720,
    Tier: "Basic",
  },
  {
    tokenId: 721,
    Tier: "Basic",
  },
  {
    tokenId: 722,
    Tier: "Legendary",
  },
  {
    tokenId: 723,
    Tier: "Basic",
  },
  {
    tokenId: 724,
    Tier: "Common",
  },
  {
    tokenId: 725,
    Tier: "Legendary",
  },
  {
    tokenId: 726,
    Tier: "Basic",
  },
  {
    tokenId: 727,
    Tier: "Legendary",
  },
  {
    tokenId: 728,
    Tier: "Basic",
  },
  {
    tokenId: 729,
    Tier: "Basic",
  },
  {
    tokenId: 730,
    Tier: "Common",
  },
  {
    tokenId: 731,
    Tier: "Basic",
  },
  {
    tokenId: 732,
    Tier: "Basic",
  },
  {
    tokenId: 733,
    Tier: "Mythic",
  },
  {
    tokenId: 734,
    Tier: "Basic",
  },
  {
    tokenId: 735,
    Tier: "Uncommon",
  },
  {
    tokenId: 736,
    Tier: "Common",
  },
  {
    tokenId: 737,
    Tier: "Rare",
  },
  {
    tokenId: 738,
    Tier: "Basic",
  },
  {
    tokenId: 739,
    Tier: "Basic",
  },
  {
    tokenId: 740,
    Tier: "Basic",
  },
  {
    tokenId: 741,
    Tier: "Basic",
  },
  {
    tokenId: 742,
    Tier: "Common",
  },
  {
    tokenId: 743,
    Tier: "Uncommon",
  },
  {
    tokenId: 744,
    Tier: "Basic",
  },
  {
    tokenId: 745,
    Tier: "Uncommon",
  },
  {
    tokenId: 746,
    Tier: "Basic",
  },
  {
    tokenId: 747,
    Tier: "Basic",
  },
  {
    tokenId: 748,
    Tier: "Basic",
  },
  {
    tokenId: 749,
    Tier: "Epic",
  },
  {
    tokenId: 750,
    Tier: "Common",
  },
  {
    tokenId: 751,
    Tier: "Basic",
  },
  {
    tokenId: 752,
    Tier: "Basic",
  },
  {
    tokenId: 753,
    Tier: "Basic",
  },
  {
    tokenId: 754,
    Tier: "Basic",
  },
  {
    tokenId: 755,
    Tier: "Basic",
  },
  {
    tokenId: 756,
    Tier: "Basic",
  },
  {
    tokenId: 757,
    Tier: "Basic",
  },
  {
    tokenId: 758,
    Tier: "Rare",
  },
  {
    tokenId: 759,
    Tier: "Uncommon",
  },
  {
    tokenId: 760,
    Tier: "Basic",
  },
  {
    tokenId: 761,
    Tier: "Uncommon",
  },
  {
    tokenId: 762,
    Tier: "Rare",
  },
  {
    tokenId: 763,
    Tier: "Basic",
  },
  {
    tokenId: 764,
    Tier: "Uncommon",
  },
  {
    tokenId: 765,
    Tier: "Epic",
  },
  {
    tokenId: 766,
    Tier: "Basic",
  },
  {
    tokenId: 767,
    Tier: "Basic",
  },
  {
    tokenId: 768,
    Tier: "Basic",
  },
  {
    tokenId: 769,
    Tier: "Basic",
  },
  {
    tokenId: 770,
    Tier: "Basic",
  },
  {
    tokenId: 771,
    Tier: "Basic",
  },
  {
    tokenId: 772,
    Tier: "Uncommon",
  },
  {
    tokenId: 773,
    Tier: "Basic",
  },
  {
    tokenId: 774,
    Tier: "Basic",
  },
  {
    tokenId: 775,
    Tier: "Basic",
  },
  {
    tokenId: 776,
    Tier: "Rare",
  },
  {
    tokenId: 777,
    Tier: "Basic",
  },
  {
    tokenId: 778,
    Tier: "Basic",
  },
  {
    tokenId: 779,
    Tier: "Uncommon",
  },
  {
    tokenId: 780,
    Tier: "Uncommon",
  },
  {
    tokenId: 781,
    Tier: "Uncommon",
  },
  {
    tokenId: 782,
    Tier: "Basic",
  },
  {
    tokenId: 783,
    Tier: "Rare",
  },
  {
    tokenId: 784,
    Tier: "Common",
  },
  {
    tokenId: 785,
    Tier: "Rare",
  },
  {
    tokenId: 786,
    Tier: "Common",
  },
  {
    tokenId: 787,
    Tier: "Basic",
  },
  {
    tokenId: 788,
    Tier: "Basic",
  },
  {
    tokenId: 789,
    Tier: "Basic",
  },
  {
    tokenId: 790,
    Tier: "Basic",
  },
  {
    tokenId: 791,
    Tier: "Basic",
  },
  {
    tokenId: 792,
    Tier: "Common",
  },
  {
    tokenId: 793,
    Tier: "Uncommon",
  },
  {
    tokenId: 794,
    Tier: "Basic",
  },
  {
    tokenId: 795,
    Tier: "Basic",
  },
  {
    tokenId: 796,
    Tier: "Basic",
  },
  {
    tokenId: 797,
    Tier: "Basic",
  },
  {
    tokenId: 798,
    Tier: "Basic",
  },
  {
    tokenId: 799,
    Tier: "Basic",
  },
  {
    tokenId: 800,
    Tier: "Basic",
  },
  {
    tokenId: 801,
    Tier: "Legendary",
  },
  {
    tokenId: 802,
    Tier: "Basic",
  },
  {
    tokenId: 803,
    Tier: "Basic",
  },
  {
    tokenId: 804,
    Tier: "Basic",
  },
  {
    tokenId: 805,
    Tier: "Basic",
  },
  {
    tokenId: 806,
    Tier: "Basic",
  },
  {
    tokenId: 807,
    Tier: "Uncommon",
  },
  {
    tokenId: 808,
    Tier: "Uncommon",
  },
  {
    tokenId: 809,
    Tier: "Basic",
  },
  {
    tokenId: 810,
    Tier: "Uncommon",
  },
  {
    tokenId: 811,
    Tier: "Uncommon",
  },
  {
    tokenId: 812,
    Tier: "Rare",
  },
  {
    tokenId: 813,
    Tier: "Rare",
  },
  {
    tokenId: 814,
    Tier: "Uncommon",
  },
  {
    tokenId: 815,
    Tier: "Epic",
  },
  {
    tokenId: 816,
    Tier: "Basic",
  },
  {
    tokenId: 817,
    Tier: "Uncommon",
  },
  {
    tokenId: 818,
    Tier: "Basic",
  },
  {
    tokenId: 819,
    Tier: "Basic",
  },
  {
    tokenId: 820,
    Tier: "Rare",
  },
  {
    tokenId: 821,
    Tier: "Basic",
  },
  {
    tokenId: 822,
    Tier: "Basic",
  },
  {
    tokenId: 823,
    Tier: "Epic",
  },
  {
    tokenId: 824,
    Tier: "Common",
  },
  {
    tokenId: 825,
    Tier: "Mythic",
  },
  {
    tokenId: 826,
    Tier: "Rare",
  },
  {
    tokenId: 827,
    Tier: "Basic",
  },
  {
    tokenId: 828,
    Tier: "Epic",
  },
  {
    tokenId: 829,
    Tier: "Legendary",
  },
  {
    tokenId: 830,
    Tier: "Common",
  },
  {
    tokenId: 831,
    Tier: "Rare",
  },
  {
    tokenId: 832,
    Tier: "Basic",
  },
  {
    tokenId: 833,
    Tier: "Legendary",
  },
  {
    tokenId: 834,
    Tier: "Uncommon",
  },
  {
    tokenId: 835,
    Tier: "Rare",
  },
  {
    tokenId: 836,
    Tier: "Basic",
  },
  {
    tokenId: 837,
    Tier: "Basic",
  },
  {
    tokenId: 838,
    Tier: "Uncommon",
  },
  {
    tokenId: 839,
    Tier: "Rare",
  },
  {
    tokenId: 840,
    Tier: "Common",
  },
  {
    tokenId: 841,
    Tier: "Basic",
  },
  {
    tokenId: 842,
    Tier: "Basic",
  },
  {
    tokenId: 843,
    Tier: "Rare",
  },
  {
    tokenId: 844,
    Tier: "Basic",
  },
  {
    tokenId: 845,
    Tier: "Epic",
  },
  {
    tokenId: 846,
    Tier: "Epic",
  },
  {
    tokenId: 847,
    Tier: "Basic",
  },
  {
    tokenId: 848,
    Tier: "Uncommon",
  },
  {
    tokenId: 849,
    Tier: "Basic",
  },
  {
    tokenId: 850,
    Tier: "Legendary",
  },
  {
    tokenId: 851,
    Tier: "Common",
  },
  {
    tokenId: 852,
    Tier: "Basic",
  },
  {
    tokenId: 853,
    Tier: "Basic",
  },
  {
    tokenId: 854,
    Tier: "Basic",
  },
  {
    tokenId: 855,
    Tier: "Basic",
  },
  {
    tokenId: 856,
    Tier: "Exotic",
  },
  {
    tokenId: 857,
    Tier: "Common",
  },
  {
    tokenId: 858,
    Tier: "Basic",
  },
  {
    tokenId: 859,
    Tier: "Basic",
  },
  {
    tokenId: 860,
    Tier: "Common",
  },
  {
    tokenId: 861,
    Tier: "Basic",
  },
  {
    tokenId: 862,
    Tier: "Uncommon",
  },
  {
    tokenId: 863,
    Tier: "Legendary",
  },
  {
    tokenId: 864,
    Tier: "Uncommon",
  },
  {
    tokenId: 865,
    Tier: "Common",
  },
  {
    tokenId: 866,
    Tier: "Basic",
  },
  {
    tokenId: 867,
    Tier: "Basic",
  },
  {
    tokenId: 868,
    Tier: "Uncommon",
  },
  {
    tokenId: 869,
    Tier: "Basic",
  },
  {
    tokenId: 870,
    Tier: "Uncommon",
  },
  {
    tokenId: 871,
    Tier: "Common",
  },
  {
    tokenId: 872,
    Tier: "Basic",
  },
  {
    tokenId: 873,
    Tier: "Basic",
  },
  {
    tokenId: 874,
    Tier: "Basic",
  },
  {
    tokenId: 875,
    Tier: "Common",
  },
  {
    tokenId: 876,
    Tier: "Epic",
  },
  {
    tokenId: 877,
    Tier: "Uncommon",
  },
  {
    tokenId: 878,
    Tier: "Common",
  },
  {
    tokenId: 879,
    Tier: "Rare",
  },
  {
    tokenId: 880,
    Tier: "Mythic",
  },
  {
    tokenId: 881,
    Tier: "Common",
  },
  {
    tokenId: 882,
    Tier: "Common",
  },
  {
    tokenId: 883,
    Tier: "Basic",
  },
  {
    tokenId: 884,
    Tier: "Common",
  },
  {
    tokenId: 885,
    Tier: "Basic",
  },
  {
    tokenId: 886,
    Tier: "Basic",
  },
  {
    tokenId: 887,
    Tier: "Epic",
  },
  {
    tokenId: 888,
    Tier: "Epic",
  },
  {
    tokenId: 889,
    Tier: "Uncommon",
  },
  {
    tokenId: 890,
    Tier: "Basic",
  },
  {
    tokenId: 891,
    Tier: "Rare",
  },
  {
    tokenId: 892,
    Tier: "Epic",
  },
  {
    tokenId: 893,
    Tier: "Basic",
  },
  {
    tokenId: 894,
    Tier: "Legendary",
  },
  {
    tokenId: 895,
    Tier: "Basic",
  },
  {
    tokenId: 896,
    Tier: "Common",
  },
  {
    tokenId: 897,
    Tier: "Mythic",
  },
  {
    tokenId: 898,
    Tier: "Basic",
  },
  {
    tokenId: 899,
    Tier: "Basic",
  },
  {
    tokenId: 900,
    Tier: "Rare",
  },
  {
    tokenId: 901,
    Tier: "Basic",
  },
  {
    tokenId: 902,
    Tier: "Basic",
  },
  {
    tokenId: 903,
    Tier: "Rare",
  },
  {
    tokenId: 904,
    Tier: "Basic",
  },
  {
    tokenId: 905,
    Tier: "Basic",
  },
  {
    tokenId: 906,
    Tier: "Uncommon",
  },
  {
    tokenId: 907,
    Tier: "Epic",
  },
  {
    tokenId: 908,
    Tier: "Legendary",
  },
  {
    tokenId: 909,
    Tier: "Common",
  },
  {
    tokenId: 910,
    Tier: "Basic",
  },
  {
    tokenId: 911,
    Tier: "Basic",
  },
  {
    tokenId: 912,
    Tier: "Basic",
  },
  {
    tokenId: 913,
    Tier: "Epic",
  },
  {
    tokenId: 914,
    Tier: "Basic",
  },
  {
    tokenId: 915,
    Tier: "Basic",
  },
  {
    tokenId: 916,
    Tier: "Common",
  },
  {
    tokenId: 917,
    Tier: "Legendary",
  },
  {
    tokenId: 918,
    Tier: "Legendary",
  },
  {
    tokenId: 919,
    Tier: "Epic",
  },
  {
    tokenId: 920,
    Tier: "Rare",
  },
  {
    tokenId: 921,
    Tier: "Basic",
  },
  {
    tokenId: 922,
    Tier: "Legendary",
  },
  {
    tokenId: 923,
    Tier: "Rare",
  },
  {
    tokenId: 924,
    Tier: "Rare",
  },
  {
    tokenId: 925,
    Tier: "Uncommon",
  },
  {
    tokenId: 926,
    Tier: "Basic",
  },
  {
    tokenId: 927,
    Tier: "Basic",
  },
  {
    tokenId: 928,
    Tier: "Basic",
  },
  {
    tokenId: 929,
    Tier: "Mythic",
  },
  {
    tokenId: 930,
    Tier: "Common",
  },
  {
    tokenId: 931,
    Tier: "Rare",
  },
  {
    tokenId: 932,
    Tier: "Basic",
  },
  {
    tokenId: 933,
    Tier: "Uncommon",
  },
  {
    tokenId: 934,
    Tier: "Basic",
  },
  {
    tokenId: 935,
    Tier: "Uncommon",
  },
  {
    tokenId: 936,
    Tier: "Common",
  },
  {
    tokenId: 937,
    Tier: "Common",
  },
  {
    tokenId: 938,
    Tier: "Common",
  },
  {
    tokenId: 939,
    Tier: "Common",
  },
  {
    tokenId: 940,
    Tier: "Basic",
  },
  {
    tokenId: 941,
    Tier: "Rare",
  },
  {
    tokenId: 942,
    Tier: "Basic",
  },
  {
    tokenId: 943,
    Tier: "Basic",
  },
  {
    tokenId: 944,
    Tier: "Common",
  },
  {
    tokenId: 945,
    Tier: "Basic",
  },
  {
    tokenId: 946,
    Tier: "Basic",
  },
  {
    tokenId: 947,
    Tier: "Basic",
  },
  {
    tokenId: 948,
    Tier: "Legendary",
  },
  {
    tokenId: 949,
    Tier: "Basic",
  },
  {
    tokenId: 950,
    Tier: "Basic",
  },
  {
    tokenId: 951,
    Tier: "Basic",
  },
  {
    tokenId: 952,
    Tier: "Uncommon",
  },
  {
    tokenId: 953,
    Tier: "Uncommon",
  },
  {
    tokenId: 954,
    Tier: "Basic",
  },
  {
    tokenId: 955,
    Tier: "Uncommon",
  },
  {
    tokenId: 956,
    Tier: "Uncommon",
  },
  {
    tokenId: 957,
    Tier: "Basic",
  },
  {
    tokenId: 958,
    Tier: "Common",
  },
  {
    tokenId: 959,
    Tier: "Epic",
  },
  {
    tokenId: 960,
    Tier: "Uncommon",
  },
  {
    tokenId: 961,
    Tier: "Common",
  },
  {
    tokenId: 962,
    Tier: "Basic",
  },
  {
    tokenId: 963,
    Tier: "Basic",
  },
  {
    tokenId: 964,
    Tier: "Basic",
  },
  {
    tokenId: 965,
    Tier: "Basic",
  },
  {
    tokenId: 966,
    Tier: "Epic",
  },
  {
    tokenId: 967,
    Tier: "Uncommon",
  },
  {
    tokenId: 968,
    Tier: "Rare",
  },
  {
    tokenId: 969,
    Tier: "Basic",
  },
  {
    tokenId: 970,
    Tier: "Rare",
  },
  {
    tokenId: 971,
    Tier: "Common",
  },
  {
    tokenId: 972,
    Tier: "Epic",
  },
  {
    tokenId: 973,
    Tier: "Uncommon",
  },
  {
    tokenId: 974,
    Tier: "Basic",
  },
  {
    tokenId: 975,
    Tier: "Basic",
  },
  {
    tokenId: 976,
    Tier: "Rare",
  },
  {
    tokenId: 977,
    Tier: "Common",
  },
  {
    tokenId: 978,
    Tier: "Legendary",
  },
  {
    tokenId: 979,
    Tier: "Basic",
  },
  {
    tokenId: 980,
    Tier: "Common",
  },
  {
    tokenId: 981,
    Tier: "Epic",
  },
  {
    tokenId: 982,
    Tier: "Basic",
  },
  {
    tokenId: 983,
    Tier: "Uncommon",
  },
  {
    tokenId: 984,
    Tier: "Basic",
  },
  {
    tokenId: 985,
    Tier: "Common",
  },
  {
    tokenId: 986,
    Tier: "Basic",
  },
  {
    tokenId: 987,
    Tier: "Basic",
  },
  {
    tokenId: 988,
    Tier: "Basic",
  },
  {
    tokenId: 989,
    Tier: "Basic",
  },
  {
    tokenId: 990,
    Tier: "Basic",
  },
  {
    tokenId: 991,
    Tier: "Epic",
  },
  {
    tokenId: 992,
    Tier: "Rare",
  },
  {
    tokenId: 993,
    Tier: "Basic",
  },
  {
    tokenId: 994,
    Tier: "Exotic",
  },
  {
    tokenId: 995,
    Tier: "Exotic",
  },
  {
    tokenId: 996,
    Tier: "Basic",
  },
  {
    tokenId: 997,
    Tier: "Legendary",
  },
  {
    tokenId: 998,
    Tier: "Common",
  },
  {
    tokenId: 999,
    Tier: "Basic",
  },
  {
    tokenId: 1000,
    Tier: "Legendary",
  },
  {
    tokenId: 1001,
    Tier: "Basic",
  },
  {
    tokenId: 1002,
    Tier: "Common",
  },
  {
    tokenId: 1003,
    Tier: "Uncommon",
  },
  {
    tokenId: 1004,
    Tier: "Basic",
  },
  {
    tokenId: 1005,
    Tier: "Basic",
  },
  {
    tokenId: 1006,
    Tier: "Basic",
  },
  {
    tokenId: 1007,
    Tier: "Basic",
  },
  {
    tokenId: 1008,
    Tier: "Epic",
  },
  {
    tokenId: 1009,
    Tier: "Basic",
  },
  {
    tokenId: 1010,
    Tier: "Basic",
  },
  {
    tokenId: 1011,
    Tier: "Uncommon",
  },
  {
    tokenId: 1012,
    Tier: "Basic",
  },
  {
    tokenId: 1013,
    Tier: "Basic",
  },
  {
    tokenId: 1014,
    Tier: "Uncommon",
  },
  {
    tokenId: 1015,
    Tier: "Basic",
  },
  {
    tokenId: 1016,
    Tier: "Basic",
  },
  {
    tokenId: 1017,
    Tier: "Basic",
  },
  {
    tokenId: 1018,
    Tier: "Uncommon",
  },
  {
    tokenId: 1019,
    Tier: "Mythic",
  },
  {
    tokenId: 1020,
    Tier: "Basic",
  },
  {
    tokenId: 1021,
    Tier: "Basic",
  },
  {
    tokenId: 1022,
    Tier: "Uncommon",
  },
  {
    tokenId: 1023,
    Tier: "Rare",
  },
  {
    tokenId: 1024,
    Tier: "Epic",
  },
  {
    tokenId: 1025,
    Tier: "Basic",
  },
  {
    tokenId: 1026,
    Tier: "Legendary",
  },
  {
    tokenId: 1027,
    Tier: "Uncommon",
  },
  {
    tokenId: 1028,
    Tier: "Uncommon",
  },
  {
    tokenId: 1029,
    Tier: "Basic",
  },
  {
    tokenId: 1030,
    Tier: "Basic",
  },
  {
    tokenId: 1031,
    Tier: "Basic",
  },
  {
    tokenId: 1032,
    Tier: "Basic",
  },
  {
    tokenId: 1033,
    Tier: "Basic",
  },
  {
    tokenId: 1034,
    Tier: "Rare",
  },
  {
    tokenId: 1035,
    Tier: "Common",
  },
  {
    tokenId: 1036,
    Tier: "Common",
  },
  {
    tokenId: 1037,
    Tier: "Basic",
  },
  {
    tokenId: 1038,
    Tier: "Basic",
  },
  {
    tokenId: 1039,
    Tier: "Basic",
  },
  {
    tokenId: 1040,
    Tier: "Common",
  },
  {
    tokenId: 1041,
    Tier: "Uncommon",
  },
  {
    tokenId: 1042,
    Tier: "Uncommon",
  },
  {
    tokenId: 1043,
    Tier: "Basic",
  },
  {
    tokenId: 1044,
    Tier: "Common",
  },
  {
    tokenId: 1045,
    Tier: "Basic",
  },
  {
    tokenId: 1046,
    Tier: "Basic",
  },
  {
    tokenId: 1047,
    Tier: "Rare",
  },
  {
    tokenId: 1048,
    Tier: "Basic",
  },
  {
    tokenId: 1049,
    Tier: "Rare",
  },
  {
    tokenId: 1050,
    Tier: "Epic",
  },
  {
    tokenId: 1051,
    Tier: "Basic",
  },
  {
    tokenId: 1052,
    Tier: "Uncommon",
  },
  {
    tokenId: 1053,
    Tier: "Legendary",
  },
  {
    tokenId: 1054,
    Tier: "Basic",
  },
  {
    tokenId: 1055,
    Tier: "Rare",
  },
  {
    tokenId: 1056,
    Tier: "Basic",
  },
  {
    tokenId: 1057,
    Tier: "Basic",
  },
  {
    tokenId: 1058,
    Tier: "Uncommon",
  },
  {
    tokenId: 1059,
    Tier: "Basic",
  },
  {
    tokenId: 1060,
    Tier: "Basic",
  },
  {
    tokenId: 1061,
    Tier: "Basic",
  },
  {
    tokenId: 1062,
    Tier: "Common",
  },
  {
    tokenId: 1063,
    Tier: "Common",
  },
  {
    tokenId: 1064,
    Tier: "Basic",
  },
  {
    tokenId: 1065,
    Tier: "Basic",
  },
  {
    tokenId: 1066,
    Tier: "Rare",
  },
  {
    tokenId: 1067,
    Tier: "Epic",
  },
  {
    tokenId: 1068,
    Tier: "Basic",
  },
  {
    tokenId: 1069,
    Tier: "Mythic",
  },
  {
    tokenId: 1070,
    Tier: "Uncommon",
  },
  {
    tokenId: 1071,
    Tier: "Basic",
  },
  {
    tokenId: 1072,
    Tier: "Epic",
  },
  {
    tokenId: 1073,
    Tier: "Basic",
  },
  {
    tokenId: 1074,
    Tier: "Basic",
  },
  {
    tokenId: 1075,
    Tier: "Basic",
  },
  {
    tokenId: 1076,
    Tier: "Basic",
  },
  {
    tokenId: 1077,
    Tier: "Basic",
  },
  {
    tokenId: 1078,
    Tier: "Basic",
  },
  {
    tokenId: 1079,
    Tier: "Basic",
  },
  {
    tokenId: 1080,
    Tier: "Uncommon",
  },
  {
    tokenId: 1081,
    Tier: "Basic",
  },
  {
    tokenId: 1082,
    Tier: "Basic",
  },
  {
    tokenId: 1083,
    Tier: "Basic",
  },
  {
    tokenId: 1084,
    Tier: "Epic",
  },
  {
    tokenId: 1085,
    Tier: "Basic",
  },
  {
    tokenId: 1086,
    Tier: "Basic",
  },
  {
    tokenId: 1087,
    Tier: "Basic",
  },
  {
    tokenId: 1088,
    Tier: "Basic",
  },
  {
    tokenId: 1089,
    Tier: "Basic",
  },
  {
    tokenId: 1090,
    Tier: "Common",
  },
  {
    tokenId: 1091,
    Tier: "Basic",
  },
  {
    tokenId: 1092,
    Tier: "Basic",
  },
  {
    tokenId: 1093,
    Tier: "Common",
  },
  {
    tokenId: 1094,
    Tier: "Basic",
  },
  {
    tokenId: 1095,
    Tier: "Mythic",
  },
  {
    tokenId: 1096,
    Tier: "Basic",
  },
  {
    tokenId: 1097,
    Tier: "Basic",
  },
  {
    tokenId: 1098,
    Tier: "Exotic",
  },
  {
    tokenId: 1099,
    Tier: "Uncommon",
  },
  {
    tokenId: 1100,
    Tier: "Basic",
  },
  {
    tokenId: 1101,
    Tier: "Basic",
  },
  {
    tokenId: 1102,
    Tier: "Basic",
  },
  {
    tokenId: 1103,
    Tier: "Mythic",
  },
  {
    tokenId: 1104,
    Tier: "Basic",
  },
  {
    tokenId: 1105,
    Tier: "Basic",
  },
  {
    tokenId: 1106,
    Tier: "Rare",
  },
  {
    tokenId: 1107,
    Tier: "Common",
  },
  {
    tokenId: 1108,
    Tier: "Basic",
  },
  {
    tokenId: 1109,
    Tier: "Basic",
  },
  {
    tokenId: 1110,
    Tier: "Common",
  },
  {
    tokenId: 1111,
    Tier: "Common",
  },
  {
    tokenId: 1112,
    Tier: "Epic",
  },
  {
    tokenId: 1113,
    Tier: "Basic",
  },
  {
    tokenId: 1114,
    Tier: "Basic",
  },
  {
    tokenId: 1115,
    Tier: "Rare",
  },
  {
    tokenId: 1116,
    Tier: "Basic",
  },
  {
    tokenId: 1117,
    Tier: "Basic",
  },
  {
    tokenId: 1118,
    Tier: "Common",
  },
  {
    tokenId: 1119,
    Tier: "Common",
  },
  {
    tokenId: 1120,
    Tier: "Basic",
  },
  {
    tokenId: 1121,
    Tier: "Basic",
  },
  {
    tokenId: 1122,
    Tier: "Basic",
  },
  {
    tokenId: 1123,
    Tier: "Basic",
  },
  {
    tokenId: 1124,
    Tier: "Basic",
  },
  {
    tokenId: 1125,
    Tier: "Common",
  },
  {
    tokenId: 1126,
    Tier: "Basic",
  },
  {
    tokenId: 1127,
    Tier: "Basic",
  },
  {
    tokenId: 1128,
    Tier: "Basic",
  },
  {
    tokenId: 1129,
    Tier: "Epic",
  },
  {
    tokenId: 1130,
    Tier: "Basic",
  },
  {
    tokenId: 1131,
    Tier: "Basic",
  },
  {
    tokenId: 1132,
    Tier: "Basic",
  },
  {
    tokenId: 1133,
    Tier: "Basic",
  },
  {
    tokenId: 1134,
    Tier: "Basic",
  },
  {
    tokenId: 1135,
    Tier: "Basic",
  },
  {
    tokenId: 1136,
    Tier: "Basic",
  },
  {
    tokenId: 1137,
    Tier: "Basic",
  },
  {
    tokenId: 1138,
    Tier: "Basic",
  },
  {
    tokenId: 1139,
    Tier: "Epic",
  },
  {
    tokenId: 1140,
    Tier: "Epic",
  },
  {
    tokenId: 1141,
    Tier: "Common",
  },
  {
    tokenId: 1142,
    Tier: "Basic",
  },
  {
    tokenId: 1143,
    Tier: "Uncommon",
  },
  {
    tokenId: 1144,
    Tier: "Common",
  },
  {
    tokenId: 1145,
    Tier: "Basic",
  },
  {
    tokenId: 1146,
    Tier: "Basic",
  },
  {
    tokenId: 1147,
    Tier: "Epic",
  },
  {
    tokenId: 1148,
    Tier: "Epic",
  },
  {
    tokenId: 1149,
    Tier: "Uncommon",
  },
  {
    tokenId: 1150,
    Tier: "Mythic",
  },
  {
    tokenId: 1151,
    Tier: "Common",
  },
  {
    tokenId: 1152,
    Tier: "Epic",
  },
  {
    tokenId: 1153,
    Tier: "Rare",
  },
  {
    tokenId: 1154,
    Tier: "Basic",
  },
  {
    tokenId: 1155,
    Tier: "Basic",
  },
  {
    tokenId: 1156,
    Tier: "Basic",
  },
  {
    tokenId: 1157,
    Tier: "Common",
  },
  {
    tokenId: 1158,
    Tier: "Basic",
  },
  {
    tokenId: 1159,
    Tier: "Basic",
  },
  {
    tokenId: 1160,
    Tier: "Basic",
  },
  {
    tokenId: 1161,
    Tier: "Basic",
  },
  {
    tokenId: 1162,
    Tier: "Common",
  },
  {
    tokenId: 1163,
    Tier: "Basic",
  },
  {
    tokenId: 1164,
    Tier: "Legendary",
  },
  {
    tokenId: 1165,
    Tier: "Basic",
  },
  {
    tokenId: 1166,
    Tier: "Basic",
  },
  {
    tokenId: 1167,
    Tier: "Legendary",
  },
  {
    tokenId: 1168,
    Tier: "Basic",
  },
  {
    tokenId: 1169,
    Tier: "Basic",
  },
  {
    tokenId: 1170,
    Tier: "Basic",
  },
  {
    tokenId: 1171,
    Tier: "Legendary",
  },
  {
    tokenId: 1172,
    Tier: "Basic",
  },
  {
    tokenId: 1173,
    Tier: "Uncommon",
  },
  {
    tokenId: 1174,
    Tier: "Basic",
  },
  {
    tokenId: 1175,
    Tier: "Uncommon",
  },
  {
    tokenId: 1176,
    Tier: "Legendary",
  },
  {
    tokenId: 1177,
    Tier: "Common",
  },
  {
    tokenId: 1178,
    Tier: "Rare",
  },
  {
    tokenId: 1179,
    Tier: "Uncommon",
  },
  {
    tokenId: 1180,
    Tier: "Basic",
  },
  {
    tokenId: 1181,
    Tier: "Basic",
  },
  {
    tokenId: 1182,
    Tier: "Basic",
  },
  {
    tokenId: 1183,
    Tier: "Basic",
  },
  {
    tokenId: 1184,
    Tier: "Uncommon",
  },
  {
    tokenId: 1185,
    Tier: "Basic",
  },
  {
    tokenId: 1186,
    Tier: "Basic",
  },
  {
    tokenId: 1187,
    Tier: "Basic",
  },
  {
    tokenId: 1188,
    Tier: "Basic",
  },
  {
    tokenId: 1189,
    Tier: "Common",
  },
  {
    tokenId: 1190,
    Tier: "Basic",
  },
  {
    tokenId: 1191,
    Tier: "Basic",
  },
  {
    tokenId: 1192,
    Tier: "Basic",
  },
  {
    tokenId: 1193,
    Tier: "Rare",
  },
  {
    tokenId: 1194,
    Tier: "Basic",
  },
  {
    tokenId: 1195,
    Tier: "Epic",
  },
  {
    tokenId: 1196,
    Tier: "Basic",
  },
  {
    tokenId: 1197,
    Tier: "Basic",
  },
  {
    tokenId: 1198,
    Tier: "Basic",
  },
  {
    tokenId: 1199,
    Tier: "Basic",
  },
  {
    tokenId: 1200,
    Tier: "Basic",
  },
  {
    tokenId: 1201,
    Tier: "Basic",
  },
  {
    tokenId: 1202,
    Tier: "Basic",
  },
  {
    tokenId: 1203,
    Tier: "Basic",
  },
  {
    tokenId: 1204,
    Tier: "Basic",
  },
  {
    tokenId: 1205,
    Tier: "Basic",
  },
  {
    tokenId: 1206,
    Tier: "Basic",
  },
  {
    tokenId: 1207,
    Tier: "Basic",
  },
  {
    tokenId: 1208,
    Tier: "Basic",
  },
  {
    tokenId: 1209,
    Tier: "Common",
  },
  {
    tokenId: 1210,
    Tier: "Uncommon",
  },
  {
    tokenId: 1211,
    Tier: "Basic",
  },
  {
    tokenId: 1212,
    Tier: "Common",
  },
  {
    tokenId: 1213,
    Tier: "Uncommon",
  },
  {
    tokenId: 1214,
    Tier: "Basic",
  },
  {
    tokenId: 1215,
    Tier: "Legendary",
  },
  {
    tokenId: 1216,
    Tier: "Rare",
  },
  {
    tokenId: 1217,
    Tier: "Basic",
  },
  {
    tokenId: 1218,
    Tier: "Basic",
  },
  {
    tokenId: 1219,
    Tier: "Basic",
  },
  {
    tokenId: 1220,
    Tier: "Mythic",
  },
  {
    tokenId: 1221,
    Tier: "Uncommon",
  },
  {
    tokenId: 1222,
    Tier: "Common",
  },
  {
    tokenId: 1223,
    Tier: "Basic",
  },
  {
    tokenId: 1224,
    Tier: "Rare",
  },
  {
    tokenId: 1225,
    Tier: "Basic",
  },
  {
    tokenId: 1226,
    Tier: "Uncommon",
  },
  {
    tokenId: 1227,
    Tier: "Basic",
  },
  {
    tokenId: 1228,
    Tier: "Common",
  },
  {
    tokenId: 1229,
    Tier: "Basic",
  },
  {
    tokenId: 1230,
    Tier: "Basic",
  },
  {
    tokenId: 1231,
    Tier: "Basic",
  },
  {
    tokenId: 1232,
    Tier: "Basic",
  },
  {
    tokenId: 1233,
    Tier: "Basic",
  },
  {
    tokenId: 1234,
    Tier: "Basic",
  },
  {
    tokenId: 1235,
    Tier: "Basic",
  },
  {
    tokenId: 1236,
    Tier: "Basic",
  },
  {
    tokenId: 1237,
    Tier: "Rare",
  },
  {
    tokenId: 1238,
    Tier: "Uncommon",
  },
  {
    tokenId: 1239,
    Tier: "Common",
  },
  {
    tokenId: 1240,
    Tier: "Basic",
  },
  {
    tokenId: 1241,
    Tier: "Basic",
  },
  {
    tokenId: 1242,
    Tier: "Uncommon",
  },
  {
    tokenId: 1243,
    Tier: "Basic",
  },
  {
    tokenId: 1244,
    Tier: "Basic",
  },
  {
    tokenId: 1245,
    Tier: "Uncommon",
  },
  {
    tokenId: 1246,
    Tier: "Basic",
  },
  {
    tokenId: 1247,
    Tier: "Common",
  },
  {
    tokenId: 1248,
    Tier: "Rare",
  },
  {
    tokenId: 1249,
    Tier: "Rare",
  },
  {
    tokenId: 1250,
    Tier: "Legendary",
  },
  {
    tokenId: 1251,
    Tier: "Basic",
  },
  {
    tokenId: 1252,
    Tier: "Epic",
  },
  {
    tokenId: 1253,
    Tier: "Legendary",
  },
  {
    tokenId: 1254,
    Tier: "Legendary",
  },
  {
    tokenId: 1255,
    Tier: "Mythic",
  },
  {
    tokenId: 1256,
    Tier: "Basic",
  },
  {
    tokenId: 1257,
    Tier: "Basic",
  },
  {
    tokenId: 1258,
    Tier: "Basic",
  },
  {
    tokenId: 1259,
    Tier: "Mythic",
  },
  {
    tokenId: 1260,
    Tier: "Rare",
  },
  {
    tokenId: 1261,
    Tier: "Basic",
  },
  {
    tokenId: 1262,
    Tier: "Mythic",
  },
  {
    tokenId: 1263,
    Tier: "Mythic",
  },
  {
    tokenId: 1264,
    Tier: "Common",
  },
  {
    tokenId: 1265,
    Tier: "Basic",
  },
  {
    tokenId: 1266,
    Tier: "Rare",
  },
  {
    tokenId: 1267,
    Tier: "Rare",
  },
  {
    tokenId: 1268,
    Tier: "Basic",
  },
  {
    tokenId: 1269,
    Tier: "Basic",
  },
  {
    tokenId: 1270,
    Tier: "Basic",
  },
  {
    tokenId: 1271,
    Tier: "Epic",
  },
  {
    tokenId: 1272,
    Tier: "Basic",
  },
  {
    tokenId: 1273,
    Tier: "Basic",
  },
  {
    tokenId: 1274,
    Tier: "Epic",
  },
  {
    tokenId: 1275,
    Tier: "Basic",
  },
  {
    tokenId: 1276,
    Tier: "Basic",
  },
  {
    tokenId: 1277,
    Tier: "Common",
  },
  {
    tokenId: 1278,
    Tier: "Basic",
  },
  {
    tokenId: 1279,
    Tier: "Rare",
  },
  {
    tokenId: 1280,
    Tier: "Basic",
  },
  {
    tokenId: 1281,
    Tier: "Epic",
  },
  {
    tokenId: 1282,
    Tier: "Basic",
  },
  {
    tokenId: 1283,
    Tier: "Rare",
  },
  {
    tokenId: 1284,
    Tier: "Basic",
  },
  {
    tokenId: 1285,
    Tier: "Epic",
  },
  {
    tokenId: 1286,
    Tier: "Basic",
  },
  {
    tokenId: 1287,
    Tier: "Common",
  },
  {
    tokenId: 1288,
    Tier: "Basic",
  },
  {
    tokenId: 1289,
    Tier: "Common",
  },
  {
    tokenId: 1290,
    Tier: "Basic",
  },
  {
    tokenId: 1291,
    Tier: "Rare",
  },
  {
    tokenId: 1292,
    Tier: "Legendary",
  },
  {
    tokenId: 1293,
    Tier: "Basic",
  },
  {
    tokenId: 1294,
    Tier: "Rare",
  },
  {
    tokenId: 1295,
    Tier: "Basic",
  },
  {
    tokenId: 1296,
    Tier: "Common",
  },
  {
    tokenId: 1297,
    Tier: "Rare",
  },
  {
    tokenId: 1298,
    Tier: "Basic",
  },
  {
    tokenId: 1299,
    Tier: "Common",
  },
  {
    tokenId: 1300,
    Tier: "Common",
  },
  {
    tokenId: 1301,
    Tier: "Rare",
  },
  {
    tokenId: 1302,
    Tier: "Basic",
  },
  {
    tokenId: 1303,
    Tier: "Basic",
  },
  {
    tokenId: 1304,
    Tier: "Common",
  },
  {
    tokenId: 1305,
    Tier: "Common",
  },
  {
    tokenId: 1306,
    Tier: "Basic",
  },
  {
    tokenId: 1307,
    Tier: "Common",
  },
  {
    tokenId: 1308,
    Tier: "Common",
  },
  {
    tokenId: 1309,
    Tier: "Legendary",
  },
  {
    tokenId: 1310,
    Tier: "Mythic",
  },
  {
    tokenId: 1311,
    Tier: "Epic",
  },
  {
    tokenId: 1312,
    Tier: "Basic",
  },
  {
    tokenId: 1313,
    Tier: "Rare",
  },
  {
    tokenId: 1314,
    Tier: "Common",
  },
  {
    tokenId: 1315,
    Tier: "Common",
  },
  {
    tokenId: 1316,
    Tier: "Legendary",
  },
  {
    tokenId: 1317,
    Tier: "Basic",
  },
  {
    tokenId: 1318,
    Tier: "Uncommon",
  },
  {
    tokenId: 1319,
    Tier: "Basic",
  },
  {
    tokenId: 1320,
    Tier: "Epic",
  },
  {
    tokenId: 1321,
    Tier: "Basic",
  },
  {
    tokenId: 1322,
    Tier: "Uncommon",
  },
  {
    tokenId: 1323,
    Tier: "Common",
  },
  {
    tokenId: 1324,
    Tier: "Basic",
  },
  {
    tokenId: 1325,
    Tier: "Basic",
  },
  {
    tokenId: 1326,
    Tier: "Basic",
  },
  {
    tokenId: 1327,
    Tier: "Basic",
  },
  {
    tokenId: 1328,
    Tier: "Rare",
  },
  {
    tokenId: 1329,
    Tier: "Basic",
  },
  {
    tokenId: 1330,
    Tier: "Basic",
  },
  {
    tokenId: 1331,
    Tier: "Basic",
  },
  {
    tokenId: 1332,
    Tier: "Common",
  },
  {
    tokenId: 1333,
    Tier: "Common",
  },
  {
    tokenId: 1334,
    Tier: "Basic",
  },
  {
    tokenId: 1335,
    Tier: "Basic",
  },
  {
    tokenId: 1336,
    Tier: "Basic",
  },
  {
    tokenId: 1337,
    Tier: "Basic",
  },
  {
    tokenId: 1338,
    Tier: "Common",
  },
  {
    tokenId: 1339,
    Tier: "Basic",
  },
  {
    tokenId: 1340,
    Tier: "Basic",
  },
  {
    tokenId: 1341,
    Tier: "Epic",
  },
  {
    tokenId: 1342,
    Tier: "Basic",
  },
  {
    tokenId: 1343,
    Tier: "Common",
  },
  {
    tokenId: 1344,
    Tier: "Common",
  },
  {
    tokenId: 1345,
    Tier: "Basic",
  },
  {
    tokenId: 1346,
    Tier: "Uncommon",
  },
  {
    tokenId: 1347,
    Tier: "Rare",
  },
  {
    tokenId: 1348,
    Tier: "Exotic",
  },
  {
    tokenId: 1349,
    Tier: "Basic",
  },
  {
    tokenId: 1350,
    Tier: "Basic",
  },
  {
    tokenId: 1351,
    Tier: "Basic",
  },
  {
    tokenId: 1352,
    Tier: "Basic",
  },
  {
    tokenId: 1353,
    Tier: "Epic",
  },
  {
    tokenId: 1354,
    Tier: "Basic",
  },
  {
    tokenId: 1355,
    Tier: "Basic",
  },
  {
    tokenId: 1356,
    Tier: "Basic",
  },
  {
    tokenId: 1357,
    Tier: "Uncommon",
  },
  {
    tokenId: 1358,
    Tier: "Basic",
  },
  {
    tokenId: 1359,
    Tier: "Uncommon",
  },
  {
    tokenId: 1360,
    Tier: "Common",
  },
  {
    tokenId: 1361,
    Tier: "Rare",
  },
  {
    tokenId: 1362,
    Tier: "Basic",
  },
  {
    tokenId: 1363,
    Tier: "Uncommon",
  },
  {
    tokenId: 1364,
    Tier: "Basic",
  },
  {
    tokenId: 1365,
    Tier: "Common",
  },
  {
    tokenId: 1366,
    Tier: "Uncommon",
  },
  {
    tokenId: 1367,
    Tier: "Basic",
  },
  {
    tokenId: 1368,
    Tier: "Basic",
  },
  {
    tokenId: 1369,
    Tier: "Basic",
  },
  {
    tokenId: 1370,
    Tier: "Basic",
  },
  {
    tokenId: 1371,
    Tier: "Basic",
  },
  {
    tokenId: 1372,
    Tier: "Uncommon",
  },
  {
    tokenId: 1373,
    Tier: "Common",
  },
  {
    tokenId: 1374,
    Tier: "Common",
  },
  {
    tokenId: 1375,
    Tier: "Uncommon",
  },
  {
    tokenId: 1376,
    Tier: "Uncommon",
  },
  {
    tokenId: 1377,
    Tier: "Basic",
  },
  {
    tokenId: 1378,
    Tier: "Basic",
  },
  {
    tokenId: 1379,
    Tier: "Basic",
  },
  {
    tokenId: 1380,
    Tier: "Uncommon",
  },
  {
    tokenId: 1381,
    Tier: "Common",
  },
  {
    tokenId: 1382,
    Tier: "Basic",
  },
  {
    tokenId: 1383,
    Tier: "Basic",
  },
  {
    tokenId: 1384,
    Tier: "Common",
  },
  {
    tokenId: 1385,
    Tier: "Basic",
  },
  {
    tokenId: 1386,
    Tier: "Rare",
  },
  {
    tokenId: 1387,
    Tier: "Basic",
  },
  {
    tokenId: 1388,
    Tier: "Basic",
  },
  {
    tokenId: 1389,
    Tier: "Common",
  },
  {
    tokenId: 1390,
    Tier: "Basic",
  },
  {
    tokenId: 1391,
    Tier: "Basic",
  },
  {
    tokenId: 1392,
    Tier: "Legendary",
  },
  {
    tokenId: 1393,
    Tier: "Common",
  },
  {
    tokenId: 1394,
    Tier: "Basic",
  },
  {
    tokenId: 1395,
    Tier: "Basic",
  },
  {
    tokenId: 1396,
    Tier: "Common",
  },
  {
    tokenId: 1397,
    Tier: "Legendary",
  },
  {
    tokenId: 1398,
    Tier: "Basic",
  },
  {
    tokenId: 1399,
    Tier: "Legendary",
  },
  {
    tokenId: 1400,
    Tier: "Basic",
  },
  {
    tokenId: 1401,
    Tier: "Basic",
  },
  {
    tokenId: 1402,
    Tier: "Basic",
  },
  {
    tokenId: 1403,
    Tier: "Basic",
  },
  {
    tokenId: 1404,
    Tier: "Basic",
  },
  {
    tokenId: 1405,
    Tier: "Basic",
  },
  {
    tokenId: 1406,
    Tier: "Basic",
  },
  {
    tokenId: 1407,
    Tier: "Basic",
  },
  {
    tokenId: 1408,
    Tier: "Rare",
  },
  {
    tokenId: 1409,
    Tier: "Basic",
  },
  {
    tokenId: 1410,
    Tier: "Uncommon",
  },
  {
    tokenId: 1411,
    Tier: "Common",
  },
  {
    tokenId: 1412,
    Tier: "Rare",
  },
  {
    tokenId: 1413,
    Tier: "Basic",
  },
  {
    tokenId: 1414,
    Tier: "Basic",
  },
  {
    tokenId: 1415,
    Tier: "Basic",
  },
  {
    tokenId: 1416,
    Tier: "Uncommon",
  },
  {
    tokenId: 1417,
    Tier: "Epic",
  },
  {
    tokenId: 1418,
    Tier: "Basic",
  },
  {
    tokenId: 1419,
    Tier: "Basic",
  },
  {
    tokenId: 1420,
    Tier: "Common",
  },
  {
    tokenId: 1421,
    Tier: "Epic",
  },
  {
    tokenId: 1422,
    Tier: "Basic",
  },
  {
    tokenId: 1423,
    Tier: "Basic",
  },
  {
    tokenId: 1424,
    Tier: "Basic",
  },
  {
    tokenId: 1425,
    Tier: "Basic",
  },
  {
    tokenId: 1426,
    Tier: "Common",
  },
  {
    tokenId: 1427,
    Tier: "Epic",
  },
  {
    tokenId: 1428,
    Tier: "Basic",
  },
  {
    tokenId: 1429,
    Tier: "Basic",
  },
  {
    tokenId: 1430,
    Tier: "Basic",
  },
  {
    tokenId: 1431,
    Tier: "Basic",
  },
  {
    tokenId: 1432,
    Tier: "Uncommon",
  },
  {
    tokenId: 1433,
    Tier: "Mythic",
  },
  {
    tokenId: 1434,
    Tier: "Uncommon",
  },
  {
    tokenId: 1435,
    Tier: "Basic",
  },
  {
    tokenId: 1436,
    Tier: "Basic",
  },
  {
    tokenId: 1437,
    Tier: "Basic",
  },
  {
    tokenId: 1438,
    Tier: "Basic",
  },
  {
    tokenId: 1439,
    Tier: "Basic",
  },
  {
    tokenId: 1440,
    Tier: "Basic",
  },
  {
    tokenId: 1441,
    Tier: "Rare",
  },
  {
    tokenId: 1442,
    Tier: "Common",
  },
  {
    tokenId: 1443,
    Tier: "Basic",
  },
  {
    tokenId: 1444,
    Tier: "Basic",
  },
  {
    tokenId: 1445,
    Tier: "Basic",
  },
  {
    tokenId: 1446,
    Tier: "Basic",
  },
  {
    tokenId: 1447,
    Tier: "Uncommon",
  },
  {
    tokenId: 1448,
    Tier: "Epic",
  },
  {
    tokenId: 1449,
    Tier: "Basic",
  },
  {
    tokenId: 1450,
    Tier: "Basic",
  },
  {
    tokenId: 1451,
    Tier: "Basic",
  },
  {
    tokenId: 1452,
    Tier: "Legendary",
  },
  {
    tokenId: 1453,
    Tier: "Common",
  },
  {
    tokenId: 1454,
    Tier: "Uncommon",
  },
  {
    tokenId: 1455,
    Tier: "Basic",
  },
  {
    tokenId: 1456,
    Tier: "Basic",
  },
  {
    tokenId: 1457,
    Tier: "Epic",
  },
  {
    tokenId: 1458,
    Tier: "Uncommon",
  },
  {
    tokenId: 1459,
    Tier: "Basic",
  },
  {
    tokenId: 1460,
    Tier: "Basic",
  },
  {
    tokenId: 1461,
    Tier: "Basic",
  },
  {
    tokenId: 1462,
    Tier: "Basic",
  },
  {
    tokenId: 1463,
    Tier: "Mythic",
  },
  {
    tokenId: 1464,
    Tier: "Basic",
  },
  {
    tokenId: 1465,
    Tier: "Epic",
  },
  {
    tokenId: 1466,
    Tier: "Common",
  },
  {
    tokenId: 1467,
    Tier: "Basic",
  },
  {
    tokenId: 1468,
    Tier: "Legendary",
  },
  {
    tokenId: 1469,
    Tier: "Basic",
  },
  {
    tokenId: 1470,
    Tier: "Basic",
  },
  {
    tokenId: 1471,
    Tier: "Basic",
  },
  {
    tokenId: 1472,
    Tier: "Basic",
  },
  {
    tokenId: 1473,
    Tier: "Basic",
  },
  {
    tokenId: 1474,
    Tier: "Legendary",
  },
  {
    tokenId: 1475,
    Tier: "Basic",
  },
  {
    tokenId: 1476,
    Tier: "Basic",
  },
  {
    tokenId: 1477,
    Tier: "Basic",
  },
  {
    tokenId: 1478,
    Tier: "Legendary",
  },
  {
    tokenId: 1479,
    Tier: "Epic",
  },
  {
    tokenId: 1480,
    Tier: "Common",
  },
  {
    tokenId: 1481,
    Tier: "Legendary",
  },
  {
    tokenId: 1482,
    Tier: "Uncommon",
  },
  {
    tokenId: 1483,
    Tier: "Basic",
  },
  {
    tokenId: 1484,
    Tier: "Basic",
  },
  {
    tokenId: 1485,
    Tier: "Basic",
  },
  {
    tokenId: 1486,
    Tier: "Basic",
  },
  {
    tokenId: 1487,
    Tier: "Basic",
  },
  {
    tokenId: 1488,
    Tier: "Common",
  },
  {
    tokenId: 1489,
    Tier: "Basic",
  },
  {
    tokenId: 1490,
    Tier: "Basic",
  },
  {
    tokenId: 1491,
    Tier: "Rare",
  },
  {
    tokenId: 1492,
    Tier: "Basic",
  },
  {
    tokenId: 1493,
    Tier: "Rare",
  },
  {
    tokenId: 1494,
    Tier: "Basic",
  },
  {
    tokenId: 1495,
    Tier: "Basic",
  },
  {
    tokenId: 1496,
    Tier: "Basic",
  },
  {
    tokenId: 1497,
    Tier: "Basic",
  },
  {
    tokenId: 1498,
    Tier: "Common",
  },
  {
    tokenId: 1499,
    Tier: "Basic",
  },
  {
    tokenId: 1500,
    Tier: "Basic",
  },
  {
    tokenId: 1501,
    Tier: "Uncommon",
  },
  {
    tokenId: 1502,
    Tier: "Common",
  },
  {
    tokenId: 1503,
    Tier: "Epic",
  },
  {
    tokenId: 1504,
    Tier: "Mythic",
  },
  {
    tokenId: 1505,
    Tier: "Rare",
  },
  {
    tokenId: 1506,
    Tier: "Common",
  },
  {
    tokenId: 1507,
    Tier: "Rare",
  },
  {
    tokenId: 1508,
    Tier: "Basic",
  },
  {
    tokenId: 1509,
    Tier: "Legendary",
  },
  {
    tokenId: 1510,
    Tier: "Basic",
  },
  {
    tokenId: 1511,
    Tier: "Common",
  },
  {
    tokenId: 1512,
    Tier: "Mythic",
  },
  {
    tokenId: 1513,
    Tier: "Basic",
  },
  {
    tokenId: 1514,
    Tier: "Basic",
  },
  {
    tokenId: 1515,
    Tier: "Legendary",
  },
  {
    tokenId: 1516,
    Tier: "Uncommon",
  },
  {
    tokenId: 1517,
    Tier: "Epic",
  },
  {
    tokenId: 1518,
    Tier: "Epic",
  },
  {
    tokenId: 1519,
    Tier: "Common",
  },
  {
    tokenId: 1520,
    Tier: "Basic",
  },
  {
    tokenId: 1521,
    Tier: "Basic",
  },
  {
    tokenId: 1522,
    Tier: "Uncommon",
  },
  {
    tokenId: 1523,
    Tier: "Common",
  },
  {
    tokenId: 1524,
    Tier: "Mythic",
  },
  {
    tokenId: 1525,
    Tier: "Basic",
  },
  {
    tokenId: 1526,
    Tier: "Basic",
  },
  {
    tokenId: 1527,
    Tier: "Basic",
  },
  {
    tokenId: 1528,
    Tier: "Basic",
  },
  {
    tokenId: 1529,
    Tier: "Uncommon",
  },
  {
    tokenId: 1530,
    Tier: "Basic",
  },
  {
    tokenId: 1531,
    Tier: "Basic",
  },
  {
    tokenId: 1532,
    Tier: "Uncommon",
  },
  {
    tokenId: 1533,
    Tier: "Rare",
  },
  {
    tokenId: 1534,
    Tier: "Basic",
  },
  {
    tokenId: 1535,
    Tier: "Common",
  },
  {
    tokenId: 1536,
    Tier: "Basic",
  },
  {
    tokenId: 1537,
    Tier: "Basic",
  },
  {
    tokenId: 1538,
    Tier: "Mythic",
  },
  {
    tokenId: 1539,
    Tier: "Uncommon",
  },
  {
    tokenId: 1540,
    Tier: "Basic",
  },
  {
    tokenId: 1541,
    Tier: "Basic",
  },
  {
    tokenId: 1542,
    Tier: "Basic",
  },
  {
    tokenId: 1543,
    Tier: "Basic",
  },
  {
    tokenId: 1544,
    Tier: "Mythic",
  },
  {
    tokenId: 1545,
    Tier: "Rare",
  },
  {
    tokenId: 1546,
    Tier: "Uncommon",
  },
  {
    tokenId: 1547,
    Tier: "Basic",
  },
  {
    tokenId: 1548,
    Tier: "Basic",
  },
  {
    tokenId: 1549,
    Tier: "Basic",
  },
  {
    tokenId: 1550,
    Tier: "Legendary",
  },
  {
    tokenId: 1551,
    Tier: "Uncommon",
  },
  {
    tokenId: 1552,
    Tier: "Basic",
  },
  {
    tokenId: 1553,
    Tier: "Common",
  },
  {
    tokenId: 1554,
    Tier: "Basic",
  },
  {
    tokenId: 1555,
    Tier: "Rare",
  },
  {
    tokenId: 1556,
    Tier: "Basic",
  },
  {
    tokenId: 1557,
    Tier: "Basic",
  },
  {
    tokenId: 1558,
    Tier: "Basic",
  },
  {
    tokenId: 1559,
    Tier: "Basic",
  },
  {
    tokenId: 1560,
    Tier: "Basic",
  },
  {
    tokenId: 1561,
    Tier: "Legendary",
  },
  {
    tokenId: 1562,
    Tier: "Uncommon",
  },
  {
    tokenId: 1563,
    Tier: "Epic",
  },
  {
    tokenId: 1564,
    Tier: "Epic",
  },
  {
    tokenId: 1565,
    Tier: "Basic",
  },
  {
    tokenId: 1566,
    Tier: "Basic",
  },
  {
    tokenId: 1567,
    Tier: "Basic",
  },
  {
    tokenId: 1568,
    Tier: "Basic",
  },
  {
    tokenId: 1569,
    Tier: "Basic",
  },
  {
    tokenId: 1570,
    Tier: "Basic",
  },
  {
    tokenId: 1571,
    Tier: "Common",
  },
  {
    tokenId: 1572,
    Tier: "Epic",
  },
  {
    tokenId: 1573,
    Tier: "Basic",
  },
  {
    tokenId: 1574,
    Tier: "Basic",
  },
  {
    tokenId: 1575,
    Tier: "Uncommon",
  },
  {
    tokenId: 1576,
    Tier: "Basic",
  },
  {
    tokenId: 1577,
    Tier: "Rare",
  },
  {
    tokenId: 1578,
    Tier: "Basic",
  },
  {
    tokenId: 1579,
    Tier: "Basic",
  },
  {
    tokenId: 1580,
    Tier: "Rare",
  },
  {
    tokenId: 1581,
    Tier: "Common",
  },
  {
    tokenId: 1582,
    Tier: "Epic",
  },
  {
    tokenId: 1583,
    Tier: "Exotic",
  },
  {
    tokenId: 1584,
    Tier: "Basic",
  },
  {
    tokenId: 1585,
    Tier: "Common",
  },
  {
    tokenId: 1586,
    Tier: "Common",
  },
  {
    tokenId: 1587,
    Tier: "Common",
  },
  {
    tokenId: 1588,
    Tier: "Basic",
  },
  {
    tokenId: 1589,
    Tier: "Basic",
  },
  {
    tokenId: 1590,
    Tier: "Basic",
  },
  {
    tokenId: 1591,
    Tier: "Basic",
  },
  {
    tokenId: 1592,
    Tier: "Basic",
  },
  {
    tokenId: 1593,
    Tier: "Basic",
  },
  {
    tokenId: 1594,
    Tier: "Uncommon",
  },
  {
    tokenId: 1595,
    Tier: "Basic",
  },
  {
    tokenId: 1596,
    Tier: "Basic",
  },
  {
    tokenId: 1597,
    Tier: "Basic",
  },
  {
    tokenId: 1598,
    Tier: "Basic",
  },
  {
    tokenId: 1599,
    Tier: "Common",
  },
  {
    tokenId: 1600,
    Tier: "Legendary",
  },
  {
    tokenId: 1601,
    Tier: "Basic",
  },
  {
    tokenId: 1602,
    Tier: "Uncommon",
  },
  {
    tokenId: 1603,
    Tier: "Basic",
  },
  {
    tokenId: 1604,
    Tier: "Legendary",
  },
  {
    tokenId: 1605,
    Tier: "Mythic",
  },
  {
    tokenId: 1606,
    Tier: "Rare",
  },
  {
    tokenId: 1607,
    Tier: "Basic",
  },
  {
    tokenId: 1608,
    Tier: "Common",
  },
  {
    tokenId: 1609,
    Tier: "Basic",
  },
  {
    tokenId: 1610,
    Tier: "Common",
  },
  {
    tokenId: 1611,
    Tier: "Basic",
  },
  {
    tokenId: 1612,
    Tier: "Basic",
  },
  {
    tokenId: 1613,
    Tier: "Uncommon",
  },
  {
    tokenId: 1614,
    Tier: "Rare",
  },
  {
    tokenId: 1615,
    Tier: "Basic",
  },
  {
    tokenId: 1616,
    Tier: "Basic",
  },
  {
    tokenId: 1617,
    Tier: "Basic",
  },
  {
    tokenId: 1618,
    Tier: "Rare",
  },
  {
    tokenId: 1619,
    Tier: "Basic",
  },
  {
    tokenId: 1620,
    Tier: "Basic",
  },
  {
    tokenId: 1621,
    Tier: "Epic",
  },
  {
    tokenId: 1622,
    Tier: "Basic",
  },
  {
    tokenId: 1623,
    Tier: "Mythic",
  },
  {
    tokenId: 1624,
    Tier: "Uncommon",
  },
  {
    tokenId: 1625,
    Tier: "Basic",
  },
  {
    tokenId: 1626,
    Tier: "Basic",
  },
  {
    tokenId: 1627,
    Tier: "Uncommon",
  },
  {
    tokenId: 1628,
    Tier: "Basic",
  },
  {
    tokenId: 1629,
    Tier: "Epic",
  },
  {
    tokenId: 1630,
    Tier: "Basic",
  },
  {
    tokenId: 1631,
    Tier: "Uncommon",
  },
  {
    tokenId: 1632,
    Tier: "Epic",
  },
  {
    tokenId: 1633,
    Tier: "Basic",
  },
  {
    tokenId: 1634,
    Tier: "Basic",
  },
  {
    tokenId: 1635,
    Tier: "Basic",
  },
  {
    tokenId: 1636,
    Tier: "Basic",
  },
  {
    tokenId: 1637,
    Tier: "Basic",
  },
  {
    tokenId: 1638,
    Tier: "Basic",
  },
  {
    tokenId: 1639,
    Tier: "Basic",
  },
  {
    tokenId: 1640,
    Tier: "Uncommon",
  },
  {
    tokenId: 1641,
    Tier: "Uncommon",
  },
  {
    tokenId: 1642,
    Tier: "Basic",
  },
  {
    tokenId: 1643,
    Tier: "Common",
  },
  {
    tokenId: 1644,
    Tier: "Basic",
  },
  {
    tokenId: 1645,
    Tier: "Basic",
  },
  {
    tokenId: 1646,
    Tier: "Basic",
  },
  {
    tokenId: 1647,
    Tier: "Basic",
  },
  {
    tokenId: 1648,
    Tier: "Basic",
  },
  {
    tokenId: 1649,
    Tier: "Basic",
  },
  {
    tokenId: 1650,
    Tier: "Basic",
  },
  {
    tokenId: 1651,
    Tier: "Basic",
  },
  {
    tokenId: 1652,
    Tier: "Common",
  },
  {
    tokenId: 1653,
    Tier: "Basic",
  },
  {
    tokenId: 1654,
    Tier: "Common",
  },
  {
    tokenId: 1655,
    Tier: "Basic",
  },
  {
    tokenId: 1656,
    Tier: "Basic",
  },
  {
    tokenId: 1657,
    Tier: "Basic",
  },
  {
    tokenId: 1658,
    Tier: "Uncommon",
  },
  {
    tokenId: 1659,
    Tier: "Common",
  },
  {
    tokenId: 1660,
    Tier: "Common",
  },
  {
    tokenId: 1661,
    Tier: "Basic",
  },
  {
    tokenId: 1662,
    Tier: "Basic",
  },
  {
    tokenId: 1663,
    Tier: "Common",
  },
  {
    tokenId: 1664,
    Tier: "Epic",
  },
  {
    tokenId: 1665,
    Tier: "Rare",
  },
  {
    tokenId: 1666,
    Tier: "Basic",
  },
  {
    tokenId: 1667,
    Tier: "Basic",
  },
  {
    tokenId: 1668,
    Tier: "Basic",
  },
  {
    tokenId: 1669,
    Tier: "Uncommon",
  },
  {
    tokenId: 1670,
    Tier: "Basic",
  },
  {
    tokenId: 1671,
    Tier: "Basic",
  },
  {
    tokenId: 1672,
    Tier: "Basic",
  },
  {
    tokenId: 1673,
    Tier: "Common",
  },
  {
    tokenId: 1674,
    Tier: "Uncommon",
  },
  {
    tokenId: 1675,
    Tier: "Basic",
  },
  {
    tokenId: 1676,
    Tier: "Uncommon",
  },
  {
    tokenId: 1677,
    Tier: "Basic",
  },
  {
    tokenId: 1678,
    Tier: "Basic",
  },
  {
    tokenId: 1679,
    Tier: "Legendary",
  },
  {
    tokenId: 1680,
    Tier: "Rare",
  },
  {
    tokenId: 1681,
    Tier: "Basic",
  },
  {
    tokenId: 1682,
    Tier: "Basic",
  },
  {
    tokenId: 1683,
    Tier: "Rare",
  },
  {
    tokenId: 1684,
    Tier: "Mythic",
  },
  {
    tokenId: 1685,
    Tier: "Basic",
  },
  {
    tokenId: 1686,
    Tier: "Uncommon",
  },
  {
    tokenId: 1687,
    Tier: "Common",
  },
  {
    tokenId: 1688,
    Tier: "Basic",
  },
  {
    tokenId: 1689,
    Tier: "Uncommon",
  },
  {
    tokenId: 1690,
    Tier: "Common",
  },
  {
    tokenId: 1691,
    Tier: "Basic",
  },
  {
    tokenId: 1692,
    Tier: "Basic",
  },
  {
    tokenId: 1693,
    Tier: "Basic",
  },
  {
    tokenId: 1694,
    Tier: "Basic",
  },
  {
    tokenId: 1695,
    Tier: "Epic",
  },
  {
    tokenId: 1696,
    Tier: "Common",
  },
  {
    tokenId: 1697,
    Tier: "Legendary",
  },
  {
    tokenId: 1698,
    Tier: "Uncommon",
  },
  {
    tokenId: 1699,
    Tier: "Common",
  },
  {
    tokenId: 1700,
    Tier: "Common",
  },
  {
    tokenId: 1701,
    Tier: "Basic",
  },
  {
    tokenId: 1702,
    Tier: "Basic",
  },
  {
    tokenId: 1703,
    Tier: "Common",
  },
  {
    tokenId: 1704,
    Tier: "Rare",
  },
  {
    tokenId: 1705,
    Tier: "Basic",
  },
  {
    tokenId: 1706,
    Tier: "Basic",
  },
  {
    tokenId: 1707,
    Tier: "Basic",
  },
  {
    tokenId: 1708,
    Tier: "Basic",
  },
  {
    tokenId: 1709,
    Tier: "Basic",
  },
  {
    tokenId: 1710,
    Tier: "Common",
  },
  {
    tokenId: 1711,
    Tier: "Basic",
  },
  {
    tokenId: 1712,
    Tier: "Uncommon",
  },
  {
    tokenId: 1713,
    Tier: "Basic",
  },
  {
    tokenId: 1714,
    Tier: "Basic",
  },
  {
    tokenId: 1715,
    Tier: "Uncommon",
  },
  {
    tokenId: 1716,
    Tier: "Basic",
  },
  {
    tokenId: 1717,
    Tier: "Rare",
  },
  {
    tokenId: 1718,
    Tier: "Basic",
  },
  {
    tokenId: 1719,
    Tier: "Basic",
  },
  {
    tokenId: 1720,
    Tier: "Mythic",
  },
  {
    tokenId: 1721,
    Tier: "Rare",
  },
  {
    tokenId: 1722,
    Tier: "Basic",
  },
  {
    tokenId: 1723,
    Tier: "Basic",
  },
  {
    tokenId: 1724,
    Tier: "Uncommon",
  },
  {
    tokenId: 1725,
    Tier: "Basic",
  },
  {
    tokenId: 1726,
    Tier: "Basic",
  },
  {
    tokenId: 1727,
    Tier: "Basic",
  },
  {
    tokenId: 1728,
    Tier: "Basic",
  },
  {
    tokenId: 1729,
    Tier: "Basic",
  },
  {
    tokenId: 1730,
    Tier: "Common",
  },
  {
    tokenId: 1731,
    Tier: "Uncommon",
  },
  {
    tokenId: 1732,
    Tier: "Basic",
  },
  {
    tokenId: 1733,
    Tier: "Basic",
  },
  {
    tokenId: 1734,
    Tier: "Basic",
  },
  {
    tokenId: 1735,
    Tier: "Basic",
  },
  {
    tokenId: 1736,
    Tier: "Basic",
  },
  {
    tokenId: 1737,
    Tier: "Rare",
  },
  {
    tokenId: 1738,
    Tier: "Basic",
  },
  {
    tokenId: 1739,
    Tier: "Epic",
  },
  {
    tokenId: 1740,
    Tier: "Uncommon",
  },
  {
    tokenId: 1741,
    Tier: "Uncommon",
  },
  {
    tokenId: 1742,
    Tier: "Basic",
  },
  {
    tokenId: 1743,
    Tier: "Basic",
  },
  {
    tokenId: 1744,
    Tier: "Epic",
  },
  {
    tokenId: 1745,
    Tier: "Basic",
  },
  {
    tokenId: 1746,
    Tier: "Common",
  },
  {
    tokenId: 1747,
    Tier: "Basic",
  },
  {
    tokenId: 1748,
    Tier: "Common",
  },
  {
    tokenId: 1749,
    Tier: "Rare",
  },
  {
    tokenId: 1750,
    Tier: "Rare",
  },
  {
    tokenId: 1751,
    Tier: "Basic",
  },
  {
    tokenId: 1752,
    Tier: "Basic",
  },
  {
    tokenId: 1753,
    Tier: "Uncommon",
  },
  {
    tokenId: 1754,
    Tier: "Legendary",
  },
  {
    tokenId: 1755,
    Tier: "Common",
  },
  {
    tokenId: 1756,
    Tier: "Basic",
  },
  {
    tokenId: 1757,
    Tier: "Uncommon",
  },
  {
    tokenId: 1758,
    Tier: "Legendary",
  },
  {
    tokenId: 1759,
    Tier: "Common",
  },
  {
    tokenId: 1760,
    Tier: "Uncommon",
  },
  {
    tokenId: 1761,
    Tier: "Basic",
  },
  {
    tokenId: 1762,
    Tier: "Epic",
  },
  {
    tokenId: 1763,
    Tier: "Common",
  },
  {
    tokenId: 1764,
    Tier: "Rare",
  },
  {
    tokenId: 1765,
    Tier: "Uncommon",
  },
  {
    tokenId: 1766,
    Tier: "Epic",
  },
  {
    tokenId: 1767,
    Tier: "Rare",
  },
  {
    tokenId: 1768,
    Tier: "Basic",
  },
  {
    tokenId: 1769,
    Tier: "Legendary",
  },
  {
    tokenId: 1770,
    Tier: "Common",
  },
  {
    tokenId: 1771,
    Tier: "Basic",
  },
  {
    tokenId: 1772,
    Tier: "Uncommon",
  },
  {
    tokenId: 1773,
    Tier: "Legendary",
  },
  {
    tokenId: 1774,
    Tier: "Basic",
  },
  {
    tokenId: 1775,
    Tier: "Basic",
  },
  {
    tokenId: 1776,
    Tier: "Basic",
  },
  {
    tokenId: 1777,
    Tier: "Common",
  },
  {
    tokenId: 1778,
    Tier: "Basic",
  },
  {
    tokenId: 1779,
    Tier: "Basic",
  },
  {
    tokenId: 1780,
    Tier: "Rare",
  },
  {
    tokenId: 1781,
    Tier: "Basic",
  },
  {
    tokenId: 1782,
    Tier: "Legendary",
  },
  {
    tokenId: 1783,
    Tier: "Uncommon",
  },
  {
    tokenId: 1784,
    Tier: "Basic",
  },
  {
    tokenId: 1785,
    Tier: "Basic",
  },
  {
    tokenId: 1786,
    Tier: "Rare",
  },
  {
    tokenId: 1787,
    Tier: "Mythic",
  },
  {
    tokenId: 1788,
    Tier: "Common",
  },
  {
    tokenId: 1789,
    Tier: "Uncommon",
  },
  {
    tokenId: 1790,
    Tier: "Legendary",
  },
  {
    tokenId: 1791,
    Tier: "Uncommon",
  },
  {
    tokenId: 1792,
    Tier: "Rare",
  },
  {
    tokenId: 1793,
    Tier: "Epic",
  },
  {
    tokenId: 1794,
    Tier: "Common",
  },
  {
    tokenId: 1795,
    Tier: "Rare",
  },
  {
    tokenId: 1796,
    Tier: "Basic",
  },
  {
    tokenId: 1797,
    Tier: "Common",
  },
  {
    tokenId: 1798,
    Tier: "Basic",
  },
  {
    tokenId: 1799,
    Tier: "Common",
  },
  {
    tokenId: 1800,
    Tier: "Legendary",
  },
  {
    tokenId: 1801,
    Tier: "Basic",
  },
  {
    tokenId: 1802,
    Tier: "Epic",
  },
  {
    tokenId: 1803,
    Tier: "Basic",
  },
  {
    tokenId: 1804,
    Tier: "Basic",
  },
  {
    tokenId: 1805,
    Tier: "Basic",
  },
  {
    tokenId: 1806,
    Tier: "Common",
  },
  {
    tokenId: 1807,
    Tier: "Basic",
  },
  {
    tokenId: 1808,
    Tier: "Basic",
  },
  {
    tokenId: 1809,
    Tier: "Epic",
  },
  {
    tokenId: 1810,
    Tier: "Rare",
  },
  {
    tokenId: 1811,
    Tier: "Uncommon",
  },
  {
    tokenId: 1812,
    Tier: "Basic",
  },
  {
    tokenId: 1813,
    Tier: "Basic",
  },
  {
    tokenId: 1814,
    Tier: "Basic",
  },
  {
    tokenId: 1815,
    Tier: "Basic",
  },
  {
    tokenId: 1816,
    Tier: "Basic",
  },
  {
    tokenId: 1817,
    Tier: "Basic",
  },
  {
    tokenId: 1818,
    Tier: "Basic",
  },
  {
    tokenId: 1819,
    Tier: "Basic",
  },
  {
    tokenId: 1820,
    Tier: "Basic",
  },
  {
    tokenId: 1821,
    Tier: "Basic",
  },
  {
    tokenId: 1822,
    Tier: "Basic",
  },
  {
    tokenId: 1823,
    Tier: "Basic",
  },
  {
    tokenId: 1824,
    Tier: "Basic",
  },
  {
    tokenId: 1825,
    Tier: "Basic",
  },
  {
    tokenId: 1826,
    Tier: "Basic",
  },
  {
    tokenId: 1827,
    Tier: "Uncommon",
  },
  {
    tokenId: 1828,
    Tier: "Basic",
  },
  {
    tokenId: 1829,
    Tier: "Common",
  },
  {
    tokenId: 1830,
    Tier: "Uncommon",
  },
  {
    tokenId: 1831,
    Tier: "Basic",
  },
  {
    tokenId: 1832,
    Tier: "Basic",
  },
  {
    tokenId: 1833,
    Tier: "Common",
  },
  {
    tokenId: 1834,
    Tier: "Common",
  },
  {
    tokenId: 1835,
    Tier: "Basic",
  },
  {
    tokenId: 1836,
    Tier: "Basic",
  },
  {
    tokenId: 1837,
    Tier: "Rare",
  },
  {
    tokenId: 1838,
    Tier: "Basic",
  },
  {
    tokenId: 1839,
    Tier: "Common",
  },
  {
    tokenId: 1840,
    Tier: "Rare",
  },
  {
    tokenId: 1841,
    Tier: "Uncommon",
  },
  {
    tokenId: 1842,
    Tier: "Basic",
  },
  {
    tokenId: 1843,
    Tier: "Uncommon",
  },
  {
    tokenId: 1844,
    Tier: "Common",
  },
  {
    tokenId: 1845,
    Tier: "Uncommon",
  },
  {
    tokenId: 1846,
    Tier: "Basic",
  },
  {
    tokenId: 1847,
    Tier: "Basic",
  },
  {
    tokenId: 1848,
    Tier: "Uncommon",
  },
  {
    tokenId: 1849,
    Tier: "Epic",
  },
  {
    tokenId: 1850,
    Tier: "Rare",
  },
  {
    tokenId: 1851,
    Tier: "Basic",
  },
  {
    tokenId: 1852,
    Tier: "Common",
  },
  {
    tokenId: 1853,
    Tier: "Legendary",
  },
  {
    tokenId: 1854,
    Tier: "Basic",
  },
  {
    tokenId: 1855,
    Tier: "Basic",
  },
  {
    tokenId: 1856,
    Tier: "Basic",
  },
  {
    tokenId: 1857,
    Tier: "Common",
  },
  {
    tokenId: 1858,
    Tier: "Basic",
  },
  {
    tokenId: 1859,
    Tier: "Basic",
  },
  {
    tokenId: 1860,
    Tier: "Basic",
  },
  {
    tokenId: 1861,
    Tier: "Basic",
  },
  {
    tokenId: 1862,
    Tier: "Basic",
  },
  {
    tokenId: 1863,
    Tier: "Common",
  },
  {
    tokenId: 1864,
    Tier: "Common",
  },
  {
    tokenId: 1865,
    Tier: "Uncommon",
  },
  {
    tokenId: 1866,
    Tier: "Basic",
  },
  {
    tokenId: 1867,
    Tier: "Mythic",
  },
  {
    tokenId: 1868,
    Tier: "Basic",
  },
  {
    tokenId: 1869,
    Tier: "Basic",
  },
  {
    tokenId: 1870,
    Tier: "Basic",
  },
  {
    tokenId: 1871,
    Tier: "Basic",
  },
  {
    tokenId: 1872,
    Tier: "Basic",
  },
  {
    tokenId: 1873,
    Tier: "Basic",
  },
  {
    tokenId: 1874,
    Tier: "Common",
  },
  {
    tokenId: 1875,
    Tier: "Basic",
  },
  {
    tokenId: 1876,
    Tier: "Basic",
  },
  {
    tokenId: 1877,
    Tier: "Basic",
  },
  {
    tokenId: 1878,
    Tier: "Rare",
  },
  {
    tokenId: 1879,
    Tier: "Legendary",
  },
  {
    tokenId: 1880,
    Tier: "Basic",
  },
  {
    tokenId: 1881,
    Tier: "Common",
  },
  {
    tokenId: 1882,
    Tier: "Epic",
  },
  {
    tokenId: 1883,
    Tier: "Basic",
  },
  {
    tokenId: 1884,
    Tier: "Uncommon",
  },
  {
    tokenId: 1885,
    Tier: "Basic",
  },
  {
    tokenId: 1886,
    Tier: "Basic",
  },
  {
    tokenId: 1887,
    Tier: "Exotic",
  },
  {
    tokenId: 1888,
    Tier: "Legendary",
  },
  {
    tokenId: 1889,
    Tier: "Basic",
  },
  {
    tokenId: 1890,
    Tier: "Basic",
  },
  {
    tokenId: 1891,
    Tier: "Basic",
  },
  {
    tokenId: 1892,
    Tier: "Basic",
  },
  {
    tokenId: 1893,
    Tier: "Basic",
  },
  {
    tokenId: 1894,
    Tier: "Uncommon",
  },
  {
    tokenId: 1895,
    Tier: "Basic",
  },
  {
    tokenId: 1896,
    Tier: "Common",
  },
  {
    tokenId: 1897,
    Tier: "Basic",
  },
  {
    tokenId: 1898,
    Tier: "Basic",
  },
  {
    tokenId: 1899,
    Tier: "Rare",
  },
  {
    tokenId: 1900,
    Tier: "Epic",
  },
  {
    tokenId: 1901,
    Tier: "Basic",
  },
  {
    tokenId: 1902,
    Tier: "Mythic",
  },
  {
    tokenId: 1903,
    Tier: "Uncommon",
  },
  {
    tokenId: 1904,
    Tier: "Basic",
  },
  {
    tokenId: 1905,
    Tier: "Rare",
  },
  {
    tokenId: 1906,
    Tier: "Common",
  },
  {
    tokenId: 1907,
    Tier: "Basic",
  },
  {
    tokenId: 1908,
    Tier: "Basic",
  },
  {
    tokenId: 1909,
    Tier: "Basic",
  },
  {
    tokenId: 1910,
    Tier: "Common",
  },
  {
    tokenId: 1911,
    Tier: "Mythic",
  },
  {
    tokenId: 1912,
    Tier: "Basic",
  },
  {
    tokenId: 1913,
    Tier: "Epic",
  },
  {
    tokenId: 1914,
    Tier: "Basic",
  },
  {
    tokenId: 1915,
    Tier: "Epic",
  },
  {
    tokenId: 1916,
    Tier: "Rare",
  },
  {
    tokenId: 1917,
    Tier: "Basic",
  },
  {
    tokenId: 1918,
    Tier: "Basic",
  },
  {
    tokenId: 1919,
    Tier: "Basic",
  },
  {
    tokenId: 1920,
    Tier: "Rare",
  },
  {
    tokenId: 1921,
    Tier: "Mythic",
  },
  {
    tokenId: 1922,
    Tier: "Basic",
  },
  {
    tokenId: 1923,
    Tier: "Epic",
  },
  {
    tokenId: 1924,
    Tier: "Common",
  },
  {
    tokenId: 1925,
    Tier: "Basic",
  },
  {
    tokenId: 1926,
    Tier: "Uncommon",
  },
  {
    tokenId: 1927,
    Tier: "Basic",
  },
  {
    tokenId: 1928,
    Tier: "Basic",
  },
  {
    tokenId: 1929,
    Tier: "Basic",
  },
  {
    tokenId: 1930,
    Tier: "Basic",
  },
  {
    tokenId: 1931,
    Tier: "Basic",
  },
  {
    tokenId: 1932,
    Tier: "Basic",
  },
  {
    tokenId: 1933,
    Tier: "Common",
  },
  {
    tokenId: 1934,
    Tier: "Basic",
  },
  {
    tokenId: 1935,
    Tier: "Basic",
  },
  {
    tokenId: 1936,
    Tier: "Basic",
  },
  {
    tokenId: 1937,
    Tier: "Basic",
  },
  {
    tokenId: 1938,
    Tier: "Epic",
  },
  {
    tokenId: 1939,
    Tier: "Epic",
  },
  {
    tokenId: 1940,
    Tier: "Legendary",
  },
  {
    tokenId: 1941,
    Tier: "Common",
  },
  {
    tokenId: 1942,
    Tier: "Basic",
  },
  {
    tokenId: 1943,
    Tier: "Basic",
  },
  {
    tokenId: 1944,
    Tier: "Rare",
  },
  {
    tokenId: 1945,
    Tier: "Epic",
  },
  {
    tokenId: 1946,
    Tier: "Uncommon",
  },
  {
    tokenId: 1947,
    Tier: "Basic",
  },
  {
    tokenId: 1948,
    Tier: "Uncommon",
  },
  {
    tokenId: 1949,
    Tier: "Common",
  },
  {
    tokenId: 1950,
    Tier: "Rare",
  },
  {
    tokenId: 1951,
    Tier: "Uncommon",
  },
  {
    tokenId: 1952,
    Tier: "Mythic",
  },
  {
    tokenId: 1953,
    Tier: "Common",
  },
  {
    tokenId: 1954,
    Tier: "Basic",
  },
  {
    tokenId: 1955,
    Tier: "Common",
  },
  {
    tokenId: 1956,
    Tier: "Rare",
  },
  {
    tokenId: 1957,
    Tier: "Legendary",
  },
  {
    tokenId: 1958,
    Tier: "Exotic",
  },
  {
    tokenId: 1959,
    Tier: "Basic",
  },
  {
    tokenId: 1960,
    Tier: "Basic",
  },
  {
    tokenId: 1961,
    Tier: "Common",
  },
  {
    tokenId: 1962,
    Tier: "Basic",
  },
  {
    tokenId: 1963,
    Tier: "Basic",
  },
  {
    tokenId: 1964,
    Tier: "Rare",
  },
  {
    tokenId: 1965,
    Tier: "Basic",
  },
  {
    tokenId: 1966,
    Tier: "Rare",
  },
  {
    tokenId: 1967,
    Tier: "Basic",
  },
  {
    tokenId: 1968,
    Tier: "Basic",
  },
  {
    tokenId: 1969,
    Tier: "Legendary",
  },
  {
    tokenId: 1970,
    Tier: "Basic",
  },
  {
    tokenId: 1971,
    Tier: "Common",
  },
  {
    tokenId: 1972,
    Tier: "Mythic",
  },
  {
    tokenId: 1973,
    Tier: "Rare",
  },
  {
    tokenId: 1974,
    Tier: "Uncommon",
  },
  {
    tokenId: 1975,
    Tier: "Uncommon",
  },
  {
    tokenId: 1976,
    Tier: "Epic",
  },
  {
    tokenId: 1977,
    Tier: "Basic",
  },
  {
    tokenId: 1978,
    Tier: "Basic",
  },
  {
    tokenId: 1979,
    Tier: "Basic",
  },
  {
    tokenId: 1980,
    Tier: "Basic",
  },
  {
    tokenId: 1981,
    Tier: "Basic",
  },
  {
    tokenId: 1982,
    Tier: "Epic",
  },
  {
    tokenId: 1983,
    Tier: "Rare",
  },
  {
    tokenId: 1984,
    Tier: "Mythic",
  },
  {
    tokenId: 1985,
    Tier: "Basic",
  },
  {
    tokenId: 1986,
    Tier: "Basic",
  },
  {
    tokenId: 1987,
    Tier: "Basic",
  },
  {
    tokenId: 1988,
    Tier: "Common",
  },
  {
    tokenId: 1989,
    Tier: "Basic",
  },
  {
    tokenId: 1990,
    Tier: "Basic",
  },
  {
    tokenId: 1991,
    Tier: "Epic",
  },
  {
    tokenId: 1992,
    Tier: "Basic",
  },
  {
    tokenId: 1993,
    Tier: "Basic",
  },
  {
    tokenId: 1994,
    Tier: "Basic",
  },
  {
    tokenId: 1995,
    Tier: "Legendary",
  },
  {
    tokenId: 1996,
    Tier: "Basic",
  },
  {
    tokenId: 1997,
    Tier: "Basic",
  },
  {
    tokenId: 1998,
    Tier: "Basic",
  },
  {
    tokenId: 1999,
    Tier: "Basic",
  },
  {
    tokenId: 2000,
    Tier: "Basic",
  },
  {
    tokenId: 2001,
    Tier: "Epic",
  },
  {
    tokenId: 2002,
    Tier: "Basic",
  },
  {
    tokenId: 2003,
    Tier: "Uncommon",
  },
  {
    tokenId: 2004,
    Tier: "Uncommon",
  },
  {
    tokenId: 2005,
    Tier: "Basic",
  },
  {
    tokenId: 2006,
    Tier: "Basic",
  },
  {
    tokenId: 2007,
    Tier: "Basic",
  },
  {
    tokenId: 2008,
    Tier: "Uncommon",
  },
  {
    tokenId: 2009,
    Tier: "Basic",
  },
  {
    tokenId: 2010,
    Tier: "Uncommon",
  },
  {
    tokenId: 2011,
    Tier: "Uncommon",
  },
  {
    tokenId: 2012,
    Tier: "Basic",
  },
  {
    tokenId: 2013,
    Tier: "Uncommon",
  },
  {
    tokenId: 2014,
    Tier: "Basic",
  },
  {
    tokenId: 2015,
    Tier: "Basic",
  },
  {
    tokenId: 2016,
    Tier: "Basic",
  },
  {
    tokenId: 2017,
    Tier: "Rare",
  },
  {
    tokenId: 2018,
    Tier: "Basic",
  },
  {
    tokenId: 2019,
    Tier: "Mythic",
  },
  {
    tokenId: 2020,
    Tier: "Epic",
  },
  {
    tokenId: 2021,
    Tier: "Basic",
  },
  {
    tokenId: 2022,
    Tier: "Basic",
  },
  {
    tokenId: 2023,
    Tier: "Common",
  },
  {
    tokenId: 2024,
    Tier: "Basic",
  },
  {
    tokenId: 2025,
    Tier: "Basic",
  },
  {
    tokenId: 2026,
    Tier: "Uncommon",
  },
  {
    tokenId: 2027,
    Tier: "Basic",
  },
  {
    tokenId: 2028,
    Tier: "Basic",
  },
  {
    tokenId: 2029,
    Tier: "Uncommon",
  },
  {
    tokenId: 2030,
    Tier: "Uncommon",
  },
  {
    tokenId: 2031,
    Tier: "Basic",
  },
  {
    tokenId: 2032,
    Tier: "Uncommon",
  },
  {
    tokenId: 2033,
    Tier: "Mythic",
  },
  {
    tokenId: 2034,
    Tier: "Common",
  },
  {
    tokenId: 2035,
    Tier: "Uncommon",
  },
  {
    tokenId: 2036,
    Tier: "Basic",
  },
  {
    tokenId: 2037,
    Tier: "Basic",
  },
  {
    tokenId: 2038,
    Tier: "Uncommon",
  },
  {
    tokenId: 2039,
    Tier: "Legendary",
  },
  {
    tokenId: 2040,
    Tier: "Basic",
  },
  {
    tokenId: 2041,
    Tier: "Epic",
  },
  {
    tokenId: 2042,
    Tier: "Uncommon",
  },
  {
    tokenId: 2043,
    Tier: "Uncommon",
  },
  {
    tokenId: 2044,
    Tier: "Common",
  },
  {
    tokenId: 2045,
    Tier: "Common",
  },
  {
    tokenId: 2046,
    Tier: "Basic",
  },
  {
    tokenId: 2047,
    Tier: "Legendary",
  },
  {
    tokenId: 2048,
    Tier: "Basic",
  },
  {
    tokenId: 2049,
    Tier: "Basic",
  },
  {
    tokenId: 2050,
    Tier: "Uncommon",
  },
  {
    tokenId: 2051,
    Tier: "Uncommon",
  },
  {
    tokenId: 2052,
    Tier: "Uncommon",
  },
  {
    tokenId: 2053,
    Tier: "Rare",
  },
  {
    tokenId: 2054,
    Tier: "Basic",
  },
  {
    tokenId: 2055,
    Tier: "Basic",
  },
  {
    tokenId: 2056,
    Tier: "Basic",
  },
  {
    tokenId: 2057,
    Tier: "Basic",
  },
  {
    tokenId: 2058,
    Tier: "Common",
  },
  {
    tokenId: 2059,
    Tier: "Epic",
  },
  {
    tokenId: 2060,
    Tier: "Basic",
  },
  {
    tokenId: 2061,
    Tier: "Basic",
  },
  {
    tokenId: 2062,
    Tier: "Uncommon",
  },
  {
    tokenId: 2063,
    Tier: "Uncommon",
  },
  {
    tokenId: 2064,
    Tier: "Basic",
  },
  {
    tokenId: 2065,
    Tier: "Basic",
  },
  {
    tokenId: 2066,
    Tier: "Basic",
  },
  {
    tokenId: 2067,
    Tier: "Exotic",
  },
  {
    tokenId: 2068,
    Tier: "Basic",
  },
  {
    tokenId: 2069,
    Tier: "Basic",
  },
  {
    tokenId: 2070,
    Tier: "Legendary",
  },
  {
    tokenId: 2071,
    Tier: "Epic",
  },
  {
    tokenId: 2072,
    Tier: "Epic",
  },
  {
    tokenId: 2073,
    Tier: "Basic",
  },
  {
    tokenId: 2074,
    Tier: "Common",
  },
  {
    tokenId: 2075,
    Tier: "Basic",
  },
  {
    tokenId: 2076,
    Tier: "Basic",
  },
  {
    tokenId: 2077,
    Tier: "Epic",
  },
  {
    tokenId: 2078,
    Tier: "Basic",
  },
  {
    tokenId: 2079,
    Tier: "Basic",
  },
  {
    tokenId: 2080,
    Tier: "Basic",
  },
  {
    tokenId: 2081,
    Tier: "Mythic",
  },
  {
    tokenId: 2082,
    Tier: "Basic",
  },
  {
    tokenId: 2083,
    Tier: "Basic",
  },
  {
    tokenId: 2084,
    Tier: "Basic",
  },
  {
    tokenId: 2085,
    Tier: "Common",
  },
  {
    tokenId: 2086,
    Tier: "Basic",
  },
  {
    tokenId: 2087,
    Tier: "Common",
  },
  {
    tokenId: 2088,
    Tier: "Basic",
  },
  {
    tokenId: 2089,
    Tier: "Uncommon",
  },
  {
    tokenId: 2090,
    Tier: "Rare",
  },
  {
    tokenId: 2091,
    Tier: "Epic",
  },
  {
    tokenId: 2092,
    Tier: "Basic",
  },
  {
    tokenId: 2093,
    Tier: "Basic",
  },
  {
    tokenId: 2094,
    Tier: "Epic",
  },
  {
    tokenId: 2095,
    Tier: "Exotic",
  },
  {
    tokenId: 2096,
    Tier: "Common",
  },
  {
    tokenId: 2097,
    Tier: "Common",
  },
  {
    tokenId: 2098,
    Tier: "Mythic",
  },
  {
    tokenId: 2099,
    Tier: "Uncommon",
  },
  {
    tokenId: 2100,
    Tier: "Rare",
  },
  {
    tokenId: 2101,
    Tier: "Basic",
  },
  {
    tokenId: 2102,
    Tier: "Uncommon",
  },
  {
    tokenId: 2103,
    Tier: "Rare",
  },
  {
    tokenId: 2104,
    Tier: "Basic",
  },
  {
    tokenId: 2105,
    Tier: "Basic",
  },
  {
    tokenId: 2106,
    Tier: "Basic",
  },
  {
    tokenId: 2107,
    Tier: "Uncommon",
  },
  {
    tokenId: 2108,
    Tier: "Common",
  },
  {
    tokenId: 2109,
    Tier: "Uncommon",
  },
  {
    tokenId: 2110,
    Tier: "Basic",
  },
  {
    tokenId: 2111,
    Tier: "Uncommon",
  },
  {
    tokenId: 2112,
    Tier: "Basic",
  },
  {
    tokenId: 2113,
    Tier: "Basic",
  },
  {
    tokenId: 2114,
    Tier: "Uncommon",
  },
  {
    tokenId: 2115,
    Tier: "Basic",
  },
  {
    tokenId: 2116,
    Tier: "Basic",
  },
  {
    tokenId: 2117,
    Tier: "Uncommon",
  },
  {
    tokenId: 2118,
    Tier: "Uncommon",
  },
  {
    tokenId: 2119,
    Tier: "Basic",
  },
  {
    tokenId: 2120,
    Tier: "Common",
  },
  {
    tokenId: 2121,
    Tier: "Basic",
  },
  {
    tokenId: 2122,
    Tier: "Uncommon",
  },
  {
    tokenId: 2123,
    Tier: "Basic",
  },
  {
    tokenId: 2124,
    Tier: "Basic",
  },
  {
    tokenId: 2125,
    Tier: "Basic",
  },
  {
    tokenId: 2126,
    Tier: "Uncommon",
  },
  {
    tokenId: 2127,
    Tier: "Common",
  },
  {
    tokenId: 2128,
    Tier: "Common",
  },
  {
    tokenId: 2129,
    Tier: "Basic",
  },
  {
    tokenId: 2130,
    Tier: "Basic",
  },
  {
    tokenId: 2131,
    Tier: "Basic",
  },
  {
    tokenId: 2132,
    Tier: "Epic",
  },
  {
    tokenId: 2133,
    Tier: "Basic",
  },
  {
    tokenId: 2134,
    Tier: "Legendary",
  },
  {
    tokenId: 2135,
    Tier: "Basic",
  },
  {
    tokenId: 2136,
    Tier: "Mythic",
  },
  {
    tokenId: 2137,
    Tier: "Uncommon",
  },
  {
    tokenId: 2138,
    Tier: "Common",
  },
  {
    tokenId: 2139,
    Tier: "Epic",
  },
  {
    tokenId: 2140,
    Tier: "Basic",
  },
  {
    tokenId: 2141,
    Tier: "Basic",
  },
  {
    tokenId: 2142,
    Tier: "Basic",
  },
  {
    tokenId: 2143,
    Tier: "Legendary",
  },
  {
    tokenId: 2144,
    Tier: "Basic",
  },
  {
    tokenId: 2145,
    Tier: "Common",
  },
  {
    tokenId: 2146,
    Tier: "Rare",
  },
  {
    tokenId: 2147,
    Tier: "Basic",
  },
  {
    tokenId: 2148,
    Tier: "Rare",
  },
  {
    tokenId: 2149,
    Tier: "Common",
  },
  {
    tokenId: 2150,
    Tier: "Basic",
  },
  {
    tokenId: 2151,
    Tier: "Basic",
  },
  {
    tokenId: 2152,
    Tier: "Basic",
  },
  {
    tokenId: 2153,
    Tier: "Uncommon",
  },
  {
    tokenId: 2154,
    Tier: "Legendary",
  },
  {
    tokenId: 2155,
    Tier: "Basic",
  },
  {
    tokenId: 2156,
    Tier: "Basic",
  },
  {
    tokenId: 2157,
    Tier: "Basic",
  },
  {
    tokenId: 2158,
    Tier: "Uncommon",
  },
  {
    tokenId: 2159,
    Tier: "Basic",
  },
  {
    tokenId: 2160,
    Tier: "Basic",
  },
  {
    tokenId: 2161,
    Tier: "Rare",
  },
  {
    tokenId: 2162,
    Tier: "Epic",
  },
  {
    tokenId: 2163,
    Tier: "Uncommon",
  },
  {
    tokenId: 2164,
    Tier: "Basic",
  },
  {
    tokenId: 2165,
    Tier: "Rare",
  },
  {
    tokenId: 2166,
    Tier: "Uncommon",
  },
  {
    tokenId: 2167,
    Tier: "Uncommon",
  },
  {
    tokenId: 2168,
    Tier: "Basic",
  },
  {
    tokenId: 2169,
    Tier: "Basic",
  },
  {
    tokenId: 2170,
    Tier: "Basic",
  },
  {
    tokenId: 2171,
    Tier: "Rare",
  },
  {
    tokenId: 2172,
    Tier: "Common",
  },
  {
    tokenId: 2173,
    Tier: "Uncommon",
  },
  {
    tokenId: 2174,
    Tier: "Basic",
  },
  {
    tokenId: 2175,
    Tier: "Mythic",
  },
  {
    tokenId: 2176,
    Tier: "Legendary",
  },
  {
    tokenId: 2177,
    Tier: "Basic",
  },
  {
    tokenId: 2178,
    Tier: "Basic",
  },
  {
    tokenId: 2179,
    Tier: "Basic",
  },
  {
    tokenId: 2180,
    Tier: "Epic",
  },
  {
    tokenId: 2181,
    Tier: "Basic",
  },
  {
    tokenId: 2182,
    Tier: "Basic",
  },
  {
    tokenId: 2183,
    Tier: "Basic",
  },
  {
    tokenId: 2184,
    Tier: "Basic",
  },
  {
    tokenId: 2185,
    Tier: "Epic",
  },
  {
    tokenId: 2186,
    Tier: "Basic",
  },
  {
    tokenId: 2187,
    Tier: "Basic",
  },
  {
    tokenId: 2188,
    Tier: "Basic",
  },
  {
    tokenId: 2189,
    Tier: "Basic",
  },
  {
    tokenId: 2190,
    Tier: "Basic",
  },
  {
    tokenId: 2191,
    Tier: "Common",
  },
  {
    tokenId: 2192,
    Tier: "Common",
  },
  {
    tokenId: 2193,
    Tier: "Rare",
  },
  {
    tokenId: 2194,
    Tier: "Basic",
  },
  {
    tokenId: 2195,
    Tier: "Basic",
  },
  {
    tokenId: 2196,
    Tier: "Basic",
  },
  {
    tokenId: 2197,
    Tier: "Rare",
  },
  {
    tokenId: 2198,
    Tier: "Uncommon",
  },
  {
    tokenId: 2199,
    Tier: "Rare",
  },
  {
    tokenId: 2200,
    Tier: "Epic",
  },
  {
    tokenId: 2201,
    Tier: "Uncommon",
  },
  {
    tokenId: 2202,
    Tier: "Epic",
  },
  {
    tokenId: 2203,
    Tier: "Epic",
  },
  {
    tokenId: 2204,
    Tier: "Basic",
  },
  {
    tokenId: 2205,
    Tier: "Rare",
  },
  {
    tokenId: 2206,
    Tier: "Uncommon",
  },
  {
    tokenId: 2207,
    Tier: "Basic",
  },
  {
    tokenId: 2208,
    Tier: "Epic",
  },
  {
    tokenId: 2209,
    Tier: "Basic",
  },
  {
    tokenId: 2210,
    Tier: "Legendary",
  },
  {
    tokenId: 2211,
    Tier: "Basic",
  },
  {
    tokenId: 2212,
    Tier: "Basic",
  },
  {
    tokenId: 2213,
    Tier: "Uncommon",
  },
  {
    tokenId: 2214,
    Tier: "Common",
  },
  {
    tokenId: 2215,
    Tier: "Basic",
  },
  {
    tokenId: 2216,
    Tier: "Epic",
  },
  {
    tokenId: 2217,
    Tier: "Basic",
  },
  {
    tokenId: 2218,
    Tier: "Basic",
  },
  {
    tokenId: 2219,
    Tier: "Basic",
  },
  {
    tokenId: 2220,
    Tier: "Common",
  },
  {
    tokenId: 2221,
    Tier: "Uncommon",
  },
  {
    tokenId: 2222,
    Tier: "Basic",
  },
  {
    tokenId: 2223,
    Tier: "Basic",
  },
  {
    tokenId: 2224,
    Tier: "Basic",
  },
  {
    tokenId: 2225,
    Tier: "Basic",
  },
  {
    tokenId: 2226,
    Tier: "Common",
  },
  {
    tokenId: 2227,
    Tier: "Basic",
  },
  {
    tokenId: 2228,
    Tier: "Uncommon",
  },
  {
    tokenId: 2229,
    Tier: "Basic",
  },
  {
    tokenId: 2230,
    Tier: "Basic",
  },
  {
    tokenId: 2231,
    Tier: "Basic",
  },
  {
    tokenId: 2232,
    Tier: "Basic",
  },
  {
    tokenId: 2233,
    Tier: "Mythic",
  },
  {
    tokenId: 2234,
    Tier: "Rare",
  },
  {
    tokenId: 2235,
    Tier: "Common",
  },
  {
    tokenId: 2236,
    Tier: "Basic",
  },
  {
    tokenId: 2237,
    Tier: "Basic",
  },
  {
    tokenId: 2238,
    Tier: "Basic",
  },
  {
    tokenId: 2239,
    Tier: "Basic",
  },
  {
    tokenId: 2240,
    Tier: "Mythic",
  },
  {
    tokenId: 2241,
    Tier: "Basic",
  },
  {
    tokenId: 2242,
    Tier: "Basic",
  },
  {
    tokenId: 2243,
    Tier: "Basic",
  },
  {
    tokenId: 2244,
    Tier: "Common",
  },
  {
    tokenId: 2245,
    Tier: "Basic",
  },
  {
    tokenId: 2246,
    Tier: "Common",
  },
  {
    tokenId: 2247,
    Tier: "Mythic",
  },
  {
    tokenId: 2248,
    Tier: "Basic",
  },
  {
    tokenId: 2249,
    Tier: "Basic",
  },
  {
    tokenId: 2250,
    Tier: "Basic",
  },
  {
    tokenId: 2251,
    Tier: "Rare",
  },
  {
    tokenId: 2252,
    Tier: "Basic",
  },
  {
    tokenId: 2253,
    Tier: "Legendary",
  },
  {
    tokenId: 2254,
    Tier: "Common",
  },
  {
    tokenId: 2255,
    Tier: "Basic",
  },
  {
    tokenId: 2256,
    Tier: "Uncommon",
  },
  {
    tokenId: 2257,
    Tier: "Rare",
  },
  {
    tokenId: 2258,
    Tier: "Uncommon",
  },
  {
    tokenId: 2259,
    Tier: "Basic",
  },
  {
    tokenId: 2260,
    Tier: "Basic",
  },
  {
    tokenId: 2261,
    Tier: "Epic",
  },
  {
    tokenId: 2262,
    Tier: "Rare",
  },
  {
    tokenId: 2263,
    Tier: "Uncommon",
  },
  {
    tokenId: 2264,
    Tier: "Basic",
  },
  {
    tokenId: 2265,
    Tier: "Epic",
  },
  {
    tokenId: 2266,
    Tier: "Basic",
  },
  {
    tokenId: 2267,
    Tier: "Basic",
  },
  {
    tokenId: 2268,
    Tier: "Basic",
  },
  {
    tokenId: 2269,
    Tier: "Rare",
  },
  {
    tokenId: 2270,
    Tier: "Uncommon",
  },
  {
    tokenId: 2271,
    Tier: "Uncommon",
  },
  {
    tokenId: 2272,
    Tier: "Exotic",
  },
  {
    tokenId: 2273,
    Tier: "Uncommon",
  },
  {
    tokenId: 2274,
    Tier: "Basic",
  },
  {
    tokenId: 2275,
    Tier: "Basic",
  },
  {
    tokenId: 2276,
    Tier: "Basic",
  },
  {
    tokenId: 2277,
    Tier: "Basic",
  },
  {
    tokenId: 2278,
    Tier: "Basic",
  },
  {
    tokenId: 2279,
    Tier: "Basic",
  },
  {
    tokenId: 2280,
    Tier: "Legendary",
  },
  {
    tokenId: 2281,
    Tier: "Common",
  },
  {
    tokenId: 2282,
    Tier: "Basic",
  },
  {
    tokenId: 2283,
    Tier: "Legendary",
  },
  {
    tokenId: 2284,
    Tier: "Basic",
  },
  {
    tokenId: 2285,
    Tier: "Uncommon",
  },
  {
    tokenId: 2286,
    Tier: "Basic",
  },
  {
    tokenId: 2287,
    Tier: "Uncommon",
  },
  {
    tokenId: 2288,
    Tier: "Epic",
  },
  {
    tokenId: 2289,
    Tier: "Basic",
  },
  {
    tokenId: 2290,
    Tier: "Legendary",
  },
  {
    tokenId: 2291,
    Tier: "Basic",
  },
  {
    tokenId: 2292,
    Tier: "Epic",
  },
  {
    tokenId: 2293,
    Tier: "Basic",
  },
  {
    tokenId: 2294,
    Tier: "Epic",
  },
  {
    tokenId: 2295,
    Tier: "Basic",
  },
  {
    tokenId: 2296,
    Tier: "Common",
  },
  {
    tokenId: 2297,
    Tier: "Uncommon",
  },
  {
    tokenId: 2298,
    Tier: "Legendary",
  },
  {
    tokenId: 2299,
    Tier: "Basic",
  },
  {
    tokenId: 2300,
    Tier: "Epic",
  },
  {
    tokenId: 2301,
    Tier: "Basic",
  },
  {
    tokenId: 2302,
    Tier: "Legendary",
  },
  {
    tokenId: 2303,
    Tier: "Common",
  },
  {
    tokenId: 2304,
    Tier: "Basic",
  },
  {
    tokenId: 2305,
    Tier: "Epic",
  },
  {
    tokenId: 2306,
    Tier: "Common",
  },
  {
    tokenId: 2307,
    Tier: "Uncommon",
  },
  {
    tokenId: 2308,
    Tier: "Legendary",
  },
  {
    tokenId: 2309,
    Tier: "Epic",
  },
  {
    tokenId: 2310,
    Tier: "Legendary",
  },
  {
    tokenId: 2311,
    Tier: "Basic",
  },
  {
    tokenId: 2312,
    Tier: "Basic",
  },
  {
    tokenId: 2313,
    Tier: "Basic",
  },
  {
    tokenId: 2314,
    Tier: "Uncommon",
  },
  {
    tokenId: 2315,
    Tier: "Basic",
  },
  {
    tokenId: 2316,
    Tier: "Uncommon",
  },
  {
    tokenId: 2317,
    Tier: "Basic",
  },
  {
    tokenId: 2318,
    Tier: "Mythic",
  },
  {
    tokenId: 2319,
    Tier: "Basic",
  },
  {
    tokenId: 2320,
    Tier: "Mythic",
  },
  {
    tokenId: 2321,
    Tier: "Rare",
  },
  {
    tokenId: 2322,
    Tier: "Basic",
  },
  {
    tokenId: 2323,
    Tier: "Basic",
  },
  {
    tokenId: 2324,
    Tier: "Epic",
  },
  {
    tokenId: 2325,
    Tier: "Common",
  },
  {
    tokenId: 2326,
    Tier: "Basic",
  },
  {
    tokenId: 2327,
    Tier: "Rare",
  },
  {
    tokenId: 2328,
    Tier: "Uncommon",
  },
  {
    tokenId: 2329,
    Tier: "Basic",
  },
  {
    tokenId: 2330,
    Tier: "Basic",
  },
  {
    tokenId: 2331,
    Tier: "Common",
  },
  {
    tokenId: 2332,
    Tier: "Basic",
  },
  {
    tokenId: 2333,
    Tier: "Uncommon",
  },
  {
    tokenId: 2334,
    Tier: "Epic",
  },
  {
    tokenId: 2335,
    Tier: "Epic",
  },
  {
    tokenId: 2336,
    Tier: "Rare",
  },
  {
    tokenId: 2337,
    Tier: "Common",
  },
  {
    tokenId: 2338,
    Tier: "Basic",
  },
  {
    tokenId: 2339,
    Tier: "Uncommon",
  },
  {
    tokenId: 2340,
    Tier: "Basic",
  },
  {
    tokenId: 2341,
    Tier: "Legendary",
  },
  {
    tokenId: 2342,
    Tier: "Basic",
  },
  {
    tokenId: 2343,
    Tier: "Basic",
  },
  {
    tokenId: 2344,
    Tier: "Uncommon",
  },
  {
    tokenId: 2345,
    Tier: "Common",
  },
  {
    tokenId: 2346,
    Tier: "Basic",
  },
  {
    tokenId: 2347,
    Tier: "Basic",
  },
  {
    tokenId: 2348,
    Tier: "Common",
  },
  {
    tokenId: 2349,
    Tier: "Basic",
  },
  {
    tokenId: 2350,
    Tier: "Uncommon",
  },
  {
    tokenId: 2351,
    Tier: "Rare",
  },
  {
    tokenId: 2352,
    Tier: "Basic",
  },
  {
    tokenId: 2353,
    Tier: "Basic",
  },
  {
    tokenId: 2354,
    Tier: "Common",
  },
  {
    tokenId: 2355,
    Tier: "Basic",
  },
  {
    tokenId: 2356,
    Tier: "Common",
  },
  {
    tokenId: 2357,
    Tier: "Basic",
  },
  {
    tokenId: 2358,
    Tier: "Basic",
  },
  {
    tokenId: 2359,
    Tier: "Basic",
  },
  {
    tokenId: 2360,
    Tier: "Common",
  },
  {
    tokenId: 2361,
    Tier: "Common",
  },
  {
    tokenId: 2362,
    Tier: "Mythic",
  },
  {
    tokenId: 2363,
    Tier: "Epic",
  },
  {
    tokenId: 2364,
    Tier: "Common",
  },
  {
    tokenId: 2365,
    Tier: "Basic",
  },
  {
    tokenId: 2366,
    Tier: "Basic",
  },
  {
    tokenId: 2367,
    Tier: "Basic",
  },
  {
    tokenId: 2368,
    Tier: "Common",
  },
  {
    tokenId: 2369,
    Tier: "Rare",
  },
  {
    tokenId: 2370,
    Tier: "Legendary",
  },
  {
    tokenId: 2371,
    Tier: "Common",
  },
  {
    tokenId: 2372,
    Tier: "Mythic",
  },
  {
    tokenId: 2373,
    Tier: "Basic",
  },
  {
    tokenId: 2374,
    Tier: "Common",
  },
  {
    tokenId: 2375,
    Tier: "Epic",
  },
  {
    tokenId: 2376,
    Tier: "Legendary",
  },
  {
    tokenId: 2377,
    Tier: "Rare",
  },
  {
    tokenId: 2378,
    Tier: "Basic",
  },
  {
    tokenId: 2379,
    Tier: "Common",
  },
  {
    tokenId: 2380,
    Tier: "Epic",
  },
  {
    tokenId: 2381,
    Tier: "Uncommon",
  },
  {
    tokenId: 2382,
    Tier: "Uncommon",
  },
  {
    tokenId: 2383,
    Tier: "Basic",
  },
  {
    tokenId: 2384,
    Tier: "Basic",
  },
  {
    tokenId: 2385,
    Tier: "Legendary",
  },
  {
    tokenId: 2386,
    Tier: "Basic",
  },
  {
    tokenId: 2387,
    Tier: "Rare",
  },
  {
    tokenId: 2388,
    Tier: "Basic",
  },
  {
    tokenId: 2389,
    Tier: "Rare",
  },
  {
    tokenId: 2390,
    Tier: "Basic",
  },
  {
    tokenId: 2391,
    Tier: "Legendary",
  },
  {
    tokenId: 2392,
    Tier: "Basic",
  },
  {
    tokenId: 2393,
    Tier: "Mythic",
  },
  {
    tokenId: 2394,
    Tier: "Common",
  },
  {
    tokenId: 2395,
    Tier: "Legendary",
  },
  {
    tokenId: 2396,
    Tier: "Basic",
  },
  {
    tokenId: 2397,
    Tier: "Rare",
  },
  {
    tokenId: 2398,
    Tier: "Rare",
  },
  {
    tokenId: 2399,
    Tier: "Uncommon",
  },
  {
    tokenId: 2400,
    Tier: "Rare",
  },
  {
    tokenId: 2401,
    Tier: "Uncommon",
  },
  {
    tokenId: 2402,
    Tier: "Common",
  },
  {
    tokenId: 2403,
    Tier: "Basic",
  },
  {
    tokenId: 2404,
    Tier: "Basic",
  },
  {
    tokenId: 2405,
    Tier: "Basic",
  },
  {
    tokenId: 2406,
    Tier: "Basic",
  },
  {
    tokenId: 2407,
    Tier: "Uncommon",
  },
  {
    tokenId: 2408,
    Tier: "Basic",
  },
  {
    tokenId: 2409,
    Tier: "Basic",
  },
  {
    tokenId: 2410,
    Tier: "Common",
  },
  {
    tokenId: 2411,
    Tier: "Common",
  },
  {
    tokenId: 2412,
    Tier: "Basic",
  },
  {
    tokenId: 2413,
    Tier: "Basic",
  },
  {
    tokenId: 2414,
    Tier: "Basic",
  },
  {
    tokenId: 2415,
    Tier: "Basic",
  },
  {
    tokenId: 2416,
    Tier: "Basic",
  },
  {
    tokenId: 2417,
    Tier: "Basic",
  },
  {
    tokenId: 2418,
    Tier: "Epic",
  },
  {
    tokenId: 2419,
    Tier: "Basic",
  },
  {
    tokenId: 2420,
    Tier: "Rare",
  },
  {
    tokenId: 2421,
    Tier: "Basic",
  },
  {
    tokenId: 2422,
    Tier: "Basic",
  },
  {
    tokenId: 2423,
    Tier: "Basic",
  },
  {
    tokenId: 2424,
    Tier: "Basic",
  },
  {
    tokenId: 2425,
    Tier: "Basic",
  },
  {
    tokenId: 2426,
    Tier: "Basic",
  },
  {
    tokenId: 2427,
    Tier: "Uncommon",
  },
  {
    tokenId: 2428,
    Tier: "Common",
  },
  {
    tokenId: 2429,
    Tier: "Basic",
  },
  {
    tokenId: 2430,
    Tier: "Common",
  },
  {
    tokenId: 2431,
    Tier: "Basic",
  },
  {
    tokenId: 2432,
    Tier: "Basic",
  },
  {
    tokenId: 2433,
    Tier: "Basic",
  },
  {
    tokenId: 2434,
    Tier: "Common",
  },
  {
    tokenId: 2435,
    Tier: "Basic",
  },
  {
    tokenId: 2436,
    Tier: "Common",
  },
  {
    tokenId: 2437,
    Tier: "Basic",
  },
  {
    tokenId: 2438,
    Tier: "Uncommon",
  },
  {
    tokenId: 2439,
    Tier: "Exotic",
  },
  {
    tokenId: 2440,
    Tier: "Basic",
  },
  {
    tokenId: 2441,
    Tier: "Uncommon",
  },
  {
    tokenId: 2442,
    Tier: "Uncommon",
  },
  {
    tokenId: 2443,
    Tier: "Basic",
  },
  {
    tokenId: 2444,
    Tier: "Rare",
  },
  {
    tokenId: 2445,
    Tier: "Basic",
  },
  {
    tokenId: 2446,
    Tier: "Basic",
  },
  {
    tokenId: 2447,
    Tier: "Rare",
  },
  {
    tokenId: 2448,
    Tier: "Common",
  },
  {
    tokenId: 2449,
    Tier: "Basic",
  },
  {
    tokenId: 2450,
    Tier: "Basic",
  },
  {
    tokenId: 2451,
    Tier: "Common",
  },
  {
    tokenId: 2452,
    Tier: "Basic",
  },
  {
    tokenId: 2453,
    Tier: "Common",
  },
  {
    tokenId: 2454,
    Tier: "Basic",
  },
  {
    tokenId: 2455,
    Tier: "Basic",
  },
  {
    tokenId: 2456,
    Tier: "Exotic",
  },
  {
    tokenId: 2457,
    Tier: "Mythic",
  },
  {
    tokenId: 2458,
    Tier: "Common",
  },
  {
    tokenId: 2459,
    Tier: "Rare",
  },
  {
    tokenId: 2460,
    Tier: "Basic",
  },
  {
    tokenId: 2461,
    Tier: "Uncommon",
  },
  {
    tokenId: 2462,
    Tier: "Basic",
  },
  {
    tokenId: 2463,
    Tier: "Basic",
  },
  {
    tokenId: 2464,
    Tier: "Basic",
  },
  {
    tokenId: 2465,
    Tier: "Basic",
  },
  {
    tokenId: 2466,
    Tier: "Rare",
  },
  {
    tokenId: 2467,
    Tier: "Common",
  },
  {
    tokenId: 2468,
    Tier: "Basic",
  },
  {
    tokenId: 2469,
    Tier: "Basic",
  },
  {
    tokenId: 2470,
    Tier: "Epic",
  },
  {
    tokenId: 2471,
    Tier: "Common",
  },
  {
    tokenId: 2472,
    Tier: "Basic",
  },
  {
    tokenId: 2473,
    Tier: "Common",
  },
  {
    tokenId: 2474,
    Tier: "Common",
  },
  {
    tokenId: 2475,
    Tier: "Rare",
  },
  {
    tokenId: 2476,
    Tier: "Basic",
  },
  {
    tokenId: 2477,
    Tier: "Basic",
  },
  {
    tokenId: 2478,
    Tier: "Basic",
  },
  {
    tokenId: 2479,
    Tier: "Uncommon",
  },
  {
    tokenId: 2480,
    Tier: "Uncommon",
  },
  {
    tokenId: 2481,
    Tier: "Common",
  },
  {
    tokenId: 2482,
    Tier: "Epic",
  },
  {
    tokenId: 2483,
    Tier: "Basic",
  },
  {
    tokenId: 2484,
    Tier: "Basic",
  },
  {
    tokenId: 2485,
    Tier: "Basic",
  },
  {
    tokenId: 2486,
    Tier: "Rare",
  },
  {
    tokenId: 2487,
    Tier: "Common",
  },
  {
    tokenId: 2488,
    Tier: "Mythic",
  },
  {
    tokenId: 2489,
    Tier: "Basic",
  },
  {
    tokenId: 2490,
    Tier: "Uncommon",
  },
  {
    tokenId: 2491,
    Tier: "Basic",
  },
  {
    tokenId: 2492,
    Tier: "Basic",
  },
  {
    tokenId: 2493,
    Tier: "Common",
  },
  {
    tokenId: 2494,
    Tier: "Basic",
  },
  {
    tokenId: 2495,
    Tier: "Uncommon",
  },
  {
    tokenId: 2496,
    Tier: "Uncommon",
  },
  {
    tokenId: 2497,
    Tier: "Mythic",
  },
  {
    tokenId: 2498,
    Tier: "Epic",
  },
  {
    tokenId: 2499,
    Tier: "Uncommon",
  },
  {
    tokenId: 2500,
    Tier: "Basic",
  },
  {
    tokenId: 2501,
    Tier: "Rare",
  },
  {
    tokenId: 2502,
    Tier: "Basic",
  },
  {
    tokenId: 2503,
    Tier: "Uncommon",
  },
  {
    tokenId: 2504,
    Tier: "Common",
  },
  {
    tokenId: 2505,
    Tier: "Mythic",
  },
  {
    tokenId: 2506,
    Tier: "Basic",
  },
  {
    tokenId: 2507,
    Tier: "Basic",
  },
  {
    tokenId: 2508,
    Tier: "Basic",
  },
  {
    tokenId: 2509,
    Tier: "Basic",
  },
  {
    tokenId: 2510,
    Tier: "Basic",
  },
  {
    tokenId: 2511,
    Tier: "Basic",
  },
  {
    tokenId: 2512,
    Tier: "Epic",
  },
  {
    tokenId: 2513,
    Tier: "Epic",
  },
  {
    tokenId: 2514,
    Tier: "Basic",
  },
  {
    tokenId: 2515,
    Tier: "Basic",
  },
  {
    tokenId: 2516,
    Tier: "Basic",
  },
  {
    tokenId: 2517,
    Tier: "Basic",
  },
  {
    tokenId: 2518,
    Tier: "Epic",
  },
  {
    tokenId: 2519,
    Tier: "Basic",
  },
  {
    tokenId: 2520,
    Tier: "Mythic",
  },
  {
    tokenId: 2521,
    Tier: "Uncommon",
  },
  {
    tokenId: 2522,
    Tier: "Basic",
  },
  {
    tokenId: 2523,
    Tier: "Basic",
  },
  {
    tokenId: 2524,
    Tier: "Common",
  },
  {
    tokenId: 2525,
    Tier: "Basic",
  },
  {
    tokenId: 2526,
    Tier: "Basic",
  },
  {
    tokenId: 2527,
    Tier: "Common",
  },
  {
    tokenId: 2528,
    Tier: "Uncommon",
  },
  {
    tokenId: 2529,
    Tier: "Uncommon",
  },
  {
    tokenId: 2530,
    Tier: "Basic",
  },
  {
    tokenId: 2531,
    Tier: "Basic",
  },
  {
    tokenId: 2532,
    Tier: "Basic",
  },
  {
    tokenId: 2533,
    Tier: "Basic",
  },
  {
    tokenId: 2534,
    Tier: "Basic",
  },
  {
    tokenId: 2535,
    Tier: "Basic",
  },
  {
    tokenId: 2536,
    Tier: "Epic",
  },
  {
    tokenId: 2537,
    Tier: "Basic",
  },
  {
    tokenId: 2538,
    Tier: "Rare",
  },
  {
    tokenId: 2539,
    Tier: "Basic",
  },
  {
    tokenId: 2540,
    Tier: "Rare",
  },
  {
    tokenId: 2541,
    Tier: "Basic",
  },
  {
    tokenId: 2542,
    Tier: "Common",
  },
  {
    tokenId: 2543,
    Tier: "Basic",
  },
  {
    tokenId: 2544,
    Tier: "Basic",
  },
  {
    tokenId: 2545,
    Tier: "Epic",
  },
  {
    tokenId: 2546,
    Tier: "Uncommon",
  },
  {
    tokenId: 2547,
    Tier: "Rare",
  },
  {
    tokenId: 2548,
    Tier: "Epic",
  },
  {
    tokenId: 2549,
    Tier: "Rare",
  },
  {
    tokenId: 2550,
    Tier: "Basic",
  },
  {
    tokenId: 2551,
    Tier: "Uncommon",
  },
  {
    tokenId: 2552,
    Tier: "Basic",
  },
  {
    tokenId: 2553,
    Tier: "Epic",
  },
  {
    tokenId: 2554,
    Tier: "Legendary",
  },
  {
    tokenId: 2555,
    Tier: "Basic",
  },
  {
    tokenId: 2556,
    Tier: "Uncommon",
  },
  {
    tokenId: 2557,
    Tier: "Common",
  },
  {
    tokenId: 2558,
    Tier: "Basic",
  },
  {
    tokenId: 2559,
    Tier: "Basic",
  },
  {
    tokenId: 2560,
    Tier: "Common",
  },
  {
    tokenId: 2561,
    Tier: "Mythic",
  },
  {
    tokenId: 2562,
    Tier: "Basic",
  },
  {
    tokenId: 2563,
    Tier: "Mythic",
  },
  {
    tokenId: 2564,
    Tier: "Basic",
  },
  {
    tokenId: 2565,
    Tier: "Basic",
  },
  {
    tokenId: 2566,
    Tier: "Common",
  },
  {
    tokenId: 2567,
    Tier: "Rare",
  },
  {
    tokenId: 2568,
    Tier: "Rare",
  },
  {
    tokenId: 2569,
    Tier: "Basic",
  },
  {
    tokenId: 2570,
    Tier: "Basic",
  },
  {
    tokenId: 2571,
    Tier: "Basic",
  },
  {
    tokenId: 2572,
    Tier: "Common",
  },
  {
    tokenId: 2573,
    Tier: "Basic",
  },
  {
    tokenId: 2574,
    Tier: "Basic",
  },
  {
    tokenId: 2575,
    Tier: "Common",
  },
  {
    tokenId: 2576,
    Tier: "Common",
  },
  {
    tokenId: 2577,
    Tier: "Uncommon",
  },
  {
    tokenId: 2578,
    Tier: "Epic",
  },
  {
    tokenId: 2579,
    Tier: "Common",
  },
  {
    tokenId: 2580,
    Tier: "Basic",
  },
  {
    tokenId: 2581,
    Tier: "Basic",
  },
  {
    tokenId: 2582,
    Tier: "Rare",
  },
  {
    tokenId: 2583,
    Tier: "Uncommon",
  },
  {
    tokenId: 2584,
    Tier: "Basic",
  },
  {
    tokenId: 2585,
    Tier: "Epic",
  },
  {
    tokenId: 2586,
    Tier: "Basic",
  },
  {
    tokenId: 2587,
    Tier: "Basic",
  },
  {
    tokenId: 2588,
    Tier: "Basic",
  },
  {
    tokenId: 2589,
    Tier: "Legendary",
  },
  {
    tokenId: 2590,
    Tier: "Mythic",
  },
  {
    tokenId: 2591,
    Tier: "Rare",
  },
  {
    tokenId: 2592,
    Tier: "Basic",
  },
  {
    tokenId: 2593,
    Tier: "Basic",
  },
  {
    tokenId: 2594,
    Tier: "Common",
  },
  {
    tokenId: 2595,
    Tier: "Basic",
  },
  {
    tokenId: 2596,
    Tier: "Uncommon",
  },
  {
    tokenId: 2597,
    Tier: "Rare",
  },
  {
    tokenId: 2598,
    Tier: "Basic",
  },
  {
    tokenId: 2599,
    Tier: "Basic",
  },
  {
    tokenId: 2600,
    Tier: "Epic",
  },
  {
    tokenId: 2601,
    Tier: "Basic",
  },
  {
    tokenId: 2602,
    Tier: "Exotic",
  },
  {
    tokenId: 2603,
    Tier: "Basic",
  },
  {
    tokenId: 2604,
    Tier: "Common",
  },
  {
    tokenId: 2605,
    Tier: "Basic",
  },
  {
    tokenId: 2606,
    Tier: "Basic",
  },
  {
    tokenId: 2607,
    Tier: "Basic",
  },
  {
    tokenId: 2608,
    Tier: "Basic",
  },
  {
    tokenId: 2609,
    Tier: "Basic",
  },
  {
    tokenId: 2610,
    Tier: "Mythic",
  },
  {
    tokenId: 2611,
    Tier: "Legendary",
  },
  {
    tokenId: 2612,
    Tier: "Basic",
  },
  {
    tokenId: 2613,
    Tier: "Basic",
  },
  {
    tokenId: 2614,
    Tier: "Common",
  },
  {
    tokenId: 2615,
    Tier: "Basic",
  },
  {
    tokenId: 2616,
    Tier: "Basic",
  },
  {
    tokenId: 2617,
    Tier: "Basic",
  },
  {
    tokenId: 2618,
    Tier: "Basic",
  },
  {
    tokenId: 2619,
    Tier: "Common",
  },
  {
    tokenId: 2620,
    Tier: "Legendary",
  },
  {
    tokenId: 2621,
    Tier: "Common",
  },
  {
    tokenId: 2622,
    Tier: "Uncommon",
  },
  {
    tokenId: 2623,
    Tier: "Uncommon",
  },
  {
    tokenId: 2624,
    Tier: "Uncommon",
  },
  {
    tokenId: 2625,
    Tier: "Legendary",
  },
  {
    tokenId: 2626,
    Tier: "Basic",
  },
  {
    tokenId: 2627,
    Tier: "Uncommon",
  },
  {
    tokenId: 2628,
    Tier: "Basic",
  },
  {
    tokenId: 2629,
    Tier: "Basic",
  },
  {
    tokenId: 2630,
    Tier: "Epic",
  },
  {
    tokenId: 2631,
    Tier: "Rare",
  },
  {
    tokenId: 2632,
    Tier: "Uncommon",
  },
  {
    tokenId: 2633,
    Tier: "Rare",
  },
  {
    tokenId: 2634,
    Tier: "Basic",
  },
  {
    tokenId: 2635,
    Tier: "Epic",
  },
  {
    tokenId: 2636,
    Tier: "Basic",
  },
  {
    tokenId: 2637,
    Tier: "Common",
  },
  {
    tokenId: 2638,
    Tier: "Epic",
  },
  {
    tokenId: 2639,
    Tier: "Basic",
  },
  {
    tokenId: 2640,
    Tier: "Basic",
  },
  {
    tokenId: 2641,
    Tier: "Common",
  },
  {
    tokenId: 2642,
    Tier: "Basic",
  },
  {
    tokenId: 2643,
    Tier: "Common",
  },
  {
    tokenId: 2644,
    Tier: "Basic",
  },
  {
    tokenId: 2645,
    Tier: "Common",
  },
  {
    tokenId: 2646,
    Tier: "Basic",
  },
  {
    tokenId: 2647,
    Tier: "Rare",
  },
  {
    tokenId: 2648,
    Tier: "Basic",
  },
  {
    tokenId: 2649,
    Tier: "Basic",
  },
  {
    tokenId: 2650,
    Tier: "Common",
  },
  {
    tokenId: 2651,
    Tier: "Basic",
  },
  {
    tokenId: 2652,
    Tier: "Basic",
  },
  {
    tokenId: 2653,
    Tier: "Basic",
  },
  {
    tokenId: 2654,
    Tier: "Epic",
  },
  {
    tokenId: 2655,
    Tier: "Uncommon",
  },
  {
    tokenId: 2656,
    Tier: "Basic",
  },
  {
    tokenId: 2657,
    Tier: "Basic",
  },
  {
    tokenId: 2658,
    Tier: "Basic",
  },
  {
    tokenId: 2659,
    Tier: "Basic",
  },
  {
    tokenId: 2660,
    Tier: "Basic",
  },
  {
    tokenId: 2661,
    Tier: "Rare",
  },
  {
    tokenId: 2662,
    Tier: "Basic",
  },
  {
    tokenId: 2663,
    Tier: "Basic",
  },
  {
    tokenId: 2664,
    Tier: "Basic",
  },
  {
    tokenId: 2665,
    Tier: "Common",
  },
  {
    tokenId: 2666,
    Tier: "Epic",
  },
  {
    tokenId: 2667,
    Tier: "Uncommon",
  },
  {
    tokenId: 2668,
    Tier: "Basic",
  },
  {
    tokenId: 2669,
    Tier: "Basic",
  },
  {
    tokenId: 2670,
    Tier: "Uncommon",
  },
  {
    tokenId: 2671,
    Tier: "Basic",
  },
  {
    tokenId: 2672,
    Tier: "Basic",
  },
  {
    tokenId: 2673,
    Tier: "Basic",
  },
  {
    tokenId: 2674,
    Tier: "Basic",
  },
  {
    tokenId: 2675,
    Tier: "Epic",
  },
  {
    tokenId: 2676,
    Tier: "Uncommon",
  },
  {
    tokenId: 2677,
    Tier: "Basic",
  },
  {
    tokenId: 2678,
    Tier: "Basic",
  },
  {
    tokenId: 2679,
    Tier: "Common",
  },
  {
    tokenId: 2680,
    Tier: "Basic",
  },
  {
    tokenId: 2681,
    Tier: "Basic",
  },
  {
    tokenId: 2682,
    Tier: "Basic",
  },
  {
    tokenId: 2683,
    Tier: "Basic",
  },
  {
    tokenId: 2684,
    Tier: "Epic",
  },
  {
    tokenId: 2685,
    Tier: "Common",
  },
  {
    tokenId: 2686,
    Tier: "Rare",
  },
  {
    tokenId: 2687,
    Tier: "Basic",
  },
  {
    tokenId: 2688,
    Tier: "Uncommon",
  },
  {
    tokenId: 2689,
    Tier: "Common",
  },
  {
    tokenId: 2690,
    Tier: "Uncommon",
  },
  {
    tokenId: 2691,
    Tier: "Basic",
  },
  {
    tokenId: 2692,
    Tier: "Common",
  },
  {
    tokenId: 2693,
    Tier: "Basic",
  },
  {
    tokenId: 2694,
    Tier: "Basic",
  },
  {
    tokenId: 2695,
    Tier: "Uncommon",
  },
  {
    tokenId: 2696,
    Tier: "Mythic",
  },
  {
    tokenId: 2697,
    Tier: "Rare",
  },
  {
    tokenId: 2698,
    Tier: "Basic",
  },
  {
    tokenId: 2699,
    Tier: "Basic",
  },
  {
    tokenId: 2700,
    Tier: "Epic",
  },
  {
    tokenId: 2701,
    Tier: "Basic",
  },
  {
    tokenId: 2702,
    Tier: "Uncommon",
  },
  {
    tokenId: 2703,
    Tier: "Common",
  },
  {
    tokenId: 2704,
    Tier: "Common",
  },
  {
    tokenId: 2705,
    Tier: "Uncommon",
  },
  {
    tokenId: 2706,
    Tier: "Basic",
  },
  {
    tokenId: 2707,
    Tier: "Common",
  },
  {
    tokenId: 2708,
    Tier: "Uncommon",
  },
  {
    tokenId: 2709,
    Tier: "Basic",
  },
  {
    tokenId: 2710,
    Tier: "Uncommon",
  },
  {
    tokenId: 2711,
    Tier: "Common",
  },
  {
    tokenId: 2712,
    Tier: "Exotic",
  },
  {
    tokenId: 2713,
    Tier: "Rare",
  },
  {
    tokenId: 2714,
    Tier: "Basic",
  },
  {
    tokenId: 2715,
    Tier: "Mythic",
  },
  {
    tokenId: 2716,
    Tier: "Basic",
  },
  {
    tokenId: 2717,
    Tier: "Basic",
  },
  {
    tokenId: 2718,
    Tier: "Common",
  },
  {
    tokenId: 2719,
    Tier: "Common",
  },
  {
    tokenId: 2720,
    Tier: "Basic",
  },
  {
    tokenId: 2721,
    Tier: "Basic",
  },
  {
    tokenId: 2722,
    Tier: "Basic",
  },
  {
    tokenId: 2723,
    Tier: "Basic",
  },
  {
    tokenId: 2724,
    Tier: "Uncommon",
  },
  {
    tokenId: 2725,
    Tier: "Basic",
  },
  {
    tokenId: 2726,
    Tier: "Common",
  },
  {
    tokenId: 2727,
    Tier: "Rare",
  },
  {
    tokenId: 2728,
    Tier: "Basic",
  },
  {
    tokenId: 2729,
    Tier: "Basic",
  },
  {
    tokenId: 2730,
    Tier: "Basic",
  },
  {
    tokenId: 2731,
    Tier: "Basic",
  },
  {
    tokenId: 2732,
    Tier: "Basic",
  },
  {
    tokenId: 2733,
    Tier: "Rare",
  },
  {
    tokenId: 2734,
    Tier: "Uncommon",
  },
  {
    tokenId: 2735,
    Tier: "Uncommon",
  },
  {
    tokenId: 2736,
    Tier: "Common",
  },
  {
    tokenId: 2737,
    Tier: "Basic",
  },
  {
    tokenId: 2738,
    Tier: "Common",
  },
  {
    tokenId: 2739,
    Tier: "Uncommon",
  },
  {
    tokenId: 2740,
    Tier: "Basic",
  },
  {
    tokenId: 2741,
    Tier: "Epic",
  },
  {
    tokenId: 2742,
    Tier: "Basic",
  },
  {
    tokenId: 2743,
    Tier: "Basic",
  },
  {
    tokenId: 2744,
    Tier: "Basic",
  },
  {
    tokenId: 2745,
    Tier: "Legendary",
  },
  {
    tokenId: 2746,
    Tier: "Basic",
  },
  {
    tokenId: 2747,
    Tier: "Epic",
  },
  {
    tokenId: 2748,
    Tier: "Basic",
  },
  {
    tokenId: 2749,
    Tier: "Basic",
  },
  {
    tokenId: 2750,
    Tier: "Epic",
  },
  {
    tokenId: 2751,
    Tier: "Basic",
  },
  {
    tokenId: 2752,
    Tier: "Basic",
  },
  {
    tokenId: 2753,
    Tier: "Rare",
  },
  {
    tokenId: 2754,
    Tier: "Basic",
  },
  {
    tokenId: 2755,
    Tier: "Basic",
  },
  {
    tokenId: 2756,
    Tier: "Uncommon",
  },
  {
    tokenId: 2757,
    Tier: "Mythic",
  },
  {
    tokenId: 2758,
    Tier: "Basic",
  },
  {
    tokenId: 2759,
    Tier: "Epic",
  },
  {
    tokenId: 2760,
    Tier: "Uncommon",
  },
  {
    tokenId: 2761,
    Tier: "Basic",
  },
  {
    tokenId: 2762,
    Tier: "Epic",
  },
  {
    tokenId: 2763,
    Tier: "Basic",
  },
  {
    tokenId: 2764,
    Tier: "Basic",
  },
  {
    tokenId: 2765,
    Tier: "Basic",
  },
  {
    tokenId: 2766,
    Tier: "Epic",
  },
  {
    tokenId: 2767,
    Tier: "Legendary",
  },
  {
    tokenId: 2768,
    Tier: "Basic",
  },
  {
    tokenId: 2769,
    Tier: "Common",
  },
  {
    tokenId: 2770,
    Tier: "Epic",
  },
  {
    tokenId: 2771,
    Tier: "Epic",
  },
  {
    tokenId: 2772,
    Tier: "Basic",
  },
  {
    tokenId: 2773,
    Tier: "Basic",
  },
  {
    tokenId: 2774,
    Tier: "Mythic",
  },
  {
    tokenId: 2775,
    Tier: "Common",
  },
  {
    tokenId: 2776,
    Tier: "Basic",
  },
  {
    tokenId: 2777,
    Tier: "Basic",
  },
  {
    tokenId: 2778,
    Tier: "Basic",
  },
  {
    tokenId: 2779,
    Tier: "Exotic",
  },
  {
    tokenId: 2780,
    Tier: "Uncommon",
  },
  {
    tokenId: 2781,
    Tier: "Basic",
  },
  {
    tokenId: 2782,
    Tier: "Basic",
  },
  {
    tokenId: 2783,
    Tier: "Uncommon",
  },
  {
    tokenId: 2784,
    Tier: "Basic",
  },
  {
    tokenId: 2785,
    Tier: "Mythic",
  },
  {
    tokenId: 2786,
    Tier: "Basic",
  },
  {
    tokenId: 2787,
    Tier: "Common",
  },
  {
    tokenId: 2788,
    Tier: "Basic",
  },
  {
    tokenId: 2789,
    Tier: "Basic",
  },
  {
    tokenId: 2790,
    Tier: "Basic",
  },
  {
    tokenId: 2791,
    Tier: "Basic",
  },
  {
    tokenId: 2792,
    Tier: "Mythic",
  },
  {
    tokenId: 2793,
    Tier: "Uncommon",
  },
  {
    tokenId: 2794,
    Tier: "Basic",
  },
  {
    tokenId: 2795,
    Tier: "Legendary",
  },
  {
    tokenId: 2796,
    Tier: "Basic",
  },
  {
    tokenId: 2797,
    Tier: "Basic",
  },
  {
    tokenId: 2798,
    Tier: "Epic",
  },
  {
    tokenId: 2799,
    Tier: "Basic",
  },
  {
    tokenId: 2800,
    Tier: "Rare",
  },
  {
    tokenId: 2801,
    Tier: "Basic",
  },
  {
    tokenId: 2802,
    Tier: "Rare",
  },
  {
    tokenId: 2803,
    Tier: "Common",
  },
  {
    tokenId: 2804,
    Tier: "Basic",
  },
  {
    tokenId: 2805,
    Tier: "Basic",
  },
  {
    tokenId: 2806,
    Tier: "Basic",
  },
  {
    tokenId: 2807,
    Tier: "Basic",
  },
  {
    tokenId: 2808,
    Tier: "Basic",
  },
  {
    tokenId: 2809,
    Tier: "Epic",
  },
  {
    tokenId: 2810,
    Tier: "Epic",
  },
  {
    tokenId: 2811,
    Tier: "Basic",
  },
  {
    tokenId: 2812,
    Tier: "Common",
  },
  {
    tokenId: 2813,
    Tier: "Legendary",
  },
  {
    tokenId: 2814,
    Tier: "Basic",
  },
  {
    tokenId: 2815,
    Tier: "Epic",
  },
  {
    tokenId: 2816,
    Tier: "Basic",
  },
  {
    tokenId: 2817,
    Tier: "Basic",
  },
  {
    tokenId: 2818,
    Tier: "Uncommon",
  },
  {
    tokenId: 2819,
    Tier: "Legendary",
  },
  {
    tokenId: 2820,
    Tier: "Basic",
  },
  {
    tokenId: 2821,
    Tier: "Uncommon",
  },
  {
    tokenId: 2822,
    Tier: "Basic",
  },
  {
    tokenId: 2823,
    Tier: "Common",
  },
  {
    tokenId: 2824,
    Tier: "Uncommon",
  },
  {
    tokenId: 2825,
    Tier: "Common",
  },
  {
    tokenId: 2826,
    Tier: "Uncommon",
  },
  {
    tokenId: 2827,
    Tier: "Basic",
  },
  {
    tokenId: 2828,
    Tier: "Epic",
  },
  {
    tokenId: 2829,
    Tier: "Common",
  },
  {
    tokenId: 2830,
    Tier: "Uncommon",
  },
  {
    tokenId: 2831,
    Tier: "Uncommon",
  },
  {
    tokenId: 2832,
    Tier: "Rare",
  },
  {
    tokenId: 2833,
    Tier: "Basic",
  },
  {
    tokenId: 2834,
    Tier: "Basic",
  },
  {
    tokenId: 2835,
    Tier: "Basic",
  },
  {
    tokenId: 2836,
    Tier: "Rare",
  },
  {
    tokenId: 2837,
    Tier: "Common",
  },
  {
    tokenId: 2838,
    Tier: "Rare",
  },
  {
    tokenId: 2839,
    Tier: "Basic",
  },
  {
    tokenId: 2840,
    Tier: "Basic",
  },
  {
    tokenId: 2841,
    Tier: "Basic",
  },
  {
    tokenId: 2842,
    Tier: "Mythic",
  },
  {
    tokenId: 2843,
    Tier: "Uncommon",
  },
  {
    tokenId: 2844,
    Tier: "Basic",
  },
  {
    tokenId: 2845,
    Tier: "Basic",
  },
  {
    tokenId: 2846,
    Tier: "Basic",
  },
  {
    tokenId: 2847,
    Tier: "Common",
  },
  {
    tokenId: 2848,
    Tier: "Basic",
  },
  {
    tokenId: 2849,
    Tier: "Basic",
  },
  {
    tokenId: 2850,
    Tier: "Common",
  },
  {
    tokenId: 2851,
    Tier: "Common",
  },
  {
    tokenId: 2852,
    Tier: "Basic",
  },
  {
    tokenId: 2853,
    Tier: "Basic",
  },
  {
    tokenId: 2854,
    Tier: "Uncommon",
  },
  {
    tokenId: 2855,
    Tier: "Epic",
  },
  {
    tokenId: 2856,
    Tier: "Common",
  },
  {
    tokenId: 2857,
    Tier: "Uncommon",
  },
  {
    tokenId: 2858,
    Tier: "Basic",
  },
  {
    tokenId: 2859,
    Tier: "Basic",
  },
  {
    tokenId: 2860,
    Tier: "Basic",
  },
  {
    tokenId: 2861,
    Tier: "Basic",
  },
  {
    tokenId: 2862,
    Tier: "Uncommon",
  },
  {
    tokenId: 2863,
    Tier: "Basic",
  },
  {
    tokenId: 2864,
    Tier: "Common",
  },
  {
    tokenId: 2865,
    Tier: "Basic",
  },
  {
    tokenId: 2866,
    Tier: "Basic",
  },
  {
    tokenId: 2867,
    Tier: "Epic",
  },
  {
    tokenId: 2868,
    Tier: "Legendary",
  },
  {
    tokenId: 2869,
    Tier: "Rare",
  },
  {
    tokenId: 2870,
    Tier: "Exotic",
  },
  {
    tokenId: 2871,
    Tier: "Basic",
  },
  {
    tokenId: 2872,
    Tier: "Rare",
  },
  {
    tokenId: 2873,
    Tier: "Basic",
  },
  {
    tokenId: 2874,
    Tier: "Basic",
  },
  {
    tokenId: 2875,
    Tier: "Basic",
  },
  {
    tokenId: 2876,
    Tier: "Basic",
  },
  {
    tokenId: 2877,
    Tier: "Basic",
  },
  {
    tokenId: 2878,
    Tier: "Basic",
  },
  {
    tokenId: 2879,
    Tier: "Basic",
  },
  {
    tokenId: 2880,
    Tier: "Basic",
  },
  {
    tokenId: 2881,
    Tier: "Uncommon",
  },
  {
    tokenId: 2882,
    Tier: "Rare",
  },
  {
    tokenId: 2883,
    Tier: "Basic",
  },
  {
    tokenId: 2884,
    Tier: "Uncommon",
  },
  {
    tokenId: 2885,
    Tier: "Basic",
  },
  {
    tokenId: 2886,
    Tier: "Mythic",
  },
  {
    tokenId: 2887,
    Tier: "Basic",
  },
  {
    tokenId: 2888,
    Tier: "Common",
  },
  {
    tokenId: 2889,
    Tier: "Basic",
  },
  {
    tokenId: 2890,
    Tier: "Basic",
  },
  {
    tokenId: 2891,
    Tier: "Legendary",
  },
  {
    tokenId: 2892,
    Tier: "Common",
  },
  {
    tokenId: 2893,
    Tier: "Basic",
  },
  {
    tokenId: 2894,
    Tier: "Common",
  },
  {
    tokenId: 2895,
    Tier: "Basic",
  },
  {
    tokenId: 2896,
    Tier: "Basic",
  },
  {
    tokenId: 2897,
    Tier: "Uncommon",
  },
  {
    tokenId: 2898,
    Tier: "Basic",
  },
  {
    tokenId: 2899,
    Tier: "Basic",
  },
  {
    tokenId: 2900,
    Tier: "Basic",
  },
  {
    tokenId: 2901,
    Tier: "Common",
  },
  {
    tokenId: 2902,
    Tier: "Basic",
  },
  {
    tokenId: 2903,
    Tier: "Common",
  },
  {
    tokenId: 2904,
    Tier: "Basic",
  },
  {
    tokenId: 2905,
    Tier: "Basic",
  },
  {
    tokenId: 2906,
    Tier: "Uncommon",
  },
  {
    tokenId: 2907,
    Tier: "Rare",
  },
  {
    tokenId: 2908,
    Tier: "Basic",
  },
  {
    tokenId: 2909,
    Tier: "Basic",
  },
  {
    tokenId: 2910,
    Tier: "Uncommon",
  },
  {
    tokenId: 2911,
    Tier: "Basic",
  },
  {
    tokenId: 2912,
    Tier: "Basic",
  },
  {
    tokenId: 2913,
    Tier: "Epic",
  },
  {
    tokenId: 2914,
    Tier: "Rare",
  },
  {
    tokenId: 2915,
    Tier: "Epic",
  },
  {
    tokenId: 2916,
    Tier: "Basic",
  },
  {
    tokenId: 2917,
    Tier: "Basic",
  },
  {
    tokenId: 2918,
    Tier: "Basic",
  },
  {
    tokenId: 2919,
    Tier: "Basic",
  },
  {
    tokenId: 2920,
    Tier: "Basic",
  },
  {
    tokenId: 2921,
    Tier: "Basic",
  },
  {
    tokenId: 2922,
    Tier: "Uncommon",
  },
  {
    tokenId: 2923,
    Tier: "Rare",
  },
  {
    tokenId: 2924,
    Tier: "Common",
  },
  {
    tokenId: 2925,
    Tier: "Rare",
  },
  {
    tokenId: 2926,
    Tier: "Common",
  },
  {
    tokenId: 2927,
    Tier: "Basic",
  },
  {
    tokenId: 2928,
    Tier: "Basic",
  },
  {
    tokenId: 2929,
    Tier: "Basic",
  },
  {
    tokenId: 2930,
    Tier: "Basic",
  },
  {
    tokenId: 2931,
    Tier: "Common",
  },
  {
    tokenId: 2932,
    Tier: "Basic",
  },
  {
    tokenId: 2933,
    Tier: "Exotic",
  },
  {
    tokenId: 2934,
    Tier: "Common",
  },
  {
    tokenId: 2935,
    Tier: "Rare",
  },
  {
    tokenId: 2936,
    Tier: "Uncommon",
  },
  {
    tokenId: 2937,
    Tier: "Basic",
  },
  {
    tokenId: 2938,
    Tier: "Basic",
  },
  {
    tokenId: 2939,
    Tier: "Legendary",
  },
  {
    tokenId: 2940,
    Tier: "Rare",
  },
  {
    tokenId: 2941,
    Tier: "Basic",
  },
  {
    tokenId: 2942,
    Tier: "Basic",
  },
  {
    tokenId: 2943,
    Tier: "Basic",
  },
  {
    tokenId: 2944,
    Tier: "Basic",
  },
  {
    tokenId: 2945,
    Tier: "Common",
  },
  {
    tokenId: 2946,
    Tier: "Common",
  },
  {
    tokenId: 2947,
    Tier: "Basic",
  },
  {
    tokenId: 2948,
    Tier: "Uncommon",
  },
  {
    tokenId: 2949,
    Tier: "Basic",
  },
  {
    tokenId: 2950,
    Tier: "Common",
  },
  {
    tokenId: 2951,
    Tier: "Uncommon",
  },
  {
    tokenId: 2952,
    Tier: "Epic",
  },
  {
    tokenId: 2953,
    Tier: "Uncommon",
  },
  {
    tokenId: 2954,
    Tier: "Basic",
  },
  {
    tokenId: 2955,
    Tier: "Basic",
  },
  {
    tokenId: 2956,
    Tier: "Basic",
  },
  {
    tokenId: 2957,
    Tier: "Rare",
  },
  {
    tokenId: 2958,
    Tier: "Legendary",
  },
  {
    tokenId: 2959,
    Tier: "Basic",
  },
  {
    tokenId: 2960,
    Tier: "Legendary",
  },
  {
    tokenId: 2961,
    Tier: "Rare",
  },
  {
    tokenId: 2962,
    Tier: "Basic",
  },
  {
    tokenId: 2963,
    Tier: "Basic",
  },
  {
    tokenId: 2964,
    Tier: "Uncommon",
  },
  {
    tokenId: 2965,
    Tier: "Basic",
  },
  {
    tokenId: 2966,
    Tier: "Basic",
  },
  {
    tokenId: 2967,
    Tier: "Common",
  },
  {
    tokenId: 2968,
    Tier: "Basic",
  },
  {
    tokenId: 2969,
    Tier: "Basic",
  },
  {
    tokenId: 2970,
    Tier: "Uncommon",
  },
  {
    tokenId: 2971,
    Tier: "Uncommon",
  },
  {
    tokenId: 2972,
    Tier: "Rare",
  },
  {
    tokenId: 2973,
    Tier: "Common",
  },
  {
    tokenId: 2974,
    Tier: "Common",
  },
  {
    tokenId: 2975,
    Tier: "Basic",
  },
  {
    tokenId: 2976,
    Tier: "Rare",
  },
  {
    tokenId: 2977,
    Tier: "Uncommon",
  },
  {
    tokenId: 2978,
    Tier: "Mythic",
  },
  {
    tokenId: 2979,
    Tier: "Basic",
  },
  {
    tokenId: 2980,
    Tier: "Basic",
  },
  {
    tokenId: 2981,
    Tier: "Basic",
  },
  {
    tokenId: 2982,
    Tier: "Basic",
  },
  {
    tokenId: 2983,
    Tier: "Epic",
  },
  {
    tokenId: 2984,
    Tier: "Basic",
  },
  {
    tokenId: 2985,
    Tier: "Epic",
  },
  {
    tokenId: 2986,
    Tier: "Common",
  },
  {
    tokenId: 2987,
    Tier: "Uncommon",
  },
  {
    tokenId: 2988,
    Tier: "Basic",
  },
  {
    tokenId: 2989,
    Tier: "Basic",
  },
  {
    tokenId: 2990,
    Tier: "Basic",
  },
  {
    tokenId: 2991,
    Tier: "Basic",
  },
  {
    tokenId: 2992,
    Tier: "Common",
  },
  {
    tokenId: 2993,
    Tier: "Uncommon",
  },
  {
    tokenId: 2994,
    Tier: "Basic",
  },
  {
    tokenId: 2995,
    Tier: "Rare",
  },
  {
    tokenId: 2996,
    Tier: "Epic",
  },
  {
    tokenId: 2997,
    Tier: "Epic",
  },
  {
    tokenId: 2998,
    Tier: "Uncommon",
  },
  {
    tokenId: 2999,
    Tier: "Basic",
  },
  {
    tokenId: 3000,
    Tier: "Mythic",
  },
  {
    tokenId: 3001,
    Tier: "Uncommon",
  },
  {
    tokenId: 3002,
    Tier: "Uncommon",
  },
  {
    tokenId: 3003,
    Tier: "Uncommon",
  },
  {
    tokenId: 3004,
    Tier: "Mythic",
  },
  {
    tokenId: 3005,
    Tier: "Rare",
  },
  {
    tokenId: 3006,
    Tier: "Basic",
  },
  {
    tokenId: 3007,
    Tier: "Basic",
  },
  {
    tokenId: 3008,
    Tier: "Basic",
  },
  {
    tokenId: 3009,
    Tier: "Legendary",
  },
  {
    tokenId: 3010,
    Tier: "Common",
  },
  {
    tokenId: 3011,
    Tier: "Legendary",
  },
  {
    tokenId: 3012,
    Tier: "Common",
  },
  {
    tokenId: 3013,
    Tier: "Legendary",
  },
  {
    tokenId: 3014,
    Tier: "Basic",
  },
  {
    tokenId: 3015,
    Tier: "Basic",
  },
  {
    tokenId: 3016,
    Tier: "Common",
  },
  {
    tokenId: 3017,
    Tier: "Common",
  },
  {
    tokenId: 3018,
    Tier: "Uncommon",
  },
  {
    tokenId: 3019,
    Tier: "Basic",
  },
  {
    tokenId: 3020,
    Tier: "Common",
  },
  {
    tokenId: 3021,
    Tier: "Basic",
  },
  {
    tokenId: 3022,
    Tier: "Epic",
  },
  {
    tokenId: 3023,
    Tier: "Basic",
  },
  {
    tokenId: 3024,
    Tier: "Epic",
  },
  {
    tokenId: 3025,
    Tier: "Basic",
  },
  {
    tokenId: 3026,
    Tier: "Mythic",
  },
  {
    tokenId: 3027,
    Tier: "Basic",
  },
  {
    tokenId: 3028,
    Tier: "Basic",
  },
  {
    tokenId: 3029,
    Tier: "Uncommon",
  },
  {
    tokenId: 3030,
    Tier: "Common",
  },
  {
    tokenId: 3031,
    Tier: "Basic",
  },
  {
    tokenId: 3032,
    Tier: "Epic",
  },
  {
    tokenId: 3033,
    Tier: "Mythic",
  },
  {
    tokenId: 3034,
    Tier: "Basic",
  },
  {
    tokenId: 3035,
    Tier: "Mythic",
  },
  {
    tokenId: 3036,
    Tier: "Basic",
  },
  {
    tokenId: 3037,
    Tier: "Basic",
  },
  {
    tokenId: 3038,
    Tier: "Basic",
  },
  {
    tokenId: 3039,
    Tier: "Rare",
  },
  {
    tokenId: 3040,
    Tier: "Uncommon",
  },
  {
    tokenId: 3041,
    Tier: "Common",
  },
  {
    tokenId: 3042,
    Tier: "Basic",
  },
  {
    tokenId: 3043,
    Tier: "Common",
  },
  {
    tokenId: 3044,
    Tier: "Mythic",
  },
  {
    tokenId: 3045,
    Tier: "Rare",
  },
  {
    tokenId: 3046,
    Tier: "Basic",
  },
  {
    tokenId: 3047,
    Tier: "Legendary",
  },
  {
    tokenId: 3048,
    Tier: "Uncommon",
  },
  {
    tokenId: 3049,
    Tier: "Mythic",
  },
  {
    tokenId: 3050,
    Tier: "Common",
  },
  {
    tokenId: 3051,
    Tier: "Common",
  },
  {
    tokenId: 3052,
    Tier: "Legendary",
  },
  {
    tokenId: 3053,
    Tier: "Rare",
  },
  {
    tokenId: 3054,
    Tier: "Epic",
  },
  {
    tokenId: 3055,
    Tier: "Rare",
  },
  {
    tokenId: 3056,
    Tier: "Epic",
  },
  {
    tokenId: 3057,
    Tier: "Basic",
  },
  {
    tokenId: 3058,
    Tier: "Basic",
  },
  {
    tokenId: 3059,
    Tier: "Basic",
  },
  {
    tokenId: 3060,
    Tier: "Epic",
  },
  {
    tokenId: 3061,
    Tier: "Basic",
  },
  {
    tokenId: 3062,
    Tier: "Basic",
  },
  {
    tokenId: 3063,
    Tier: "Basic",
  },
  {
    tokenId: 3064,
    Tier: "Basic",
  },
  {
    tokenId: 3065,
    Tier: "Legendary",
  },
  {
    tokenId: 3066,
    Tier: "Rare",
  },
  {
    tokenId: 3067,
    Tier: "Basic",
  },
  {
    tokenId: 3068,
    Tier: "Uncommon",
  },
  {
    tokenId: 3069,
    Tier: "Epic",
  },
  {
    tokenId: 3070,
    Tier: "Basic",
  },
  {
    tokenId: 3071,
    Tier: "Common",
  },
  {
    tokenId: 3072,
    Tier: "Basic",
  },
  {
    tokenId: 3073,
    Tier: "Rare",
  },
  {
    tokenId: 3074,
    Tier: "Rare",
  },
  {
    tokenId: 3075,
    Tier: "Mythic",
  },
  {
    tokenId: 3076,
    Tier: "Basic",
  },
  {
    tokenId: 3077,
    Tier: "Basic",
  },
  {
    tokenId: 3078,
    Tier: "Basic",
  },
  {
    tokenId: 3079,
    Tier: "Basic",
  },
  {
    tokenId: 3080,
    Tier: "Basic",
  },
  {
    tokenId: 3081,
    Tier: "Basic",
  },
  {
    tokenId: 3082,
    Tier: "Basic",
  },
  {
    tokenId: 3083,
    Tier: "Basic",
  },
  {
    tokenId: 3084,
    Tier: "Basic",
  },
  {
    tokenId: 3085,
    Tier: "Basic",
  },
  {
    tokenId: 3086,
    Tier: "Uncommon",
  },
  {
    tokenId: 3087,
    Tier: "Uncommon",
  },
  {
    tokenId: 3088,
    Tier: "Basic",
  },
  {
    tokenId: 3089,
    Tier: "Common",
  },
  {
    tokenId: 3090,
    Tier: "Basic",
  },
  {
    tokenId: 3091,
    Tier: "Basic",
  },
  {
    tokenId: 3092,
    Tier: "Basic",
  },
  {
    tokenId: 3093,
    Tier: "Basic",
  },
  {
    tokenId: 3094,
    Tier: "Legendary",
  },
  {
    tokenId: 3095,
    Tier: "Basic",
  },
  {
    tokenId: 3096,
    Tier: "Basic",
  },
  {
    tokenId: 3097,
    Tier: "Basic",
  },
  {
    tokenId: 3098,
    Tier: "Basic",
  },
  {
    tokenId: 3099,
    Tier: "Uncommon",
  },
  {
    tokenId: 3100,
    Tier: "Common",
  },
  {
    tokenId: 3101,
    Tier: "Common",
  },
  {
    tokenId: 3102,
    Tier: "Basic",
  },
  {
    tokenId: 3103,
    Tier: "Basic",
  },
  {
    tokenId: 3104,
    Tier: "Common",
  },
  {
    tokenId: 3105,
    Tier: "Basic",
  },
  {
    tokenId: 3106,
    Tier: "Basic",
  },
  {
    tokenId: 3107,
    Tier: "Uncommon",
  },
  {
    tokenId: 3108,
    Tier: "Basic",
  },
  {
    tokenId: 3109,
    Tier: "Basic",
  },
  {
    tokenId: 3110,
    Tier: "Basic",
  },
  {
    tokenId: 3111,
    Tier: "Common",
  },
  {
    tokenId: 3112,
    Tier: "Basic",
  },
  {
    tokenId: 3113,
    Tier: "Basic",
  },
  {
    tokenId: 3114,
    Tier: "Common",
  },
  {
    tokenId: 3115,
    Tier: "Common",
  },
  {
    tokenId: 3116,
    Tier: "Basic",
  },
  {
    tokenId: 3117,
    Tier: "Basic",
  },
  {
    tokenId: 3118,
    Tier: "Epic",
  },
  {
    tokenId: 3119,
    Tier: "Rare",
  },
  {
    tokenId: 3120,
    Tier: "Basic",
  },
  {
    tokenId: 3121,
    Tier: "Epic",
  },
  {
    tokenId: 3122,
    Tier: "Basic",
  },
  {
    tokenId: 3123,
    Tier: "Basic",
  },
  {
    tokenId: 3124,
    Tier: "Basic",
  },
  {
    tokenId: 3125,
    Tier: "Rare",
  },
  {
    tokenId: 3126,
    Tier: "Basic",
  },
  {
    tokenId: 3127,
    Tier: "Legendary",
  },
  {
    tokenId: 3128,
    Tier: "Epic",
  },
  {
    tokenId: 3129,
    Tier: "Legendary",
  },
  {
    tokenId: 3130,
    Tier: "Rare",
  },
  {
    tokenId: 3131,
    Tier: "Basic",
  },
  {
    tokenId: 3132,
    Tier: "Basic",
  },
  {
    tokenId: 3133,
    Tier: "Uncommon",
  },
  {
    tokenId: 3134,
    Tier: "Rare",
  },
  {
    tokenId: 3135,
    Tier: "Rare",
  },
  {
    tokenId: 3136,
    Tier: "Common",
  },
  {
    tokenId: 3137,
    Tier: "Basic",
  },
  {
    tokenId: 3138,
    Tier: "Basic",
  },
  {
    tokenId: 3139,
    Tier: "Rare",
  },
  {
    tokenId: 3140,
    Tier: "Basic",
  },
  {
    tokenId: 3141,
    Tier: "Basic",
  },
  {
    tokenId: 3142,
    Tier: "Mythic",
  },
  {
    tokenId: 3143,
    Tier: "Common",
  },
  {
    tokenId: 3144,
    Tier: "Basic",
  },
  {
    tokenId: 3145,
    Tier: "Rare",
  },
  {
    tokenId: 3146,
    Tier: "Basic",
  },
  {
    tokenId: 3147,
    Tier: "Basic",
  },
  {
    tokenId: 3148,
    Tier: "Basic",
  },
  {
    tokenId: 3149,
    Tier: "Epic",
  },
  {
    tokenId: 3150,
    Tier: "Rare",
  },
  {
    tokenId: 3151,
    Tier: "Uncommon",
  },
  {
    tokenId: 3152,
    Tier: "Basic",
  },
  {
    tokenId: 3153,
    Tier: "Basic",
  },
  {
    tokenId: 3154,
    Tier: "Basic",
  },
  {
    tokenId: 3155,
    Tier: "Rare",
  },
  {
    tokenId: 3156,
    Tier: "Basic",
  },
  {
    tokenId: 3157,
    Tier: "Rare",
  },
  {
    tokenId: 3158,
    Tier: "Uncommon",
  },
  {
    tokenId: 3159,
    Tier: "Epic",
  },
  {
    tokenId: 3160,
    Tier: "Common",
  },
  {
    tokenId: 3161,
    Tier: "Basic",
  },
  {
    tokenId: 3162,
    Tier: "Uncommon",
  },
  {
    tokenId: 3163,
    Tier: "Common",
  },
  {
    tokenId: 3164,
    Tier: "Rare",
  },
  {
    tokenId: 3165,
    Tier: "Uncommon",
  },
  {
    tokenId: 3166,
    Tier: "Basic",
  },
  {
    tokenId: 3167,
    Tier: "Common",
  },
  {
    tokenId: 3168,
    Tier: "Basic",
  },
  {
    tokenId: 3169,
    Tier: "Basic",
  },
  {
    tokenId: 3170,
    Tier: "Legendary",
  },
  {
    tokenId: 3171,
    Tier: "Common",
  },
  {
    tokenId: 3172,
    Tier: "Basic",
  },
  {
    tokenId: 3173,
    Tier: "Rare",
  },
  {
    tokenId: 3174,
    Tier: "Basic",
  },
  {
    tokenId: 3175,
    Tier: "Basic",
  },
  {
    tokenId: 3176,
    Tier: "Legendary",
  },
  {
    tokenId: 3177,
    Tier: "Uncommon",
  },
  {
    tokenId: 3178,
    Tier: "Basic",
  },
  {
    tokenId: 3179,
    Tier: "Legendary",
  },
  {
    tokenId: 3180,
    Tier: "Basic",
  },
  {
    tokenId: 3181,
    Tier: "Basic",
  },
  {
    tokenId: 3182,
    Tier: "Basic",
  },
  {
    tokenId: 3183,
    Tier: "Common",
  },
  {
    tokenId: 3184,
    Tier: "Common",
  },
  {
    tokenId: 3185,
    Tier: "Rare",
  },
  {
    tokenId: 3186,
    Tier: "Rare",
  },
  {
    tokenId: 3187,
    Tier: "Basic",
  },
  {
    tokenId: 3188,
    Tier: "Legendary",
  },
  {
    tokenId: 3189,
    Tier: "Common",
  },
  {
    tokenId: 3190,
    Tier: "Common",
  },
  {
    tokenId: 3191,
    Tier: "Common",
  },
  {
    tokenId: 3192,
    Tier: "Basic",
  },
  {
    tokenId: 3193,
    Tier: "Uncommon",
  },
  {
    tokenId: 3194,
    Tier: "Common",
  },
  {
    tokenId: 3195,
    Tier: "Legendary",
  },
  {
    tokenId: 3196,
    Tier: "Common",
  },
  {
    tokenId: 3197,
    Tier: "Basic",
  },
  {
    tokenId: 3198,
    Tier: "Basic",
  },
  {
    tokenId: 3199,
    Tier: "Basic",
  },
  {
    tokenId: 3200,
    Tier: "Uncommon",
  },
  {
    tokenId: 3201,
    Tier: "Uncommon",
  },
  {
    tokenId: 3202,
    Tier: "Mythic",
  },
  {
    tokenId: 3203,
    Tier: "Basic",
  },
  {
    tokenId: 3204,
    Tier: "Basic",
  },
  {
    tokenId: 3205,
    Tier: "Mythic",
  },
  {
    tokenId: 3206,
    Tier: "Rare",
  },
  {
    tokenId: 3207,
    Tier: "Basic",
  },
  {
    tokenId: 3208,
    Tier: "Exotic",
  },
  {
    tokenId: 3209,
    Tier: "Rare",
  },
  {
    tokenId: 3210,
    Tier: "Common",
  },
  {
    tokenId: 3211,
    Tier: "Basic",
  },
  {
    tokenId: 3212,
    Tier: "Uncommon",
  },
  {
    tokenId: 3213,
    Tier: "Basic",
  },
  {
    tokenId: 3214,
    Tier: "Rare",
  },
  {
    tokenId: 3215,
    Tier: "Common",
  },
  {
    tokenId: 3216,
    Tier: "Basic",
  },
  {
    tokenId: 3217,
    Tier: "Epic",
  },
  {
    tokenId: 3218,
    Tier: "Basic",
  },
  {
    tokenId: 3219,
    Tier: "Uncommon",
  },
  {
    tokenId: 3220,
    Tier: "Uncommon",
  },
  {
    tokenId: 3221,
    Tier: "Uncommon",
  },
  {
    tokenId: 3222,
    Tier: "Basic",
  },
  {
    tokenId: 3223,
    Tier: "Basic",
  },
  {
    tokenId: 3224,
    Tier: "Basic",
  },
  {
    tokenId: 3225,
    Tier: "Epic",
  },
  {
    tokenId: 3226,
    Tier: "Common",
  },
  {
    tokenId: 3227,
    Tier: "Common",
  },
  {
    tokenId: 3228,
    Tier: "Epic",
  },
  {
    tokenId: 3229,
    Tier: "Basic",
  },
  {
    tokenId: 3230,
    Tier: "Basic",
  },
  {
    tokenId: 3231,
    Tier: "Common",
  },
  {
    tokenId: 3232,
    Tier: "Basic",
  },
  {
    tokenId: 3233,
    Tier: "Uncommon",
  },
  {
    tokenId: 3234,
    Tier: "Basic",
  },
  {
    tokenId: 3235,
    Tier: "Basic",
  },
  {
    tokenId: 3236,
    Tier: "Uncommon",
  },
  {
    tokenId: 3237,
    Tier: "Uncommon",
  },
  {
    tokenId: 3238,
    Tier: "Basic",
  },
  {
    tokenId: 3239,
    Tier: "Basic",
  },
  {
    tokenId: 3240,
    Tier: "Basic",
  },
  {
    tokenId: 3241,
    Tier: "Common",
  },
  {
    tokenId: 3242,
    Tier: "Common",
  },
  {
    tokenId: 3243,
    Tier: "Basic",
  },
  {
    tokenId: 3244,
    Tier: "Basic",
  },
  {
    tokenId: 3245,
    Tier: "Basic",
  },
  {
    tokenId: 3246,
    Tier: "Legendary",
  },
  {
    tokenId: 3247,
    Tier: "Legendary",
  },
  {
    tokenId: 3248,
    Tier: "Basic",
  },
  {
    tokenId: 3249,
    Tier: "Basic",
  },
  {
    tokenId: 3250,
    Tier: "Basic",
  },
  {
    tokenId: 3251,
    Tier: "Legendary",
  },
  {
    tokenId: 3252,
    Tier: "Basic",
  },
  {
    tokenId: 3253,
    Tier: "Basic",
  },
  {
    tokenId: 3254,
    Tier: "Basic",
  },
  {
    tokenId: 3255,
    Tier: "Common",
  },
  {
    tokenId: 3256,
    Tier: "Basic",
  },
  {
    tokenId: 3257,
    Tier: "Basic",
  },
  {
    tokenId: 3258,
    Tier: "Uncommon",
  },
  {
    tokenId: 3259,
    Tier: "Basic",
  },
  {
    tokenId: 3260,
    Tier: "Rare",
  },
  {
    tokenId: 3261,
    Tier: "Common",
  },
  {
    tokenId: 3262,
    Tier: "Uncommon",
  },
  {
    tokenId: 3263,
    Tier: "Basic",
  },
  {
    tokenId: 3264,
    Tier: "Basic",
  },
  {
    tokenId: 3265,
    Tier: "Uncommon",
  },
  {
    tokenId: 3266,
    Tier: "Basic",
  },
  {
    tokenId: 3267,
    Tier: "Mythic",
  },
  {
    tokenId: 3268,
    Tier: "Basic",
  },
  {
    tokenId: 3269,
    Tier: "Rare",
  },
  {
    tokenId: 3270,
    Tier: "Basic",
  },
  {
    tokenId: 3271,
    Tier: "Common",
  },
  {
    tokenId: 3272,
    Tier: "Basic",
  },
  {
    tokenId: 3273,
    Tier: "Basic",
  },
  {
    tokenId: 3274,
    Tier: "Basic",
  },
  {
    tokenId: 3275,
    Tier: "Rare",
  },
  {
    tokenId: 3276,
    Tier: "Basic",
  },
  {
    tokenId: 3277,
    Tier: "Basic",
  },
  {
    tokenId: 3278,
    Tier: "Basic",
  },
  {
    tokenId: 3279,
    Tier: "Epic",
  },
  {
    tokenId: 3280,
    Tier: "Uncommon",
  },
  {
    tokenId: 3281,
    Tier: "Basic",
  },
  {
    tokenId: 3282,
    Tier: "Epic",
  },
  {
    tokenId: 3283,
    Tier: "Basic",
  },
  {
    tokenId: 3284,
    Tier: "Basic",
  },
  {
    tokenId: 3285,
    Tier: "Basic",
  },
  {
    tokenId: 3286,
    Tier: "Basic",
  },
  {
    tokenId: 3287,
    Tier: "Basic",
  },
  {
    tokenId: 3288,
    Tier: "Basic",
  },
  {
    tokenId: 3289,
    Tier: "Basic",
  },
  {
    tokenId: 3290,
    Tier: "Uncommon",
  },
  {
    tokenId: 3291,
    Tier: "Uncommon",
  },
  {
    tokenId: 3292,
    Tier: "Uncommon",
  },
  {
    tokenId: 3293,
    Tier: "Rare",
  },
  {
    tokenId: 3294,
    Tier: "Uncommon",
  },
  {
    tokenId: 3295,
    Tier: "Epic",
  },
  {
    tokenId: 3296,
    Tier: "Uncommon",
  },
  {
    tokenId: 3297,
    Tier: "Rare",
  },
  {
    tokenId: 3298,
    Tier: "Legendary",
  },
  {
    tokenId: 3299,
    Tier: "Basic",
  },
  {
    tokenId: 3300,
    Tier: "Basic",
  },
  {
    tokenId: 3301,
    Tier: "Uncommon",
  },
  {
    tokenId: 3302,
    Tier: "Basic",
  },
  {
    tokenId: 3303,
    Tier: "Basic",
  },
  {
    tokenId: 3304,
    Tier: "Epic",
  },
  {
    tokenId: 3305,
    Tier: "Basic",
  },
  {
    tokenId: 3306,
    Tier: "Basic",
  },
  {
    tokenId: 3307,
    Tier: "Basic",
  },
  {
    tokenId: 3308,
    Tier: "Mythic",
  },
  {
    tokenId: 3309,
    Tier: "Common",
  },
  {
    tokenId: 3310,
    Tier: "Basic",
  },
  {
    tokenId: 3311,
    Tier: "Legendary",
  },
  {
    tokenId: 3312,
    Tier: "Uncommon",
  },
  {
    tokenId: 3313,
    Tier: "Common",
  },
  {
    tokenId: 3314,
    Tier: "Basic",
  },
  {
    tokenId: 3315,
    Tier: "Rare",
  },
  {
    tokenId: 3316,
    Tier: "Uncommon",
  },
  {
    tokenId: 3317,
    Tier: "Mythic",
  },
  {
    tokenId: 3318,
    Tier: "Basic",
  },
  {
    tokenId: 3319,
    Tier: "Common",
  },
  {
    tokenId: 3320,
    Tier: "Basic",
  },
  {
    tokenId: 3321,
    Tier: "Common",
  },
  {
    tokenId: 3322,
    Tier: "Basic",
  },
  {
    tokenId: 3323,
    Tier: "Basic",
  },
  {
    tokenId: 3324,
    Tier: "Basic",
  },
  {
    tokenId: 3325,
    Tier: "Uncommon",
  },
  {
    tokenId: 3326,
    Tier: "Basic",
  },
  {
    tokenId: 3327,
    Tier: "Basic",
  },
  {
    tokenId: 3328,
    Tier: "Common",
  },
  {
    tokenId: 3329,
    Tier: "Basic",
  },
  {
    tokenId: 3330,
    Tier: "Rare",
  },
  {
    tokenId: 3331,
    Tier: "Basic",
  },
  {
    tokenId: 3332,
    Tier: "Uncommon",
  },
  {
    tokenId: 3333,
    Tier: "Epic",
  },
  {
    tokenId: 3334,
    Tier: "Mythic",
  },
  {
    tokenId: 3335,
    Tier: "Rare",
  },
  {
    tokenId: 3336,
    Tier: "Basic",
  },
  {
    tokenId: 3337,
    Tier: "Uncommon",
  },
  {
    tokenId: 3338,
    Tier: "Uncommon",
  },
  {
    tokenId: 3339,
    Tier: "Mythic",
  },
  {
    tokenId: 3340,
    Tier: "Mythic",
  },
  {
    tokenId: 3341,
    Tier: "Basic",
  },
  {
    tokenId: 3342,
    Tier: "Epic",
  },
  {
    tokenId: 3343,
    Tier: "Mythic",
  },
  {
    tokenId: 3344,
    Tier: "Basic",
  },
  {
    tokenId: 3345,
    Tier: "Basic",
  },
  {
    tokenId: 3346,
    Tier: "Basic",
  },
  {
    tokenId: 3347,
    Tier: "Basic",
  },
  {
    tokenId: 3348,
    Tier: "Basic",
  },
  {
    tokenId: 3349,
    Tier: "Exotic",
  },
  {
    tokenId: 3350,
    Tier: "Legendary",
  },
  {
    tokenId: 3351,
    Tier: "Basic",
  },
  {
    tokenId: 3352,
    Tier: "Epic",
  },
  {
    tokenId: 3353,
    Tier: "Rare",
  },
  {
    tokenId: 3354,
    Tier: "Uncommon",
  },
  {
    tokenId: 3355,
    Tier: "Common",
  },
  {
    tokenId: 3356,
    Tier: "Basic",
  },
  {
    tokenId: 3357,
    Tier: "Basic",
  },
  {
    tokenId: 3358,
    Tier: "Basic",
  },
  {
    tokenId: 3359,
    Tier: "Common",
  },
  {
    tokenId: 3360,
    Tier: "Rare",
  },
  {
    tokenId: 3361,
    Tier: "Basic",
  },
  {
    tokenId: 3362,
    Tier: "Basic",
  },
  {
    tokenId: 3363,
    Tier: "Basic",
  },
  {
    tokenId: 3364,
    Tier: "Basic",
  },
  {
    tokenId: 3365,
    Tier: "Basic",
  },
  {
    tokenId: 3366,
    Tier: "Basic",
  },
  {
    tokenId: 3367,
    Tier: "Epic",
  },
  {
    tokenId: 3368,
    Tier: "Common",
  },
  {
    tokenId: 3369,
    Tier: "Legendary",
  },
  {
    tokenId: 3370,
    Tier: "Common",
  },
  {
    tokenId: 3371,
    Tier: "Basic",
  },
  {
    tokenId: 3372,
    Tier: "Basic",
  },
  {
    tokenId: 3373,
    Tier: "Basic",
  },
  {
    tokenId: 3374,
    Tier: "Epic",
  },
  {
    tokenId: 3375,
    Tier: "Basic",
  },
  {
    tokenId: 3376,
    Tier: "Basic",
  },
  {
    tokenId: 3377,
    Tier: "Common",
  },
  {
    tokenId: 3378,
    Tier: "Basic",
  },
  {
    tokenId: 3379,
    Tier: "Basic",
  },
  {
    tokenId: 3380,
    Tier: "Basic",
  },
  {
    tokenId: 3381,
    Tier: "Epic",
  },
  {
    tokenId: 3382,
    Tier: "Common",
  },
  {
    tokenId: 3383,
    Tier: "Epic",
  },
  {
    tokenId: 3384,
    Tier: "Basic",
  },
  {
    tokenId: 3385,
    Tier: "Basic",
  },
  {
    tokenId: 3386,
    Tier: "Basic",
  },
  {
    tokenId: 3387,
    Tier: "Common",
  },
  {
    tokenId: 3388,
    Tier: "Basic",
  },
  {
    tokenId: 3389,
    Tier: "Uncommon",
  },
  {
    tokenId: 3390,
    Tier: "Common",
  },
  {
    tokenId: 3391,
    Tier: "Basic",
  },
  {
    tokenId: 3392,
    Tier: "Common",
  },
  {
    tokenId: 3393,
    Tier: "Common",
  },
  {
    tokenId: 3394,
    Tier: "Basic",
  },
  {
    tokenId: 3395,
    Tier: "Rare",
  },
  {
    tokenId: 3396,
    Tier: "Rare",
  },
  {
    tokenId: 3397,
    Tier: "Basic",
  },
  {
    tokenId: 3398,
    Tier: "Common",
  },
  {
    tokenId: 3399,
    Tier: "Epic",
  },
  {
    tokenId: 3400,
    Tier: "Basic",
  },
  {
    tokenId: 3401,
    Tier: "Basic",
  },
  {
    tokenId: 3402,
    Tier: "Uncommon",
  },
  {
    tokenId: 3403,
    Tier: "Basic",
  },
  {
    tokenId: 3404,
    Tier: "Basic",
  },
  {
    tokenId: 3405,
    Tier: "Uncommon",
  },
  {
    tokenId: 3406,
    Tier: "Epic",
  },
  {
    tokenId: 3407,
    Tier: "Mythic",
  },
  {
    tokenId: 3408,
    Tier: "Basic",
  },
  {
    tokenId: 3409,
    Tier: "Basic",
  },
  {
    tokenId: 3410,
    Tier: "Basic",
  },
  {
    tokenId: 3411,
    Tier: "Common",
  },
  {
    tokenId: 3412,
    Tier: "Epic",
  },
  {
    tokenId: 3413,
    Tier: "Basic",
  },
  {
    tokenId: 3414,
    Tier: "Uncommon",
  },
  {
    tokenId: 3415,
    Tier: "Rare",
  },
  {
    tokenId: 3416,
    Tier: "Epic",
  },
  {
    tokenId: 3417,
    Tier: "Rare",
  },
  {
    tokenId: 3418,
    Tier: "Basic",
  },
  {
    tokenId: 3419,
    Tier: "Basic",
  },
  {
    tokenId: 3420,
    Tier: "Basic",
  },
  {
    tokenId: 3421,
    Tier: "Basic",
  },
  {
    tokenId: 3422,
    Tier: "Basic",
  },
  {
    tokenId: 3423,
    Tier: "Basic",
  },
  {
    tokenId: 3424,
    Tier: "Common",
  },
  {
    tokenId: 3425,
    Tier: "Basic",
  },
  {
    tokenId: 3426,
    Tier: "Uncommon",
  },
  {
    tokenId: 3427,
    Tier: "Basic",
  },
  {
    tokenId: 3428,
    Tier: "Basic",
  },
  {
    tokenId: 3429,
    Tier: "Common",
  },
  {
    tokenId: 3430,
    Tier: "Common",
  },
  {
    tokenId: 3431,
    Tier: "Basic",
  },
  {
    tokenId: 3432,
    Tier: "Basic",
  },
  {
    tokenId: 3433,
    Tier: "Basic",
  },
  {
    tokenId: 3434,
    Tier: "Basic",
  },
  {
    tokenId: 3435,
    Tier: "Basic",
  },
  {
    tokenId: 3436,
    Tier: "Mythic",
  },
  {
    tokenId: 3437,
    Tier: "Basic",
  },
  {
    tokenId: 3438,
    Tier: "Basic",
  },
  {
    tokenId: 3439,
    Tier: "Uncommon",
  },
  {
    tokenId: 3440,
    Tier: "Rare",
  },
  {
    tokenId: 3441,
    Tier: "Common",
  },
  {
    tokenId: 3442,
    Tier: "Legendary",
  },
  {
    tokenId: 3443,
    Tier: "Basic",
  },
  {
    tokenId: 3444,
    Tier: "Epic",
  },
  {
    tokenId: 3445,
    Tier: "Rare",
  },
  {
    tokenId: 3446,
    Tier: "Basic",
  },
  {
    tokenId: 3447,
    Tier: "Basic",
  },
  {
    tokenId: 3448,
    Tier: "Rare",
  },
  {
    tokenId: 3449,
    Tier: "Basic",
  },
  {
    tokenId: 3450,
    Tier: "Epic",
  },
  {
    tokenId: 3451,
    Tier: "Rare",
  },
  {
    tokenId: 3452,
    Tier: "Basic",
  },
  {
    tokenId: 3453,
    Tier: "Basic",
  },
  {
    tokenId: 3454,
    Tier: "Basic",
  },
  {
    tokenId: 3455,
    Tier: "Common",
  },
  {
    tokenId: 3456,
    Tier: "Basic",
  },
  {
    tokenId: 3457,
    Tier: "Rare",
  },
  {
    tokenId: 3458,
    Tier: "Legendary",
  },
  {
    tokenId: 3459,
    Tier: "Basic",
  },
  {
    tokenId: 3460,
    Tier: "Basic",
  },
  {
    tokenId: 3461,
    Tier: "Common",
  },
  {
    tokenId: 3462,
    Tier: "Basic",
  },
  {
    tokenId: 3463,
    Tier: "Basic",
  },
  {
    tokenId: 3464,
    Tier: "Common",
  },
  {
    tokenId: 3465,
    Tier: "Basic",
  },
  {
    tokenId: 3466,
    Tier: "Basic",
  },
  {
    tokenId: 3467,
    Tier: "Basic",
  },
  {
    tokenId: 3468,
    Tier: "Common",
  },
  {
    tokenId: 3469,
    Tier: "Basic",
  },
  {
    tokenId: 3470,
    Tier: "Uncommon",
  },
  {
    tokenId: 3471,
    Tier: "Common",
  },
  {
    tokenId: 3472,
    Tier: "Basic",
  },
  {
    tokenId: 3473,
    Tier: "Basic",
  },
  {
    tokenId: 3474,
    Tier: "Basic",
  },
  {
    tokenId: 3475,
    Tier: "Basic",
  },
  {
    tokenId: 3476,
    Tier: "Basic",
  },
  {
    tokenId: 3477,
    Tier: "Mythic",
  },
  {
    tokenId: 3478,
    Tier: "Basic",
  },
  {
    tokenId: 3479,
    Tier: "Basic",
  },
  {
    tokenId: 3480,
    Tier: "Uncommon",
  },
  {
    tokenId: 3481,
    Tier: "Common",
  },
  {
    tokenId: 3482,
    Tier: "Legendary",
  },
  {
    tokenId: 3483,
    Tier: "Basic",
  },
  {
    tokenId: 3484,
    Tier: "Basic",
  },
  {
    tokenId: 3485,
    Tier: "Common",
  },
  {
    tokenId: 3486,
    Tier: "Rare",
  },
  {
    tokenId: 3487,
    Tier: "Epic",
  },
  {
    tokenId: 3488,
    Tier: "Epic",
  },
  {
    tokenId: 3489,
    Tier: "Basic",
  },
  {
    tokenId: 3490,
    Tier: "Basic",
  },
  {
    tokenId: 3491,
    Tier: "Basic",
  },
  {
    tokenId: 3492,
    Tier: "Basic",
  },
  {
    tokenId: 3493,
    Tier: "Basic",
  },
  {
    tokenId: 3494,
    Tier: "Basic",
  },
  {
    tokenId: 3495,
    Tier: "Uncommon",
  },
  {
    tokenId: 3496,
    Tier: "Mythic",
  },
  {
    tokenId: 3497,
    Tier: "Legendary",
  },
  {
    tokenId: 3498,
    Tier: "Legendary",
  },
  {
    tokenId: 3499,
    Tier: "Basic",
  },
  {
    tokenId: 3500,
    Tier: "Epic",
  },
  {
    tokenId: 3501,
    Tier: "Basic",
  },
  {
    tokenId: 3502,
    Tier: "Common",
  },
  {
    tokenId: 3503,
    Tier: "Common",
  },
  {
    tokenId: 3504,
    Tier: "Basic",
  },
  {
    tokenId: 3505,
    Tier: "Basic",
  },
  {
    tokenId: 3506,
    Tier: "Basic",
  },
  {
    tokenId: 3507,
    Tier: "Uncommon",
  },
  {
    tokenId: 3508,
    Tier: "Basic",
  },
  {
    tokenId: 3509,
    Tier: "Basic",
  },
  {
    tokenId: 3510,
    Tier: "Basic",
  },
  {
    tokenId: 3511,
    Tier: "Rare",
  },
  {
    tokenId: 3512,
    Tier: "Basic",
  },
  {
    tokenId: 3513,
    Tier: "Legendary",
  },
  {
    tokenId: 3514,
    Tier: "Common",
  },
  {
    tokenId: 3515,
    Tier: "Basic",
  },
  {
    tokenId: 3516,
    Tier: "Epic",
  },
  {
    tokenId: 3517,
    Tier: "Basic",
  },
  {
    tokenId: 3518,
    Tier: "Basic",
  },
  {
    tokenId: 3519,
    Tier: "Basic",
  },
  {
    tokenId: 3520,
    Tier: "Uncommon",
  },
  {
    tokenId: 3521,
    Tier: "Common",
  },
  {
    tokenId: 3522,
    Tier: "Epic",
  },
  {
    tokenId: 3523,
    Tier: "Basic",
  },
  {
    tokenId: 3524,
    Tier: "Common",
  },
  {
    tokenId: 3525,
    Tier: "Basic",
  },
  {
    tokenId: 3526,
    Tier: "Basic",
  },
  {
    tokenId: 3527,
    Tier: "Basic",
  },
  {
    tokenId: 3528,
    Tier: "Epic",
  },
  {
    tokenId: 3529,
    Tier: "Epic",
  },
  {
    tokenId: 3530,
    Tier: "Uncommon",
  },
  {
    tokenId: 3531,
    Tier: "Uncommon",
  },
  {
    tokenId: 3532,
    Tier: "Basic",
  },
  {
    tokenId: 3533,
    Tier: "Uncommon",
  },
  {
    tokenId: 3534,
    Tier: "Basic",
  },
  {
    tokenId: 3535,
    Tier: "Basic",
  },
  {
    tokenId: 3536,
    Tier: "Uncommon",
  },
  {
    tokenId: 3537,
    Tier: "Common",
  },
  {
    tokenId: 3538,
    Tier: "Basic",
  },
  {
    tokenId: 3539,
    Tier: "Basic",
  },
  {
    tokenId: 3540,
    Tier: "Basic",
  },
  {
    tokenId: 3541,
    Tier: "Basic",
  },
  {
    tokenId: 3542,
    Tier: "Rare",
  },
  {
    tokenId: 3543,
    Tier: "Exotic",
  },
  {
    tokenId: 3544,
    Tier: "Mythic",
  },
  {
    tokenId: 3545,
    Tier: "Basic",
  },
  {
    tokenId: 3546,
    Tier: "Basic",
  },
  {
    tokenId: 3547,
    Tier: "Basic",
  },
  {
    tokenId: 3548,
    Tier: "Legendary",
  },
  {
    tokenId: 3549,
    Tier: "Basic",
  },
  {
    tokenId: 3550,
    Tier: "Basic",
  },
  {
    tokenId: 3551,
    Tier: "Basic",
  },
  {
    tokenId: 3552,
    Tier: "Legendary",
  },
  {
    tokenId: 3553,
    Tier: "Basic",
  },
  {
    tokenId: 3554,
    Tier: "Mythic",
  },
  {
    tokenId: 3555,
    Tier: "Epic",
  },
  {
    tokenId: 3556,
    Tier: "Basic",
  },
  {
    tokenId: 3557,
    Tier: "Basic",
  },
  {
    tokenId: 3558,
    Tier: "Basic",
  },
  {
    tokenId: 3559,
    Tier: "Uncommon",
  },
  {
    tokenId: 3560,
    Tier: "Mythic",
  },
  {
    tokenId: 3561,
    Tier: "Legendary",
  },
  {
    tokenId: 3562,
    Tier: "Basic",
  },
  {
    tokenId: 3563,
    Tier: "Basic",
  },
  {
    tokenId: 3564,
    Tier: "Rare",
  },
  {
    tokenId: 3565,
    Tier: "Common",
  },
  {
    tokenId: 3566,
    Tier: "Basic",
  },
  {
    tokenId: 3567,
    Tier: "Legendary",
  },
  {
    tokenId: 3568,
    Tier: "Epic",
  },
  {
    tokenId: 3569,
    Tier: "Common",
  },
  {
    tokenId: 3570,
    Tier: "Legendary",
  },
  {
    tokenId: 3571,
    Tier: "Basic",
  },
  {
    tokenId: 3572,
    Tier: "Rare",
  },
  {
    tokenId: 3573,
    Tier: "Epic",
  },
  {
    tokenId: 3574,
    Tier: "Basic",
  },
  {
    tokenId: 3575,
    Tier: "Basic",
  },
  {
    tokenId: 3576,
    Tier: "Common",
  },
  {
    tokenId: 3577,
    Tier: "Rare",
  },
  {
    tokenId: 3578,
    Tier: "Uncommon",
  },
  {
    tokenId: 3579,
    Tier: "Basic",
  },
  {
    tokenId: 3580,
    Tier: "Basic",
  },
  {
    tokenId: 3581,
    Tier: "Legendary",
  },
  {
    tokenId: 3582,
    Tier: "Basic",
  },
  {
    tokenId: 3583,
    Tier: "Basic",
  },
  {
    tokenId: 3584,
    Tier: "Common",
  },
  {
    tokenId: 3585,
    Tier: "Basic",
  },
  {
    tokenId: 3586,
    Tier: "Basic",
  },
  {
    tokenId: 3587,
    Tier: "Uncommon",
  },
  {
    tokenId: 3588,
    Tier: "Uncommon",
  },
  {
    tokenId: 3589,
    Tier: "Common",
  },
  {
    tokenId: 3590,
    Tier: "Basic",
  },
  {
    tokenId: 3591,
    Tier: "Basic",
  },
  {
    tokenId: 3592,
    Tier: "Basic",
  },
  {
    tokenId: 3593,
    Tier: "Legendary",
  },
  {
    tokenId: 3594,
    Tier: "Exotic",
  },
  {
    tokenId: 3595,
    Tier: "Legendary",
  },
  {
    tokenId: 3596,
    Tier: "Basic",
  },
  {
    tokenId: 3597,
    Tier: "Legendary",
  },
  {
    tokenId: 3598,
    Tier: "Basic",
  },
  {
    tokenId: 3599,
    Tier: "Basic",
  },
  {
    tokenId: 3600,
    Tier: "Uncommon",
  },
  {
    tokenId: 3601,
    Tier: "Basic",
  },
  {
    tokenId: 3602,
    Tier: "Common",
  },
  {
    tokenId: 3603,
    Tier: "Epic",
  },
  {
    tokenId: 3604,
    Tier: "Basic",
  },
  {
    tokenId: 3605,
    Tier: "Rare",
  },
  {
    tokenId: 3606,
    Tier: "Basic",
  },
  {
    tokenId: 3607,
    Tier: "Basic",
  },
  {
    tokenId: 3608,
    Tier: "Basic",
  },
  {
    tokenId: 3609,
    Tier: "Basic",
  },
  {
    tokenId: 3610,
    Tier: "Common",
  },
  {
    tokenId: 3611,
    Tier: "Basic",
  },
  {
    tokenId: 3612,
    Tier: "Basic",
  },
  {
    tokenId: 3613,
    Tier: "Basic",
  },
  {
    tokenId: 3614,
    Tier: "Rare",
  },
  {
    tokenId: 3615,
    Tier: "Exotic",
  },
  {
    tokenId: 3616,
    Tier: "Mythic",
  },
  {
    tokenId: 3617,
    Tier: "Uncommon",
  },
  {
    tokenId: 3618,
    Tier: "Common",
  },
  {
    tokenId: 3619,
    Tier: "Rare",
  },
  {
    tokenId: 3620,
    Tier: "Basic",
  },
  {
    tokenId: 3621,
    Tier: "Basic",
  },
  {
    tokenId: 3622,
    Tier: "Common",
  },
  {
    tokenId: 3623,
    Tier: "Common",
  },
  {
    tokenId: 3624,
    Tier: "Epic",
  },
  {
    tokenId: 3625,
    Tier: "Uncommon",
  },
  {
    tokenId: 3626,
    Tier: "Legendary",
  },
  {
    tokenId: 3627,
    Tier: "Basic",
  },
  {
    tokenId: 3628,
    Tier: "Exotic",
  },
  {
    tokenId: 3629,
    Tier: "Uncommon",
  },
  {
    tokenId: 3630,
    Tier: "Rare",
  },
  {
    tokenId: 3631,
    Tier: "Basic",
  },
  {
    tokenId: 3632,
    Tier: "Common",
  },
  {
    tokenId: 3633,
    Tier: "Basic",
  },
  {
    tokenId: 3634,
    Tier: "Common",
  },
  {
    tokenId: 3635,
    Tier: "Basic",
  },
  {
    tokenId: 3636,
    Tier: "Basic",
  },
  {
    tokenId: 3637,
    Tier: "Common",
  },
  {
    tokenId: 3638,
    Tier: "Legendary",
  },
  {
    tokenId: 3639,
    Tier: "Common",
  },
  {
    tokenId: 3640,
    Tier: "Basic",
  },
  {
    tokenId: 3641,
    Tier: "Basic",
  },
  {
    tokenId: 3642,
    Tier: "Basic",
  },
  {
    tokenId: 3643,
    Tier: "Epic",
  },
  {
    tokenId: 3644,
    Tier: "Basic",
  },
  {
    tokenId: 3645,
    Tier: "Common",
  },
  {
    tokenId: 3646,
    Tier: "Epic",
  },
  {
    tokenId: 3647,
    Tier: "Basic",
  },
  {
    tokenId: 3648,
    Tier: "Basic",
  },
  {
    tokenId: 3649,
    Tier: "Basic",
  },
  {
    tokenId: 3650,
    Tier: "Epic",
  },
  {
    tokenId: 3651,
    Tier: "Common",
  },
  {
    tokenId: 3652,
    Tier: "Basic",
  },
  {
    tokenId: 3653,
    Tier: "Basic",
  },
  {
    tokenId: 3654,
    Tier: "Basic",
  },
  {
    tokenId: 3655,
    Tier: "Common",
  },
  {
    tokenId: 3656,
    Tier: "Epic",
  },
  {
    tokenId: 3657,
    Tier: "Basic",
  },
  {
    tokenId: 3658,
    Tier: "Basic",
  },
  {
    tokenId: 3659,
    Tier: "Legendary",
  },
  {
    tokenId: 3660,
    Tier: "Basic",
  },
  {
    tokenId: 3661,
    Tier: "Rare",
  },
  {
    tokenId: 3662,
    Tier: "Uncommon",
  },
  {
    tokenId: 3663,
    Tier: "Basic",
  },
  {
    tokenId: 3664,
    Tier: "Basic",
  },
  {
    tokenId: 3665,
    Tier: "Rare",
  },
  {
    tokenId: 3666,
    Tier: "Basic",
  },
  {
    tokenId: 3667,
    Tier: "Basic",
  },
  {
    tokenId: 3668,
    Tier: "Common",
  },
  {
    tokenId: 3669,
    Tier: "Basic",
  },
  {
    tokenId: 3670,
    Tier: "Basic",
  },
  {
    tokenId: 3671,
    Tier: "Basic",
  },
  {
    tokenId: 3672,
    Tier: "Epic",
  },
  {
    tokenId: 3673,
    Tier: "Basic",
  },
  {
    tokenId: 3674,
    Tier: "Basic",
  },
  {
    tokenId: 3675,
    Tier: "Rare",
  },
  {
    tokenId: 3676,
    Tier: "Basic",
  },
  {
    tokenId: 3677,
    Tier: "Basic",
  },
  {
    tokenId: 3678,
    Tier: "Epic",
  },
  {
    tokenId: 3679,
    Tier: "Basic",
  },
  {
    tokenId: 3680,
    Tier: "Mythic",
  },
  {
    tokenId: 3681,
    Tier: "Basic",
  },
  {
    tokenId: 3682,
    Tier: "Basic",
  },
  {
    tokenId: 3683,
    Tier: "Basic",
  },
  {
    tokenId: 3684,
    Tier: "Uncommon",
  },
  {
    tokenId: 3685,
    Tier: "Basic",
  },
  {
    tokenId: 3686,
    Tier: "Legendary",
  },
  {
    tokenId: 3687,
    Tier: "Common",
  },
  {
    tokenId: 3688,
    Tier: "Basic",
  },
  {
    tokenId: 3689,
    Tier: "Basic",
  },
  {
    tokenId: 3690,
    Tier: "Common",
  },
  {
    tokenId: 3691,
    Tier: "Uncommon",
  },
  {
    tokenId: 3692,
    Tier: "Common",
  },
  {
    tokenId: 3693,
    Tier: "Basic",
  },
  {
    tokenId: 3694,
    Tier: "Basic",
  },
  {
    tokenId: 3695,
    Tier: "Basic",
  },
  {
    tokenId: 3696,
    Tier: "Basic",
  },
  {
    tokenId: 3697,
    Tier: "Common",
  },
  {
    tokenId: 3698,
    Tier: "Basic",
  },
  {
    tokenId: 3699,
    Tier: "Legendary",
  },
  {
    tokenId: 3700,
    Tier: "Uncommon",
  },
  {
    tokenId: 3701,
    Tier: "Uncommon",
  },
  {
    tokenId: 3702,
    Tier: "Basic",
  },
  {
    tokenId: 3703,
    Tier: "Basic",
  },
  {
    tokenId: 3704,
    Tier: "Basic",
  },
  {
    tokenId: 3705,
    Tier: "Basic",
  },
  {
    tokenId: 3706,
    Tier: "Basic",
  },
  {
    tokenId: 3707,
    Tier: "Basic",
  },
  {
    tokenId: 3708,
    Tier: "Basic",
  },
  {
    tokenId: 3709,
    Tier: "Mythic",
  },
  {
    tokenId: 3710,
    Tier: "Rare",
  },
  {
    tokenId: 3711,
    Tier: "Basic",
  },
  {
    tokenId: 3712,
    Tier: "Basic",
  },
  {
    tokenId: 3713,
    Tier: "Basic",
  },
  {
    tokenId: 3714,
    Tier: "Basic",
  },
  {
    tokenId: 3715,
    Tier: "Basic",
  },
  {
    tokenId: 3716,
    Tier: "Basic",
  },
  {
    tokenId: 3717,
    Tier: "Uncommon",
  },
  {
    tokenId: 3718,
    Tier: "Basic",
  },
  {
    tokenId: 3719,
    Tier: "Epic",
  },
  {
    tokenId: 3720,
    Tier: "Uncommon",
  },
  {
    tokenId: 3721,
    Tier: "Uncommon",
  },
  {
    tokenId: 3722,
    Tier: "Uncommon",
  },
  {
    tokenId: 3723,
    Tier: "Basic",
  },
  {
    tokenId: 3724,
    Tier: "Legendary",
  },
  {
    tokenId: 3725,
    Tier: "Basic",
  },
  {
    tokenId: 3726,
    Tier: "Basic",
  },
  {
    tokenId: 3727,
    Tier: "Common",
  },
  {
    tokenId: 3728,
    Tier: "Mythic",
  },
  {
    tokenId: 3729,
    Tier: "Basic",
  },
  {
    tokenId: 3730,
    Tier: "Basic",
  },
  {
    tokenId: 3731,
    Tier: "Basic",
  },
  {
    tokenId: 3732,
    Tier: "Uncommon",
  },
  {
    tokenId: 3733,
    Tier: "Uncommon",
  },
  {
    tokenId: 3734,
    Tier: "Basic",
  },
  {
    tokenId: 3735,
    Tier: "Common",
  },
  {
    tokenId: 3736,
    Tier: "Epic",
  },
  {
    tokenId: 3737,
    Tier: "Basic",
  },
  {
    tokenId: 3738,
    Tier: "Rare",
  },
  {
    tokenId: 3739,
    Tier: "Mythic",
  },
  {
    tokenId: 3740,
    Tier: "Uncommon",
  },
  {
    tokenId: 3741,
    Tier: "Common",
  },
  {
    tokenId: 3742,
    Tier: "Uncommon",
  },
  {
    tokenId: 3743,
    Tier: "Basic",
  },
  {
    tokenId: 3744,
    Tier: "Rare",
  },
  {
    tokenId: 3745,
    Tier: "Common",
  },
  {
    tokenId: 3746,
    Tier: "Common",
  },
  {
    tokenId: 3747,
    Tier: "Basic",
  },
  {
    tokenId: 3748,
    Tier: "Epic",
  },
  {
    tokenId: 3749,
    Tier: "Uncommon",
  },
  {
    tokenId: 3750,
    Tier: "Basic",
  },
  {
    tokenId: 3751,
    Tier: "Legendary",
  },
  {
    tokenId: 3752,
    Tier: "Uncommon",
  },
  {
    tokenId: 3753,
    Tier: "Basic",
  },
  {
    tokenId: 3754,
    Tier: "Basic",
  },
  {
    tokenId: 3755,
    Tier: "Rare",
  },
  {
    tokenId: 3756,
    Tier: "Basic",
  },
  {
    tokenId: 3757,
    Tier: "Basic",
  },
  {
    tokenId: 3758,
    Tier: "Basic",
  },
  {
    tokenId: 3759,
    Tier: "Uncommon",
  },
  {
    tokenId: 3760,
    Tier: "Basic",
  },
  {
    tokenId: 3761,
    Tier: "Basic",
  },
  {
    tokenId: 3762,
    Tier: "Common",
  },
  {
    tokenId: 3763,
    Tier: "Uncommon",
  },
  {
    tokenId: 3764,
    Tier: "Epic",
  },
  {
    tokenId: 3765,
    Tier: "Epic",
  },
  {
    tokenId: 3766,
    Tier: "Legendary",
  },
  {
    tokenId: 3767,
    Tier: "Common",
  },
  {
    tokenId: 3768,
    Tier: "Rare",
  },
  {
    tokenId: 3769,
    Tier: "Basic",
  },
  {
    tokenId: 3770,
    Tier: "Basic",
  },
  {
    tokenId: 3771,
    Tier: "Common",
  },
  {
    tokenId: 3772,
    Tier: "Basic",
  },
  {
    tokenId: 3773,
    Tier: "Basic",
  },
  {
    tokenId: 3774,
    Tier: "Basic",
  },
  {
    tokenId: 3775,
    Tier: "Basic",
  },
  {
    tokenId: 3776,
    Tier: "Basic",
  },
  {
    tokenId: 3777,
    Tier: "Uncommon",
  },
  {
    tokenId: 3778,
    Tier: "Basic",
  },
  {
    tokenId: 3779,
    Tier: "Epic",
  },
  {
    tokenId: 3780,
    Tier: "Basic",
  },
  {
    tokenId: 3781,
    Tier: "Basic",
  },
  {
    tokenId: 3782,
    Tier: "Basic",
  },
  {
    tokenId: 3783,
    Tier: "Uncommon",
  },
  {
    tokenId: 3784,
    Tier: "Basic",
  },
  {
    tokenId: 3785,
    Tier: "Rare",
  },
  {
    tokenId: 3786,
    Tier: "Mythic",
  },
  {
    tokenId: 3787,
    Tier: "Basic",
  },
  {
    tokenId: 3788,
    Tier: "Epic",
  },
  {
    tokenId: 3789,
    Tier: "Rare",
  },
  {
    tokenId: 3790,
    Tier: "Mythic",
  },
  {
    tokenId: 3791,
    Tier: "Epic",
  },
  {
    tokenId: 3792,
    Tier: "Basic",
  },
  {
    tokenId: 3793,
    Tier: "Basic",
  },
  {
    tokenId: 3794,
    Tier: "Epic",
  },
  {
    tokenId: 3795,
    Tier: "Basic",
  },
  {
    tokenId: 3796,
    Tier: "Basic",
  },
  {
    tokenId: 3797,
    Tier: "Basic",
  },
  {
    tokenId: 3798,
    Tier: "Basic",
  },
  {
    tokenId: 3799,
    Tier: "Basic",
  },
  {
    tokenId: 3800,
    Tier: "Uncommon",
  },
  {
    tokenId: 3801,
    Tier: "Basic",
  },
  {
    tokenId: 3802,
    Tier: "Basic",
  },
  {
    tokenId: 3803,
    Tier: "Uncommon",
  },
  {
    tokenId: 3804,
    Tier: "Common",
  },
  {
    tokenId: 3805,
    Tier: "Common",
  },
  {
    tokenId: 3806,
    Tier: "Common",
  },
  {
    tokenId: 3807,
    Tier: "Mythic",
  },
  {
    tokenId: 3808,
    Tier: "Epic",
  },
  {
    tokenId: 3809,
    Tier: "Mythic",
  },
  {
    tokenId: 3810,
    Tier: "Legendary",
  },
  {
    tokenId: 3811,
    Tier: "Common",
  },
  {
    tokenId: 3812,
    Tier: "Basic",
  },
  {
    tokenId: 3813,
    Tier: "Basic",
  },
  {
    tokenId: 3814,
    Tier: "Basic",
  },
  {
    tokenId: 3815,
    Tier: "Basic",
  },
  {
    tokenId: 3816,
    Tier: "Basic",
  },
  {
    tokenId: 3817,
    Tier: "Uncommon",
  },
  {
    tokenId: 3818,
    Tier: "Basic",
  },
  {
    tokenId: 3819,
    Tier: "Common",
  },
  {
    tokenId: 3820,
    Tier: "Uncommon",
  },
  {
    tokenId: 3821,
    Tier: "Basic",
  },
  {
    tokenId: 3822,
    Tier: "Common",
  },
  {
    tokenId: 3823,
    Tier: "Basic",
  },
  {
    tokenId: 3824,
    Tier: "Basic",
  },
  {
    tokenId: 3825,
    Tier: "Basic",
  },
  {
    tokenId: 3826,
    Tier: "Rare",
  },
  {
    tokenId: 3827,
    Tier: "Epic",
  },
  {
    tokenId: 3828,
    Tier: "Uncommon",
  },
  {
    tokenId: 3829,
    Tier: "Basic",
  },
  {
    tokenId: 3830,
    Tier: "Basic",
  },
  {
    tokenId: 3831,
    Tier: "Uncommon",
  },
  {
    tokenId: 3832,
    Tier: "Mythic",
  },
  {
    tokenId: 3833,
    Tier: "Basic",
  },
  {
    tokenId: 3834,
    Tier: "Basic",
  },
  {
    tokenId: 3835,
    Tier: "Basic",
  },
  {
    tokenId: 3836,
    Tier: "Legendary",
  },
  {
    tokenId: 3837,
    Tier: "Basic",
  },
  {
    tokenId: 3838,
    Tier: "Rare",
  },
  {
    tokenId: 3839,
    Tier: "Basic",
  },
  {
    tokenId: 3840,
    Tier: "Basic",
  },
  {
    tokenId: 3841,
    Tier: "Common",
  },
  {
    tokenId: 3842,
    Tier: "Basic",
  },
  {
    tokenId: 3843,
    Tier: "Uncommon",
  },
  {
    tokenId: 3844,
    Tier: "Uncommon",
  },
  {
    tokenId: 3845,
    Tier: "Common",
  },
  {
    tokenId: 3846,
    Tier: "Uncommon",
  },
  {
    tokenId: 3847,
    Tier: "Legendary",
  },
  {
    tokenId: 3848,
    Tier: "Basic",
  },
  {
    tokenId: 3849,
    Tier: "Basic",
  },
  {
    tokenId: 3850,
    Tier: "Rare",
  },
  {
    tokenId: 3851,
    Tier: "Basic",
  },
  {
    tokenId: 3852,
    Tier: "Basic",
  },
  {
    tokenId: 3853,
    Tier: "Common",
  },
  {
    tokenId: 3854,
    Tier: "Basic",
  },
  {
    tokenId: 3855,
    Tier: "Basic",
  },
  {
    tokenId: 3856,
    Tier: "Basic",
  },
  {
    tokenId: 3857,
    Tier: "Common",
  },
  {
    tokenId: 3858,
    Tier: "Rare",
  },
  {
    tokenId: 3859,
    Tier: "Epic",
  },
  {
    tokenId: 3860,
    Tier: "Epic",
  },
  {
    tokenId: 3861,
    Tier: "Common",
  },
  {
    tokenId: 3862,
    Tier: "Basic",
  },
  {
    tokenId: 3863,
    Tier: "Uncommon",
  },
  {
    tokenId: 3864,
    Tier: "Common",
  },
  {
    tokenId: 3865,
    Tier: "Rare",
  },
  {
    tokenId: 3866,
    Tier: "Common",
  },
  {
    tokenId: 3867,
    Tier: "Basic",
  },
  {
    tokenId: 3868,
    Tier: "Basic",
  },
  {
    tokenId: 3869,
    Tier: "Rare",
  },
  {
    tokenId: 3870,
    Tier: "Rare",
  },
  {
    tokenId: 3871,
    Tier: "Basic",
  },
  {
    tokenId: 3872,
    Tier: "Basic",
  },
  {
    tokenId: 3873,
    Tier: "Common",
  },
  {
    tokenId: 3874,
    Tier: "Uncommon",
  },
  {
    tokenId: 3875,
    Tier: "Common",
  },
  {
    tokenId: 3876,
    Tier: "Mythic",
  },
  {
    tokenId: 3877,
    Tier: "Basic",
  },
  {
    tokenId: 3878,
    Tier: "Basic",
  },
  {
    tokenId: 3879,
    Tier: "Common",
  },
  {
    tokenId: 3880,
    Tier: "Uncommon",
  },
  {
    tokenId: 3881,
    Tier: "Basic",
  },
  {
    tokenId: 3882,
    Tier: "Basic",
  },
  {
    tokenId: 3883,
    Tier: "Epic",
  },
  {
    tokenId: 3884,
    Tier: "Common",
  },
  {
    tokenId: 3885,
    Tier: "Exotic",
  },
  {
    tokenId: 3886,
    Tier: "Basic",
  },
  {
    tokenId: 3887,
    Tier: "Basic",
  },
  {
    tokenId: 3888,
    Tier: "Basic",
  },
  {
    tokenId: 3889,
    Tier: "Rare",
  },
  {
    tokenId: 3890,
    Tier: "Basic",
  },
  {
    tokenId: 3891,
    Tier: "Basic",
  },
  {
    tokenId: 3892,
    Tier: "Uncommon",
  },
  {
    tokenId: 3893,
    Tier: "Basic",
  },
  {
    tokenId: 3894,
    Tier: "Basic",
  },
  {
    tokenId: 3895,
    Tier: "Basic",
  },
  {
    tokenId: 3896,
    Tier: "Basic",
  },
  {
    tokenId: 3897,
    Tier: "Basic",
  },
  {
    tokenId: 3898,
    Tier: "Rare",
  },
  {
    tokenId: 3899,
    Tier: "Basic",
  },
  {
    tokenId: 3900,
    Tier: "Basic",
  },
  {
    tokenId: 3901,
    Tier: "Basic",
  },
  {
    tokenId: 3902,
    Tier: "Basic",
  },
  {
    tokenId: 3903,
    Tier: "Exotic",
  },
  {
    tokenId: 3904,
    Tier: "Basic",
  },
  {
    tokenId: 3905,
    Tier: "Basic",
  },
  {
    tokenId: 3906,
    Tier: "Rare",
  },
  {
    tokenId: 3907,
    Tier: "Basic",
  },
  {
    tokenId: 3908,
    Tier: "Basic",
  },
  {
    tokenId: 3909,
    Tier: "Common",
  },
  {
    tokenId: 3910,
    Tier: "Basic",
  },
  {
    tokenId: 3911,
    Tier: "Uncommon",
  },
  {
    tokenId: 3912,
    Tier: "Epic",
  },
  {
    tokenId: 3913,
    Tier: "Rare",
  },
  {
    tokenId: 3914,
    Tier: "Basic",
  },
  {
    tokenId: 3915,
    Tier: "Uncommon",
  },
  {
    tokenId: 3916,
    Tier: "Basic",
  },
  {
    tokenId: 3917,
    Tier: "Common",
  },
  {
    tokenId: 3918,
    Tier: "Legendary",
  },
  {
    tokenId: 3919,
    Tier: "Basic",
  },
  {
    tokenId: 3920,
    Tier: "Legendary",
  },
  {
    tokenId: 3921,
    Tier: "Common",
  },
  {
    tokenId: 3922,
    Tier: "Uncommon",
  },
  {
    tokenId: 3923,
    Tier: "Common",
  },
  {
    tokenId: 3924,
    Tier: "Common",
  },
  {
    tokenId: 3925,
    Tier: "Basic",
  },
  {
    tokenId: 3926,
    Tier: "Basic",
  },
  {
    tokenId: 3927,
    Tier: "Rare",
  },
  {
    tokenId: 3928,
    Tier: "Mythic",
  },
  {
    tokenId: 3929,
    Tier: "Mythic",
  },
  {
    tokenId: 3930,
    Tier: "Basic",
  },
  {
    tokenId: 3931,
    Tier: "Basic",
  },
  {
    tokenId: 3932,
    Tier: "Basic",
  },
  {
    tokenId: 3933,
    Tier: "Common",
  },
  {
    tokenId: 3934,
    Tier: "Basic",
  },
  {
    tokenId: 3935,
    Tier: "Mythic",
  },
  {
    tokenId: 3936,
    Tier: "Epic",
  },
  {
    tokenId: 3937,
    Tier: "Common",
  },
  {
    tokenId: 3938,
    Tier: "Uncommon",
  },
  {
    tokenId: 3939,
    Tier: "Basic",
  },
  {
    tokenId: 3940,
    Tier: "Basic",
  },
  {
    tokenId: 3941,
    Tier: "Common",
  },
  {
    tokenId: 3942,
    Tier: "Basic",
  },
  {
    tokenId: 3943,
    Tier: "Basic",
  },
  {
    tokenId: 3944,
    Tier: "Basic",
  },
  {
    tokenId: 3945,
    Tier: "Basic",
  },
  {
    tokenId: 3946,
    Tier: "Uncommon",
  },
  {
    tokenId: 3947,
    Tier: "Basic",
  },
  {
    tokenId: 3948,
    Tier: "Basic",
  },
  {
    tokenId: 3949,
    Tier: "Basic",
  },
  {
    tokenId: 3950,
    Tier: "Basic",
  },
  {
    tokenId: 3951,
    Tier: "Uncommon",
  },
  {
    tokenId: 3952,
    Tier: "Basic",
  },
  {
    tokenId: 3953,
    Tier: "Basic",
  },
  {
    tokenId: 3954,
    Tier: "Basic",
  },
  {
    tokenId: 3955,
    Tier: "Basic",
  },
  {
    tokenId: 3956,
    Tier: "Basic",
  },
  {
    tokenId: 3957,
    Tier: "Rare",
  },
  {
    tokenId: 3958,
    Tier: "Common",
  },
  {
    tokenId: 3959,
    Tier: "Basic",
  },
  {
    tokenId: 3960,
    Tier: "Basic",
  },
  {
    tokenId: 3961,
    Tier: "Basic",
  },
  {
    tokenId: 3962,
    Tier: "Epic",
  },
  {
    tokenId: 3963,
    Tier: "Basic",
  },
  {
    tokenId: 3964,
    Tier: "Basic",
  },
  {
    tokenId: 3965,
    Tier: "Basic",
  },
  {
    tokenId: 3966,
    Tier: "Basic",
  },
  {
    tokenId: 3967,
    Tier: "Common",
  },
  {
    tokenId: 3968,
    Tier: "Common",
  },
  {
    tokenId: 3969,
    Tier: "Epic",
  },
  {
    tokenId: 3970,
    Tier: "Common",
  },
  {
    tokenId: 3971,
    Tier: "Basic",
  },
  {
    tokenId: 3972,
    Tier: "Basic",
  },
  {
    tokenId: 3973,
    Tier: "Basic",
  },
  {
    tokenId: 3974,
    Tier: "Basic",
  },
  {
    tokenId: 3975,
    Tier: "Uncommon",
  },
  {
    tokenId: 3976,
    Tier: "Basic",
  },
  {
    tokenId: 3977,
    Tier: "Basic",
  },
  {
    tokenId: 3978,
    Tier: "Epic",
  },
  {
    tokenId: 3979,
    Tier: "Basic",
  },
  {
    tokenId: 3980,
    Tier: "Mythic",
  },
  {
    tokenId: 3981,
    Tier: "Basic",
  },
  {
    tokenId: 3982,
    Tier: "Common",
  },
  {
    tokenId: 3983,
    Tier: "Uncommon",
  },
  {
    tokenId: 3984,
    Tier: "Basic",
  },
  {
    tokenId: 3985,
    Tier: "Uncommon",
  },
  {
    tokenId: 3986,
    Tier: "Common",
  },
  {
    tokenId: 3987,
    Tier: "Epic",
  },
  {
    tokenId: 3988,
    Tier: "Basic",
  },
  {
    tokenId: 3989,
    Tier: "Mythic",
  },
  {
    tokenId: 3990,
    Tier: "Common",
  },
  {
    tokenId: 3991,
    Tier: "Basic",
  },
  {
    tokenId: 3992,
    Tier: "Basic",
  },
  {
    tokenId: 3993,
    Tier: "Basic",
  },
  {
    tokenId: 3994,
    Tier: "Rare",
  },
  {
    tokenId: 3995,
    Tier: "Basic",
  },
  {
    tokenId: 3996,
    Tier: "Epic",
  },
  {
    tokenId: 3997,
    Tier: "Legendary",
  },
  {
    tokenId: 3998,
    Tier: "Rare",
  },
  {
    tokenId: 3999,
    Tier: "Basic",
  },
  {
    tokenId: 4000,
    Tier: "Basic",
  },
  {
    tokenId: 4001,
    Tier: "Epic",
  },
  {
    tokenId: 4002,
    Tier: "Basic",
  },
  {
    tokenId: 4003,
    Tier: "Basic",
  },
  {
    tokenId: 4004,
    Tier: "Legendary",
  },
  {
    tokenId: 4005,
    Tier: "Basic",
  },
  {
    tokenId: 4006,
    Tier: "Basic",
  },
  {
    tokenId: 4007,
    Tier: "Common",
  },
  {
    tokenId: 4008,
    Tier: "Basic",
  },
  {
    tokenId: 4009,
    Tier: "Basic",
  },
  {
    tokenId: 4010,
    Tier: "Common",
  },
  {
    tokenId: 4011,
    Tier: "Basic",
  },
  {
    tokenId: 4012,
    Tier: "Basic",
  },
  {
    tokenId: 4013,
    Tier: "Basic",
  },
  {
    tokenId: 4014,
    Tier: "Basic",
  },
  {
    tokenId: 4015,
    Tier: "Basic",
  },
  {
    tokenId: 4016,
    Tier: "Rare",
  },
  {
    tokenId: 4017,
    Tier: "Rare",
  },
  {
    tokenId: 4018,
    Tier: "Common",
  },
  {
    tokenId: 4019,
    Tier: "Legendary",
  },
  {
    tokenId: 4020,
    Tier: "Epic",
  },
  {
    tokenId: 4021,
    Tier: "Basic",
  },
  {
    tokenId: 4022,
    Tier: "Basic",
  },
  {
    tokenId: 4023,
    Tier: "Basic",
  },
  {
    tokenId: 4024,
    Tier: "Basic",
  },
  {
    tokenId: 4025,
    Tier: "Epic",
  },
  {
    tokenId: 4026,
    Tier: "Basic",
  },
  {
    tokenId: 4027,
    Tier: "Common",
  },
  {
    tokenId: 4028,
    Tier: "Basic",
  },
  {
    tokenId: 4029,
    Tier: "Basic",
  },
  {
    tokenId: 4030,
    Tier: "Basic",
  },
  {
    tokenId: 4031,
    Tier: "Common",
  },
  {
    tokenId: 4032,
    Tier: "Basic",
  },
  {
    tokenId: 4033,
    Tier: "Basic",
  },
  {
    tokenId: 4034,
    Tier: "Uncommon",
  },
  {
    tokenId: 4035,
    Tier: "Basic",
  },
  {
    tokenId: 4036,
    Tier: "Basic",
  },
  {
    tokenId: 4037,
    Tier: "Rare",
  },
  {
    tokenId: 4038,
    Tier: "Rare",
  },
  {
    tokenId: 4039,
    Tier: "Mythic",
  },
  {
    tokenId: 4040,
    Tier: "Uncommon",
  },
  {
    tokenId: 4041,
    Tier: "Legendary",
  },
  {
    tokenId: 4042,
    Tier: "Uncommon",
  },
  {
    tokenId: 4043,
    Tier: "Legendary",
  },
  {
    tokenId: 4044,
    Tier: "Basic",
  },
  {
    tokenId: 4045,
    Tier: "Basic",
  },
  {
    tokenId: 4046,
    Tier: "Basic",
  },
  {
    tokenId: 4047,
    Tier: "Basic",
  },
  {
    tokenId: 4048,
    Tier: "Basic",
  },
  {
    tokenId: 4049,
    Tier: "Basic",
  },
  {
    tokenId: 4050,
    Tier: "Basic",
  },
  {
    tokenId: 4051,
    Tier: "Basic",
  },
  {
    tokenId: 4052,
    Tier: "Mythic",
  },
  {
    tokenId: 4053,
    Tier: "Epic",
  },
  {
    tokenId: 4054,
    Tier: "Basic",
  },
  {
    tokenId: 4055,
    Tier: "Basic",
  },
  {
    tokenId: 4056,
    Tier: "Mythic",
  },
  {
    tokenId: 4057,
    Tier: "Common",
  },
  {
    tokenId: 4058,
    Tier: "Basic",
  },
  {
    tokenId: 4059,
    Tier: "Basic",
  },
  {
    tokenId: 4060,
    Tier: "Basic",
  },
  {
    tokenId: 4061,
    Tier: "Common",
  },
  {
    tokenId: 4062,
    Tier: "Basic",
  },
  {
    tokenId: 4063,
    Tier: "Common",
  },
  {
    tokenId: 4064,
    Tier: "Epic",
  },
  {
    tokenId: 4065,
    Tier: "Uncommon",
  },
  {
    tokenId: 4066,
    Tier: "Rare",
  },
  {
    tokenId: 4067,
    Tier: "Basic",
  },
  {
    tokenId: 4068,
    Tier: "Common",
  },
  {
    tokenId: 4069,
    Tier: "Basic",
  },
  {
    tokenId: 4070,
    Tier: "Rare",
  },
  {
    tokenId: 4071,
    Tier: "Basic",
  },
  {
    tokenId: 4072,
    Tier: "Basic",
  },
  {
    tokenId: 4073,
    Tier: "Common",
  },
  {
    tokenId: 4074,
    Tier: "Uncommon",
  },
  {
    tokenId: 4075,
    Tier: "Common",
  },
  {
    tokenId: 4076,
    Tier: "Basic",
  },
  {
    tokenId: 4077,
    Tier: "Basic",
  },
  {
    tokenId: 4078,
    Tier: "Basic",
  },
  {
    tokenId: 4079,
    Tier: "Basic",
  },
  {
    tokenId: 4080,
    Tier: "Basic",
  },
  {
    tokenId: 4081,
    Tier: "Basic",
  },
  {
    tokenId: 4082,
    Tier: "Rare",
  },
  {
    tokenId: 4083,
    Tier: "Basic",
  },
  {
    tokenId: 4084,
    Tier: "Basic",
  },
  {
    tokenId: 4085,
    Tier: "Basic",
  },
  {
    tokenId: 4086,
    Tier: "Basic",
  },
  {
    tokenId: 4087,
    Tier: "Basic",
  },
  {
    tokenId: 4088,
    Tier: "Basic",
  },
  {
    tokenId: 4089,
    Tier: "Uncommon",
  },
  {
    tokenId: 4090,
    Tier: "Uncommon",
  },
  {
    tokenId: 4091,
    Tier: "Rare",
  },
  {
    tokenId: 4092,
    Tier: "Basic",
  },
  {
    tokenId: 4093,
    Tier: "Rare",
  },
  {
    tokenId: 4094,
    Tier: "Uncommon",
  },
  {
    tokenId: 4095,
    Tier: "Common",
  },
  {
    tokenId: 4096,
    Tier: "Uncommon",
  },
  {
    tokenId: 4097,
    Tier: "Basic",
  },
  {
    tokenId: 4098,
    Tier: "Basic",
  },
  {
    tokenId: 4099,
    Tier: "Basic",
  },
  {
    tokenId: 4100,
    Tier: "Mythic",
  },
  {
    tokenId: 4101,
    Tier: "Basic",
  },
  {
    tokenId: 4102,
    Tier: "Common",
  },
  {
    tokenId: 4103,
    Tier: "Rare",
  },
  {
    tokenId: 4104,
    Tier: "Uncommon",
  },
  {
    tokenId: 4105,
    Tier: "Legendary",
  },
  {
    tokenId: 4106,
    Tier: "Uncommon",
  },
  {
    tokenId: 4107,
    Tier: "Epic",
  },
  {
    tokenId: 4108,
    Tier: "Basic",
  },
  {
    tokenId: 4109,
    Tier: "Basic",
  },
  {
    tokenId: 4110,
    Tier: "Basic",
  },
  {
    tokenId: 4111,
    Tier: "Common",
  },
  {
    tokenId: 4112,
    Tier: "Basic",
  },
  {
    tokenId: 4113,
    Tier: "Basic",
  },
  {
    tokenId: 4114,
    Tier: "Basic",
  },
  {
    tokenId: 4115,
    Tier: "Basic",
  },
  {
    tokenId: 4116,
    Tier: "Basic",
  },
  {
    tokenId: 4117,
    Tier: "Common",
  },
  {
    tokenId: 4118,
    Tier: "Common",
  },
  {
    tokenId: 4119,
    Tier: "Basic",
  },
  {
    tokenId: 4120,
    Tier: "Basic",
  },
  {
    tokenId: 4121,
    Tier: "Basic",
  },
  {
    tokenId: 4122,
    Tier: "Basic",
  },
  {
    tokenId: 4123,
    Tier: "Basic",
  },
  {
    tokenId: 4124,
    Tier: "Basic",
  },
  {
    tokenId: 4125,
    Tier: "Rare",
  },
  {
    tokenId: 4126,
    Tier: "Basic",
  },
  {
    tokenId: 4127,
    Tier: "Basic",
  },
  {
    tokenId: 4128,
    Tier: "Basic",
  },
  {
    tokenId: 4129,
    Tier: "Basic",
  },
  {
    tokenId: 4130,
    Tier: "Basic",
  },
  {
    tokenId: 4131,
    Tier: "Legendary",
  },
  {
    tokenId: 4132,
    Tier: "Uncommon",
  },
  {
    tokenId: 4133,
    Tier: "Basic",
  },
  {
    tokenId: 4134,
    Tier: "Basic",
  },
  {
    tokenId: 4135,
    Tier: "Basic",
  },
  {
    tokenId: 4136,
    Tier: "Epic",
  },
  {
    tokenId: 4137,
    Tier: "Basic",
  },
  {
    tokenId: 4138,
    Tier: "Rare",
  },
  {
    tokenId: 4139,
    Tier: "Basic",
  },
  {
    tokenId: 4140,
    Tier: "Uncommon",
  },
  {
    tokenId: 4141,
    Tier: "Rare",
  },
  {
    tokenId: 4142,
    Tier: "Basic",
  },
  {
    tokenId: 4143,
    Tier: "Basic",
  },
  {
    tokenId: 4144,
    Tier: "Basic",
  },
  {
    tokenId: 4145,
    Tier: "Common",
  },
  {
    tokenId: 4146,
    Tier: "Common",
  },
  {
    tokenId: 4147,
    Tier: "Basic",
  },
  {
    tokenId: 4148,
    Tier: "Rare",
  },
  {
    tokenId: 4149,
    Tier: "Basic",
  },
  {
    tokenId: 4150,
    Tier: "Rare",
  },
  {
    tokenId: 4151,
    Tier: "Basic",
  },
  {
    tokenId: 4152,
    Tier: "Rare",
  },
  {
    tokenId: 4153,
    Tier: "Rare",
  },
  {
    tokenId: 4154,
    Tier: "Basic",
  },
  {
    tokenId: 4155,
    Tier: "Common",
  },
  {
    tokenId: 4156,
    Tier: "Common",
  },
  {
    tokenId: 4157,
    Tier: "Basic",
  },
  {
    tokenId: 4158,
    Tier: "Uncommon",
  },
  {
    tokenId: 4159,
    Tier: "Mythic",
  },
  {
    tokenId: 4160,
    Tier: "Uncommon",
  },
  {
    tokenId: 4161,
    Tier: "Basic",
  },
  {
    tokenId: 4162,
    Tier: "Uncommon",
  },
  {
    tokenId: 4163,
    Tier: "Uncommon",
  },
  {
    tokenId: 4164,
    Tier: "Common",
  },
  {
    tokenId: 4165,
    Tier: "Epic",
  },
  {
    tokenId: 4166,
    Tier: "Epic",
  },
  {
    tokenId: 4167,
    Tier: "Common",
  },
  {
    tokenId: 4168,
    Tier: "Uncommon",
  },
  {
    tokenId: 4169,
    Tier: "Basic",
  },
  {
    tokenId: 4170,
    Tier: "Common",
  },
  {
    tokenId: 4171,
    Tier: "Basic",
  },
  {
    tokenId: 4172,
    Tier: "Uncommon",
  },
  {
    tokenId: 4173,
    Tier: "Basic",
  },
  {
    tokenId: 4174,
    Tier: "Basic",
  },
  {
    tokenId: 4175,
    Tier: "Common",
  },
  {
    tokenId: 4176,
    Tier: "Basic",
  },
  {
    tokenId: 4177,
    Tier: "Basic",
  },
  {
    tokenId: 4178,
    Tier: "Uncommon",
  },
  {
    tokenId: 4179,
    Tier: "Basic",
  },
  {
    tokenId: 4180,
    Tier: "Uncommon",
  },
  {
    tokenId: 4181,
    Tier: "Legendary",
  },
  {
    tokenId: 4182,
    Tier: "Common",
  },
  {
    tokenId: 4183,
    Tier: "Basic",
  },
  {
    tokenId: 4184,
    Tier: "Common",
  },
  {
    tokenId: 4185,
    Tier: "Mythic",
  },
  {
    tokenId: 4186,
    Tier: "Basic",
  },
  {
    tokenId: 4187,
    Tier: "Common",
  },
  {
    tokenId: 4188,
    Tier: "Basic",
  },
  {
    tokenId: 4189,
    Tier: "Basic",
  },
  {
    tokenId: 4190,
    Tier: "Common",
  },
  {
    tokenId: 4191,
    Tier: "Uncommon",
  },
  {
    tokenId: 4192,
    Tier: "Common",
  },
  {
    tokenId: 4193,
    Tier: "Basic",
  },
  {
    tokenId: 4194,
    Tier: "Rare",
  },
  {
    tokenId: 4195,
    Tier: "Basic",
  },
  {
    tokenId: 4196,
    Tier: "Basic",
  },
  {
    tokenId: 4197,
    Tier: "Legendary",
  },
  {
    tokenId: 4198,
    Tier: "Basic",
  },
  {
    tokenId: 4199,
    Tier: "Basic",
  },
  {
    tokenId: 4200,
    Tier: "Basic",
  },
  {
    tokenId: 4201,
    Tier: "Basic",
  },
  {
    tokenId: 4202,
    Tier: "Uncommon",
  },
  {
    tokenId: 4203,
    Tier: "Basic",
  },
  {
    tokenId: 4204,
    Tier: "Legendary",
  },
  {
    tokenId: 4205,
    Tier: "Common",
  },
  {
    tokenId: 4206,
    Tier: "Common",
  },
  {
    tokenId: 4207,
    Tier: "Common",
  },
  {
    tokenId: 4208,
    Tier: "Basic",
  },
  {
    tokenId: 4209,
    Tier: "Epic",
  },
  {
    tokenId: 4210,
    Tier: "Common",
  },
  {
    tokenId: 4211,
    Tier: "Basic",
  },
  {
    tokenId: 4212,
    Tier: "Common",
  },
  {
    tokenId: 4213,
    Tier: "Rare",
  },
  {
    tokenId: 4214,
    Tier: "Rare",
  },
  {
    tokenId: 4215,
    Tier: "Uncommon",
  },
  {
    tokenId: 4216,
    Tier: "Epic",
  },
  {
    tokenId: 4217,
    Tier: "Common",
  },
  {
    tokenId: 4218,
    Tier: "Basic",
  },
  {
    tokenId: 4219,
    Tier: "Rare",
  },
  {
    tokenId: 4220,
    Tier: "Basic",
  },
  {
    tokenId: 4221,
    Tier: "Epic",
  },
  {
    tokenId: 4222,
    Tier: "Basic",
  },
  {
    tokenId: 4223,
    Tier: "Basic",
  },
  {
    tokenId: 4224,
    Tier: "Epic",
  },
  {
    tokenId: 4225,
    Tier: "Rare",
  },
  {
    tokenId: 4226,
    Tier: "Basic",
  },
  {
    tokenId: 4227,
    Tier: "Basic",
  },
  {
    tokenId: 4228,
    Tier: "Common",
  },
  {
    tokenId: 4229,
    Tier: "Mythic",
  },
  {
    tokenId: 4230,
    Tier: "Legendary",
  },
  {
    tokenId: 4231,
    Tier: "Rare",
  },
  {
    tokenId: 4232,
    Tier: "Basic",
  },
  {
    tokenId: 4233,
    Tier: "Basic",
  },
  {
    tokenId: 4234,
    Tier: "Common",
  },
  {
    tokenId: 4235,
    Tier: "Basic",
  },
  {
    tokenId: 4236,
    Tier: "Uncommon",
  },
  {
    tokenId: 4237,
    Tier: "Common",
  },
  {
    tokenId: 4238,
    Tier: "Common",
  },
  {
    tokenId: 4239,
    Tier: "Basic",
  },
  {
    tokenId: 4240,
    Tier: "Basic",
  },
  {
    tokenId: 4241,
    Tier: "Uncommon",
  },
  {
    tokenId: 4242,
    Tier: "Basic",
  },
  {
    tokenId: 4243,
    Tier: "Basic",
  },
  {
    tokenId: 4244,
    Tier: "Basic",
  },
  {
    tokenId: 4245,
    Tier: "Common",
  },
  {
    tokenId: 4246,
    Tier: "Epic",
  },
  {
    tokenId: 4247,
    Tier: "Common",
  },
  {
    tokenId: 4248,
    Tier: "Epic",
  },
  {
    tokenId: 4249,
    Tier: "Basic",
  },
  {
    tokenId: 4250,
    Tier: "Basic",
  },
  {
    tokenId: 4251,
    Tier: "Common",
  },
  {
    tokenId: 4252,
    Tier: "Rare",
  },
  {
    tokenId: 4253,
    Tier: "Basic",
  },
  {
    tokenId: 4254,
    Tier: "Basic",
  },
  {
    tokenId: 4255,
    Tier: "Epic",
  },
  {
    tokenId: 4256,
    Tier: "Basic",
  },
  {
    tokenId: 4257,
    Tier: "Basic",
  },
  {
    tokenId: 4258,
    Tier: "Common",
  },
  {
    tokenId: 4259,
    Tier: "Basic",
  },
  {
    tokenId: 4260,
    Tier: "Basic",
  },
  {
    tokenId: 4261,
    Tier: "Basic",
  },
  {
    tokenId: 4262,
    Tier: "Basic",
  },
  {
    tokenId: 4263,
    Tier: "Common",
  },
  {
    tokenId: 4264,
    Tier: "Basic",
  },
  {
    tokenId: 4265,
    Tier: "Legendary",
  },
  {
    tokenId: 4266,
    Tier: "Basic",
  },
  {
    tokenId: 4267,
    Tier: "Basic",
  },
  {
    tokenId: 4268,
    Tier: "Basic",
  },
  {
    tokenId: 4269,
    Tier: "Common",
  },
  {
    tokenId: 4270,
    Tier: "Basic",
  },
  {
    tokenId: 4271,
    Tier: "Rare",
  },
  {
    tokenId: 4272,
    Tier: "Rare",
  },
  {
    tokenId: 4273,
    Tier: "Basic",
  },
  {
    tokenId: 4274,
    Tier: "Basic",
  },
  {
    tokenId: 4275,
    Tier: "Basic",
  },
  {
    tokenId: 4276,
    Tier: "Basic",
  },
  {
    tokenId: 4277,
    Tier: "Legendary",
  },
  {
    tokenId: 4278,
    Tier: "Basic",
  },
  {
    tokenId: 4279,
    Tier: "Basic",
  },
  {
    tokenId: 4280,
    Tier: "Basic",
  },
  {
    tokenId: 4281,
    Tier: "Basic",
  },
  {
    tokenId: 4282,
    Tier: "Uncommon",
  },
  {
    tokenId: 4283,
    Tier: "Basic",
  },
  {
    tokenId: 4284,
    Tier: "Basic",
  },
  {
    tokenId: 4285,
    Tier: "Common",
  },
  {
    tokenId: 4286,
    Tier: "Basic",
  },
  {
    tokenId: 4287,
    Tier: "Legendary",
  },
  {
    tokenId: 4288,
    Tier: "Basic",
  },
  {
    tokenId: 4289,
    Tier: "Uncommon",
  },
  {
    tokenId: 4290,
    Tier: "Legendary",
  },
  {
    tokenId: 4291,
    Tier: "Basic",
  },
  {
    tokenId: 4292,
    Tier: "Basic",
  },
  {
    tokenId: 4293,
    Tier: "Basic",
  },
  {
    tokenId: 4294,
    Tier: "Basic",
  },
  {
    tokenId: 4295,
    Tier: "Epic",
  },
  {
    tokenId: 4296,
    Tier: "Uncommon",
  },
  {
    tokenId: 4297,
    Tier: "Basic",
  },
  {
    tokenId: 4298,
    Tier: "Basic",
  },
  {
    tokenId: 4299,
    Tier: "Basic",
  },
  {
    tokenId: 4300,
    Tier: "Basic",
  },
  {
    tokenId: 4301,
    Tier: "Basic",
  },
  {
    tokenId: 4302,
    Tier: "Basic",
  },
  {
    tokenId: 4303,
    Tier: "Basic",
  },
  {
    tokenId: 4304,
    Tier: "Basic",
  },
  {
    tokenId: 4305,
    Tier: "Basic",
  },
  {
    tokenId: 4306,
    Tier: "Legendary",
  },
  {
    tokenId: 4307,
    Tier: "Basic",
  },
  {
    tokenId: 4308,
    Tier: "Basic",
  },
  {
    tokenId: 4309,
    Tier: "Basic",
  },
  {
    tokenId: 4310,
    Tier: "Basic",
  },
  {
    tokenId: 4311,
    Tier: "Common",
  },
  {
    tokenId: 4312,
    Tier: "Uncommon",
  },
  {
    tokenId: 4313,
    Tier: "Basic",
  },
  {
    tokenId: 4314,
    Tier: "Basic",
  },
  {
    tokenId: 4315,
    Tier: "Common",
  },
  {
    tokenId: 4316,
    Tier: "Basic",
  },
  {
    tokenId: 4317,
    Tier: "Rare",
  },
  {
    tokenId: 4318,
    Tier: "Basic",
  },
  {
    tokenId: 4319,
    Tier: "Common",
  },
  {
    tokenId: 4320,
    Tier: "Uncommon",
  },
  {
    tokenId: 4321,
    Tier: "Mythic",
  },
  {
    tokenId: 4322,
    Tier: "Basic",
  },
  {
    tokenId: 4323,
    Tier: "Basic",
  },
  {
    tokenId: 4324,
    Tier: "Basic",
  },
  {
    tokenId: 4325,
    Tier: "Common",
  },
  {
    tokenId: 4326,
    Tier: "Basic",
  },
  {
    tokenId: 4327,
    Tier: "Epic",
  },
  {
    tokenId: 4328,
    Tier: "Basic",
  },
  {
    tokenId: 4329,
    Tier: "Basic",
  },
  {
    tokenId: 4330,
    Tier: "Basic",
  },
  {
    tokenId: 4331,
    Tier: "Uncommon",
  },
  {
    tokenId: 4332,
    Tier: "Uncommon",
  },
  {
    tokenId: 4333,
    Tier: "Basic",
  },
  {
    tokenId: 4334,
    Tier: "Basic",
  },
  {
    tokenId: 4335,
    Tier: "Common",
  },
  {
    tokenId: 4336,
    Tier: "Basic",
  },
  {
    tokenId: 4337,
    Tier: "Common",
  },
  {
    tokenId: 4338,
    Tier: "Basic",
  },
  {
    tokenId: 4339,
    Tier: "Basic",
  },
  {
    tokenId: 4340,
    Tier: "Legendary",
  },
  {
    tokenId: 4341,
    Tier: "Basic",
  },
  {
    tokenId: 4342,
    Tier: "Common",
  },
  {
    tokenId: 4343,
    Tier: "Mythic",
  },
  {
    tokenId: 4344,
    Tier: "Basic",
  },
  {
    tokenId: 4345,
    Tier: "Legendary",
  },
  {
    tokenId: 4346,
    Tier: "Common",
  },
  {
    tokenId: 4347,
    Tier: "Uncommon",
  },
  {
    tokenId: 4348,
    Tier: "Common",
  },
  {
    tokenId: 4349,
    Tier: "Common",
  },
  {
    tokenId: 4350,
    Tier: "Basic",
  },
  {
    tokenId: 4351,
    Tier: "Basic",
  },
  {
    tokenId: 4352,
    Tier: "Uncommon",
  },
  {
    tokenId: 4353,
    Tier: "Uncommon",
  },
  {
    tokenId: 4354,
    Tier: "Basic",
  },
  {
    tokenId: 4355,
    Tier: "Rare",
  },
  {
    tokenId: 4356,
    Tier: "Legendary",
  },
  {
    tokenId: 4357,
    Tier: "Common",
  },
  {
    tokenId: 4358,
    Tier: "Rare",
  },
  {
    tokenId: 4359,
    Tier: "Basic",
  },
  {
    tokenId: 4360,
    Tier: "Basic",
  },
  {
    tokenId: 4361,
    Tier: "Common",
  },
  {
    tokenId: 4362,
    Tier: "Basic",
  },
  {
    tokenId: 4363,
    Tier: "Basic",
  },
  {
    tokenId: 4364,
    Tier: "Rare",
  },
  {
    tokenId: 4365,
    Tier: "Basic",
  },
  {
    tokenId: 4366,
    Tier: "Rare",
  },
  {
    tokenId: 4367,
    Tier: "Rare",
  },
  {
    tokenId: 4368,
    Tier: "Basic",
  },
  {
    tokenId: 4369,
    Tier: "Rare",
  },
  {
    tokenId: 4370,
    Tier: "Common",
  },
  {
    tokenId: 4371,
    Tier: "Basic",
  },
  {
    tokenId: 4372,
    Tier: "Uncommon",
  },
  {
    tokenId: 4373,
    Tier: "Uncommon",
  },
  {
    tokenId: 4374,
    Tier: "Epic",
  },
  {
    tokenId: 4375,
    Tier: "Uncommon",
  },
  {
    tokenId: 4376,
    Tier: "Rare",
  },
  {
    tokenId: 4377,
    Tier: "Uncommon",
  },
  {
    tokenId: 4378,
    Tier: "Common",
  },
  {
    tokenId: 4379,
    Tier: "Basic",
  },
  {
    tokenId: 4380,
    Tier: "Basic",
  },
  {
    tokenId: 4381,
    Tier: "Basic",
  },
  {
    tokenId: 4382,
    Tier: "Common",
  },
  {
    tokenId: 4383,
    Tier: "Basic",
  },
  {
    tokenId: 4384,
    Tier: "Basic",
  },
  {
    tokenId: 4385,
    Tier: "Basic",
  },
  {
    tokenId: 4386,
    Tier: "Common",
  },
  {
    tokenId: 4387,
    Tier: "Uncommon",
  },
  {
    tokenId: 4388,
    Tier: "Basic",
  },
  {
    tokenId: 4389,
    Tier: "Basic",
  },
  {
    tokenId: 4390,
    Tier: "Rare",
  },
  {
    tokenId: 4391,
    Tier: "Epic",
  },
  {
    tokenId: 4392,
    Tier: "Basic",
  },
  {
    tokenId: 4393,
    Tier: "Basic",
  },
  {
    tokenId: 4394,
    Tier: "Rare",
  },
  {
    tokenId: 4395,
    Tier: "Common",
  },
  {
    tokenId: 4396,
    Tier: "Uncommon",
  },
  {
    tokenId: 4397,
    Tier: "Basic",
  },
  {
    tokenId: 4398,
    Tier: "Basic",
  },
  {
    tokenId: 4399,
    Tier: "Basic",
  },
  {
    tokenId: 4400,
    Tier: "Common",
  },
  {
    tokenId: 4401,
    Tier: "Basic",
  },
  {
    tokenId: 4402,
    Tier: "Uncommon",
  },
  {
    tokenId: 4403,
    Tier: "Basic",
  },
  {
    tokenId: 4404,
    Tier: "Mythic",
  },
  {
    tokenId: 4405,
    Tier: "Common",
  },
  {
    tokenId: 4406,
    Tier: "Rare",
  },
  {
    tokenId: 4407,
    Tier: "Common",
  },
  {
    tokenId: 4408,
    Tier: "Uncommon",
  },
  {
    tokenId: 4409,
    Tier: "Rare",
  },
  {
    tokenId: 4410,
    Tier: "Basic",
  },
  {
    tokenId: 4411,
    Tier: "Basic",
  },
  {
    tokenId: 4412,
    Tier: "Basic",
  },
  {
    tokenId: 4413,
    Tier: "Basic",
  },
  {
    tokenId: 4414,
    Tier: "Basic",
  },
  {
    tokenId: 4415,
    Tier: "Basic",
  },
  {
    tokenId: 4416,
    Tier: "Common",
  },
  {
    tokenId: 4417,
    Tier: "Uncommon",
  },
  {
    tokenId: 4418,
    Tier: "Uncommon",
  },
  {
    tokenId: 4419,
    Tier: "Uncommon",
  },
  {
    tokenId: 4420,
    Tier: "Rare",
  },
  {
    tokenId: 4421,
    Tier: "Basic",
  },
  {
    tokenId: 4422,
    Tier: "Basic",
  },
  {
    tokenId: 4423,
    Tier: "Basic",
  },
  {
    tokenId: 4424,
    Tier: "Epic",
  },
  {
    tokenId: 4425,
    Tier: "Basic",
  },
  {
    tokenId: 4426,
    Tier: "Basic",
  },
  {
    tokenId: 4427,
    Tier: "Basic",
  },
  {
    tokenId: 4428,
    Tier: "Basic",
  },
  {
    tokenId: 4429,
    Tier: "Basic",
  },
  {
    tokenId: 4430,
    Tier: "Common",
  },
  {
    tokenId: 4431,
    Tier: "Basic",
  },
  {
    tokenId: 4432,
    Tier: "Basic",
  },
  {
    tokenId: 4433,
    Tier: "Basic",
  },
  {
    tokenId: 4434,
    Tier: "Rare",
  },
  {
    tokenId: 4435,
    Tier: "Epic",
  },
  {
    tokenId: 4436,
    Tier: "Common",
  },
  {
    tokenId: 4437,
    Tier: "Basic",
  },
  {
    tokenId: 4438,
    Tier: "Common",
  },
  {
    tokenId: 4439,
    Tier: "Basic",
  },
  {
    tokenId: 4440,
    Tier: "Legendary",
  },
  {
    tokenId: 4441,
    Tier: "Basic",
  },
  {
    tokenId: 4442,
    Tier: "Basic",
  },
  {
    tokenId: 4443,
    Tier: "Basic",
  },
  {
    tokenId: 4444,
    Tier: "Basic",
  },
  {
    tokenId: 4445,
    Tier: "Epic",
  },
  {
    tokenId: 4446,
    Tier: "Common",
  },
  {
    tokenId: 4447,
    Tier: "Basic",
  },
  {
    tokenId: 4448,
    Tier: "Basic",
  },
  {
    tokenId: 4449,
    Tier: "Rare",
  },
  {
    tokenId: 4450,
    Tier: "Basic",
  },
  {
    tokenId: 4451,
    Tier: "Basic",
  },
  {
    tokenId: 4452,
    Tier: "Basic",
  },
  {
    tokenId: 4453,
    Tier: "Uncommon",
  },
  {
    tokenId: 4454,
    Tier: "Basic",
  },
  {
    tokenId: 4455,
    Tier: "Epic",
  },
  {
    tokenId: 4456,
    Tier: "Common",
  },
  {
    tokenId: 4457,
    Tier: "Rare",
  },
  {
    tokenId: 4458,
    Tier: "Common",
  },
  {
    tokenId: 4459,
    Tier: "Basic",
  },
  {
    tokenId: 4460,
    Tier: "Epic",
  },
  {
    tokenId: 4461,
    Tier: "Basic",
  },
  {
    tokenId: 4462,
    Tier: "Uncommon",
  },
  {
    tokenId: 4463,
    Tier: "Basic",
  },
  {
    tokenId: 4464,
    Tier: "Basic",
  },
  {
    tokenId: 4465,
    Tier: "Basic",
  },
  {
    tokenId: 4466,
    Tier: "Basic",
  },
  {
    tokenId: 4467,
    Tier: "Common",
  },
  {
    tokenId: 4468,
    Tier: "Basic",
  },
  {
    tokenId: 4469,
    Tier: "Common",
  },
  {
    tokenId: 4470,
    Tier: "Epic",
  },
  {
    tokenId: 4471,
    Tier: "Uncommon",
  },
  {
    tokenId: 4472,
    Tier: "Basic",
  },
  {
    tokenId: 4473,
    Tier: "Basic",
  },
  {
    tokenId: 4474,
    Tier: "Basic",
  },
  {
    tokenId: 4475,
    Tier: "Epic",
  },
  {
    tokenId: 4476,
    Tier: "Basic",
  },
  {
    tokenId: 4477,
    Tier: "Basic",
  },
  {
    tokenId: 4478,
    Tier: "Epic",
  },
  {
    tokenId: 4479,
    Tier: "Basic",
  },
  {
    tokenId: 4480,
    Tier: "Basic",
  },
  {
    tokenId: 4481,
    Tier: "Uncommon",
  },
  {
    tokenId: 4482,
    Tier: "Epic",
  },
  {
    tokenId: 4483,
    Tier: "Basic",
  },
  {
    tokenId: 4484,
    Tier: "Mythic",
  },
  {
    tokenId: 4485,
    Tier: "Basic",
  },
  {
    tokenId: 4486,
    Tier: "Mythic",
  },
  {
    tokenId: 4487,
    Tier: "Common",
  },
  {
    tokenId: 4488,
    Tier: "Basic",
  },
  {
    tokenId: 4489,
    Tier: "Common",
  },
  {
    tokenId: 4490,
    Tier: "Epic",
  },
  {
    tokenId: 4491,
    Tier: "Mythic",
  },
  {
    tokenId: 4492,
    Tier: "Common",
  },
  {
    tokenId: 4493,
    Tier: "Basic",
  },
  {
    tokenId: 4494,
    Tier: "Basic",
  },
  {
    tokenId: 4495,
    Tier: "Common",
  },
  {
    tokenId: 4496,
    Tier: "Common",
  },
  {
    tokenId: 4497,
    Tier: "Mythic",
  },
  {
    tokenId: 4498,
    Tier: "Basic",
  },
  {
    tokenId: 4499,
    Tier: "Basic",
  },
  {
    tokenId: 4500,
    Tier: "Rare",
  },
  {
    tokenId: 4501,
    Tier: "Basic",
  },
  {
    tokenId: 4502,
    Tier: "Rare",
  },
  {
    tokenId: 4503,
    Tier: "Basic",
  },
  {
    tokenId: 4504,
    Tier: "Legendary",
  },
  {
    tokenId: 4505,
    Tier: "Epic",
  },
  {
    tokenId: 4506,
    Tier: "Basic",
  },
  {
    tokenId: 4507,
    Tier: "Basic",
  },
  {
    tokenId: 4508,
    Tier: "Basic",
  },
  {
    tokenId: 4509,
    Tier: "Common",
  },
  {
    tokenId: 4510,
    Tier: "Basic",
  },
  {
    tokenId: 4511,
    Tier: "Basic",
  },
  {
    tokenId: 4512,
    Tier: "Common",
  },
  {
    tokenId: 4513,
    Tier: "Epic",
  },
  {
    tokenId: 4514,
    Tier: "Legendary",
  },
  {
    tokenId: 4515,
    Tier: "Epic",
  },
  {
    tokenId: 4516,
    Tier: "Basic",
  },
  {
    tokenId: 4517,
    Tier: "Rare",
  },
  {
    tokenId: 4518,
    Tier: "Basic",
  },
  {
    tokenId: 4519,
    Tier: "Common",
  },
  {
    tokenId: 4520,
    Tier: "Epic",
  },
  {
    tokenId: 4521,
    Tier: "Legendary",
  },
  {
    tokenId: 4522,
    Tier: "Rare",
  },
  {
    tokenId: 4523,
    Tier: "Basic",
  },
  {
    tokenId: 4524,
    Tier: "Basic",
  },
  {
    tokenId: 4525,
    Tier: "Uncommon",
  },
  {
    tokenId: 4526,
    Tier: "Rare",
  },
  {
    tokenId: 4527,
    Tier: "Mythic",
  },
  {
    tokenId: 4528,
    Tier: "Uncommon",
  },
  {
    tokenId: 4529,
    Tier: "Basic",
  },
  {
    tokenId: 4530,
    Tier: "Epic",
  },
  {
    tokenId: 4531,
    Tier: "Epic",
  },
  {
    tokenId: 4532,
    Tier: "Basic",
  },
  {
    tokenId: 4533,
    Tier: "Mythic",
  },
  {
    tokenId: 4534,
    Tier: "Basic",
  },
  {
    tokenId: 4535,
    Tier: "Rare",
  },
  {
    tokenId: 4536,
    Tier: "Rare",
  },
  {
    tokenId: 4537,
    Tier: "Basic",
  },
  {
    tokenId: 4538,
    Tier: "Uncommon",
  },
  {
    tokenId: 4539,
    Tier: "Rare",
  },
  {
    tokenId: 4540,
    Tier: "Rare",
  },
  {
    tokenId: 4541,
    Tier: "Legendary",
  },
  {
    tokenId: 4542,
    Tier: "Common",
  },
  {
    tokenId: 4543,
    Tier: "Uncommon",
  },
  {
    tokenId: 4544,
    Tier: "Basic",
  },
  {
    tokenId: 4545,
    Tier: "Basic",
  },
  {
    tokenId: 4546,
    Tier: "Basic",
  },
  {
    tokenId: 4547,
    Tier: "Basic",
  },
  {
    tokenId: 4548,
    Tier: "Basic",
  },
  {
    tokenId: 4549,
    Tier: "Basic",
  },
  {
    tokenId: 4550,
    Tier: "Basic",
  },
  {
    tokenId: 4551,
    Tier: "Common",
  },
  {
    tokenId: 4552,
    Tier: "Legendary",
  },
  {
    tokenId: 4553,
    Tier: "Epic",
  },
  {
    tokenId: 4554,
    Tier: "Common",
  },
  {
    tokenId: 4555,
    Tier: "Uncommon",
  },
  {
    tokenId: 4556,
    Tier: "Basic",
  },
  {
    tokenId: 4557,
    Tier: "Basic",
  },
  {
    tokenId: 4558,
    Tier: "Basic",
  },
  {
    tokenId: 4559,
    Tier: "Mythic",
  },
  {
    tokenId: 4560,
    Tier: "Mythic",
  },
  {
    tokenId: 4561,
    Tier: "Common",
  },
  {
    tokenId: 4562,
    Tier: "Basic",
  },
  {
    tokenId: 4563,
    Tier: "Basic",
  },
  {
    tokenId: 4564,
    Tier: "Uncommon",
  },
  {
    tokenId: 4565,
    Tier: "Common",
  },
  {
    tokenId: 4566,
    Tier: "Uncommon",
  },
  {
    tokenId: 4567,
    Tier: "Common",
  },
  {
    tokenId: 4568,
    Tier: "Mythic",
  },
  {
    tokenId: 4569,
    Tier: "Basic",
  },
  {
    tokenId: 4570,
    Tier: "Basic",
  },
  {
    tokenId: 4571,
    Tier: "Basic",
  },
  {
    tokenId: 4572,
    Tier: "Basic",
  },
  {
    tokenId: 4573,
    Tier: "Basic",
  },
  {
    tokenId: 4574,
    Tier: "Epic",
  },
  {
    tokenId: 4575,
    Tier: "Basic",
  },
  {
    tokenId: 4576,
    Tier: "Legendary",
  },
  {
    tokenId: 4577,
    Tier: "Legendary",
  },
  {
    tokenId: 4578,
    Tier: "Basic",
  },
  {
    tokenId: 4579,
    Tier: "Legendary",
  },
  {
    tokenId: 4580,
    Tier: "Common",
  },
  {
    tokenId: 4581,
    Tier: "Exotic",
  },
  {
    tokenId: 4582,
    Tier: "Basic",
  },
  {
    tokenId: 4583,
    Tier: "Basic",
  },
  {
    tokenId: 4584,
    Tier: "Basic",
  },
  {
    tokenId: 4585,
    Tier: "Basic",
  },
  {
    tokenId: 4586,
    Tier: "Basic",
  },
  {
    tokenId: 4587,
    Tier: "Basic",
  },
  {
    tokenId: 4588,
    Tier: "Common",
  },
  {
    tokenId: 4589,
    Tier: "Rare",
  },
  {
    tokenId: 4590,
    Tier: "Basic",
  },
  {
    tokenId: 4591,
    Tier: "Basic",
  },
  {
    tokenId: 4592,
    Tier: "Uncommon",
  },
  {
    tokenId: 4593,
    Tier: "Basic",
  },
  {
    tokenId: 4594,
    Tier: "Basic",
  },
  {
    tokenId: 4595,
    Tier: "Basic",
  },
  {
    tokenId: 4596,
    Tier: "Basic",
  },
  {
    tokenId: 4597,
    Tier: "Basic",
  },
  {
    tokenId: 4598,
    Tier: "Rare",
  },
  {
    tokenId: 4599,
    Tier: "Common",
  },
  {
    tokenId: 4600,
    Tier: "Basic",
  },
  {
    tokenId: 4601,
    Tier: "Rare",
  },
  {
    tokenId: 4602,
    Tier: "Common",
  },
  {
    tokenId: 4603,
    Tier: "Basic",
  },
  {
    tokenId: 4604,
    Tier: "Basic",
  },
  {
    tokenId: 4605,
    Tier: "Epic",
  },
  {
    tokenId: 4606,
    Tier: "Mythic",
  },
  {
    tokenId: 4607,
    Tier: "Basic",
  },
  {
    tokenId: 4608,
    Tier: "Basic",
  },
  {
    tokenId: 4609,
    Tier: "Basic",
  },
  {
    tokenId: 4610,
    Tier: "Basic",
  },
  {
    tokenId: 4611,
    Tier: "Basic",
  },
  {
    tokenId: 4612,
    Tier: "Epic",
  },
  {
    tokenId: 4613,
    Tier: "Basic",
  },
  {
    tokenId: 4614,
    Tier: "Basic",
  },
  {
    tokenId: 4615,
    Tier: "Basic",
  },
  {
    tokenId: 4616,
    Tier: "Rare",
  },
  {
    tokenId: 4617,
    Tier: "Uncommon",
  },
  {
    tokenId: 4618,
    Tier: "Basic",
  },
  {
    tokenId: 4619,
    Tier: "Common",
  },
  {
    tokenId: 4620,
    Tier: "Basic",
  },
  {
    tokenId: 4621,
    Tier: "Rare",
  },
  {
    tokenId: 4622,
    Tier: "Mythic",
  },
  {
    tokenId: 4623,
    Tier: "Basic",
  },
  {
    tokenId: 4624,
    Tier: "Common",
  },
  {
    tokenId: 4625,
    Tier: "Basic",
  },
  {
    tokenId: 4626,
    Tier: "Epic",
  },
  {
    tokenId: 4627,
    Tier: "Basic",
  },
  {
    tokenId: 4628,
    Tier: "Basic",
  },
  {
    tokenId: 4629,
    Tier: "Basic",
  },
  {
    tokenId: 4630,
    Tier: "Basic",
  },
  {
    tokenId: 4631,
    Tier: "Epic",
  },
  {
    tokenId: 4632,
    Tier: "Common",
  },
  {
    tokenId: 4633,
    Tier: "Basic",
  },
  {
    tokenId: 4634,
    Tier: "Uncommon",
  },
  {
    tokenId: 4635,
    Tier: "Uncommon",
  },
  {
    tokenId: 4636,
    Tier: "Rare",
  },
  {
    tokenId: 4637,
    Tier: "Legendary",
  },
  {
    tokenId: 4638,
    Tier: "Epic",
  },
  {
    tokenId: 4639,
    Tier: "Basic",
  },
  {
    tokenId: 4640,
    Tier: "Basic",
  },
  {
    tokenId: 4641,
    Tier: "Uncommon",
  },
  {
    tokenId: 4642,
    Tier: "Uncommon",
  },
  {
    tokenId: 4643,
    Tier: "Basic",
  },
  {
    tokenId: 4644,
    Tier: "Mythic",
  },
  {
    tokenId: 4645,
    Tier: "Common",
  },
  {
    tokenId: 4646,
    Tier: "Common",
  },
  {
    tokenId: 4647,
    Tier: "Basic",
  },
  {
    tokenId: 4648,
    Tier: "Common",
  },
  {
    tokenId: 4649,
    Tier: "Uncommon",
  },
  {
    tokenId: 4650,
    Tier: "Rare",
  },
  {
    tokenId: 4651,
    Tier: "Rare",
  },
  {
    tokenId: 4652,
    Tier: "Common",
  },
  {
    tokenId: 4653,
    Tier: "Basic",
  },
  {
    tokenId: 4654,
    Tier: "Common",
  },
  {
    tokenId: 4655,
    Tier: "Basic",
  },
  {
    tokenId: 4656,
    Tier: "Rare",
  },
  {
    tokenId: 4657,
    Tier: "Epic",
  },
  {
    tokenId: 4658,
    Tier: "Epic",
  },
  {
    tokenId: 4659,
    Tier: "Basic",
  },
  {
    tokenId: 4660,
    Tier: "Basic",
  },
  {
    tokenId: 4661,
    Tier: "Basic",
  },
  {
    tokenId: 4662,
    Tier: "Uncommon",
  },
  {
    tokenId: 4663,
    Tier: "Basic",
  },
  {
    tokenId: 4664,
    Tier: "Basic",
  },
  {
    tokenId: 4665,
    Tier: "Rare",
  },
  {
    tokenId: 4666,
    Tier: "Basic",
  },
  {
    tokenId: 4667,
    Tier: "Uncommon",
  },
  {
    tokenId: 4668,
    Tier: "Basic",
  },
  {
    tokenId: 4669,
    Tier: "Common",
  },
  {
    tokenId: 4670,
    Tier: "Rare",
  },
  {
    tokenId: 4671,
    Tier: "Epic",
  },
  {
    tokenId: 4672,
    Tier: "Epic",
  },
  {
    tokenId: 4673,
    Tier: "Epic",
  },
  {
    tokenId: 4674,
    Tier: "Basic",
  },
  {
    tokenId: 4675,
    Tier: "Uncommon",
  },
  {
    tokenId: 4676,
    Tier: "Basic",
  },
  {
    tokenId: 4677,
    Tier: "Epic",
  },
  {
    tokenId: 4678,
    Tier: "Uncommon",
  },
  {
    tokenId: 4679,
    Tier: "Basic",
  },
  {
    tokenId: 4680,
    Tier: "Basic",
  },
  {
    tokenId: 4681,
    Tier: "Basic",
  },
  {
    tokenId: 4682,
    Tier: "Basic",
  },
  {
    tokenId: 4683,
    Tier: "Common",
  },
  {
    tokenId: 4684,
    Tier: "Uncommon",
  },
  {
    tokenId: 4685,
    Tier: "Mythic",
  },
  {
    tokenId: 4686,
    Tier: "Uncommon",
  },
  {
    tokenId: 4687,
    Tier: "Basic",
  },
  {
    tokenId: 4688,
    Tier: "Basic",
  },
  {
    tokenId: 4689,
    Tier: "Legendary",
  },
  {
    tokenId: 4690,
    Tier: "Uncommon",
  },
  {
    tokenId: 4691,
    Tier: "Uncommon",
  },
  {
    tokenId: 4692,
    Tier: "Basic",
  },
  {
    tokenId: 4693,
    Tier: "Basic",
  },
  {
    tokenId: 4694,
    Tier: "Basic",
  },
  {
    tokenId: 4695,
    Tier: "Exotic",
  },
  {
    tokenId: 4696,
    Tier: "Basic",
  },
  {
    tokenId: 4697,
    Tier: "Common",
  },
  {
    tokenId: 4698,
    Tier: "Mythic",
  },
  {
    tokenId: 4699,
    Tier: "Basic",
  },
  {
    tokenId: 4700,
    Tier: "Basic",
  },
  {
    tokenId: 4701,
    Tier: "Epic",
  },
  {
    tokenId: 4702,
    Tier: "Basic",
  },
  {
    tokenId: 4703,
    Tier: "Basic",
  },
  {
    tokenId: 4704,
    Tier: "Basic",
  },
  {
    tokenId: 4705,
    Tier: "Rare",
  },
  {
    tokenId: 4706,
    Tier: "Legendary",
  },
  {
    tokenId: 4707,
    Tier: "Basic",
  },
  {
    tokenId: 4708,
    Tier: "Common",
  },
  {
    tokenId: 4709,
    Tier: "Rare",
  },
  {
    tokenId: 4710,
    Tier: "Common",
  },
  {
    tokenId: 4711,
    Tier: "Basic",
  },
  {
    tokenId: 4712,
    Tier: "Basic",
  },
  {
    tokenId: 4713,
    Tier: "Mythic",
  },
  {
    tokenId: 4714,
    Tier: "Basic",
  },
  {
    tokenId: 4715,
    Tier: "Basic",
  },
  {
    tokenId: 4716,
    Tier: "Basic",
  },
  {
    tokenId: 4717,
    Tier: "Basic",
  },
  {
    tokenId: 4718,
    Tier: "Common",
  },
  {
    tokenId: 4719,
    Tier: "Rare",
  },
  {
    tokenId: 4720,
    Tier: "Uncommon",
  },
  {
    tokenId: 4721,
    Tier: "Basic",
  },
  {
    tokenId: 4722,
    Tier: "Basic",
  },
  {
    tokenId: 4723,
    Tier: "Legendary",
  },
  {
    tokenId: 4724,
    Tier: "Rare",
  },
  {
    tokenId: 4725,
    Tier: "Rare",
  },
  {
    tokenId: 4726,
    Tier: "Exotic",
  },
  {
    tokenId: 4727,
    Tier: "Basic",
  },
  {
    tokenId: 4728,
    Tier: "Legendary",
  },
  {
    tokenId: 4729,
    Tier: "Basic",
  },
  {
    tokenId: 4730,
    Tier: "Basic",
  },
  {
    tokenId: 4731,
    Tier: "Common",
  },
  {
    tokenId: 4732,
    Tier: "Basic",
  },
  {
    tokenId: 4733,
    Tier: "Basic",
  },
  {
    tokenId: 4734,
    Tier: "Uncommon",
  },
  {
    tokenId: 4735,
    Tier: "Basic",
  },
  {
    tokenId: 4736,
    Tier: "Basic",
  },
  {
    tokenId: 4737,
    Tier: "Basic",
  },
  {
    tokenId: 4738,
    Tier: "Rare",
  },
  {
    tokenId: 4739,
    Tier: "Epic",
  },
  {
    tokenId: 4740,
    Tier: "Basic",
  },
  {
    tokenId: 4741,
    Tier: "Epic",
  },
  {
    tokenId: 4742,
    Tier: "Basic",
  },
  {
    tokenId: 4743,
    Tier: "Epic",
  },
  {
    tokenId: 4744,
    Tier: "Common",
  },
  {
    tokenId: 4745,
    Tier: "Basic",
  },
  {
    tokenId: 4746,
    Tier: "Exotic",
  },
  {
    tokenId: 4747,
    Tier: "Basic",
  },
  {
    tokenId: 4748,
    Tier: "Legendary",
  },
  {
    tokenId: 4749,
    Tier: "Common",
  },
  {
    tokenId: 4750,
    Tier: "Basic",
  },
  {
    tokenId: 4751,
    Tier: "Basic",
  },
  {
    tokenId: 4752,
    Tier: "Basic",
  },
  {
    tokenId: 4753,
    Tier: "Rare",
  },
  {
    tokenId: 4754,
    Tier: "Basic",
  },
  {
    tokenId: 4755,
    Tier: "Common",
  },
  {
    tokenId: 4756,
    Tier: "Epic",
  },
  {
    tokenId: 4757,
    Tier: "Basic",
  },
  {
    tokenId: 4758,
    Tier: "Common",
  },
  {
    tokenId: 4759,
    Tier: "Common",
  },
  {
    tokenId: 4760,
    Tier: "Basic",
  },
  {
    tokenId: 4761,
    Tier: "Legendary",
  },
  {
    tokenId: 4762,
    Tier: "Basic",
  },
  {
    tokenId: 4763,
    Tier: "Basic",
  },
  {
    tokenId: 4764,
    Tier: "Uncommon",
  },
  {
    tokenId: 4765,
    Tier: "Rare",
  },
  {
    tokenId: 4766,
    Tier: "Basic",
  },
  {
    tokenId: 4767,
    Tier: "Basic",
  },
  {
    tokenId: 4768,
    Tier: "Exotic",
  },
  {
    tokenId: 4769,
    Tier: "Basic",
  },
  {
    tokenId: 4770,
    Tier: "Legendary",
  },
  {
    tokenId: 4771,
    Tier: "Common",
  },
  {
    tokenId: 4772,
    Tier: "Basic",
  },
  {
    tokenId: 4773,
    Tier: "Basic",
  },
  {
    tokenId: 4774,
    Tier: "Uncommon",
  },
  {
    tokenId: 4775,
    Tier: "Basic",
  },
  {
    tokenId: 4776,
    Tier: "Legendary",
  },
  {
    tokenId: 4777,
    Tier: "Basic",
  },
  {
    tokenId: 4778,
    Tier: "Mythic",
  },
  {
    tokenId: 4779,
    Tier: "Basic",
  },
  {
    tokenId: 4780,
    Tier: "Rare",
  },
  {
    tokenId: 4781,
    Tier: "Basic",
  },
  {
    tokenId: 4782,
    Tier: "Basic",
  },
  {
    tokenId: 4783,
    Tier: "Uncommon",
  },
  {
    tokenId: 4784,
    Tier: "Common",
  },
  {
    tokenId: 4785,
    Tier: "Rare",
  },
  {
    tokenId: 4786,
    Tier: "Basic",
  },
  {
    tokenId: 4787,
    Tier: "Basic",
  },
  {
    tokenId: 4788,
    Tier: "Basic",
  },
  {
    tokenId: 4789,
    Tier: "Common",
  },
  {
    tokenId: 4790,
    Tier: "Legendary",
  },
  {
    tokenId: 4791,
    Tier: "Rare",
  },
  {
    tokenId: 4792,
    Tier: "Common",
  },
  {
    tokenId: 4793,
    Tier: "Basic",
  },
  {
    tokenId: 4794,
    Tier: "Basic",
  },
  {
    tokenId: 4795,
    Tier: "Basic",
  },
  {
    tokenId: 4796,
    Tier: "Basic",
  },
  {
    tokenId: 4797,
    Tier: "Basic",
  },
  {
    tokenId: 4798,
    Tier: "Uncommon",
  },
  {
    tokenId: 4799,
    Tier: "Rare",
  },
  {
    tokenId: 4800,
    Tier: "Basic",
  },
  {
    tokenId: 4801,
    Tier: "Common",
  },
  {
    tokenId: 4802,
    Tier: "Epic",
  },
  {
    tokenId: 4803,
    Tier: "Uncommon",
  },
  {
    tokenId: 4804,
    Tier: "Basic",
  },
  {
    tokenId: 4805,
    Tier: "Rare",
  },
  {
    tokenId: 4806,
    Tier: "Uncommon",
  },
  {
    tokenId: 4807,
    Tier: "Uncommon",
  },
  {
    tokenId: 4808,
    Tier: "Common",
  },
  {
    tokenId: 4809,
    Tier: "Uncommon",
  },
  {
    tokenId: 4810,
    Tier: "Basic",
  },
  {
    tokenId: 4811,
    Tier: "Common",
  },
  {
    tokenId: 4812,
    Tier: "Common",
  },
  {
    tokenId: 4813,
    Tier: "Basic",
  },
  {
    tokenId: 4814,
    Tier: "Uncommon",
  },
  {
    tokenId: 4815,
    Tier: "Mythic",
  },
  {
    tokenId: 4816,
    Tier: "Basic",
  },
  {
    tokenId: 4817,
    Tier: "Basic",
  },
  {
    tokenId: 4818,
    Tier: "Epic",
  },
  {
    tokenId: 4819,
    Tier: "Basic",
  },
  {
    tokenId: 4820,
    Tier: "Basic",
  },
  {
    tokenId: 4821,
    Tier: "Basic",
  },
  {
    tokenId: 4822,
    Tier: "Basic",
  },
  {
    tokenId: 4823,
    Tier: "Basic",
  },
  {
    tokenId: 4824,
    Tier: "Basic",
  },
  {
    tokenId: 4825,
    Tier: "Legendary",
  },
  {
    tokenId: 4826,
    Tier: "Basic",
  },
  {
    tokenId: 4827,
    Tier: "Basic",
  },
  {
    tokenId: 4828,
    Tier: "Basic",
  },
  {
    tokenId: 4829,
    Tier: "Basic",
  },
  {
    tokenId: 4830,
    Tier: "Basic",
  },
  {
    tokenId: 4831,
    Tier: "Basic",
  },
  {
    tokenId: 4832,
    Tier: "Rare",
  },
  {
    tokenId: 4833,
    Tier: "Basic",
  },
  {
    tokenId: 4834,
    Tier: "Mythic",
  },
  {
    tokenId: 4835,
    Tier: "Basic",
  },
  {
    tokenId: 4836,
    Tier: "Uncommon",
  },
  {
    tokenId: 4837,
    Tier: "Legendary",
  },
  {
    tokenId: 4838,
    Tier: "Rare",
  },
  {
    tokenId: 4839,
    Tier: "Common",
  },
  {
    tokenId: 4840,
    Tier: "Basic",
  },
  {
    tokenId: 4841,
    Tier: "Mythic",
  },
  {
    tokenId: 4842,
    Tier: "Basic",
  },
  {
    tokenId: 4843,
    Tier: "Basic",
  },
  {
    tokenId: 4844,
    Tier: "Basic",
  },
  {
    tokenId: 4845,
    Tier: "Basic",
  },
  {
    tokenId: 4846,
    Tier: "Basic",
  },
  {
    tokenId: 4847,
    Tier: "Basic",
  },
  {
    tokenId: 4848,
    Tier: "Basic",
  },
  {
    tokenId: 4849,
    Tier: "Basic",
  },
  {
    tokenId: 4850,
    Tier: "Basic",
  },
  {
    tokenId: 4851,
    Tier: "Common",
  },
  {
    tokenId: 4852,
    Tier: "Basic",
  },
  {
    tokenId: 4853,
    Tier: "Basic",
  },
  {
    tokenId: 4854,
    Tier: "Mythic",
  },
  {
    tokenId: 4855,
    Tier: "Basic",
  },
  {
    tokenId: 4856,
    Tier: "Common",
  },
  {
    tokenId: 4857,
    Tier: "Basic",
  },
  {
    tokenId: 4858,
    Tier: "Basic",
  },
  {
    tokenId: 4859,
    Tier: "Basic",
  },
  {
    tokenId: 4860,
    Tier: "Basic",
  },
  {
    tokenId: 4861,
    Tier: "Basic",
  },
  {
    tokenId: 4862,
    Tier: "Mythic",
  },
  {
    tokenId: 4863,
    Tier: "Epic",
  },
  {
    tokenId: 4864,
    Tier: "Basic",
  },
  {
    tokenId: 4865,
    Tier: "Basic",
  },
  {
    tokenId: 4866,
    Tier: "Legendary",
  },
  {
    tokenId: 4867,
    Tier: "Rare",
  },
  {
    tokenId: 4868,
    Tier: "Basic",
  },
  {
    tokenId: 4869,
    Tier: "Common",
  },
  {
    tokenId: 4870,
    Tier: "Basic",
  },
  {
    tokenId: 4871,
    Tier: "Basic",
  },
  {
    tokenId: 4872,
    Tier: "Basic",
  },
  {
    tokenId: 4873,
    Tier: "Uncommon",
  },
  {
    tokenId: 4874,
    Tier: "Basic",
  },
  {
    tokenId: 4875,
    Tier: "Common",
  },
  {
    tokenId: 4876,
    Tier: "Basic",
  },
  {
    tokenId: 4877,
    Tier: "Common",
  },
  {
    tokenId: 4878,
    Tier: "Basic",
  },
  {
    tokenId: 4879,
    Tier: "Basic",
  },
  {
    tokenId: 4880,
    Tier: "Basic",
  },
  {
    tokenId: 4881,
    Tier: "Rare",
  },
  {
    tokenId: 4882,
    Tier: "Uncommon",
  },
  {
    tokenId: 4883,
    Tier: "Basic",
  },
  {
    tokenId: 4884,
    Tier: "Basic",
  },
  {
    tokenId: 4885,
    Tier: "Basic",
  },
  {
    tokenId: 4886,
    Tier: "Common",
  },
  {
    tokenId: 4887,
    Tier: "Uncommon",
  },
  {
    tokenId: 4888,
    Tier: "Uncommon",
  },
  {
    tokenId: 4889,
    Tier: "Common",
  },
  {
    tokenId: 4890,
    Tier: "Common",
  },
  {
    tokenId: 4891,
    Tier: "Basic",
  },
  {
    tokenId: 4892,
    Tier: "Epic",
  },
  {
    tokenId: 4893,
    Tier: "Rare",
  },
  {
    tokenId: 4894,
    Tier: "Basic",
  },
  {
    tokenId: 4895,
    Tier: "Basic",
  },
  {
    tokenId: 4896,
    Tier: "Basic",
  },
  {
    tokenId: 4897,
    Tier: "Basic",
  },
  {
    tokenId: 4898,
    Tier: "Basic",
  },
  {
    tokenId: 4899,
    Tier: "Basic",
  },
  {
    tokenId: 4900,
    Tier: "Basic",
  },
  {
    tokenId: 4901,
    Tier: "Basic",
  },
  {
    tokenId: 4902,
    Tier: "Basic",
  },
  {
    tokenId: 4903,
    Tier: "Basic",
  },
  {
    tokenId: 4904,
    Tier: "Basic",
  },
  {
    tokenId: 4905,
    Tier: "Uncommon",
  },
  {
    tokenId: 4906,
    Tier: "Basic",
  },
  {
    tokenId: 4907,
    Tier: "Rare",
  },
  {
    tokenId: 4908,
    Tier: "Basic",
  },
  {
    tokenId: 4909,
    Tier: "Epic",
  },
  {
    tokenId: 4910,
    Tier: "Basic",
  },
  {
    tokenId: 4911,
    Tier: "Exotic",
  },
  {
    tokenId: 4912,
    Tier: "Basic",
  },
  {
    tokenId: 4913,
    Tier: "Uncommon",
  },
  {
    tokenId: 4914,
    Tier: "Basic",
  },
  {
    tokenId: 4915,
    Tier: "Basic",
  },
  {
    tokenId: 4916,
    Tier: "Basic",
  },
  {
    tokenId: 4917,
    Tier: "Basic",
  },
  {
    tokenId: 4918,
    Tier: "Basic",
  },
  {
    tokenId: 4919,
    Tier: "Basic",
  },
  {
    tokenId: 4920,
    Tier: "Uncommon",
  },
  {
    tokenId: 4921,
    Tier: "Uncommon",
  },
  {
    tokenId: 4922,
    Tier: "Mythic",
  },
  {
    tokenId: 4923,
    Tier: "Basic",
  },
  {
    tokenId: 4924,
    Tier: "Basic",
  },
  {
    tokenId: 4925,
    Tier: "Basic",
  },
  {
    tokenId: 4926,
    Tier: "Epic",
  },
  {
    tokenId: 4927,
    Tier: "Basic",
  },
  {
    tokenId: 4928,
    Tier: "Basic",
  },
  {
    tokenId: 4929,
    Tier: "Uncommon",
  },
  {
    tokenId: 4930,
    Tier: "Uncommon",
  },
  {
    tokenId: 4931,
    Tier: "Uncommon",
  },
  {
    tokenId: 4932,
    Tier: "Rare",
  },
  {
    tokenId: 4933,
    Tier: "Basic",
  },
  {
    tokenId: 4934,
    Tier: "Basic",
  },
  {
    tokenId: 4935,
    Tier: "Uncommon",
  },
  {
    tokenId: 4936,
    Tier: "Basic",
  },
  {
    tokenId: 4937,
    Tier: "Uncommon",
  },
  {
    tokenId: 4938,
    Tier: "Basic",
  },
  {
    tokenId: 4939,
    Tier: "Basic",
  },
  {
    tokenId: 4940,
    Tier: "Basic",
  },
  {
    tokenId: 4941,
    Tier: "Basic",
  },
  {
    tokenId: 4942,
    Tier: "Exotic",
  },
  {
    tokenId: 4943,
    Tier: "Uncommon",
  },
  {
    tokenId: 4944,
    Tier: "Rare",
  },
  {
    tokenId: 4945,
    Tier: "Uncommon",
  },
  {
    tokenId: 4946,
    Tier: "Basic",
  },
  {
    tokenId: 4947,
    Tier: "Epic",
  },
  {
    tokenId: 4948,
    Tier: "Basic",
  },
  {
    tokenId: 4949,
    Tier: "Uncommon",
  },
  {
    tokenId: 4950,
    Tier: "Epic",
  },
  {
    tokenId: 4951,
    Tier: "Basic",
  },
  {
    tokenId: 4952,
    Tier: "Basic",
  },
  {
    tokenId: 4953,
    Tier: "Basic",
  },
  {
    tokenId: 4954,
    Tier: "Rare",
  },
  {
    tokenId: 4955,
    Tier: "Common",
  },
  {
    tokenId: 4956,
    Tier: "Basic",
  },
  {
    tokenId: 4957,
    Tier: "Basic",
  },
  {
    tokenId: 4958,
    Tier: "Rare",
  },
  {
    tokenId: 4959,
    Tier: "Rare",
  },
  {
    tokenId: 4960,
    Tier: "Basic",
  },
  {
    tokenId: 4961,
    Tier: "Basic",
  },
  {
    tokenId: 4962,
    Tier: "Rare",
  },
  {
    tokenId: 4963,
    Tier: "Basic",
  },
  {
    tokenId: 4964,
    Tier: "Rare",
  },
  {
    tokenId: 4965,
    Tier: "Basic",
  },
  {
    tokenId: 4966,
    Tier: "Rare",
  },
  {
    tokenId: 4967,
    Tier: "Epic",
  },
  {
    tokenId: 4968,
    Tier: "Basic",
  },
  {
    tokenId: 4969,
    Tier: "Uncommon",
  },
  {
    tokenId: 4970,
    Tier: "Basic",
  },
  {
    tokenId: 4971,
    Tier: "Basic",
  },
  {
    tokenId: 4972,
    Tier: "Epic",
  },
  {
    tokenId: 4973,
    Tier: "Common",
  },
  {
    tokenId: 4974,
    Tier: "Common",
  },
  {
    tokenId: 4975,
    Tier: "Basic",
  },
  {
    tokenId: 4976,
    Tier: "Basic",
  },
  {
    tokenId: 4977,
    Tier: "Basic",
  },
  {
    tokenId: 4978,
    Tier: "Rare",
  },
  {
    tokenId: 4979,
    Tier: "Legendary",
  },
  {
    tokenId: 4980,
    Tier: "Common",
  },
  {
    tokenId: 4981,
    Tier: "Basic",
  },
  {
    tokenId: 4982,
    Tier: "Basic",
  },
  {
    tokenId: 4983,
    Tier: "Common",
  },
  {
    tokenId: 4984,
    Tier: "Mythic",
  },
  {
    tokenId: 4985,
    Tier: "Common",
  },
  {
    tokenId: 4986,
    Tier: "Basic",
  },
  {
    tokenId: 4987,
    Tier: "Basic",
  },
  {
    tokenId: 4988,
    Tier: "Basic",
  },
  {
    tokenId: 4989,
    Tier: "Basic",
  },
  {
    tokenId: 4990,
    Tier: "Basic",
  },
  {
    tokenId: 4991,
    Tier: "Epic",
  },
  {
    tokenId: 4992,
    Tier: "Basic",
  },
  {
    tokenId: 4993,
    Tier: "Basic",
  },
  {
    tokenId: 4994,
    Tier: "Legendary",
  },
  {
    tokenId: 4995,
    Tier: "Basic",
  },
  {
    tokenId: 4996,
    Tier: "Basic",
  },
  {
    tokenId: 4997,
    Tier: "Common",
  },
  {
    tokenId: 4998,
    Tier: "Common",
  },
  {
    tokenId: 4999,
    Tier: "Common",
  },
  {
    tokenId: 5000,
    Tier: "Basic",
  },
  {
    tokenId: 5001,
    Tier: "Uncommon",
  },
  {
    tokenId: 5002,
    Tier: "Exotic",
  },
  {
    tokenId: 5003,
    Tier: "Basic",
  },
  {
    tokenId: 5004,
    Tier: "Rare",
  },
  {
    tokenId: 5005,
    Tier: "Basic",
  },
  {
    tokenId: 5006,
    Tier: "Basic",
  },
  {
    tokenId: 5007,
    Tier: "Common",
  },
  {
    tokenId: 5008,
    Tier: "Basic",
  },
  {
    tokenId: 5009,
    Tier: "Basic",
  },
  {
    tokenId: 5010,
    Tier: "Rare",
  },
  {
    tokenId: 5011,
    Tier: "Uncommon",
  },
  {
    tokenId: 5012,
    Tier: "Common",
  },
  {
    tokenId: 5013,
    Tier: "Basic",
  },
  {
    tokenId: 5014,
    Tier: "Uncommon",
  },
  {
    tokenId: 5015,
    Tier: "Basic",
  },
  {
    tokenId: 5016,
    Tier: "Rare",
  },
  {
    tokenId: 5017,
    Tier: "Legendary",
  },
  {
    tokenId: 5018,
    Tier: "Rare",
  },
  {
    tokenId: 5019,
    Tier: "Common",
  },
  {
    tokenId: 5020,
    Tier: "Basic",
  },
  {
    tokenId: 5021,
    Tier: "Basic",
  },
  {
    tokenId: 5022,
    Tier: "Basic",
  },
  {
    tokenId: 5023,
    Tier: "Uncommon",
  },
  {
    tokenId: 5024,
    Tier: "Common",
  },
  {
    tokenId: 5025,
    Tier: "Uncommon",
  },
  {
    tokenId: 5026,
    Tier: "Common",
  },
  {
    tokenId: 5027,
    Tier: "Common",
  },
  {
    tokenId: 5028,
    Tier: "Basic",
  },
  {
    tokenId: 5029,
    Tier: "Basic",
  },
  {
    tokenId: 5030,
    Tier: "Uncommon",
  },
  {
    tokenId: 5031,
    Tier: "Common",
  },
  {
    tokenId: 5032,
    Tier: "Basic",
  },
  {
    tokenId: 5033,
    Tier: "Epic",
  },
  {
    tokenId: 5034,
    Tier: "Common",
  },
  {
    tokenId: 5035,
    Tier: "Common",
  },
  {
    tokenId: 5036,
    Tier: "Uncommon",
  },
  {
    tokenId: 5037,
    Tier: "Uncommon",
  },
  {
    tokenId: 5038,
    Tier: "Epic",
  },
  {
    tokenId: 5039,
    Tier: "Uncommon",
  },
  {
    tokenId: 5040,
    Tier: "Basic",
  },
  {
    tokenId: 5041,
    Tier: "Exotic",
  },
  {
    tokenId: 5042,
    Tier: "Common",
  },
  {
    tokenId: 5043,
    Tier: "Basic",
  },
  {
    tokenId: 5044,
    Tier: "Basic",
  },
  {
    tokenId: 5045,
    Tier: "Basic",
  },
  {
    tokenId: 5046,
    Tier: "Epic",
  },
  {
    tokenId: 5047,
    Tier: "Basic",
  },
  {
    tokenId: 5048,
    Tier: "Basic",
  },
  {
    tokenId: 5049,
    Tier: "Basic",
  },
  {
    tokenId: 5050,
    Tier: "Epic",
  },
  {
    tokenId: 5051,
    Tier: "Basic",
  },
  {
    tokenId: 5052,
    Tier: "Epic",
  },
  {
    tokenId: 5053,
    Tier: "Basic",
  },
  {
    tokenId: 5054,
    Tier: "Basic",
  },
  {
    tokenId: 5055,
    Tier: "Basic",
  },
  {
    tokenId: 5056,
    Tier: "Basic",
  },
  {
    tokenId: 5057,
    Tier: "Basic",
  },
  {
    tokenId: 5058,
    Tier: "Basic",
  },
  {
    tokenId: 5059,
    Tier: "Uncommon",
  },
  {
    tokenId: 5060,
    Tier: "Common",
  },
  {
    tokenId: 5061,
    Tier: "Common",
  },
  {
    tokenId: 5062,
    Tier: "Basic",
  },
  {
    tokenId: 5063,
    Tier: "Basic",
  },
  {
    tokenId: 5064,
    Tier: "Basic",
  },
  {
    tokenId: 5065,
    Tier: "Basic",
  },
  {
    tokenId: 5066,
    Tier: "Basic",
  },
  {
    tokenId: 5067,
    Tier: "Basic",
  },
  {
    tokenId: 5068,
    Tier: "Uncommon",
  },
  {
    tokenId: 5069,
    Tier: "Basic",
  },
  {
    tokenId: 5070,
    Tier: "Basic",
  },
  {
    tokenId: 5071,
    Tier: "Common",
  },
  {
    tokenId: 5072,
    Tier: "Basic",
  },
  {
    tokenId: 5073,
    Tier: "Basic",
  },
  {
    tokenId: 5074,
    Tier: "Legendary",
  },
  {
    tokenId: 5075,
    Tier: "Mythic",
  },
  {
    tokenId: 5076,
    Tier: "Epic",
  },
  {
    tokenId: 5077,
    Tier: "Basic",
  },
  {
    tokenId: 5078,
    Tier: "Basic",
  },
  {
    tokenId: 5079,
    Tier: "Uncommon",
  },
  {
    tokenId: 5080,
    Tier: "Common",
  },
  {
    tokenId: 5081,
    Tier: "Basic",
  },
  {
    tokenId: 5082,
    Tier: "Basic",
  },
  {
    tokenId: 5083,
    Tier: "Basic",
  },
  {
    tokenId: 5084,
    Tier: "Uncommon",
  },
  {
    tokenId: 5085,
    Tier: "Epic",
  },
  {
    tokenId: 5086,
    Tier: "Basic",
  },
  {
    tokenId: 5087,
    Tier: "Mythic",
  },
  {
    tokenId: 5088,
    Tier: "Basic",
  },
  {
    tokenId: 5089,
    Tier: "Rare",
  },
  {
    tokenId: 5090,
    Tier: "Uncommon",
  },
  {
    tokenId: 5091,
    Tier: "Epic",
  },
  {
    tokenId: 5092,
    Tier: "Basic",
  },
  {
    tokenId: 5093,
    Tier: "Common",
  },
  {
    tokenId: 5094,
    Tier: "Mythic",
  },
  {
    tokenId: 5095,
    Tier: "Basic",
  },
  {
    tokenId: 5096,
    Tier: "Epic",
  },
  {
    tokenId: 5097,
    Tier: "Basic",
  },
  {
    tokenId: 5098,
    Tier: "Basic",
  },
  {
    tokenId: 5099,
    Tier: "Basic",
  },
  {
    tokenId: 5100,
    Tier: "Basic",
  },
  {
    tokenId: 5101,
    Tier: "Basic",
  },
  {
    tokenId: 5102,
    Tier: "Mythic",
  },
  {
    tokenId: 5103,
    Tier: "Common",
  },
  {
    tokenId: 5104,
    Tier: "Basic",
  },
  {
    tokenId: 5105,
    Tier: "Basic",
  },
  {
    tokenId: 5106,
    Tier: "Rare",
  },
  {
    tokenId: 5107,
    Tier: "Uncommon",
  },
  {
    tokenId: 5108,
    Tier: "Basic",
  },
  {
    tokenId: 5109,
    Tier: "Basic",
  },
  {
    tokenId: 5110,
    Tier: "Basic",
  },
  {
    tokenId: 5111,
    Tier: "Basic",
  },
  {
    tokenId: 5112,
    Tier: "Epic",
  },
  {
    tokenId: 5113,
    Tier: "Basic",
  },
  {
    tokenId: 5114,
    Tier: "Uncommon",
  },
  {
    tokenId: 5115,
    Tier: "Basic",
  },
  {
    tokenId: 5116,
    Tier: "Rare",
  },
  {
    tokenId: 5117,
    Tier: "Epic",
  },
  {
    tokenId: 5118,
    Tier: "Basic",
  },
  {
    tokenId: 5119,
    Tier: "Uncommon",
  },
  {
    tokenId: 5120,
    Tier: "Common",
  },
  {
    tokenId: 5121,
    Tier: "Basic",
  },
  {
    tokenId: 5122,
    Tier: "Basic",
  },
  {
    tokenId: 5123,
    Tier: "Basic",
  },
  {
    tokenId: 5124,
    Tier: "Basic",
  },
  {
    tokenId: 5125,
    Tier: "Basic",
  },
  {
    tokenId: 5126,
    Tier: "Basic",
  },
  {
    tokenId: 5127,
    Tier: "Uncommon",
  },
  {
    tokenId: 5128,
    Tier: "Basic",
  },
  {
    tokenId: 5129,
    Tier: "Rare",
  },
  {
    tokenId: 5130,
    Tier: "Basic",
  },
  {
    tokenId: 5131,
    Tier: "Common",
  },
  {
    tokenId: 5132,
    Tier: "Uncommon",
  },
  {
    tokenId: 5133,
    Tier: "Basic",
  },
  {
    tokenId: 5134,
    Tier: "Epic",
  },
  {
    tokenId: 5135,
    Tier: "Basic",
  },
  {
    tokenId: 5136,
    Tier: "Uncommon",
  },
  {
    tokenId: 5137,
    Tier: "Basic",
  },
  {
    tokenId: 5138,
    Tier: "Epic",
  },
  {
    tokenId: 5139,
    Tier: "Basic",
  },
  {
    tokenId: 5140,
    Tier: "Basic",
  },
  {
    tokenId: 5141,
    Tier: "Common",
  },
  {
    tokenId: 5142,
    Tier: "Basic",
  },
  {
    tokenId: 5143,
    Tier: "Basic",
  },
  {
    tokenId: 5144,
    Tier: "Common",
  },
  {
    tokenId: 5145,
    Tier: "Basic",
  },
  {
    tokenId: 5146,
    Tier: "Basic",
  },
  {
    tokenId: 5147,
    Tier: "Basic",
  },
  {
    tokenId: 5148,
    Tier: "Common",
  },
  {
    tokenId: 5149,
    Tier: "Epic",
  },
  {
    tokenId: 5150,
    Tier: "Basic",
  },
  {
    tokenId: 5151,
    Tier: "Epic",
  },
  {
    tokenId: 5152,
    Tier: "Uncommon",
  },
  {
    tokenId: 5153,
    Tier: "Legendary",
  },
  {
    tokenId: 5154,
    Tier: "Uncommon",
  },
  {
    tokenId: 5155,
    Tier: "Basic",
  },
  {
    tokenId: 5156,
    Tier: "Common",
  },
  {
    tokenId: 5157,
    Tier: "Common",
  },
  {
    tokenId: 5158,
    Tier: "Epic",
  },
  {
    tokenId: 5159,
    Tier: "Basic",
  },
  {
    tokenId: 5160,
    Tier: "Mythic",
  },
  {
    tokenId: 5161,
    Tier: "Epic",
  },
  {
    tokenId: 5162,
    Tier: "Uncommon",
  },
  {
    tokenId: 5163,
    Tier: "Exotic",
  },
  {
    tokenId: 5164,
    Tier: "Basic",
  },
  {
    tokenId: 5165,
    Tier: "Epic",
  },
  {
    tokenId: 5166,
    Tier: "Basic",
  },
  {
    tokenId: 5167,
    Tier: "Basic",
  },
  {
    tokenId: 5168,
    Tier: "Basic",
  },
  {
    tokenId: 5169,
    Tier: "Common",
  },
  {
    tokenId: 5170,
    Tier: "Epic",
  },
  {
    tokenId: 5171,
    Tier: "Basic",
  },
  {
    tokenId: 5172,
    Tier: "Legendary",
  },
  {
    tokenId: 5173,
    Tier: "Basic",
  },
  {
    tokenId: 5174,
    Tier: "Basic",
  },
  {
    tokenId: 5175,
    Tier: "Basic",
  },
  {
    tokenId: 5176,
    Tier: "Basic",
  },
  {
    tokenId: 5177,
    Tier: "Basic",
  },
  {
    tokenId: 5178,
    Tier: "Legendary",
  },
  {
    tokenId: 5179,
    Tier: "Uncommon",
  },
  {
    tokenId: 5180,
    Tier: "Basic",
  },
  {
    tokenId: 5181,
    Tier: "Basic",
  },
  {
    tokenId: 5182,
    Tier: "Basic",
  },
  {
    tokenId: 5183,
    Tier: "Epic",
  },
  {
    tokenId: 5184,
    Tier: "Basic",
  },
  {
    tokenId: 5185,
    Tier: "Rare",
  },
  {
    tokenId: 5186,
    Tier: "Basic",
  },
  {
    tokenId: 5187,
    Tier: "Uncommon",
  },
  {
    tokenId: 5188,
    Tier: "Basic",
  },
  {
    tokenId: 5189,
    Tier: "Basic",
  },
  {
    tokenId: 5190,
    Tier: "Basic",
  },
  {
    tokenId: 5191,
    Tier: "Basic",
  },
  {
    tokenId: 5192,
    Tier: "Basic",
  },
  {
    tokenId: 5193,
    Tier: "Basic",
  },
  {
    tokenId: 5194,
    Tier: "Basic",
  },
  {
    tokenId: 5195,
    Tier: "Basic",
  },
  {
    tokenId: 5196,
    Tier: "Uncommon",
  },
  {
    tokenId: 5197,
    Tier: "Basic",
  },
  {
    tokenId: 5198,
    Tier: "Basic",
  },
  {
    tokenId: 5199,
    Tier: "Basic",
  },
  {
    tokenId: 5200,
    Tier: "Basic",
  },
  {
    tokenId: 5201,
    Tier: "Basic",
  },
  {
    tokenId: 5202,
    Tier: "Mythic",
  },
  {
    tokenId: 5203,
    Tier: "Common",
  },
  {
    tokenId: 5204,
    Tier: "Common",
  },
  {
    tokenId: 5205,
    Tier: "Common",
  },
  {
    tokenId: 5206,
    Tier: "Uncommon",
  },
  {
    tokenId: 5207,
    Tier: "Basic",
  },
  {
    tokenId: 5208,
    Tier: "Basic",
  },
  {
    tokenId: 5209,
    Tier: "Uncommon",
  },
  {
    tokenId: 5210,
    Tier: "Epic",
  },
  {
    tokenId: 5211,
    Tier: "Uncommon",
  },
  {
    tokenId: 5212,
    Tier: "Uncommon",
  },
  {
    tokenId: 5213,
    Tier: "Uncommon",
  },
  {
    tokenId: 5214,
    Tier: "Basic",
  },
  {
    tokenId: 5215,
    Tier: "Rare",
  },
  {
    tokenId: 5216,
    Tier: "Basic",
  },
  {
    tokenId: 5217,
    Tier: "Rare",
  },
  {
    tokenId: 5218,
    Tier: "Basic",
  },
  {
    tokenId: 5219,
    Tier: "Epic",
  },
  {
    tokenId: 5220,
    Tier: "Uncommon",
  },
  {
    tokenId: 5221,
    Tier: "Mythic",
  },
  {
    tokenId: 5222,
    Tier: "Rare",
  },
  {
    tokenId: 5223,
    Tier: "Basic",
  },
  {
    tokenId: 5224,
    Tier: "Rare",
  },
  {
    tokenId: 5225,
    Tier: "Uncommon",
  },
  {
    tokenId: 5226,
    Tier: "Basic",
  },
  {
    tokenId: 5227,
    Tier: "Common",
  },
  {
    tokenId: 5228,
    Tier: "Basic",
  },
  {
    tokenId: 5229,
    Tier: "Basic",
  },
  {
    tokenId: 5230,
    Tier: "Common",
  },
  {
    tokenId: 5231,
    Tier: "Basic",
  },
  {
    tokenId: 5232,
    Tier: "Legendary",
  },
  {
    tokenId: 5233,
    Tier: "Common",
  },
  {
    tokenId: 5234,
    Tier: "Basic",
  },
  {
    tokenId: 5235,
    Tier: "Uncommon",
  },
  {
    tokenId: 5236,
    Tier: "Uncommon",
  },
  {
    tokenId: 5237,
    Tier: "Uncommon",
  },
  {
    tokenId: 5238,
    Tier: "Common",
  },
  {
    tokenId: 5239,
    Tier: "Uncommon",
  },
  {
    tokenId: 5240,
    Tier: "Basic",
  },
  {
    tokenId: 5241,
    Tier: "Basic",
  },
  {
    tokenId: 5242,
    Tier: "Common",
  },
  {
    tokenId: 5243,
    Tier: "Uncommon",
  },
  {
    tokenId: 5244,
    Tier: "Exotic",
  },
  {
    tokenId: 5245,
    Tier: "Mythic",
  },
  {
    tokenId: 5246,
    Tier: "Rare",
  },
  {
    tokenId: 5247,
    Tier: "Basic",
  },
  {
    tokenId: 5248,
    Tier: "Basic",
  },
  {
    tokenId: 5249,
    Tier: "Basic",
  },
  {
    tokenId: 5250,
    Tier: "Basic",
  },
  {
    tokenId: 5251,
    Tier: "Basic",
  },
  {
    tokenId: 5252,
    Tier: "Basic",
  },
  {
    tokenId: 5253,
    Tier: "Basic",
  },
  {
    tokenId: 5254,
    Tier: "Basic",
  },
  {
    tokenId: 5255,
    Tier: "Basic",
  },
  {
    tokenId: 5256,
    Tier: "Epic",
  },
  {
    tokenId: 5257,
    Tier: "Basic",
  },
  {
    tokenId: 5258,
    Tier: "Basic",
  },
  {
    tokenId: 5259,
    Tier: "Basic",
  },
  {
    tokenId: 5260,
    Tier: "Basic",
  },
  {
    tokenId: 5261,
    Tier: "Rare",
  },
  {
    tokenId: 5262,
    Tier: "Basic",
  },
  {
    tokenId: 5263,
    Tier: "Basic",
  },
  {
    tokenId: 5264,
    Tier: "Basic",
  },
  {
    tokenId: 5265,
    Tier: "Basic",
  },
  {
    tokenId: 5266,
    Tier: "Legendary",
  },
  {
    tokenId: 5267,
    Tier: "Common",
  },
  {
    tokenId: 5268,
    Tier: "Basic",
  },
  {
    tokenId: 5269,
    Tier: "Epic",
  },
  {
    tokenId: 5270,
    Tier: "Rare",
  },
  {
    tokenId: 5271,
    Tier: "Basic",
  },
  {
    tokenId: 5272,
    Tier: "Basic",
  },
  {
    tokenId: 5273,
    Tier: "Common",
  },
  {
    tokenId: 5274,
    Tier: "Epic",
  },
  {
    tokenId: 5275,
    Tier: "Basic",
  },
  {
    tokenId: 5276,
    Tier: "Common",
  },
  {
    tokenId: 5277,
    Tier: "Basic",
  },
  {
    tokenId: 5278,
    Tier: "Uncommon",
  },
  {
    tokenId: 5279,
    Tier: "Epic",
  },
  {
    tokenId: 5280,
    Tier: "Basic",
  },
  {
    tokenId: 5281,
    Tier: "Basic",
  },
  {
    tokenId: 5282,
    Tier: "Uncommon",
  },
  {
    tokenId: 5283,
    Tier: "Basic",
  },
  {
    tokenId: 5284,
    Tier: "Rare",
  },
  {
    tokenId: 5285,
    Tier: "Basic",
  },
  {
    tokenId: 5286,
    Tier: "Common",
  },
  {
    tokenId: 5287,
    Tier: "Basic",
  },
  {
    tokenId: 5288,
    Tier: "Basic",
  },
  {
    tokenId: 5289,
    Tier: "Basic",
  },
  {
    tokenId: 5290,
    Tier: "Basic",
  },
  {
    tokenId: 5291,
    Tier: "Rare",
  },
  {
    tokenId: 5292,
    Tier: "Exotic",
  },
  {
    tokenId: 5293,
    Tier: "Basic",
  },
  {
    tokenId: 5294,
    Tier: "Basic",
  },
  {
    tokenId: 5295,
    Tier: "Basic",
  },
  {
    tokenId: 5296,
    Tier: "Uncommon",
  },
  {
    tokenId: 5297,
    Tier: "Mythic",
  },
  {
    tokenId: 5298,
    Tier: "Basic",
  },
  {
    tokenId: 5299,
    Tier: "Basic",
  },
  {
    tokenId: 5300,
    Tier: "Basic",
  },
  {
    tokenId: 5301,
    Tier: "Basic",
  },
  {
    tokenId: 5302,
    Tier: "Basic",
  },
  {
    tokenId: 5303,
    Tier: "Basic",
  },
  {
    tokenId: 5304,
    Tier: "Basic",
  },
  {
    tokenId: 5305,
    Tier: "Uncommon",
  },
  {
    tokenId: 5306,
    Tier: "Basic",
  },
  {
    tokenId: 5307,
    Tier: "Epic",
  },
  {
    tokenId: 5308,
    Tier: "Basic",
  },
  {
    tokenId: 5309,
    Tier: "Basic",
  },
  {
    tokenId: 5310,
    Tier: "Basic",
  },
  {
    tokenId: 5311,
    Tier: "Epic",
  },
  {
    tokenId: 5312,
    Tier: "Common",
  },
  {
    tokenId: 5313,
    Tier: "Basic",
  },
  {
    tokenId: 5314,
    Tier: "Basic",
  },
  {
    tokenId: 5315,
    Tier: "Basic",
  },
  {
    tokenId: 5316,
    Tier: "Basic",
  },
  {
    tokenId: 5317,
    Tier: "Uncommon",
  },
  {
    tokenId: 5318,
    Tier: "Legendary",
  },
  {
    tokenId: 5319,
    Tier: "Basic",
  },
  {
    tokenId: 5320,
    Tier: "Basic",
  },
  {
    tokenId: 5321,
    Tier: "Basic",
  },
  {
    tokenId: 5322,
    Tier: "Common",
  },
  {
    tokenId: 5323,
    Tier: "Basic",
  },
  {
    tokenId: 5324,
    Tier: "Basic",
  },
  {
    tokenId: 5325,
    Tier: "Epic",
  },
  {
    tokenId: 5326,
    Tier: "Basic",
  },
  {
    tokenId: 5327,
    Tier: "Common",
  },
  {
    tokenId: 5328,
    Tier: "Uncommon",
  },
  {
    tokenId: 5329,
    Tier: "Common",
  },
  {
    tokenId: 5330,
    Tier: "Uncommon",
  },
  {
    tokenId: 5331,
    Tier: "Legendary",
  },
  {
    tokenId: 5332,
    Tier: "Basic",
  },
  {
    tokenId: 5333,
    Tier: "Basic",
  },
  {
    tokenId: 5334,
    Tier: "Epic",
  },
  {
    tokenId: 5335,
    Tier: "Common",
  },
  {
    tokenId: 5336,
    Tier: "Basic",
  },
  {
    tokenId: 5337,
    Tier: "Basic",
  },
  {
    tokenId: 5338,
    Tier: "Basic",
  },
  {
    tokenId: 5339,
    Tier: "Uncommon",
  },
  {
    tokenId: 5340,
    Tier: "Legendary",
  },
  {
    tokenId: 5341,
    Tier: "Basic",
  },
  {
    tokenId: 5342,
    Tier: "Basic",
  },
  {
    tokenId: 5343,
    Tier: "Basic",
  },
  {
    tokenId: 5344,
    Tier: "Epic",
  },
  {
    tokenId: 5345,
    Tier: "Basic",
  },
  {
    tokenId: 5346,
    Tier: "Rare",
  },
  {
    tokenId: 5347,
    Tier: "Common",
  },
  {
    tokenId: 5348,
    Tier: "Exotic",
  },
  {
    tokenId: 5349,
    Tier: "Uncommon",
  },
  {
    tokenId: 5350,
    Tier: "Uncommon",
  },
  {
    tokenId: 5351,
    Tier: "Basic",
  },
  {
    tokenId: 5352,
    Tier: "Uncommon",
  },
  {
    tokenId: 5353,
    Tier: "Exotic",
  },
  {
    tokenId: 5354,
    Tier: "Basic",
  },
  {
    tokenId: 5355,
    Tier: "Basic",
  },
  {
    tokenId: 5356,
    Tier: "Basic",
  },
  {
    tokenId: 5357,
    Tier: "Basic",
  },
  {
    tokenId: 5358,
    Tier: "Basic",
  },
  {
    tokenId: 5359,
    Tier: "Basic",
  },
  {
    tokenId: 5360,
    Tier: "Rare",
  },
  {
    tokenId: 5361,
    Tier: "Common",
  },
  {
    tokenId: 5362,
    Tier: "Basic",
  },
  {
    tokenId: 5363,
    Tier: "Basic",
  },
  {
    tokenId: 5364,
    Tier: "Basic",
  },
  {
    tokenId: 5365,
    Tier: "Basic",
  },
  {
    tokenId: 5366,
    Tier: "Legendary",
  },
  {
    tokenId: 5367,
    Tier: "Basic",
  },
  {
    tokenId: 5368,
    Tier: "Rare",
  },
  {
    tokenId: 5369,
    Tier: "Basic",
  },
  {
    tokenId: 5370,
    Tier: "Basic",
  },
  {
    tokenId: 5371,
    Tier: "Rare",
  },
  {
    tokenId: 5372,
    Tier: "Common",
  },
  {
    tokenId: 5373,
    Tier: "Basic",
  },
  {
    tokenId: 5374,
    Tier: "Basic",
  },
  {
    tokenId: 5375,
    Tier: "Common",
  },
  {
    tokenId: 5376,
    Tier: "Basic",
  },
  {
    tokenId: 5377,
    Tier: "Rare",
  },
  {
    tokenId: 5378,
    Tier: "Common",
  },
  {
    tokenId: 5379,
    Tier: "Basic",
  },
  {
    tokenId: 5380,
    Tier: "Common",
  },
  {
    tokenId: 5381,
    Tier: "Basic",
  },
  {
    tokenId: 5382,
    Tier: "Basic",
  },
  {
    tokenId: 5383,
    Tier: "Common",
  },
  {
    tokenId: 5384,
    Tier: "Rare",
  },
  {
    tokenId: 5385,
    Tier: "Mythic",
  },
  {
    tokenId: 5386,
    Tier: "Basic",
  },
  {
    tokenId: 5387,
    Tier: "Basic",
  },
  {
    tokenId: 5388,
    Tier: "Basic",
  },
  {
    tokenId: 5389,
    Tier: "Basic",
  },
  {
    tokenId: 5390,
    Tier: "Rare",
  },
  {
    tokenId: 5391,
    Tier: "Basic",
  },
  {
    tokenId: 5392,
    Tier: "Legendary",
  },
  {
    tokenId: 5393,
    Tier: "Epic",
  },
  {
    tokenId: 5394,
    Tier: "Basic",
  },
  {
    tokenId: 5395,
    Tier: "Rare",
  },
  {
    tokenId: 5396,
    Tier: "Basic",
  },
  {
    tokenId: 5397,
    Tier: "Basic",
  },
  {
    tokenId: 5398,
    Tier: "Uncommon",
  },
  {
    tokenId: 5399,
    Tier: "Basic",
  },
  {
    tokenId: 5400,
    Tier: "Basic",
  },
  {
    tokenId: 5401,
    Tier: "Uncommon",
  },
  {
    tokenId: 5402,
    Tier: "Epic",
  },
  {
    tokenId: 5403,
    Tier: "Basic",
  },
  {
    tokenId: 5404,
    Tier: "Basic",
  },
  {
    tokenId: 5405,
    Tier: "Common",
  },
  {
    tokenId: 5406,
    Tier: "Rare",
  },
  {
    tokenId: 5407,
    Tier: "Uncommon",
  },
  {
    tokenId: 5408,
    Tier: "Exotic",
  },
  {
    tokenId: 5409,
    Tier: "Basic",
  },
  {
    tokenId: 5410,
    Tier: "Rare",
  },
  {
    tokenId: 5411,
    Tier: "Basic",
  },
  {
    tokenId: 5412,
    Tier: "Basic",
  },
  {
    tokenId: 5413,
    Tier: "Mythic",
  },
  {
    tokenId: 5414,
    Tier: "Basic",
  },
  {
    tokenId: 5415,
    Tier: "Basic",
  },
  {
    tokenId: 5416,
    Tier: "Basic",
  },
  {
    tokenId: 5417,
    Tier: "Basic",
  },
  {
    tokenId: 5418,
    Tier: "Basic",
  },
  {
    tokenId: 5419,
    Tier: "Epic",
  },
  {
    tokenId: 5420,
    Tier: "Epic",
  },
  {
    tokenId: 5421,
    Tier: "Uncommon",
  },
  {
    tokenId: 5422,
    Tier: "Basic",
  },
  {
    tokenId: 5423,
    Tier: "Basic",
  },
  {
    tokenId: 5424,
    Tier: "Common",
  },
  {
    tokenId: 5425,
    Tier: "Rare",
  },
  {
    tokenId: 5426,
    Tier: "Common",
  },
  {
    tokenId: 5427,
    Tier: "Rare",
  },
  {
    tokenId: 5428,
    Tier: "Common",
  },
  {
    tokenId: 5429,
    Tier: "Basic",
  },
  {
    tokenId: 5430,
    Tier: "Basic",
  },
  {
    tokenId: 5431,
    Tier: "Basic",
  },
  {
    tokenId: 5432,
    Tier: "Basic",
  },
  {
    tokenId: 5433,
    Tier: "Basic",
  },
  {
    tokenId: 5434,
    Tier: "Uncommon",
  },
  {
    tokenId: 5435,
    Tier: "Epic",
  },
  {
    tokenId: 5436,
    Tier: "Basic",
  },
  {
    tokenId: 5437,
    Tier: "Uncommon",
  },
  {
    tokenId: 5438,
    Tier: "Basic",
  },
  {
    tokenId: 5439,
    Tier: "Basic",
  },
  {
    tokenId: 5440,
    Tier: "Common",
  },
  {
    tokenId: 5441,
    Tier: "Legendary",
  },
  {
    tokenId: 5442,
    Tier: "Rare",
  },
  {
    tokenId: 5443,
    Tier: "Common",
  },
  {
    tokenId: 5444,
    Tier: "Uncommon",
  },
  {
    tokenId: 5445,
    Tier: "Uncommon",
  },
  {
    tokenId: 5446,
    Tier: "Basic",
  },
  {
    tokenId: 5447,
    Tier: "Uncommon",
  },
  {
    tokenId: 5448,
    Tier: "Basic",
  },
  {
    tokenId: 5449,
    Tier: "Epic",
  },
  {
    tokenId: 5450,
    Tier: "Basic",
  },
  {
    tokenId: 5451,
    Tier: "Basic",
  },
  {
    tokenId: 5452,
    Tier: "Epic",
  },
  {
    tokenId: 5453,
    Tier: "Common",
  },
  {
    tokenId: 5454,
    Tier: "Basic",
  },
  {
    tokenId: 5455,
    Tier: "Epic",
  },
  {
    tokenId: 5456,
    Tier: "Basic",
  },
  {
    tokenId: 5457,
    Tier: "Uncommon",
  },
  {
    tokenId: 5458,
    Tier: "Uncommon",
  },
  {
    tokenId: 5459,
    Tier: "Basic",
  },
  {
    tokenId: 5460,
    Tier: "Basic",
  },
  {
    tokenId: 5461,
    Tier: "Common",
  },
  {
    tokenId: 5462,
    Tier: "Rare",
  },
  {
    tokenId: 5463,
    Tier: "Basic",
  },
  {
    tokenId: 5464,
    Tier: "Basic",
  },
  {
    tokenId: 5465,
    Tier: "Legendary",
  },
  {
    tokenId: 5466,
    Tier: "Basic",
  },
  {
    tokenId: 5467,
    Tier: "Exotic",
  },
  {
    tokenId: 5468,
    Tier: "Basic",
  },
  {
    tokenId: 5469,
    Tier: "Basic",
  },
  {
    tokenId: 5470,
    Tier: "Common",
  },
  {
    tokenId: 5471,
    Tier: "Basic",
  },
  {
    tokenId: 5472,
    Tier: "Basic",
  },
  {
    tokenId: 5473,
    Tier: "Basic",
  },
  {
    tokenId: 5474,
    Tier: "Uncommon",
  },
  {
    tokenId: 5475,
    Tier: "Basic",
  },
  {
    tokenId: 5476,
    Tier: "Common",
  },
  {
    tokenId: 5477,
    Tier: "Basic",
  },
  {
    tokenId: 5478,
    Tier: "Basic",
  },
  {
    tokenId: 5479,
    Tier: "Common",
  },
  {
    tokenId: 5480,
    Tier: "Basic",
  },
  {
    tokenId: 5481,
    Tier: "Basic",
  },
  {
    tokenId: 5482,
    Tier: "Basic",
  },
  {
    tokenId: 5483,
    Tier: "Mythic",
  },
  {
    tokenId: 5484,
    Tier: "Epic",
  },
  {
    tokenId: 5485,
    Tier: "Basic",
  },
  {
    tokenId: 5486,
    Tier: "Uncommon",
  },
  {
    tokenId: 5487,
    Tier: "Common",
  },
  {
    tokenId: 5488,
    Tier: "Basic",
  },
  {
    tokenId: 5489,
    Tier: "Basic",
  },
  {
    tokenId: 5490,
    Tier: "Common",
  },
  {
    tokenId: 5491,
    Tier: "Uncommon",
  },
  {
    tokenId: 5492,
    Tier: "Basic",
  },
  {
    tokenId: 5493,
    Tier: "Basic",
  },
  {
    tokenId: 5494,
    Tier: "Basic",
  },
  {
    tokenId: 5495,
    Tier: "Basic",
  },
  {
    tokenId: 5496,
    Tier: "Common",
  },
  {
    tokenId: 5497,
    Tier: "Basic",
  },
  {
    tokenId: 5498,
    Tier: "Uncommon",
  },
  {
    tokenId: 5499,
    Tier: "Common",
  },
  {
    tokenId: 5500,
    Tier: "Common",
  },
  {
    tokenId: 5501,
    Tier: "Common",
  },
  {
    tokenId: 5502,
    Tier: "Basic",
  },
  {
    tokenId: 5503,
    Tier: "Basic",
  },
  {
    tokenId: 5504,
    Tier: "Common",
  },
  {
    tokenId: 5505,
    Tier: "Epic",
  },
  {
    tokenId: 5506,
    Tier: "Uncommon",
  },
  {
    tokenId: 5507,
    Tier: "Rare",
  },
  {
    tokenId: 5508,
    Tier: "Basic",
  },
  {
    tokenId: 5509,
    Tier: "Uncommon",
  },
  {
    tokenId: 5510,
    Tier: "Uncommon",
  },
  {
    tokenId: 5511,
    Tier: "Basic",
  },
  {
    tokenId: 5512,
    Tier: "Basic",
  },
  {
    tokenId: 5513,
    Tier: "Common",
  },
  {
    tokenId: 5514,
    Tier: "Basic",
  },
  {
    tokenId: 5515,
    Tier: "Basic",
  },
  {
    tokenId: 5516,
    Tier: "Basic",
  },
  {
    tokenId: 5517,
    Tier: "Legendary",
  },
  {
    tokenId: 5518,
    Tier: "Basic",
  },
  {
    tokenId: 5519,
    Tier: "Basic",
  },
  {
    tokenId: 5520,
    Tier: "Basic",
  },
  {
    tokenId: 5521,
    Tier: "Basic",
  },
  {
    tokenId: 5522,
    Tier: "Basic",
  },
  {
    tokenId: 5523,
    Tier: "Basic",
  },
  {
    tokenId: 5524,
    Tier: "Legendary",
  },
  {
    tokenId: 5525,
    Tier: "Legendary",
  },
  {
    tokenId: 5526,
    Tier: "Basic",
  },
  {
    tokenId: 5527,
    Tier: "Basic",
  },
  {
    tokenId: 5528,
    Tier: "Basic",
  },
  {
    tokenId: 5529,
    Tier: "Rare",
  },
  {
    tokenId: 5530,
    Tier: "Common",
  },
  {
    tokenId: 5531,
    Tier: "Rare",
  },
  {
    tokenId: 5532,
    Tier: "Uncommon",
  },
  {
    tokenId: 5533,
    Tier: "Basic",
  },
  {
    tokenId: 5534,
    Tier: "Uncommon",
  },
  {
    tokenId: 5535,
    Tier: "Basic",
  },
  {
    tokenId: 5536,
    Tier: "Mythic",
  },
  {
    tokenId: 5537,
    Tier: "Basic",
  },
  {
    tokenId: 5538,
    Tier: "Basic",
  },
  {
    tokenId: 5539,
    Tier: "Epic",
  },
  {
    tokenId: 5540,
    Tier: "Uncommon",
  },
  {
    tokenId: 5541,
    Tier: "Epic",
  },
  {
    tokenId: 5542,
    Tier: "Basic",
  },
  {
    tokenId: 5543,
    Tier: "Basic",
  },
  {
    tokenId: 5544,
    Tier: "Basic",
  },
  {
    tokenId: 5545,
    Tier: "Common",
  },
  {
    tokenId: 5546,
    Tier: "Common",
  },
  {
    tokenId: 5547,
    Tier: "Basic",
  },
  {
    tokenId: 5548,
    Tier: "Basic",
  },
  {
    tokenId: 5549,
    Tier: "Common",
  },
  {
    tokenId: 5550,
    Tier: "Basic",
  },
  {
    tokenId: 5551,
    Tier: "Basic",
  },
  {
    tokenId: 5552,
    Tier: "Mythic",
  },
  {
    tokenId: 5553,
    Tier: "Uncommon",
  },
  {
    tokenId: 5554,
    Tier: "Common",
  },
  {
    tokenId: 5555,
    Tier: "Basic",
  },
  {
    tokenId: 5556,
    Tier: "Legendary",
  },
  {
    tokenId: 5557,
    Tier: "Basic",
  },
  {
    tokenId: 5558,
    Tier: "Basic",
  },
  {
    tokenId: 5559,
    Tier: "Basic",
  },
  {
    tokenId: 5560,
    Tier: "Uncommon",
  },
  {
    tokenId: 5561,
    Tier: "Epic",
  },
  {
    tokenId: 5562,
    Tier: "Legendary",
  },
  {
    tokenId: 5563,
    Tier: "Basic",
  },
  {
    tokenId: 5564,
    Tier: "Basic",
  },
  {
    tokenId: 5565,
    Tier: "Epic",
  },
  {
    tokenId: 5566,
    Tier: "Uncommon",
  },
  {
    tokenId: 5567,
    Tier: "Basic",
  },
  {
    tokenId: 5568,
    Tier: "Basic",
  },
  {
    tokenId: 5569,
    Tier: "Basic",
  },
  {
    tokenId: 5570,
    Tier: "Basic",
  },
  {
    tokenId: 5571,
    Tier: "Basic",
  },
  {
    tokenId: 5572,
    Tier: "Epic",
  },
  {
    tokenId: 5573,
    Tier: "Basic",
  },
  {
    tokenId: 5574,
    Tier: "Basic",
  },
  {
    tokenId: 5575,
    Tier: "Basic",
  },
  {
    tokenId: 5576,
    Tier: "Basic",
  },
  {
    tokenId: 5577,
    Tier: "Basic",
  },
  {
    tokenId: 5578,
    Tier: "Uncommon",
  },
  {
    tokenId: 5579,
    Tier: "Rare",
  },
  {
    tokenId: 5580,
    Tier: "Basic",
  },
  {
    tokenId: 5581,
    Tier: "Basic",
  },
  {
    tokenId: 5582,
    Tier: "Basic",
  },
  {
    tokenId: 5583,
    Tier: "Uncommon",
  },
  {
    tokenId: 5584,
    Tier: "Basic",
  },
  {
    tokenId: 5585,
    Tier: "Legendary",
  },
  {
    tokenId: 5586,
    Tier: "Basic",
  },
  {
    tokenId: 5587,
    Tier: "Basic",
  },
  {
    tokenId: 5588,
    Tier: "Basic",
  },
  {
    tokenId: 5589,
    Tier: "Basic",
  },
  {
    tokenId: 5590,
    Tier: "Basic",
  },
  {
    tokenId: 5591,
    Tier: "Basic",
  },
  {
    tokenId: 5592,
    Tier: "Uncommon",
  },
  {
    tokenId: 5593,
    Tier: "Rare",
  },
  {
    tokenId: 5594,
    Tier: "Basic",
  },
  {
    tokenId: 5595,
    Tier: "Rare",
  },
  {
    tokenId: 5596,
    Tier: "Common",
  },
  {
    tokenId: 5597,
    Tier: "Basic",
  },
  {
    tokenId: 5598,
    Tier: "Basic",
  },
  {
    tokenId: 5599,
    Tier: "Mythic",
  },
  {
    tokenId: 5600,
    Tier: "Rare",
  },
  {
    tokenId: 5601,
    Tier: "Common",
  },
  {
    tokenId: 5602,
    Tier: "Rare",
  },
  {
    tokenId: 5603,
    Tier: "Uncommon",
  },
  {
    tokenId: 5604,
    Tier: "Basic",
  },
  {
    tokenId: 5605,
    Tier: "Basic",
  },
  {
    tokenId: 5606,
    Tier: "Epic",
  },
  {
    tokenId: 5607,
    Tier: "Uncommon",
  },
  {
    tokenId: 5608,
    Tier: "Basic",
  },
  {
    tokenId: 5609,
    Tier: "Basic",
  },
  {
    tokenId: 5610,
    Tier: "Common",
  },
  {
    tokenId: 5611,
    Tier: "Legendary",
  },
  {
    tokenId: 5612,
    Tier: "Basic",
  },
  {
    tokenId: 5613,
    Tier: "Common",
  },
  {
    tokenId: 5614,
    Tier: "Legendary",
  },
  {
    tokenId: 5615,
    Tier: "Basic",
  },
  {
    tokenId: 5616,
    Tier: "Basic",
  },
  {
    tokenId: 5617,
    Tier: "Rare",
  },
  {
    tokenId: 5618,
    Tier: "Basic",
  },
  {
    tokenId: 5619,
    Tier: "Uncommon",
  },
  {
    tokenId: 5620,
    Tier: "Basic",
  },
  {
    tokenId: 5621,
    Tier: "Basic",
  },
  {
    tokenId: 5622,
    Tier: "Basic",
  },
  {
    tokenId: 5623,
    Tier: "Basic",
  },
  {
    tokenId: 5624,
    Tier: "Basic",
  },
  {
    tokenId: 5625,
    Tier: "Uncommon",
  },
  {
    tokenId: 5626,
    Tier: "Common",
  },
  {
    tokenId: 5627,
    Tier: "Basic",
  },
  {
    tokenId: 5628,
    Tier: "Legendary",
  },
  {
    tokenId: 5629,
    Tier: "Common",
  },
  {
    tokenId: 5630,
    Tier: "Mythic",
  },
  {
    tokenId: 5631,
    Tier: "Uncommon",
  },
  {
    tokenId: 5632,
    Tier: "Basic",
  },
  {
    tokenId: 5633,
    Tier: "Basic",
  },
  {
    tokenId: 5634,
    Tier: "Epic",
  },
  {
    tokenId: 5635,
    Tier: "Exotic",
  },
  {
    tokenId: 5636,
    Tier: "Uncommon",
  },
  {
    tokenId: 5637,
    Tier: "Rare",
  },
  {
    tokenId: 5638,
    Tier: "Basic",
  },
  {
    tokenId: 5639,
    Tier: "Epic",
  },
  {
    tokenId: 5640,
    Tier: "Mythic",
  },
  {
    tokenId: 5641,
    Tier: "Basic",
  },
  {
    tokenId: 5642,
    Tier: "Basic",
  },
  {
    tokenId: 5643,
    Tier: "Common",
  },
  {
    tokenId: 5644,
    Tier: "Rare",
  },
  {
    tokenId: 5645,
    Tier: "Basic",
  },
  {
    tokenId: 5646,
    Tier: "Epic",
  },
  {
    tokenId: 5647,
    Tier: "Uncommon",
  },
  {
    tokenId: 5648,
    Tier: "Basic",
  },
  {
    tokenId: 5649,
    Tier: "Basic",
  },
  {
    tokenId: 5650,
    Tier: "Uncommon",
  },
  {
    tokenId: 5651,
    Tier: "Basic",
  },
  {
    tokenId: 5652,
    Tier: "Basic",
  },
  {
    tokenId: 5653,
    Tier: "Basic",
  },
  {
    tokenId: 5654,
    Tier: "Basic",
  },
  {
    tokenId: 5655,
    Tier: "Basic",
  },
  {
    tokenId: 5656,
    Tier: "Basic",
  },
  {
    tokenId: 5657,
    Tier: "Common",
  },
  {
    tokenId: 5658,
    Tier: "Basic",
  },
  {
    tokenId: 5659,
    Tier: "Basic",
  },
  {
    tokenId: 5660,
    Tier: "Epic",
  },
  {
    tokenId: 5661,
    Tier: "Basic",
  },
  {
    tokenId: 5662,
    Tier: "Basic",
  },
  {
    tokenId: 5663,
    Tier: "Rare",
  },
  {
    tokenId: 5664,
    Tier: "Basic",
  },
  {
    tokenId: 5665,
    Tier: "Basic",
  },
  {
    tokenId: 5666,
    Tier: "Epic",
  },
  {
    tokenId: 5667,
    Tier: "Basic",
  },
  {
    tokenId: 5668,
    Tier: "Basic",
  },
  {
    tokenId: 5669,
    Tier: "Basic",
  },
  {
    tokenId: 5670,
    Tier: "Common",
  },
  {
    tokenId: 5671,
    Tier: "Basic",
  },
  {
    tokenId: 5672,
    Tier: "Basic",
  },
  {
    tokenId: 5673,
    Tier: "Rare",
  },
  {
    tokenId: 5674,
    Tier: "Basic",
  },
  {
    tokenId: 5675,
    Tier: "Basic",
  },
  {
    tokenId: 5676,
    Tier: "Rare",
  },
  {
    tokenId: 5677,
    Tier: "Uncommon",
  },
  {
    tokenId: 5678,
    Tier: "Exotic",
  },
  {
    tokenId: 5679,
    Tier: "Mythic",
  },
  {
    tokenId: 5680,
    Tier: "Basic",
  },
  {
    tokenId: 5681,
    Tier: "Exotic",
  },
  {
    tokenId: 5682,
    Tier: "Rare",
  },
  {
    tokenId: 5683,
    Tier: "Basic",
  },
  {
    tokenId: 5684,
    Tier: "Common",
  },
  {
    tokenId: 5685,
    Tier: "Basic",
  },
  {
    tokenId: 5686,
    Tier: "Rare",
  },
  {
    tokenId: 5687,
    Tier: "Uncommon",
  },
  {
    tokenId: 5688,
    Tier: "Legendary",
  },
  {
    tokenId: 5689,
    Tier: "Epic",
  },
  {
    tokenId: 5690,
    Tier: "Common",
  },
  {
    tokenId: 5691,
    Tier: "Common",
  },
  {
    tokenId: 5692,
    Tier: "Epic",
  },
  {
    tokenId: 5693,
    Tier: "Basic",
  },
  {
    tokenId: 5694,
    Tier: "Basic",
  },
  {
    tokenId: 5695,
    Tier: "Uncommon",
  },
  {
    tokenId: 5696,
    Tier: "Rare",
  },
  {
    tokenId: 5697,
    Tier: "Basic",
  },
  {
    tokenId: 5698,
    Tier: "Rare",
  },
  {
    tokenId: 5699,
    Tier: "Common",
  },
  {
    tokenId: 5700,
    Tier: "Common",
  },
  {
    tokenId: 5701,
    Tier: "Epic",
  },
  {
    tokenId: 5702,
    Tier: "Basic",
  },
  {
    tokenId: 5703,
    Tier: "Basic",
  },
  {
    tokenId: 5704,
    Tier: "Basic",
  },
  {
    tokenId: 5705,
    Tier: "Common",
  },
  {
    tokenId: 5706,
    Tier: "Basic",
  },
  {
    tokenId: 5707,
    Tier: "Basic",
  },
  {
    tokenId: 5708,
    Tier: "Basic",
  },
  {
    tokenId: 5709,
    Tier: "Basic",
  },
  {
    tokenId: 5710,
    Tier: "Basic",
  },
  {
    tokenId: 5711,
    Tier: "Basic",
  },
  {
    tokenId: 5712,
    Tier: "Basic",
  },
  {
    tokenId: 5713,
    Tier: "Basic",
  },
  {
    tokenId: 5714,
    Tier: "Uncommon",
  },
  {
    tokenId: 5715,
    Tier: "Uncommon",
  },
  {
    tokenId: 5716,
    Tier: "Mythic",
  },
  {
    tokenId: 5717,
    Tier: "Basic",
  },
  {
    tokenId: 5718,
    Tier: "Basic",
  },
  {
    tokenId: 5719,
    Tier: "Basic",
  },
  {
    tokenId: 5720,
    Tier: "Basic",
  },
  {
    tokenId: 5721,
    Tier: "Uncommon",
  },
  {
    tokenId: 5722,
    Tier: "Rare",
  },
  {
    tokenId: 5723,
    Tier: "Basic",
  },
  {
    tokenId: 5724,
    Tier: "Rare",
  },
  {
    tokenId: 5725,
    Tier: "Basic",
  },
  {
    tokenId: 5726,
    Tier: "Basic",
  },
  {
    tokenId: 5727,
    Tier: "Basic",
  },
  {
    tokenId: 5728,
    Tier: "Basic",
  },
  {
    tokenId: 5729,
    Tier: "Uncommon",
  },
  {
    tokenId: 5730,
    Tier: "Rare",
  },
  {
    tokenId: 5731,
    Tier: "Common",
  },
  {
    tokenId: 5732,
    Tier: "Basic",
  },
  {
    tokenId: 5733,
    Tier: "Legendary",
  },
  {
    tokenId: 5734,
    Tier: "Basic",
  },
  {
    tokenId: 5735,
    Tier: "Basic",
  },
  {
    tokenId: 5736,
    Tier: "Basic",
  },
  {
    tokenId: 5737,
    Tier: "Legendary",
  },
  {
    tokenId: 5738,
    Tier: "Basic",
  },
  {
    tokenId: 5739,
    Tier: "Legendary",
  },
  {
    tokenId: 5740,
    Tier: "Common",
  },
  {
    tokenId: 5741,
    Tier: "Basic",
  },
  {
    tokenId: 5742,
    Tier: "Legendary",
  },
  {
    tokenId: 5743,
    Tier: "Uncommon",
  },
  {
    tokenId: 5744,
    Tier: "Rare",
  },
  {
    tokenId: 5745,
    Tier: "Basic",
  },
  {
    tokenId: 5746,
    Tier: "Rare",
  },
  {
    tokenId: 5747,
    Tier: "Epic",
  },
  {
    tokenId: 5748,
    Tier: "Uncommon",
  },
  {
    tokenId: 5749,
    Tier: "Legendary",
  },
  {
    tokenId: 5750,
    Tier: "Basic",
  },
  {
    tokenId: 5751,
    Tier: "Basic",
  },
  {
    tokenId: 5752,
    Tier: "Epic",
  },
  {
    tokenId: 5753,
    Tier: "Basic",
  },
  {
    tokenId: 5754,
    Tier: "Basic",
  },
  {
    tokenId: 5755,
    Tier: "Basic",
  },
  {
    tokenId: 5756,
    Tier: "Basic",
  },
  {
    tokenId: 5757,
    Tier: "Rare",
  },
  {
    tokenId: 5758,
    Tier: "Rare",
  },
  {
    tokenId: 5759,
    Tier: "Basic",
  },
  {
    tokenId: 5760,
    Tier: "Basic",
  },
  {
    tokenId: 5761,
    Tier: "Mythic",
  },
  {
    tokenId: 5762,
    Tier: "Basic",
  },
  {
    tokenId: 5763,
    Tier: "Basic",
  },
  {
    tokenId: 5764,
    Tier: "Basic",
  },
  {
    tokenId: 5765,
    Tier: "Basic",
  },
  {
    tokenId: 5766,
    Tier: "Mythic",
  },
  {
    tokenId: 5767,
    Tier: "Rare",
  },
  {
    tokenId: 5768,
    Tier: "Basic",
  },
  {
    tokenId: 5769,
    Tier: "Basic",
  },
  {
    tokenId: 5770,
    Tier: "Exotic",
  },
  {
    tokenId: 5771,
    Tier: "Uncommon",
  },
  {
    tokenId: 5772,
    Tier: "Epic",
  },
  {
    tokenId: 5773,
    Tier: "Basic",
  },
  {
    tokenId: 5774,
    Tier: "Basic",
  },
  {
    tokenId: 5775,
    Tier: "Uncommon",
  },
  {
    tokenId: 5776,
    Tier: "Basic",
  },
  {
    tokenId: 5777,
    Tier: "Common",
  },
  {
    tokenId: 5778,
    Tier: "Uncommon",
  },
  {
    tokenId: 5779,
    Tier: "Basic",
  },
  {
    tokenId: 5780,
    Tier: "Common",
  },
  {
    tokenId: 5781,
    Tier: "Basic",
  },
  {
    tokenId: 5782,
    Tier: "Basic",
  },
  {
    tokenId: 5783,
    Tier: "Common",
  },
  {
    tokenId: 5784,
    Tier: "Basic",
  },
  {
    tokenId: 5785,
    Tier: "Basic",
  },
  {
    tokenId: 5786,
    Tier: "Common",
  },
  {
    tokenId: 5787,
    Tier: "Common",
  },
  {
    tokenId: 5788,
    Tier: "Epic",
  },
  {
    tokenId: 5789,
    Tier: "Uncommon",
  },
  {
    tokenId: 5790,
    Tier: "Rare",
  },
  {
    tokenId: 5791,
    Tier: "Basic",
  },
  {
    tokenId: 5792,
    Tier: "Uncommon",
  },
  {
    tokenId: 5793,
    Tier: "Basic",
  },
  {
    tokenId: 5794,
    Tier: "Basic",
  },
  {
    tokenId: 5795,
    Tier: "Mythic",
  },
  {
    tokenId: 5796,
    Tier: "Common",
  },
  {
    tokenId: 5797,
    Tier: "Uncommon",
  },
  {
    tokenId: 5798,
    Tier: "Basic",
  },
  {
    tokenId: 5799,
    Tier: "Legendary",
  },
  {
    tokenId: 5800,
    Tier: "Common",
  },
  {
    tokenId: 5801,
    Tier: "Rare",
  },
  {
    tokenId: 5802,
    Tier: "Basic",
  },
  {
    tokenId: 5803,
    Tier: "Basic",
  },
  {
    tokenId: 5804,
    Tier: "Basic",
  },
  {
    tokenId: 5805,
    Tier: "Basic",
  },
  {
    tokenId: 5806,
    Tier: "Rare",
  },
  {
    tokenId: 5807,
    Tier: "Basic",
  },
  {
    tokenId: 5808,
    Tier: "Basic",
  },
  {
    tokenId: 5809,
    Tier: "Basic",
  },
  {
    tokenId: 5810,
    Tier: "Common",
  },
  {
    tokenId: 5811,
    Tier: "Uncommon",
  },
  {
    tokenId: 5812,
    Tier: "Uncommon",
  },
  {
    tokenId: 5813,
    Tier: "Basic",
  },
  {
    tokenId: 5814,
    Tier: "Epic",
  },
  {
    tokenId: 5815,
    Tier: "Basic",
  },
  {
    tokenId: 5816,
    Tier: "Basic",
  },
  {
    tokenId: 5817,
    Tier: "Basic",
  },
  {
    tokenId: 5818,
    Tier: "Basic",
  },
  {
    tokenId: 5819,
    Tier: "Basic",
  },
  {
    tokenId: 5820,
    Tier: "Basic",
  },
  {
    tokenId: 5821,
    Tier: "Common",
  },
  {
    tokenId: 5822,
    Tier: "Basic",
  },
  {
    tokenId: 5823,
    Tier: "Basic",
  },
  {
    tokenId: 5824,
    Tier: "Legendary",
  },
  {
    tokenId: 5825,
    Tier: "Uncommon",
  },
  {
    tokenId: 5826,
    Tier: "Basic",
  },
  {
    tokenId: 5827,
    Tier: "Basic",
  },
  {
    tokenId: 5828,
    Tier: "Basic",
  },
  {
    tokenId: 5829,
    Tier: "Exotic",
  },
  {
    tokenId: 5830,
    Tier: "Basic",
  },
  {
    tokenId: 5831,
    Tier: "Common",
  },
  {
    tokenId: 5832,
    Tier: "Legendary",
  },
  {
    tokenId: 5833,
    Tier: "Epic",
  },
  {
    tokenId: 5834,
    Tier: "Basic",
  },
  {
    tokenId: 5835,
    Tier: "Basic",
  },
  {
    tokenId: 5836,
    Tier: "Common",
  },
  {
    tokenId: 5837,
    Tier: "Epic",
  },
  {
    tokenId: 5838,
    Tier: "Basic",
  },
  {
    tokenId: 5839,
    Tier: "Basic",
  },
  {
    tokenId: 5840,
    Tier: "Basic",
  },
  {
    tokenId: 5841,
    Tier: "Basic",
  },
  {
    tokenId: 5842,
    Tier: "Basic",
  },
  {
    tokenId: 5843,
    Tier: "Rare",
  },
  {
    tokenId: 5844,
    Tier: "Basic",
  },
  {
    tokenId: 5845,
    Tier: "Common",
  },
  {
    tokenId: 5846,
    Tier: "Common",
  },
  {
    tokenId: 5847,
    Tier: "Common",
  },
  {
    tokenId: 5848,
    Tier: "Uncommon",
  },
  {
    tokenId: 5849,
    Tier: "Basic",
  },
  {
    tokenId: 5850,
    Tier: "Basic",
  },
  {
    tokenId: 5851,
    Tier: "Basic",
  },
  {
    tokenId: 5852,
    Tier: "Basic",
  },
  {
    tokenId: 5853,
    Tier: "Common",
  },
  {
    tokenId: 5854,
    Tier: "Common",
  },
  {
    tokenId: 5855,
    Tier: "Basic",
  },
  {
    tokenId: 5856,
    Tier: "Basic",
  },
  {
    tokenId: 5857,
    Tier: "Basic",
  },
  {
    tokenId: 5858,
    Tier: "Legendary",
  },
  {
    tokenId: 5859,
    Tier: "Rare",
  },
  {
    tokenId: 5860,
    Tier: "Common",
  },
  {
    tokenId: 5861,
    Tier: "Basic",
  },
  {
    tokenId: 5862,
    Tier: "Basic",
  },
  {
    tokenId: 5863,
    Tier: "Basic",
  },
  {
    tokenId: 5864,
    Tier: "Common",
  },
  {
    tokenId: 5865,
    Tier: "Uncommon",
  },
  {
    tokenId: 5866,
    Tier: "Basic",
  },
  {
    tokenId: 5867,
    Tier: "Basic",
  },
  {
    tokenId: 5868,
    Tier: "Basic",
  },
  {
    tokenId: 5869,
    Tier: "Uncommon",
  },
  {
    tokenId: 5870,
    Tier: "Basic",
  },
  {
    tokenId: 5871,
    Tier: "Basic",
  },
  {
    tokenId: 5872,
    Tier: "Common",
  },
  {
    tokenId: 5873,
    Tier: "Legendary",
  },
  {
    tokenId: 5874,
    Tier: "Common",
  },
  {
    tokenId: 5875,
    Tier: "Uncommon",
  },
  {
    tokenId: 5876,
    Tier: "Exotic",
  },
  {
    tokenId: 5877,
    Tier: "Legendary",
  },
  {
    tokenId: 5878,
    Tier: "Rare",
  },
  {
    tokenId: 5879,
    Tier: "Basic",
  },
  {
    tokenId: 5880,
    Tier: "Epic",
  },
  {
    tokenId: 5881,
    Tier: "Common",
  },
  {
    tokenId: 5882,
    Tier: "Basic",
  },
  {
    tokenId: 5883,
    Tier: "Basic",
  },
  {
    tokenId: 5884,
    Tier: "Basic",
  },
  {
    tokenId: 5885,
    Tier: "Basic",
  },
  {
    tokenId: 5886,
    Tier: "Basic",
  },
  {
    tokenId: 5887,
    Tier: "Mythic",
  },
  {
    tokenId: 5888,
    Tier: "Common",
  },
  {
    tokenId: 5889,
    Tier: "Uncommon",
  },
  {
    tokenId: 5890,
    Tier: "Uncommon",
  },
  {
    tokenId: 5891,
    Tier: "Basic",
  },
  {
    tokenId: 5892,
    Tier: "Basic",
  },
  {
    tokenId: 5893,
    Tier: "Epic",
  },
  {
    tokenId: 5894,
    Tier: "Basic",
  },
  {
    tokenId: 5895,
    Tier: "Basic",
  },
  {
    tokenId: 5896,
    Tier: "Basic",
  },
  {
    tokenId: 5897,
    Tier: "Basic",
  },
  {
    tokenId: 5898,
    Tier: "Uncommon",
  },
  {
    tokenId: 5899,
    Tier: "Uncommon",
  },
  {
    tokenId: 5900,
    Tier: "Epic",
  },
  {
    tokenId: 5901,
    Tier: "Uncommon",
  },
  {
    tokenId: 5902,
    Tier: "Basic",
  },
  {
    tokenId: 5903,
    Tier: "Basic",
  },
  {
    tokenId: 5904,
    Tier: "Epic",
  },
  {
    tokenId: 5905,
    Tier: "Common",
  },
  {
    tokenId: 5906,
    Tier: "Basic",
  },
  {
    tokenId: 5907,
    Tier: "Basic",
  },
  {
    tokenId: 5908,
    Tier: "Uncommon",
  },
  {
    tokenId: 5909,
    Tier: "Basic",
  },
  {
    tokenId: 5910,
    Tier: "Uncommon",
  },
  {
    tokenId: 5911,
    Tier: "Rare",
  },
  {
    tokenId: 5912,
    Tier: "Common",
  },
  {
    tokenId: 5913,
    Tier: "Rare",
  },
  {
    tokenId: 5914,
    Tier: "Basic",
  },
  {
    tokenId: 5915,
    Tier: "Legendary",
  },
  {
    tokenId: 5916,
    Tier: "Epic",
  },
  {
    tokenId: 5917,
    Tier: "Basic",
  },
  {
    tokenId: 5918,
    Tier: "Basic",
  },
  {
    tokenId: 5919,
    Tier: "Uncommon",
  },
  {
    tokenId: 5920,
    Tier: "Uncommon",
  },
  {
    tokenId: 5921,
    Tier: "Basic",
  },
  {
    tokenId: 5922,
    Tier: "Basic",
  },
  {
    tokenId: 5923,
    Tier: "Basic",
  },
  {
    tokenId: 5924,
    Tier: "Basic",
  },
  {
    tokenId: 5925,
    Tier: "Legendary",
  },
  {
    tokenId: 5926,
    Tier: "Basic",
  },
  {
    tokenId: 5927,
    Tier: "Uncommon",
  },
  {
    tokenId: 5928,
    Tier: "Mythic",
  },
  {
    tokenId: 5929,
    Tier: "Uncommon",
  },
  {
    tokenId: 5930,
    Tier: "Uncommon",
  },
  {
    tokenId: 5931,
    Tier: "Basic",
  },
  {
    tokenId: 5932,
    Tier: "Uncommon",
  },
  {
    tokenId: 5933,
    Tier: "Basic",
  },
  {
    tokenId: 5934,
    Tier: "Uncommon",
  },
  {
    tokenId: 5935,
    Tier: "Basic",
  },
  {
    tokenId: 5936,
    Tier: "Mythic",
  },
  {
    tokenId: 5937,
    Tier: "Common",
  },
  {
    tokenId: 5938,
    Tier: "Basic",
  },
  {
    tokenId: 5939,
    Tier: "Basic",
  },
  {
    tokenId: 5940,
    Tier: "Epic",
  },
  {
    tokenId: 5941,
    Tier: "Basic",
  },
  {
    tokenId: 5942,
    Tier: "Basic",
  },
  {
    tokenId: 5943,
    Tier: "Epic",
  },
  {
    tokenId: 5944,
    Tier: "Basic",
  },
  {
    tokenId: 5945,
    Tier: "Basic",
  },
  {
    tokenId: 5946,
    Tier: "Rare",
  },
  {
    tokenId: 5947,
    Tier: "Basic",
  },
  {
    tokenId: 5948,
    Tier: "Basic",
  },
  {
    tokenId: 5949,
    Tier: "Basic",
  },
  {
    tokenId: 5950,
    Tier: "Common",
  },
  {
    tokenId: 5951,
    Tier: "Common",
  },
  {
    tokenId: 5952,
    Tier: "Basic",
  },
  {
    tokenId: 5953,
    Tier: "Basic",
  },
  {
    tokenId: 5954,
    Tier: "Basic",
  },
  {
    tokenId: 5955,
    Tier: "Basic",
  },
  {
    tokenId: 5956,
    Tier: "Uncommon",
  },
  {
    tokenId: 5957,
    Tier: "Rare",
  },
  {
    tokenId: 5958,
    Tier: "Basic",
  },
  {
    tokenId: 5959,
    Tier: "Rare",
  },
  {
    tokenId: 5960,
    Tier: "Basic",
  },
  {
    tokenId: 5961,
    Tier: "Rare",
  },
  {
    tokenId: 5962,
    Tier: "Basic",
  },
  {
    tokenId: 5963,
    Tier: "Basic",
  },
  {
    tokenId: 5964,
    Tier: "Basic",
  },
  {
    tokenId: 5965,
    Tier: "Basic",
  },
  {
    tokenId: 5966,
    Tier: "Common",
  },
  {
    tokenId: 5967,
    Tier: "Basic",
  },
  {
    tokenId: 5968,
    Tier: "Epic",
  },
  {
    tokenId: 5969,
    Tier: "Uncommon",
  },
  {
    tokenId: 5970,
    Tier: "Uncommon",
  },
  {
    tokenId: 5971,
    Tier: "Uncommon",
  },
  {
    tokenId: 5972,
    Tier: "Epic",
  },
  {
    tokenId: 5973,
    Tier: "Basic",
  },
  {
    tokenId: 5974,
    Tier: "Uncommon",
  },
  {
    tokenId: 5975,
    Tier: "Uncommon",
  },
  {
    tokenId: 5976,
    Tier: "Basic",
  },
  {
    tokenId: 5977,
    Tier: "Basic",
  },
  {
    tokenId: 5978,
    Tier: "Basic",
  },
  {
    tokenId: 5979,
    Tier: "Basic",
  },
  {
    tokenId: 5980,
    Tier: "Basic",
  },
  {
    tokenId: 5981,
    Tier: "Basic",
  },
  {
    tokenId: 5982,
    Tier: "Legendary",
  },
  {
    tokenId: 5983,
    Tier: "Basic",
  },
  {
    tokenId: 5984,
    Tier: "Basic",
  },
  {
    tokenId: 5985,
    Tier: "Basic",
  },
  {
    tokenId: 5986,
    Tier: "Rare",
  },
  {
    tokenId: 5987,
    Tier: "Basic",
  },
  {
    tokenId: 5988,
    Tier: "Basic",
  },
  {
    tokenId: 5989,
    Tier: "Basic",
  },
  {
    tokenId: 5990,
    Tier: "Rare",
  },
  {
    tokenId: 5991,
    Tier: "Basic",
  },
  {
    tokenId: 5992,
    Tier: "Uncommon",
  },
  {
    tokenId: 5993,
    Tier: "Basic",
  },
  {
    tokenId: 5994,
    Tier: "Basic",
  },
  {
    tokenId: 5995,
    Tier: "Basic",
  },
  {
    tokenId: 5996,
    Tier: "Basic",
  },
  {
    tokenId: 5997,
    Tier: "Common",
  },
  {
    tokenId: 5998,
    Tier: "Basic",
  },
  {
    tokenId: 5999,
    Tier: "Epic",
  },
  {
    tokenId: 6000,
    Tier: "Basic",
  },
  {
    tokenId: 6001,
    Tier: "Common",
  },
  {
    tokenId: 6002,
    Tier: "Basic",
  },
  {
    tokenId: 6003,
    Tier: "Basic",
  },
  {
    tokenId: 6004,
    Tier: "Basic",
  },
  {
    tokenId: 6005,
    Tier: "Basic",
  },
  {
    tokenId: 6006,
    Tier: "Basic",
  },
  {
    tokenId: 6007,
    Tier: "Basic",
  },
  {
    tokenId: 6008,
    Tier: "Uncommon",
  },
  {
    tokenId: 6009,
    Tier: "Uncommon",
  },
  {
    tokenId: 6010,
    Tier: "Uncommon",
  },
  {
    tokenId: 6011,
    Tier: "Epic",
  },
  {
    tokenId: 6012,
    Tier: "Common",
  },
  {
    tokenId: 6013,
    Tier: "Rare",
  },
  {
    tokenId: 6014,
    Tier: "Basic",
  },
  {
    tokenId: 6015,
    Tier: "Basic",
  },
  {
    tokenId: 6016,
    Tier: "Epic",
  },
  {
    tokenId: 6017,
    Tier: "Basic",
  },
  {
    tokenId: 6018,
    Tier: "Common",
  },
  {
    tokenId: 6019,
    Tier: "Basic",
  },
  {
    tokenId: 6020,
    Tier: "Common",
  },
  {
    tokenId: 6021,
    Tier: "Epic",
  },
  {
    tokenId: 6022,
    Tier: "Basic",
  },
  {
    tokenId: 6023,
    Tier: "Basic",
  },
  {
    tokenId: 6024,
    Tier: "Epic",
  },
  {
    tokenId: 6025,
    Tier: "Basic",
  },
  {
    tokenId: 6026,
    Tier: "Common",
  },
  {
    tokenId: 6027,
    Tier: "Basic",
  },
  {
    tokenId: 6028,
    Tier: "Basic",
  },
  {
    tokenId: 6029,
    Tier: "Basic",
  },
  {
    tokenId: 6030,
    Tier: "Basic",
  },
  {
    tokenId: 6031,
    Tier: "Basic",
  },
  {
    tokenId: 6032,
    Tier: "Basic",
  },
  {
    tokenId: 6033,
    Tier: "Common",
  },
  {
    tokenId: 6034,
    Tier: "Basic",
  },
  {
    tokenId: 6035,
    Tier: "Basic",
  },
  {
    tokenId: 6036,
    Tier: "Basic",
  },
  {
    tokenId: 6037,
    Tier: "Basic",
  },
  {
    tokenId: 6038,
    Tier: "Common",
  },
  {
    tokenId: 6039,
    Tier: "Basic",
  },
  {
    tokenId: 6040,
    Tier: "Uncommon",
  },
  {
    tokenId: 6041,
    Tier: "Basic",
  },
  {
    tokenId: 6042,
    Tier: "Common",
  },
  {
    tokenId: 6043,
    Tier: "Common",
  },
  {
    tokenId: 6044,
    Tier: "Epic",
  },
  {
    tokenId: 6045,
    Tier: "Uncommon",
  },
  {
    tokenId: 6046,
    Tier: "Uncommon",
  },
  {
    tokenId: 6047,
    Tier: "Basic",
  },
  {
    tokenId: 6048,
    Tier: "Basic",
  },
  {
    tokenId: 6049,
    Tier: "Basic",
  },
  {
    tokenId: 6050,
    Tier: "Basic",
  },
  {
    tokenId: 6051,
    Tier: "Basic",
  },
  {
    tokenId: 6052,
    Tier: "Uncommon",
  },
  {
    tokenId: 6053,
    Tier: "Uncommon",
  },
  {
    tokenId: 6054,
    Tier: "Basic",
  },
  {
    tokenId: 6055,
    Tier: "Basic",
  },
  {
    tokenId: 6056,
    Tier: "Rare",
  },
  {
    tokenId: 6057,
    Tier: "Common",
  },
  {
    tokenId: 6058,
    Tier: "Common",
  },
  {
    tokenId: 6059,
    Tier: "Basic",
  },
  {
    tokenId: 6060,
    Tier: "Basic",
  },
  {
    tokenId: 6061,
    Tier: "Basic",
  },
  {
    tokenId: 6062,
    Tier: "Rare",
  },
  {
    tokenId: 6063,
    Tier: "Uncommon",
  },
  {
    tokenId: 6064,
    Tier: "Common",
  },
  {
    tokenId: 6065,
    Tier: "Basic",
  },
  {
    tokenId: 6066,
    Tier: "Epic",
  },
  {
    tokenId: 6067,
    Tier: "Mythic",
  },
  {
    tokenId: 6068,
    Tier: "Mythic",
  },
  {
    tokenId: 6069,
    Tier: "Common",
  },
  {
    tokenId: 6070,
    Tier: "Uncommon",
  },
  {
    tokenId: 6071,
    Tier: "Common",
  },
  {
    tokenId: 6072,
    Tier: "Basic",
  },
  {
    tokenId: 6073,
    Tier: "Basic",
  },
  {
    tokenId: 6074,
    Tier: "Basic",
  },
  {
    tokenId: 6075,
    Tier: "Basic",
  },
  {
    tokenId: 6076,
    Tier: "Common",
  },
  {
    tokenId: 6077,
    Tier: "Basic",
  },
  {
    tokenId: 6078,
    Tier: "Basic",
  },
  {
    tokenId: 6079,
    Tier: "Basic",
  },
  {
    tokenId: 6080,
    Tier: "Mythic",
  },
  {
    tokenId: 6081,
    Tier: "Basic",
  },
  {
    tokenId: 6082,
    Tier: "Uncommon",
  },
  {
    tokenId: 6083,
    Tier: "Basic",
  },
  {
    tokenId: 6084,
    Tier: "Legendary",
  },
  {
    tokenId: 6085,
    Tier: "Uncommon",
  },
  {
    tokenId: 6086,
    Tier: "Common",
  },
  {
    tokenId: 6087,
    Tier: "Basic",
  },
  {
    tokenId: 6088,
    Tier: "Common",
  },
  {
    tokenId: 6089,
    Tier: "Uncommon",
  },
  {
    tokenId: 6090,
    Tier: "Rare",
  },
  {
    tokenId: 6091,
    Tier: "Basic",
  },
  {
    tokenId: 6092,
    Tier: "Basic",
  },
  {
    tokenId: 6093,
    Tier: "Common",
  },
  {
    tokenId: 6094,
    Tier: "Basic",
  },
  {
    tokenId: 6095,
    Tier: "Common",
  },
  {
    tokenId: 6096,
    Tier: "Uncommon",
  },
  {
    tokenId: 6097,
    Tier: "Common",
  },
  {
    tokenId: 6098,
    Tier: "Basic",
  },
  {
    tokenId: 6099,
    Tier: "Common",
  },
  {
    tokenId: 6100,
    Tier: "Uncommon",
  },
  {
    tokenId: 6101,
    Tier: "Common",
  },
  {
    tokenId: 6102,
    Tier: "Uncommon",
  },
  {
    tokenId: 6103,
    Tier: "Common",
  },
  {
    tokenId: 6104,
    Tier: "Basic",
  },
  {
    tokenId: 6105,
    Tier: "Basic",
  },
  {
    tokenId: 6106,
    Tier: "Epic",
  },
  {
    tokenId: 6107,
    Tier: "Epic",
  },
  {
    tokenId: 6108,
    Tier: "Epic",
  },
  {
    tokenId: 6109,
    Tier: "Basic",
  },
  {
    tokenId: 6110,
    Tier: "Rare",
  },
  {
    tokenId: 6111,
    Tier: "Epic",
  },
  {
    tokenId: 6112,
    Tier: "Basic",
  },
  {
    tokenId: 6113,
    Tier: "Epic",
  },
  {
    tokenId: 6114,
    Tier: "Epic",
  },
  {
    tokenId: 6115,
    Tier: "Basic",
  },
  {
    tokenId: 6116,
    Tier: "Basic",
  },
  {
    tokenId: 6117,
    Tier: "Basic",
  },
  {
    tokenId: 6118,
    Tier: "Basic",
  },
  {
    tokenId: 6119,
    Tier: "Common",
  },
  {
    tokenId: 6120,
    Tier: "Rare",
  },
  {
    tokenId: 6121,
    Tier: "Basic",
  },
  {
    tokenId: 6122,
    Tier: "Mythic",
  },
  {
    tokenId: 6123,
    Tier: "Basic",
  },
  {
    tokenId: 6124,
    Tier: "Basic",
  },
  {
    tokenId: 6125,
    Tier: "Common",
  },
  {
    tokenId: 6126,
    Tier: "Basic",
  },
  {
    tokenId: 6127,
    Tier: "Basic",
  },
  {
    tokenId: 6128,
    Tier: "Basic",
  },
  {
    tokenId: 6129,
    Tier: "Legendary",
  },
  {
    tokenId: 6130,
    Tier: "Basic",
  },
  {
    tokenId: 6131,
    Tier: "Basic",
  },
  {
    tokenId: 6132,
    Tier: "Basic",
  },
  {
    tokenId: 6133,
    Tier: "Legendary",
  },
  {
    tokenId: 6134,
    Tier: "Legendary",
  },
  {
    tokenId: 6135,
    Tier: "Rare",
  },
  {
    tokenId: 6136,
    Tier: "Basic",
  },
  {
    tokenId: 6137,
    Tier: "Uncommon",
  },
  {
    tokenId: 6138,
    Tier: "Basic",
  },
  {
    tokenId: 6139,
    Tier: "Mythic",
  },
  {
    tokenId: 6140,
    Tier: "Basic",
  },
  {
    tokenId: 6141,
    Tier: "Rare",
  },
  {
    tokenId: 6142,
    Tier: "Exotic",
  },
  {
    tokenId: 6143,
    Tier: "Basic",
  },
  {
    tokenId: 6144,
    Tier: "Basic",
  },
  {
    tokenId: 6145,
    Tier: "Basic",
  },
  {
    tokenId: 6146,
    Tier: "Legendary",
  },
  {
    tokenId: 6147,
    Tier: "Mythic",
  },
  {
    tokenId: 6148,
    Tier: "Legendary",
  },
  {
    tokenId: 6149,
    Tier: "Uncommon",
  },
  {
    tokenId: 6150,
    Tier: "Epic",
  },
  {
    tokenId: 6151,
    Tier: "Basic",
  },
  {
    tokenId: 6152,
    Tier: "Rare",
  },
  {
    tokenId: 6153,
    Tier: "Basic",
  },
  {
    tokenId: 6154,
    Tier: "Common",
  },
  {
    tokenId: 6155,
    Tier: "Basic",
  },
  {
    tokenId: 6156,
    Tier: "Basic",
  },
  {
    tokenId: 6157,
    Tier: "Basic",
  },
  {
    tokenId: 6158,
    Tier: "Basic",
  },
  {
    tokenId: 6159,
    Tier: "Rare",
  },
  {
    tokenId: 6160,
    Tier: "Epic",
  },
  {
    tokenId: 6161,
    Tier: "Basic",
  },
  {
    tokenId: 6162,
    Tier: "Basic",
  },
  {
    tokenId: 6163,
    Tier: "Uncommon",
  },
  {
    tokenId: 6164,
    Tier: "Basic",
  },
  {
    tokenId: 6165,
    Tier: "Basic",
  },
  {
    tokenId: 6166,
    Tier: "Basic",
  },
  {
    tokenId: 6167,
    Tier: "Uncommon",
  },
  {
    tokenId: 6168,
    Tier: "Basic",
  },
  {
    tokenId: 6169,
    Tier: "Basic",
  },
  {
    tokenId: 6170,
    Tier: "Basic",
  },
  {
    tokenId: 6171,
    Tier: "Rare",
  },
  {
    tokenId: 6172,
    Tier: "Common",
  },
  {
    tokenId: 6173,
    Tier: "Basic",
  },
  {
    tokenId: 6174,
    Tier: "Legendary",
  },
  {
    tokenId: 6175,
    Tier: "Uncommon",
  },
  {
    tokenId: 6176,
    Tier: "Basic",
  },
  {
    tokenId: 6177,
    Tier: "Basic",
  },
  {
    tokenId: 6178,
    Tier: "Basic",
  },
  {
    tokenId: 6179,
    Tier: "Uncommon",
  },
  {
    tokenId: 6180,
    Tier: "Rare",
  },
  {
    tokenId: 6181,
    Tier: "Rare",
  },
  {
    tokenId: 6182,
    Tier: "Basic",
  },
  {
    tokenId: 6183,
    Tier: "Basic",
  },
  {
    tokenId: 6184,
    Tier: "Common",
  },
  {
    tokenId: 6185,
    Tier: "Rare",
  },
  {
    tokenId: 6186,
    Tier: "Legendary",
  },
  {
    tokenId: 6187,
    Tier: "Epic",
  },
  {
    tokenId: 6188,
    Tier: "Basic",
  },
  {
    tokenId: 6189,
    Tier: "Legendary",
  },
  {
    tokenId: 6190,
    Tier: "Basic",
  },
  {
    tokenId: 6191,
    Tier: "Common",
  },
  {
    tokenId: 6192,
    Tier: "Basic",
  },
  {
    tokenId: 6193,
    Tier: "Mythic",
  },
  {
    tokenId: 6194,
    Tier: "Common",
  },
  {
    tokenId: 6195,
    Tier: "Basic",
  },
  {
    tokenId: 6196,
    Tier: "Common",
  },
  {
    tokenId: 6197,
    Tier: "Rare",
  },
  {
    tokenId: 6198,
    Tier: "Rare",
  },
  {
    tokenId: 6199,
    Tier: "Basic",
  },
  {
    tokenId: 6200,
    Tier: "Uncommon",
  },
  {
    tokenId: 6201,
    Tier: "Basic",
  },
  {
    tokenId: 6202,
    Tier: "Uncommon",
  },
  {
    tokenId: 6203,
    Tier: "Basic",
  },
  {
    tokenId: 6204,
    Tier: "Common",
  },
  {
    tokenId: 6205,
    Tier: "Epic",
  },
  {
    tokenId: 6206,
    Tier: "Common",
  },
  {
    tokenId: 6207,
    Tier: "Common",
  },
  {
    tokenId: 6208,
    Tier: "Basic",
  },
  {
    tokenId: 6209,
    Tier: "Basic",
  },
  {
    tokenId: 6210,
    Tier: "Basic",
  },
  {
    tokenId: 6211,
    Tier: "Rare",
  },
  {
    tokenId: 6212,
    Tier: "Rare",
  },
  {
    tokenId: 6213,
    Tier: "Basic",
  },
  {
    tokenId: 6214,
    Tier: "Basic",
  },
  {
    tokenId: 6215,
    Tier: "Common",
  },
  {
    tokenId: 6216,
    Tier: "Basic",
  },
  {
    tokenId: 6217,
    Tier: "Mythic",
  },
  {
    tokenId: 6218,
    Tier: "Basic",
  },
  {
    tokenId: 6219,
    Tier: "Common",
  },
  {
    tokenId: 6220,
    Tier: "Uncommon",
  },
  {
    tokenId: 6221,
    Tier: "Rare",
  },
  {
    tokenId: 6222,
    Tier: "Uncommon",
  },
  {
    tokenId: 6223,
    Tier: "Exotic",
  },
  {
    tokenId: 6224,
    Tier: "Common",
  },
  {
    tokenId: 6225,
    Tier: "Uncommon",
  },
  {
    tokenId: 6226,
    Tier: "Basic",
  },
  {
    tokenId: 6227,
    Tier: "Basic",
  },
  {
    tokenId: 6228,
    Tier: "Basic",
  },
  {
    tokenId: 6229,
    Tier: "Basic",
  },
  {
    tokenId: 6230,
    Tier: "Basic",
  },
  {
    tokenId: 6231,
    Tier: "Common",
  },
  {
    tokenId: 6232,
    Tier: "Mythic",
  },
  {
    tokenId: 6233,
    Tier: "Uncommon",
  },
  {
    tokenId: 6234,
    Tier: "Basic",
  },
  {
    tokenId: 6235,
    Tier: "Basic",
  },
  {
    tokenId: 6236,
    Tier: "Basic",
  },
  {
    tokenId: 6237,
    Tier: "Basic",
  },
  {
    tokenId: 6238,
    Tier: "Basic",
  },
  {
    tokenId: 6239,
    Tier: "Basic",
  },
  {
    tokenId: 6240,
    Tier: "Basic",
  },
  {
    tokenId: 6241,
    Tier: "Legendary",
  },
  {
    tokenId: 6242,
    Tier: "Common",
  },
  {
    tokenId: 6243,
    Tier: "Basic",
  },
  {
    tokenId: 6244,
    Tier: "Rare",
  },
  {
    tokenId: 6245,
    Tier: "Rare",
  },
  {
    tokenId: 6246,
    Tier: "Basic",
  },
  {
    tokenId: 6247,
    Tier: "Epic",
  },
  {
    tokenId: 6248,
    Tier: "Basic",
  },
  {
    tokenId: 6249,
    Tier: "Basic",
  },
  {
    tokenId: 6250,
    Tier: "Basic",
  },
  {
    tokenId: 6251,
    Tier: "Basic",
  },
  {
    tokenId: 6252,
    Tier: "Basic",
  },
  {
    tokenId: 6253,
    Tier: "Rare",
  },
  {
    tokenId: 6254,
    Tier: "Basic",
  },
  {
    tokenId: 6255,
    Tier: "Legendary",
  },
  {
    tokenId: 6256,
    Tier: "Common",
  },
  {
    tokenId: 6257,
    Tier: "Basic",
  },
  {
    tokenId: 6258,
    Tier: "Epic",
  },
  {
    tokenId: 6259,
    Tier: "Common",
  },
  {
    tokenId: 6260,
    Tier: "Rare",
  },
  {
    tokenId: 6261,
    Tier: "Basic",
  },
  {
    tokenId: 6262,
    Tier: "Epic",
  },
  {
    tokenId: 6263,
    Tier: "Basic",
  },
  {
    tokenId: 6264,
    Tier: "Epic",
  },
  {
    tokenId: 6265,
    Tier: "Basic",
  },
  {
    tokenId: 6266,
    Tier: "Legendary",
  },
  {
    tokenId: 6267,
    Tier: "Common",
  },
  {
    tokenId: 6268,
    Tier: "Basic",
  },
  {
    tokenId: 6269,
    Tier: "Basic",
  },
  {
    tokenId: 6270,
    Tier: "Basic",
  },
  {
    tokenId: 6271,
    Tier: "Basic",
  },
  {
    tokenId: 6272,
    Tier: "Basic",
  },
  {
    tokenId: 6273,
    Tier: "Uncommon",
  },
  {
    tokenId: 6274,
    Tier: "Basic",
  },
  {
    tokenId: 6275,
    Tier: "Basic",
  },
  {
    tokenId: 6276,
    Tier: "Uncommon",
  },
  {
    tokenId: 6277,
    Tier: "Epic",
  },
  {
    tokenId: 6278,
    Tier: "Epic",
  },
  {
    tokenId: 6279,
    Tier: "Common",
  },
  {
    tokenId: 6280,
    Tier: "Basic",
  },
  {
    tokenId: 6281,
    Tier: "Basic",
  },
  {
    tokenId: 6282,
    Tier: "Common",
  },
  {
    tokenId: 6283,
    Tier: "Basic",
  },
  {
    tokenId: 6284,
    Tier: "Basic",
  },
  {
    tokenId: 6285,
    Tier: "Mythic",
  },
  {
    tokenId: 6286,
    Tier: "Uncommon",
  },
  {
    tokenId: 6287,
    Tier: "Common",
  },
  {
    tokenId: 6288,
    Tier: "Basic",
  },
  {
    tokenId: 6289,
    Tier: "Common",
  },
  {
    tokenId: 6290,
    Tier: "Basic",
  },
  {
    tokenId: 6291,
    Tier: "Common",
  },
  {
    tokenId: 6292,
    Tier: "Rare",
  },
  {
    tokenId: 6293,
    Tier: "Common",
  },
  {
    tokenId: 6294,
    Tier: "Basic",
  },
  {
    tokenId: 6295,
    Tier: "Basic",
  },
  {
    tokenId: 6296,
    Tier: "Rare",
  },
  {
    tokenId: 6297,
    Tier: "Basic",
  },
  {
    tokenId: 6298,
    Tier: "Rare",
  },
  {
    tokenId: 6299,
    Tier: "Basic",
  },
  {
    tokenId: 6300,
    Tier: "Basic",
  },
  {
    tokenId: 6301,
    Tier: "Uncommon",
  },
  {
    tokenId: 6302,
    Tier: "Basic",
  },
  {
    tokenId: 6303,
    Tier: "Basic",
  },
  {
    tokenId: 6304,
    Tier: "Epic",
  },
  {
    tokenId: 6305,
    Tier: "Uncommon",
  },
  {
    tokenId: 6306,
    Tier: "Common",
  },
  {
    tokenId: 6307,
    Tier: "Common",
  },
  {
    tokenId: 6308,
    Tier: "Common",
  },
  {
    tokenId: 6309,
    Tier: "Legendary",
  },
  {
    tokenId: 6310,
    Tier: "Basic",
  },
  {
    tokenId: 6311,
    Tier: "Uncommon",
  },
  {
    tokenId: 6312,
    Tier: "Common",
  },
  {
    tokenId: 6313,
    Tier: "Uncommon",
  },
  {
    tokenId: 6314,
    Tier: "Basic",
  },
  {
    tokenId: 6315,
    Tier: "Basic",
  },
  {
    tokenId: 6316,
    Tier: "Basic",
  },
  {
    tokenId: 6317,
    Tier: "Basic",
  },
  {
    tokenId: 6318,
    Tier: "Basic",
  },
  {
    tokenId: 6319,
    Tier: "Basic",
  },
  {
    tokenId: 6320,
    Tier: "Mythic",
  },
  {
    tokenId: 6321,
    Tier: "Rare",
  },
  {
    tokenId: 6322,
    Tier: "Uncommon",
  },
  {
    tokenId: 6323,
    Tier: "Basic",
  },
  {
    tokenId: 6324,
    Tier: "Basic",
  },
  {
    tokenId: 6325,
    Tier: "Uncommon",
  },
  {
    tokenId: 6326,
    Tier: "Common",
  },
  {
    tokenId: 6327,
    Tier: "Basic",
  },
  {
    tokenId: 6328,
    Tier: "Basic",
  },
  {
    tokenId: 6329,
    Tier: "Legendary",
  },
  {
    tokenId: 6330,
    Tier: "Basic",
  },
  {
    tokenId: 6331,
    Tier: "Uncommon",
  },
  {
    tokenId: 6332,
    Tier: "Basic",
  },
  {
    tokenId: 6333,
    Tier: "Common",
  },
  {
    tokenId: 6334,
    Tier: "Basic",
  },
  {
    tokenId: 6335,
    Tier: "Basic",
  },
  {
    tokenId: 6336,
    Tier: "Legendary",
  },
  {
    tokenId: 6337,
    Tier: "Basic",
  },
  {
    tokenId: 6338,
    Tier: "Basic",
  },
  {
    tokenId: 6339,
    Tier: "Basic",
  },
  {
    tokenId: 6340,
    Tier: "Basic",
  },
  {
    tokenId: 6341,
    Tier: "Basic",
  },
  {
    tokenId: 6342,
    Tier: "Common",
  },
  {
    tokenId: 6343,
    Tier: "Basic",
  },
  {
    tokenId: 6344,
    Tier: "Basic",
  },
  {
    tokenId: 6345,
    Tier: "Uncommon",
  },
  {
    tokenId: 6346,
    Tier: "Basic",
  },
  {
    tokenId: 6347,
    Tier: "Basic",
  },
  {
    tokenId: 6348,
    Tier: "Basic",
  },
  {
    tokenId: 6349,
    Tier: "Basic",
  },
  {
    tokenId: 6350,
    Tier: "Uncommon",
  },
  {
    tokenId: 6351,
    Tier: "Basic",
  },
  {
    tokenId: 6352,
    Tier: "Basic",
  },
  {
    tokenId: 6353,
    Tier: "Basic",
  },
  {
    tokenId: 6354,
    Tier: "Basic",
  },
  {
    tokenId: 6355,
    Tier: "Epic",
  },
  {
    tokenId: 6356,
    Tier: "Uncommon",
  },
  {
    tokenId: 6357,
    Tier: "Uncommon",
  },
  {
    tokenId: 6358,
    Tier: "Exotic",
  },
  {
    tokenId: 6359,
    Tier: "Uncommon",
  },
  {
    tokenId: 6360,
    Tier: "Common",
  },
  {
    tokenId: 6361,
    Tier: "Uncommon",
  },
  {
    tokenId: 6362,
    Tier: "Rare",
  },
  {
    tokenId: 6363,
    Tier: "Basic",
  },
  {
    tokenId: 6364,
    Tier: "Basic",
  },
  {
    tokenId: 6365,
    Tier: "Common",
  },
  {
    tokenId: 6366,
    Tier: "Rare",
  },
  {
    tokenId: 6367,
    Tier: "Basic",
  },
  {
    tokenId: 6368,
    Tier: "Basic",
  },
  {
    tokenId: 6369,
    Tier: "Legendary",
  },
  {
    tokenId: 6370,
    Tier: "Uncommon",
  },
  {
    tokenId: 6371,
    Tier: "Common",
  },
  {
    tokenId: 6372,
    Tier: "Common",
  },
  {
    tokenId: 6373,
    Tier: "Common",
  },
  {
    tokenId: 6374,
    Tier: "Common",
  },
  {
    tokenId: 6375,
    Tier: "Uncommon",
  },
  {
    tokenId: 6376,
    Tier: "Uncommon",
  },
  {
    tokenId: 6377,
    Tier: "Basic",
  },
  {
    tokenId: 6378,
    Tier: "Basic",
  },
  {
    tokenId: 6379,
    Tier: "Basic",
  },
  {
    tokenId: 6380,
    Tier: "Basic",
  },
  {
    tokenId: 6381,
    Tier: "Basic",
  },
  {
    tokenId: 6382,
    Tier: "Basic",
  },
  {
    tokenId: 6383,
    Tier: "Basic",
  },
  {
    tokenId: 6384,
    Tier: "Basic",
  },
  {
    tokenId: 6385,
    Tier: "Basic",
  },
  {
    tokenId: 6386,
    Tier: "Mythic",
  },
  {
    tokenId: 6387,
    Tier: "Basic",
  },
  {
    tokenId: 6388,
    Tier: "Basic",
  },
  {
    tokenId: 6389,
    Tier: "Basic",
  },
  {
    tokenId: 6390,
    Tier: "Mythic",
  },
  {
    tokenId: 6391,
    Tier: "Basic",
  },
  {
    tokenId: 6392,
    Tier: "Basic",
  },
  {
    tokenId: 6393,
    Tier: "Basic",
  },
  {
    tokenId: 6394,
    Tier: "Uncommon",
  },
  {
    tokenId: 6395,
    Tier: "Mythic",
  },
  {
    tokenId: 6396,
    Tier: "Basic",
  },
  {
    tokenId: 6397,
    Tier: "Basic",
  },
  {
    tokenId: 6398,
    Tier: "Basic",
  },
  {
    tokenId: 6399,
    Tier: "Common",
  },
  {
    tokenId: 6400,
    Tier: "Basic",
  },
  {
    tokenId: 6401,
    Tier: "Common",
  },
  {
    tokenId: 6402,
    Tier: "Basic",
  },
  {
    tokenId: 6403,
    Tier: "Common",
  },
  {
    tokenId: 6404,
    Tier: "Common",
  },
  {
    tokenId: 6405,
    Tier: "Common",
  },
  {
    tokenId: 6406,
    Tier: "Basic",
  },
  {
    tokenId: 6407,
    Tier: "Basic",
  },
  {
    tokenId: 6408,
    Tier: "Basic",
  },
  {
    tokenId: 6409,
    Tier: "Basic",
  },
  {
    tokenId: 6410,
    Tier: "Basic",
  },
  {
    tokenId: 6411,
    Tier: "Epic",
  },
  {
    tokenId: 6412,
    Tier: "Basic",
  },
  {
    tokenId: 6413,
    Tier: "Legendary",
  },
  {
    tokenId: 6414,
    Tier: "Basic",
  },
  {
    tokenId: 6415,
    Tier: "Basic",
  },
  {
    tokenId: 6416,
    Tier: "Epic",
  },
  {
    tokenId: 6417,
    Tier: "Basic",
  },
  {
    tokenId: 6418,
    Tier: "Rare",
  },
  {
    tokenId: 6419,
    Tier: "Mythic",
  },
  {
    tokenId: 6420,
    Tier: "Basic",
  },
  {
    tokenId: 6421,
    Tier: "Basic",
  },
  {
    tokenId: 6422,
    Tier: "Common",
  },
  {
    tokenId: 6423,
    Tier: "Rare",
  },
  {
    tokenId: 6424,
    Tier: "Common",
  },
  {
    tokenId: 6425,
    Tier: "Uncommon",
  },
  {
    tokenId: 6426,
    Tier: "Uncommon",
  },
  {
    tokenId: 6427,
    Tier: "Basic",
  },
  {
    tokenId: 6428,
    Tier: "Epic",
  },
  {
    tokenId: 6429,
    Tier: "Basic",
  },
  {
    tokenId: 6430,
    Tier: "Basic",
  },
  {
    tokenId: 6431,
    Tier: "Uncommon",
  },
  {
    tokenId: 6432,
    Tier: "Epic",
  },
  {
    tokenId: 6433,
    Tier: "Basic",
  },
  {
    tokenId: 6434,
    Tier: "Basic",
  },
  {
    tokenId: 6435,
    Tier: "Basic",
  },
  {
    tokenId: 6436,
    Tier: "Basic",
  },
  {
    tokenId: 6437,
    Tier: "Basic",
  },
  {
    tokenId: 6438,
    Tier: "Basic",
  },
  {
    tokenId: 6439,
    Tier: "Uncommon",
  },
  {
    tokenId: 6440,
    Tier: "Basic",
  },
  {
    tokenId: 6441,
    Tier: "Basic",
  },
  {
    tokenId: 6442,
    Tier: "Basic",
  },
  {
    tokenId: 6443,
    Tier: "Rare",
  },
  {
    tokenId: 6444,
    Tier: "Legendary",
  },
  {
    tokenId: 6445,
    Tier: "Basic",
  },
  {
    tokenId: 6446,
    Tier: "Uncommon",
  },
  {
    tokenId: 6447,
    Tier: "Basic",
  },
  {
    tokenId: 6448,
    Tier: "Mythic",
  },
  {
    tokenId: 6449,
    Tier: "Basic",
  },
  {
    tokenId: 6450,
    Tier: "Basic",
  },
  {
    tokenId: 6451,
    Tier: "Basic",
  },
  {
    tokenId: 6452,
    Tier: "Basic",
  },
  {
    tokenId: 6453,
    Tier: "Basic",
  },
  {
    tokenId: 6454,
    Tier: "Basic",
  },
  {
    tokenId: 6455,
    Tier: "Basic",
  },
  {
    tokenId: 6456,
    Tier: "Uncommon",
  },
  {
    tokenId: 6457,
    Tier: "Common",
  },
  {
    tokenId: 6458,
    Tier: "Basic",
  },
  {
    tokenId: 6459,
    Tier: "Rare",
  },
  {
    tokenId: 6460,
    Tier: "Legendary",
  },
  {
    tokenId: 6461,
    Tier: "Mythic",
  },
  {
    tokenId: 6462,
    Tier: "Common",
  },
  {
    tokenId: 6463,
    Tier: "Basic",
  },
  {
    tokenId: 6464,
    Tier: "Uncommon",
  },
  {
    tokenId: 6465,
    Tier: "Basic",
  },
  {
    tokenId: 6466,
    Tier: "Basic",
  },
  {
    tokenId: 6467,
    Tier: "Basic",
  },
  {
    tokenId: 6468,
    Tier: "Uncommon",
  },
  {
    tokenId: 6469,
    Tier: "Mythic",
  },
  {
    tokenId: 6470,
    Tier: "Basic",
  },
  {
    tokenId: 6471,
    Tier: "Basic",
  },
  {
    tokenId: 6472,
    Tier: "Basic",
  },
  {
    tokenId: 6473,
    Tier: "Basic",
  },
  {
    tokenId: 6474,
    Tier: "Common",
  },
  {
    tokenId: 6475,
    Tier: "Common",
  },
  {
    tokenId: 6476,
    Tier: "Rare",
  },
  {
    tokenId: 6477,
    Tier: "Basic",
  },
  {
    tokenId: 6478,
    Tier: "Basic",
  },
  {
    tokenId: 6479,
    Tier: "Basic",
  },
  {
    tokenId: 6480,
    Tier: "Legendary",
  },
  {
    tokenId: 6481,
    Tier: "Epic",
  },
  {
    tokenId: 6482,
    Tier: "Legendary",
  },
  {
    tokenId: 6483,
    Tier: "Common",
  },
  {
    tokenId: 6484,
    Tier: "Basic",
  },
  {
    tokenId: 6485,
    Tier: "Rare",
  },
  {
    tokenId: 6486,
    Tier: "Common",
  },
  {
    tokenId: 6487,
    Tier: "Basic",
  },
  {
    tokenId: 6488,
    Tier: "Common",
  },
  {
    tokenId: 6489,
    Tier: "Basic",
  },
  {
    tokenId: 6490,
    Tier: "Common",
  },
  {
    tokenId: 6491,
    Tier: "Basic",
  },
  {
    tokenId: 6492,
    Tier: "Basic",
  },
  {
    tokenId: 6493,
    Tier: "Basic",
  },
  {
    tokenId: 6494,
    Tier: "Basic",
  },
  {
    tokenId: 6495,
    Tier: "Rare",
  },
  {
    tokenId: 6496,
    Tier: "Uncommon",
  },
  {
    tokenId: 6497,
    Tier: "Uncommon",
  },
  {
    tokenId: 6498,
    Tier: "Basic",
  },
  {
    tokenId: 6499,
    Tier: "Common",
  },
  {
    tokenId: 6500,
    Tier: "Basic",
  },
  {
    tokenId: 6501,
    Tier: "Basic",
  },
  {
    tokenId: 6502,
    Tier: "Basic",
  },
  {
    tokenId: 6503,
    Tier: "Epic",
  },
  {
    tokenId: 6504,
    Tier: "Uncommon",
  },
  {
    tokenId: 6505,
    Tier: "Basic",
  },
  {
    tokenId: 6506,
    Tier: "Rare",
  },
  {
    tokenId: 6507,
    Tier: "Uncommon",
  },
  {
    tokenId: 6508,
    Tier: "Basic",
  },
  {
    tokenId: 6509,
    Tier: "Uncommon",
  },
  {
    tokenId: 6510,
    Tier: "Basic",
  },
  {
    tokenId: 6511,
    Tier: "Basic",
  },
  {
    tokenId: 6512,
    Tier: "Basic",
  },
  {
    tokenId: 6513,
    Tier: "Common",
  },
  {
    tokenId: 6514,
    Tier: "Basic",
  },
  {
    tokenId: 6515,
    Tier: "Basic",
  },
  {
    tokenId: 6516,
    Tier: "Basic",
  },
  {
    tokenId: 6517,
    Tier: "Basic",
  },
  {
    tokenId: 6518,
    Tier: "Basic",
  },
  {
    tokenId: 6519,
    Tier: "Basic",
  },
  {
    tokenId: 6520,
    Tier: "Uncommon",
  },
  {
    tokenId: 6521,
    Tier: "Basic",
  },
  {
    tokenId: 6522,
    Tier: "Basic",
  },
  {
    tokenId: 6523,
    Tier: "Basic",
  },
  {
    tokenId: 6524,
    Tier: "Legendary",
  },
  {
    tokenId: 6525,
    Tier: "Uncommon",
  },
  {
    tokenId: 6526,
    Tier: "Basic",
  },
  {
    tokenId: 6527,
    Tier: "Uncommon",
  },
  {
    tokenId: 6528,
    Tier: "Legendary",
  },
  {
    tokenId: 6529,
    Tier: "Rare",
  },
  {
    tokenId: 6530,
    Tier: "Uncommon",
  },
  {
    tokenId: 6531,
    Tier: "Basic",
  },
  {
    tokenId: 6532,
    Tier: "Basic",
  },
  {
    tokenId: 6533,
    Tier: "Common",
  },
  {
    tokenId: 6534,
    Tier: "Basic",
  },
  {
    tokenId: 6535,
    Tier: "Common",
  },
  {
    tokenId: 6536,
    Tier: "Basic",
  },
  {
    tokenId: 6537,
    Tier: "Basic",
  },
  {
    tokenId: 6538,
    Tier: "Basic",
  },
  {
    tokenId: 6539,
    Tier: "Common",
  },
  {
    tokenId: 6540,
    Tier: "Uncommon",
  },
  {
    tokenId: 6541,
    Tier: "Basic",
  },
  {
    tokenId: 6542,
    Tier: "Uncommon",
  },
  {
    tokenId: 6543,
    Tier: "Legendary",
  },
  {
    tokenId: 6544,
    Tier: "Common",
  },
  {
    tokenId: 6545,
    Tier: "Legendary",
  },
  {
    tokenId: 6546,
    Tier: "Basic",
  },
  {
    tokenId: 6547,
    Tier: "Basic",
  },
  {
    tokenId: 6548,
    Tier: "Basic",
  },
  {
    tokenId: 6549,
    Tier: "Basic",
  },
  {
    tokenId: 6550,
    Tier: "Basic",
  },
  {
    tokenId: 6551,
    Tier: "Basic",
  },
  {
    tokenId: 6552,
    Tier: "Uncommon",
  },
  {
    tokenId: 6553,
    Tier: "Common",
  },
  {
    tokenId: 6554,
    Tier: "Epic",
  },
  {
    tokenId: 6555,
    Tier: "Basic",
  },
  {
    tokenId: 6556,
    Tier: "Basic",
  },
  {
    tokenId: 6557,
    Tier: "Basic",
  },
  {
    tokenId: 6558,
    Tier: "Basic",
  },
  {
    tokenId: 6559,
    Tier: "Uncommon",
  },
  {
    tokenId: 6560,
    Tier: "Basic",
  },
  {
    tokenId: 6561,
    Tier: "Basic",
  },
  {
    tokenId: 6562,
    Tier: "Rare",
  },
  {
    tokenId: 6563,
    Tier: "Legendary",
  },
  {
    tokenId: 6564,
    Tier: "Mythic",
  },
  {
    tokenId: 6565,
    Tier: "Basic",
  },
  {
    tokenId: 6566,
    Tier: "Rare",
  },
  {
    tokenId: 6567,
    Tier: "Uncommon",
  },
  {
    tokenId: 6568,
    Tier: "Basic",
  },
  {
    tokenId: 6569,
    Tier: "Basic",
  },
  {
    tokenId: 6570,
    Tier: "Basic",
  },
  {
    tokenId: 6571,
    Tier: "Rare",
  },
  {
    tokenId: 6572,
    Tier: "Legendary",
  },
  {
    tokenId: 6573,
    Tier: "Basic",
  },
  {
    tokenId: 6574,
    Tier: "Common",
  },
  {
    tokenId: 6575,
    Tier: "Basic",
  },
  {
    tokenId: 6576,
    Tier: "Exotic",
  },
  {
    tokenId: 6577,
    Tier: "Basic",
  },
  {
    tokenId: 6578,
    Tier: "Basic",
  },
  {
    tokenId: 6579,
    Tier: "Basic",
  },
  {
    tokenId: 6580,
    Tier: "Common",
  },
  {
    tokenId: 6581,
    Tier: "Basic",
  },
  {
    tokenId: 6582,
    Tier: "Epic",
  },
  {
    tokenId: 6583,
    Tier: "Basic",
  },
  {
    tokenId: 6584,
    Tier: "Common",
  },
  {
    tokenId: 6585,
    Tier: "Rare",
  },
  {
    tokenId: 6586,
    Tier: "Rare",
  },
  {
    tokenId: 6587,
    Tier: "Uncommon",
  },
  {
    tokenId: 6588,
    Tier: "Legendary",
  },
  {
    tokenId: 6589,
    Tier: "Uncommon",
  },
  {
    tokenId: 6590,
    Tier: "Basic",
  },
  {
    tokenId: 6591,
    Tier: "Uncommon",
  },
  {
    tokenId: 6592,
    Tier: "Basic",
  },
  {
    tokenId: 6593,
    Tier: "Rare",
  },
  {
    tokenId: 6594,
    Tier: "Common",
  },
  {
    tokenId: 6595,
    Tier: "Basic",
  },
  {
    tokenId: 6596,
    Tier: "Basic",
  },
  {
    tokenId: 6597,
    Tier: "Uncommon",
  },
  {
    tokenId: 6598,
    Tier: "Common",
  },
  {
    tokenId: 6599,
    Tier: "Rare",
  },
  {
    tokenId: 6600,
    Tier: "Basic",
  },
  {
    tokenId: 6601,
    Tier: "Rare",
  },
  {
    tokenId: 6602,
    Tier: "Common",
  },
  {
    tokenId: 6603,
    Tier: "Basic",
  },
  {
    tokenId: 6604,
    Tier: "Uncommon",
  },
  {
    tokenId: 6605,
    Tier: "Common",
  },
  {
    tokenId: 6606,
    Tier: "Uncommon",
  },
  {
    tokenId: 6607,
    Tier: "Basic",
  },
  {
    tokenId: 6608,
    Tier: "Basic",
  },
  {
    tokenId: 6609,
    Tier: "Basic",
  },
  {
    tokenId: 6610,
    Tier: "Basic",
  },
  {
    tokenId: 6611,
    Tier: "Basic",
  },
  {
    tokenId: 6612,
    Tier: "Mythic",
  },
  {
    tokenId: 6613,
    Tier: "Rare",
  },
  {
    tokenId: 6614,
    Tier: "Uncommon",
  },
  {
    tokenId: 6615,
    Tier: "Uncommon",
  },
  {
    tokenId: 6616,
    Tier: "Epic",
  },
  {
    tokenId: 6617,
    Tier: "Basic",
  },
  {
    tokenId: 6618,
    Tier: "Uncommon",
  },
  {
    tokenId: 6619,
    Tier: "Rare",
  },
  {
    tokenId: 6620,
    Tier: "Basic",
  },
  {
    tokenId: 6621,
    Tier: "Rare",
  },
  {
    tokenId: 6622,
    Tier: "Common",
  },
  {
    tokenId: 6623,
    Tier: "Mythic",
  },
  {
    tokenId: 6624,
    Tier: "Basic",
  },
  {
    tokenId: 6625,
    Tier: "Basic",
  },
  {
    tokenId: 6626,
    Tier: "Rare",
  },
  {
    tokenId: 6627,
    Tier: "Uncommon",
  },
  {
    tokenId: 6628,
    Tier: "Basic",
  },
  {
    tokenId: 6629,
    Tier: "Basic",
  },
  {
    tokenId: 6630,
    Tier: "Uncommon",
  },
  {
    tokenId: 6631,
    Tier: "Uncommon",
  },
  {
    tokenId: 6632,
    Tier: "Basic",
  },
  {
    tokenId: 6633,
    Tier: "Uncommon",
  },
  {
    tokenId: 6634,
    Tier: "Basic",
  },
  {
    tokenId: 6635,
    Tier: "Basic",
  },
  {
    tokenId: 6636,
    Tier: "Basic",
  },
  {
    tokenId: 6637,
    Tier: "Uncommon",
  },
  {
    tokenId: 6638,
    Tier: "Basic",
  },
  {
    tokenId: 6639,
    Tier: "Uncommon",
  },
  {
    tokenId: 6640,
    Tier: "Basic",
  },
  {
    tokenId: 6641,
    Tier: "Legendary",
  },
  {
    tokenId: 6642,
    Tier: "Basic",
  },
  {
    tokenId: 6643,
    Tier: "Basic",
  },
  {
    tokenId: 6644,
    Tier: "Mythic",
  },
  {
    tokenId: 6645,
    Tier: "Uncommon",
  },
  {
    tokenId: 6646,
    Tier: "Basic",
  },
  {
    tokenId: 6647,
    Tier: "Basic",
  },
  {
    tokenId: 6648,
    Tier: "Legendary",
  },
  {
    tokenId: 6649,
    Tier: "Basic",
  },
  {
    tokenId: 6650,
    Tier: "Basic",
  },
  {
    tokenId: 6651,
    Tier: "Uncommon",
  },
  {
    tokenId: 6652,
    Tier: "Legendary",
  },
  {
    tokenId: 6653,
    Tier: "Basic",
  },
  {
    tokenId: 6654,
    Tier: "Basic",
  },
  {
    tokenId: 6655,
    Tier: "Basic",
  },
  {
    tokenId: 6656,
    Tier: "Epic",
  },
  {
    tokenId: 6657,
    Tier: "Basic",
  },
  {
    tokenId: 6658,
    Tier: "Basic",
  },
  {
    tokenId: 6659,
    Tier: "Common",
  },
  {
    tokenId: 6660,
    Tier: "Basic",
  },
  {
    tokenId: 6661,
    Tier: "Uncommon",
  },
  {
    tokenId: 6662,
    Tier: "Uncommon",
  },
  {
    tokenId: 6663,
    Tier: "Uncommon",
  },
  {
    tokenId: 6664,
    Tier: "Epic",
  },
  {
    tokenId: 6665,
    Tier: "Basic",
  },
  {
    tokenId: 6666,
    Tier: "Uncommon",
  },
  {
    tokenId: 6667,
    Tier: "Basic",
  },
  {
    tokenId: 6668,
    Tier: "Common",
  },
  {
    tokenId: 6669,
    Tier: "Basic",
  },
  {
    tokenId: 6670,
    Tier: "Basic",
  },
  {
    tokenId: 6671,
    Tier: "Mythic",
  },
  {
    tokenId: 6672,
    Tier: "Rare",
  },
  {
    tokenId: 6673,
    Tier: "Mythic",
  },
  {
    tokenId: 6674,
    Tier: "Epic",
  },
  {
    tokenId: 6675,
    Tier: "Basic",
  },
  {
    tokenId: 6676,
    Tier: "Common",
  },
  {
    tokenId: 6677,
    Tier: "Legendary",
  },
  {
    tokenId: 6678,
    Tier: "Basic",
  },
  {
    tokenId: 6679,
    Tier: "Rare",
  },
  {
    tokenId: 6680,
    Tier: "Uncommon",
  },
  {
    tokenId: 6681,
    Tier: "Common",
  },
  {
    tokenId: 6682,
    Tier: "Basic",
  },
  {
    tokenId: 6683,
    Tier: "Basic",
  },
  {
    tokenId: 6684,
    Tier: "Rare",
  },
  {
    tokenId: 6685,
    Tier: "Common",
  },
  {
    tokenId: 6686,
    Tier: "Rare",
  },
  {
    tokenId: 6687,
    Tier: "Common",
  },
  {
    tokenId: 6688,
    Tier: "Basic",
  },
  {
    tokenId: 6689,
    Tier: "Common",
  },
  {
    tokenId: 6690,
    Tier: "Uncommon",
  },
  {
    tokenId: 6691,
    Tier: "Basic",
  },
  {
    tokenId: 6692,
    Tier: "Basic",
  },
  {
    tokenId: 6693,
    Tier: "Common",
  },
  {
    tokenId: 6694,
    Tier: "Basic",
  },
  {
    tokenId: 6695,
    Tier: "Common",
  },
  {
    tokenId: 6696,
    Tier: "Basic",
  },
  {
    tokenId: 6697,
    Tier: "Basic",
  },
  {
    tokenId: 6698,
    Tier: "Uncommon",
  },
  {
    tokenId: 6699,
    Tier: "Basic",
  },
  {
    tokenId: 6700,
    Tier: "Uncommon",
  },
  {
    tokenId: 6701,
    Tier: "Mythic",
  },
  {
    tokenId: 6702,
    Tier: "Basic",
  },
  {
    tokenId: 6703,
    Tier: "Basic",
  },
  {
    tokenId: 6704,
    Tier: "Basic",
  },
  {
    tokenId: 6705,
    Tier: "Legendary",
  },
  {
    tokenId: 6706,
    Tier: "Basic",
  },
  {
    tokenId: 6707,
    Tier: "Mythic",
  },
  {
    tokenId: 6708,
    Tier: "Common",
  },
  {
    tokenId: 6709,
    Tier: "Basic",
  },
  {
    tokenId: 6710,
    Tier: "Basic",
  },
  {
    tokenId: 6711,
    Tier: "Basic",
  },
  {
    tokenId: 6712,
    Tier: "Common",
  },
  {
    tokenId: 6713,
    Tier: "Basic",
  },
  {
    tokenId: 6714,
    Tier: "Rare",
  },
  {
    tokenId: 6715,
    Tier: "Basic",
  },
  {
    tokenId: 6716,
    Tier: "Rare",
  },
  {
    tokenId: 6717,
    Tier: "Basic",
  },
  {
    tokenId: 6718,
    Tier: "Legendary",
  },
  {
    tokenId: 6719,
    Tier: "Basic",
  },
  {
    tokenId: 6720,
    Tier: "Basic",
  },
  {
    tokenId: 6721,
    Tier: "Basic",
  },
  {
    tokenId: 6722,
    Tier: "Basic",
  },
  {
    tokenId: 6723,
    Tier: "Basic",
  },
  {
    tokenId: 6724,
    Tier: "Basic",
  },
  {
    tokenId: 6725,
    Tier: "Basic",
  },
  {
    tokenId: 6726,
    Tier: "Basic",
  },
  {
    tokenId: 6727,
    Tier: "Legendary",
  },
  {
    tokenId: 6728,
    Tier: "Common",
  },
  {
    tokenId: 6729,
    Tier: "Common",
  },
  {
    tokenId: 6730,
    Tier: "Epic",
  },
  {
    tokenId: 6731,
    Tier: "Common",
  },
  {
    tokenId: 6732,
    Tier: "Basic",
  },
  {
    tokenId: 6733,
    Tier: "Epic",
  },
  {
    tokenId: 6734,
    Tier: "Legendary",
  },
  {
    tokenId: 6735,
    Tier: "Common",
  },
  {
    tokenId: 6736,
    Tier: "Basic",
  },
  {
    tokenId: 6737,
    Tier: "Basic",
  },
  {
    tokenId: 6738,
    Tier: "Rare",
  },
  {
    tokenId: 6739,
    Tier: "Rare",
  },
  {
    tokenId: 6740,
    Tier: "Uncommon",
  },
  {
    tokenId: 6741,
    Tier: "Common",
  },
  {
    tokenId: 6742,
    Tier: "Common",
  },
  {
    tokenId: 6743,
    Tier: "Uncommon",
  },
  {
    tokenId: 6744,
    Tier: "Basic",
  },
  {
    tokenId: 6745,
    Tier: "Basic",
  },
  {
    tokenId: 6746,
    Tier: "Common",
  },
  {
    tokenId: 6747,
    Tier: "Common",
  },
  {
    tokenId: 6748,
    Tier: "Basic",
  },
  {
    tokenId: 6749,
    Tier: "Basic",
  },
  {
    tokenId: 6750,
    Tier: "Rare",
  },
  {
    tokenId: 6751,
    Tier: "Basic",
  },
  {
    tokenId: 6752,
    Tier: "Uncommon",
  },
  {
    tokenId: 6753,
    Tier: "Uncommon",
  },
  {
    tokenId: 6754,
    Tier: "Rare",
  },
  {
    tokenId: 6755,
    Tier: "Epic",
  },
  {
    tokenId: 6756,
    Tier: "Basic",
  },
  {
    tokenId: 6757,
    Tier: "Uncommon",
  },
  {
    tokenId: 6758,
    Tier: "Rare",
  },
  {
    tokenId: 6759,
    Tier: "Basic",
  },
  {
    tokenId: 6760,
    Tier: "Basic",
  },
  {
    tokenId: 6761,
    Tier: "Exotic",
  },
  {
    tokenId: 6762,
    Tier: "Basic",
  },
  {
    tokenId: 6763,
    Tier: "Basic",
  },
  {
    tokenId: 6764,
    Tier: "Basic",
  },
  {
    tokenId: 6765,
    Tier: "Rare",
  },
  {
    tokenId: 6766,
    Tier: "Basic",
  },
  {
    tokenId: 6767,
    Tier: "Mythic",
  },
  {
    tokenId: 6768,
    Tier: "Basic",
  },
  {
    tokenId: 6769,
    Tier: "Basic",
  },
  {
    tokenId: 6770,
    Tier: "Mythic",
  },
  {
    tokenId: 6771,
    Tier: "Basic",
  },
  {
    tokenId: 6772,
    Tier: "Basic",
  },
  {
    tokenId: 6773,
    Tier: "Basic",
  },
  {
    tokenId: 6774,
    Tier: "Uncommon",
  },
  {
    tokenId: 6775,
    Tier: "Epic",
  },
  {
    tokenId: 6776,
    Tier: "Basic",
  },
  {
    tokenId: 6777,
    Tier: "Basic",
  },
  {
    tokenId: 6778,
    Tier: "Basic",
  },
  {
    tokenId: 6779,
    Tier: "Basic",
  },
  {
    tokenId: 6780,
    Tier: "Basic",
  },
  {
    tokenId: 6781,
    Tier: "Epic",
  },
  {
    tokenId: 6782,
    Tier: "Basic",
  },
  {
    tokenId: 6783,
    Tier: "Uncommon",
  },
  {
    tokenId: 6784,
    Tier: "Basic",
  },
  {
    tokenId: 6785,
    Tier: "Basic",
  },
  {
    tokenId: 6786,
    Tier: "Rare",
  },
  {
    tokenId: 6787,
    Tier: "Basic",
  },
  {
    tokenId: 6788,
    Tier: "Uncommon",
  },
  {
    tokenId: 6789,
    Tier: "Rare",
  },
  {
    tokenId: 6790,
    Tier: "Basic",
  },
  {
    tokenId: 6791,
    Tier: "Basic",
  },
  {
    tokenId: 6792,
    Tier: "Basic",
  },
  {
    tokenId: 6793,
    Tier: "Epic",
  },
  {
    tokenId: 6794,
    Tier: "Common",
  },
  {
    tokenId: 6795,
    Tier: "Basic",
  },
  {
    tokenId: 6796,
    Tier: "Basic",
  },
  {
    tokenId: 6797,
    Tier: "Basic",
  },
  {
    tokenId: 6798,
    Tier: "Basic",
  },
  {
    tokenId: 6799,
    Tier: "Legendary",
  },
  {
    tokenId: 6800,
    Tier: "Epic",
  },
  {
    tokenId: 6801,
    Tier: "Basic",
  },
  {
    tokenId: 6802,
    Tier: "Common",
  },
  {
    tokenId: 6803,
    Tier: "Basic",
  },
  {
    tokenId: 6804,
    Tier: "Basic",
  },
  {
    tokenId: 6805,
    Tier: "Basic",
  },
  {
    tokenId: 6806,
    Tier: "Basic",
  },
  {
    tokenId: 6807,
    Tier: "Basic",
  },
  {
    tokenId: 6808,
    Tier: "Common",
  },
  {
    tokenId: 6809,
    Tier: "Basic",
  },
  {
    tokenId: 6810,
    Tier: "Basic",
  },
  {
    tokenId: 6811,
    Tier: "Basic",
  },
  {
    tokenId: 6812,
    Tier: "Legendary",
  },
  {
    tokenId: 6813,
    Tier: "Common",
  },
  {
    tokenId: 6814,
    Tier: "Uncommon",
  },
  {
    tokenId: 6815,
    Tier: "Mythic",
  },
  {
    tokenId: 6816,
    Tier: "Legendary",
  },
  {
    tokenId: 6817,
    Tier: "Common",
  },
  {
    tokenId: 6818,
    Tier: "Epic",
  },
  {
    tokenId: 6819,
    Tier: "Exotic",
  },
  {
    tokenId: 6820,
    Tier: "Uncommon",
  },
  {
    tokenId: 6821,
    Tier: "Basic",
  },
  {
    tokenId: 6822,
    Tier: "Basic",
  },
  {
    tokenId: 6823,
    Tier: "Epic",
  },
  {
    tokenId: 6824,
    Tier: "Common",
  },
  {
    tokenId: 6825,
    Tier: "Rare",
  },
  {
    tokenId: 6826,
    Tier: "Basic",
  },
  {
    tokenId: 6827,
    Tier: "Basic",
  },
  {
    tokenId: 6828,
    Tier: "Basic",
  },
  {
    tokenId: 6829,
    Tier: "Basic",
  },
  {
    tokenId: 6830,
    Tier: "Rare",
  },
  {
    tokenId: 6831,
    Tier: "Rare",
  },
  {
    tokenId: 6832,
    Tier: "Rare",
  },
  {
    tokenId: 6833,
    Tier: "Epic",
  },
  {
    tokenId: 6834,
    Tier: "Mythic",
  },
  {
    tokenId: 6835,
    Tier: "Rare",
  },
  {
    tokenId: 6836,
    Tier: "Basic",
  },
  {
    tokenId: 6837,
    Tier: "Basic",
  },
  {
    tokenId: 6838,
    Tier: "Basic",
  },
  {
    tokenId: 6839,
    Tier: "Basic",
  },
  {
    tokenId: 6840,
    Tier: "Basic",
  },
  {
    tokenId: 6841,
    Tier: "Basic",
  },
  {
    tokenId: 6842,
    Tier: "Legendary",
  },
  {
    tokenId: 6843,
    Tier: "Basic",
  },
  {
    tokenId: 6844,
    Tier: "Uncommon",
  },
  {
    tokenId: 6845,
    Tier: "Rare",
  },
  {
    tokenId: 6846,
    Tier: "Legendary",
  },
  {
    tokenId: 6847,
    Tier: "Uncommon",
  },
  {
    tokenId: 6848,
    Tier: "Uncommon",
  },
  {
    tokenId: 6849,
    Tier: "Basic",
  },
  {
    tokenId: 6850,
    Tier: "Basic",
  },
  {
    tokenId: 6851,
    Tier: "Common",
  },
  {
    tokenId: 6852,
    Tier: "Basic",
  },
  {
    tokenId: 6853,
    Tier: "Basic",
  },
  {
    tokenId: 6854,
    Tier: "Basic",
  },
  {
    tokenId: 6855,
    Tier: "Basic",
  },
  {
    tokenId: 6856,
    Tier: "Common",
  },
  {
    tokenId: 6857,
    Tier: "Basic",
  },
  {
    tokenId: 6858,
    Tier: "Rare",
  },
  {
    tokenId: 6859,
    Tier: "Basic",
  },
  {
    tokenId: 6860,
    Tier: "Common",
  },
  {
    tokenId: 6861,
    Tier: "Uncommon",
  },
  {
    tokenId: 6862,
    Tier: "Basic",
  },
  {
    tokenId: 6863,
    Tier: "Basic",
  },
  {
    tokenId: 6864,
    Tier: "Common",
  },
  {
    tokenId: 6865,
    Tier: "Common",
  },
  {
    tokenId: 6866,
    Tier: "Basic",
  },
  {
    tokenId: 6867,
    Tier: "Epic",
  },
  {
    tokenId: 6868,
    Tier: "Basic",
  },
  {
    tokenId: 6869,
    Tier: "Basic",
  },
  {
    tokenId: 6870,
    Tier: "Basic",
  },
  {
    tokenId: 6871,
    Tier: "Uncommon",
  },
  {
    tokenId: 6872,
    Tier: "Basic",
  },
  {
    tokenId: 6873,
    Tier: "Common",
  },
  {
    tokenId: 6874,
    Tier: "Basic",
  },
  {
    tokenId: 6875,
    Tier: "Basic",
  },
  {
    tokenId: 6876,
    Tier: "Uncommon",
  },
  {
    tokenId: 6877,
    Tier: "Common",
  },
  {
    tokenId: 6878,
    Tier: "Basic",
  },
  {
    tokenId: 6879,
    Tier: "Basic",
  },
  {
    tokenId: 6880,
    Tier: "Basic",
  },
  {
    tokenId: 6881,
    Tier: "Basic",
  },
  {
    tokenId: 6882,
    Tier: "Rare",
  },
  {
    tokenId: 6883,
    Tier: "Common",
  },
  {
    tokenId: 6884,
    Tier: "Basic",
  },
  {
    tokenId: 6885,
    Tier: "Basic",
  },
  {
    tokenId: 6886,
    Tier: "Basic",
  },
  {
    tokenId: 6887,
    Tier: "Epic",
  },
  {
    tokenId: 6888,
    Tier: "Basic",
  },
  {
    tokenId: 6889,
    Tier: "Basic",
  },
  {
    tokenId: 6890,
    Tier: "Basic",
  },
  {
    tokenId: 6891,
    Tier: "Basic",
  },
  {
    tokenId: 6892,
    Tier: "Common",
  },
  {
    tokenId: 6893,
    Tier: "Basic",
  },
  {
    tokenId: 6894,
    Tier: "Common",
  },
  {
    tokenId: 6895,
    Tier: "Mythic",
  },
  {
    tokenId: 6896,
    Tier: "Basic",
  },
  {
    tokenId: 6897,
    Tier: "Basic",
  },
  {
    tokenId: 6898,
    Tier: "Rare",
  },
  {
    tokenId: 6899,
    Tier: "Uncommon",
  },
  {
    tokenId: 6900,
    Tier: "Basic",
  },
  {
    tokenId: 6901,
    Tier: "Basic",
  },
  {
    tokenId: 6902,
    Tier: "Mythic",
  },
  {
    tokenId: 6903,
    Tier: "Basic",
  },
  {
    tokenId: 6904,
    Tier: "Legendary",
  },
  {
    tokenId: 6905,
    Tier: "Basic",
  },
  {
    tokenId: 6906,
    Tier: "Basic",
  },
  {
    tokenId: 6907,
    Tier: "Legendary",
  },
  {
    tokenId: 6908,
    Tier: "Mythic",
  },
  {
    tokenId: 6909,
    Tier: "Legendary",
  },
  {
    tokenId: 6910,
    Tier: "Uncommon",
  },
  {
    tokenId: 6911,
    Tier: "Uncommon",
  },
  {
    tokenId: 6912,
    Tier: "Rare",
  },
  {
    tokenId: 6913,
    Tier: "Legendary",
  },
  {
    tokenId: 6914,
    Tier: "Basic",
  },
  {
    tokenId: 6915,
    Tier: "Basic",
  },
  {
    tokenId: 6916,
    Tier: "Basic",
  },
  {
    tokenId: 6917,
    Tier: "Legendary",
  },
  {
    tokenId: 6918,
    Tier: "Rare",
  },
  {
    tokenId: 6919,
    Tier: "Legendary",
  },
  {
    tokenId: 6920,
    Tier: "Common",
  },
  {
    tokenId: 6921,
    Tier: "Epic",
  },
  {
    tokenId: 6922,
    Tier: "Common",
  },
  {
    tokenId: 6923,
    Tier: "Basic",
  },
  {
    tokenId: 6924,
    Tier: "Uncommon",
  },
  {
    tokenId: 6925,
    Tier: "Basic",
  },
  {
    tokenId: 6926,
    Tier: "Basic",
  },
  {
    tokenId: 6927,
    Tier: "Basic",
  },
  {
    tokenId: 6928,
    Tier: "Common",
  },
  {
    tokenId: 6929,
    Tier: "Basic",
  },
  {
    tokenId: 6930,
    Tier: "Common",
  },
  {
    tokenId: 6931,
    Tier: "Basic",
  },
  {
    tokenId: 6932,
    Tier: "Uncommon",
  },
  {
    tokenId: 6933,
    Tier: "Uncommon",
  },
  {
    tokenId: 6934,
    Tier: "Common",
  },
  {
    tokenId: 6935,
    Tier: "Basic",
  },
  {
    tokenId: 6936,
    Tier: "Basic",
  },
  {
    tokenId: 6937,
    Tier: "Rare",
  },
  {
    tokenId: 6938,
    Tier: "Uncommon",
  },
  {
    tokenId: 6939,
    Tier: "Uncommon",
  },
  {
    tokenId: 6940,
    Tier: "Basic",
  },
  {
    tokenId: 6941,
    Tier: "Common",
  },
  {
    tokenId: 6942,
    Tier: "Basic",
  },
  {
    tokenId: 6943,
    Tier: "Rare",
  },
  {
    tokenId: 6944,
    Tier: "Basic",
  },
  {
    tokenId: 6945,
    Tier: "Mythic",
  },
  {
    tokenId: 6946,
    Tier: "Basic",
  },
  {
    tokenId: 6947,
    Tier: "Epic",
  },
  {
    tokenId: 6948,
    Tier: "Basic",
  },
  {
    tokenId: 6949,
    Tier: "Basic",
  },
  {
    tokenId: 6950,
    Tier: "Basic",
  },
  {
    tokenId: 6951,
    Tier: "Basic",
  },
  {
    tokenId: 6952,
    Tier: "Basic",
  },
  {
    tokenId: 6953,
    Tier: "Basic",
  },
  {
    tokenId: 6954,
    Tier: "Basic",
  },
  {
    tokenId: 6955,
    Tier: "Rare",
  },
  {
    tokenId: 6956,
    Tier: "Basic",
  },
  {
    tokenId: 6957,
    Tier: "Common",
  },
  {
    tokenId: 6958,
    Tier: "Mythic",
  },
  {
    tokenId: 6959,
    Tier: "Mythic",
  },
  {
    tokenId: 6960,
    Tier: "Uncommon",
  },
  {
    tokenId: 6961,
    Tier: "Epic",
  },
  {
    tokenId: 6962,
    Tier: "Basic",
  },
  {
    tokenId: 6963,
    Tier: "Uncommon",
  },
  {
    tokenId: 6964,
    Tier: "Basic",
  },
  {
    tokenId: 6965,
    Tier: "Basic",
  },
  {
    tokenId: 6966,
    Tier: "Basic",
  },
  {
    tokenId: 6967,
    Tier: "Uncommon",
  },
  {
    tokenId: 6968,
    Tier: "Basic",
  },
  {
    tokenId: 6969,
    Tier: "Basic",
  },
  {
    tokenId: 6970,
    Tier: "Basic",
  },
  {
    tokenId: 6971,
    Tier: "Uncommon",
  },
  {
    tokenId: 6972,
    Tier: "Rare",
  },
  {
    tokenId: 6973,
    Tier: "Basic",
  },
  {
    tokenId: 6974,
    Tier: "Basic",
  },
  {
    tokenId: 6975,
    Tier: "Basic",
  },
  {
    tokenId: 6976,
    Tier: "Basic",
  },
  {
    tokenId: 6977,
    Tier: "Basic",
  },
  {
    tokenId: 6978,
    Tier: "Common",
  },
  {
    tokenId: 6979,
    Tier: "Basic",
  },
  {
    tokenId: 6980,
    Tier: "Rare",
  },
  {
    tokenId: 6981,
    Tier: "Uncommon",
  },
  {
    tokenId: 6982,
    Tier: "Basic",
  },
  {
    tokenId: 6983,
    Tier: "Basic",
  },
  {
    tokenId: 6984,
    Tier: "Uncommon",
  },
  {
    tokenId: 6985,
    Tier: "Basic",
  },
  {
    tokenId: 6986,
    Tier: "Basic",
  },
  {
    tokenId: 6987,
    Tier: "Rare",
  },
  {
    tokenId: 6988,
    Tier: "Legendary",
  },
  {
    tokenId: 6989,
    Tier: "Epic",
  },
  {
    tokenId: 6990,
    Tier: "Common",
  },
  {
    tokenId: 6991,
    Tier: "Basic",
  },
  {
    tokenId: 6992,
    Tier: "Basic",
  },
  {
    tokenId: 6993,
    Tier: "Basic",
  },
  {
    tokenId: 6994,
    Tier: "Basic",
  },
  {
    tokenId: 6995,
    Tier: "Uncommon",
  },
  {
    tokenId: 6996,
    Tier: "Basic",
  },
  {
    tokenId: 6997,
    Tier: "Basic",
  },
  {
    tokenId: 6998,
    Tier: "Basic",
  },
  {
    tokenId: 6999,
    Tier: "Basic",
  },
  {
    tokenId: 7000,
    Tier: "Uncommon",
  },
  {
    tokenId: 7001,
    Tier: "Basic",
  },
  {
    tokenId: 7002,
    Tier: "Rare",
  },
  {
    tokenId: 7003,
    Tier: "Basic",
  },
  {
    tokenId: 7004,
    Tier: "Basic",
  },
  {
    tokenId: 7005,
    Tier: "Basic",
  },
  {
    tokenId: 7006,
    Tier: "Common",
  },
  {
    tokenId: 7007,
    Tier: "Common",
  },
  {
    tokenId: 7008,
    Tier: "Basic",
  },
  {
    tokenId: 7009,
    Tier: "Epic",
  },
  {
    tokenId: 7010,
    Tier: "Common",
  },
  {
    tokenId: 7011,
    Tier: "Rare",
  },
  {
    tokenId: 7012,
    Tier: "Common",
  },
  {
    tokenId: 7013,
    Tier: "Legendary",
  },
  {
    tokenId: 7014,
    Tier: "Basic",
  },
  {
    tokenId: 7015,
    Tier: "Basic",
  },
  {
    tokenId: 7016,
    Tier: "Legendary",
  },
  {
    tokenId: 7017,
    Tier: "Uncommon",
  },
  {
    tokenId: 7018,
    Tier: "Epic",
  },
  {
    tokenId: 7019,
    Tier: "Exotic",
  },
  {
    tokenId: 7020,
    Tier: "Mythic",
  },
  {
    tokenId: 7021,
    Tier: "Basic",
  },
  {
    tokenId: 7022,
    Tier: "Basic",
  },
  {
    tokenId: 7023,
    Tier: "Uncommon",
  },
  {
    tokenId: 7024,
    Tier: "Basic",
  },
  {
    tokenId: 7025,
    Tier: "Basic",
  },
  {
    tokenId: 7026,
    Tier: "Basic",
  },
  {
    tokenId: 7027,
    Tier: "Basic",
  },
  {
    tokenId: 7028,
    Tier: "Basic",
  },
  {
    tokenId: 7029,
    Tier: "Common",
  },
  {
    tokenId: 7030,
    Tier: "Epic",
  },
  {
    tokenId: 7031,
    Tier: "Uncommon",
  },
  {
    tokenId: 7032,
    Tier: "Exotic",
  },
  {
    tokenId: 7033,
    Tier: "Epic",
  },
  {
    tokenId: 7034,
    Tier: "Basic",
  },
  {
    tokenId: 7035,
    Tier: "Basic",
  },
  {
    tokenId: 7036,
    Tier: "Mythic",
  },
  {
    tokenId: 7037,
    Tier: "Basic",
  },
  {
    tokenId: 7038,
    Tier: "Basic",
  },
  {
    tokenId: 7039,
    Tier: "Epic",
  },
  {
    tokenId: 7040,
    Tier: "Legendary",
  },
  {
    tokenId: 7041,
    Tier: "Basic",
  },
  {
    tokenId: 7042,
    Tier: "Rare",
  },
  {
    tokenId: 7043,
    Tier: "Basic",
  },
  {
    tokenId: 7044,
    Tier: "Basic",
  },
  {
    tokenId: 7045,
    Tier: "Basic",
  },
  {
    tokenId: 7046,
    Tier: "Basic",
  },
  {
    tokenId: 7047,
    Tier: "Uncommon",
  },
  {
    tokenId: 7048,
    Tier: "Basic",
  },
  {
    tokenId: 7049,
    Tier: "Uncommon",
  },
  {
    tokenId: 7050,
    Tier: "Basic",
  },
  {
    tokenId: 7051,
    Tier: "Basic",
  },
  {
    tokenId: 7052,
    Tier: "Epic",
  },
  {
    tokenId: 7053,
    Tier: "Basic",
  },
  {
    tokenId: 7054,
    Tier: "Basic",
  },
  {
    tokenId: 7055,
    Tier: "Basic",
  },
  {
    tokenId: 7056,
    Tier: "Basic",
  },
  {
    tokenId: 7057,
    Tier: "Basic",
  },
  {
    tokenId: 7058,
    Tier: "Basic",
  },
  {
    tokenId: 7059,
    Tier: "Mythic",
  },
  {
    tokenId: 7060,
    Tier: "Basic",
  },
  {
    tokenId: 7061,
    Tier: "Uncommon",
  },
  {
    tokenId: 7062,
    Tier: "Uncommon",
  },
  {
    tokenId: 7063,
    Tier: "Basic",
  },
  {
    tokenId: 7064,
    Tier: "Basic",
  },
  {
    tokenId: 7065,
    Tier: "Rare",
  },
  {
    tokenId: 7066,
    Tier: "Basic",
  },
  {
    tokenId: 7067,
    Tier: "Basic",
  },
  {
    tokenId: 7068,
    Tier: "Rare",
  },
  {
    tokenId: 7069,
    Tier: "Basic",
  },
  {
    tokenId: 7070,
    Tier: "Basic",
  },
  {
    tokenId: 7071,
    Tier: "Rare",
  },
  {
    tokenId: 7072,
    Tier: "Basic",
  },
  {
    tokenId: 7073,
    Tier: "Uncommon",
  },
  {
    tokenId: 7074,
    Tier: "Basic",
  },
  {
    tokenId: 7075,
    Tier: "Mythic",
  },
  {
    tokenId: 7076,
    Tier: "Basic",
  },
  {
    tokenId: 7077,
    Tier: "Legendary",
  },
  {
    tokenId: 7078,
    Tier: "Basic",
  },
  {
    tokenId: 7079,
    Tier: "Common",
  },
  {
    tokenId: 7080,
    Tier: "Common",
  },
  {
    tokenId: 7081,
    Tier: "Basic",
  },
  {
    tokenId: 7082,
    Tier: "Basic",
  },
  {
    tokenId: 7083,
    Tier: "Exotic",
  },
  {
    tokenId: 7084,
    Tier: "Basic",
  },
  {
    tokenId: 7085,
    Tier: "Basic",
  },
  {
    tokenId: 7086,
    Tier: "Basic",
  },
  {
    tokenId: 7087,
    Tier: "Rare",
  },
  {
    tokenId: 7088,
    Tier: "Basic",
  },
  {
    tokenId: 7089,
    Tier: "Basic",
  },
  {
    tokenId: 7090,
    Tier: "Uncommon",
  },
  {
    tokenId: 7091,
    Tier: "Uncommon",
  },
  {
    tokenId: 7092,
    Tier: "Legendary",
  },
  {
    tokenId: 7093,
    Tier: "Basic",
  },
  {
    tokenId: 7094,
    Tier: "Basic",
  },
  {
    tokenId: 7095,
    Tier: "Rare",
  },
  {
    tokenId: 7096,
    Tier: "Rare",
  },
  {
    tokenId: 7097,
    Tier: "Basic",
  },
  {
    tokenId: 7098,
    Tier: "Common",
  },
  {
    tokenId: 7099,
    Tier: "Basic",
  },
  {
    tokenId: 7100,
    Tier: "Basic",
  },
  {
    tokenId: 7101,
    Tier: "Legendary",
  },
  {
    tokenId: 7102,
    Tier: "Epic",
  },
  {
    tokenId: 7103,
    Tier: "Basic",
  },
  {
    tokenId: 7104,
    Tier: "Rare",
  },
  {
    tokenId: 7105,
    Tier: "Basic",
  },
  {
    tokenId: 7106,
    Tier: "Common",
  },
  {
    tokenId: 7107,
    Tier: "Rare",
  },
  {
    tokenId: 7108,
    Tier: "Basic",
  },
  {
    tokenId: 7109,
    Tier: "Basic",
  },
  {
    tokenId: 7110,
    Tier: "Mythic",
  },
  {
    tokenId: 7111,
    Tier: "Common",
  },
  {
    tokenId: 7112,
    Tier: "Legendary",
  },
  {
    tokenId: 7113,
    Tier: "Basic",
  },
  {
    tokenId: 7114,
    Tier: "Epic",
  },
  {
    tokenId: 7115,
    Tier: "Rare",
  },
  {
    tokenId: 7116,
    Tier: "Basic",
  },
  {
    tokenId: 7117,
    Tier: "Common",
  },
  {
    tokenId: 7118,
    Tier: "Common",
  },
  {
    tokenId: 7119,
    Tier: "Basic",
  },
  {
    tokenId: 7120,
    Tier: "Uncommon",
  },
  {
    tokenId: 7121,
    Tier: "Basic",
  },
  {
    tokenId: 7122,
    Tier: "Rare",
  },
  {
    tokenId: 7123,
    Tier: "Rare",
  },
  {
    tokenId: 7124,
    Tier: "Basic",
  },
  {
    tokenId: 7125,
    Tier: "Basic",
  },
  {
    tokenId: 7126,
    Tier: "Rare",
  },
  {
    tokenId: 7127,
    Tier: "Basic",
  },
  {
    tokenId: 7128,
    Tier: "Basic",
  },
  {
    tokenId: 7129,
    Tier: "Legendary",
  },
  {
    tokenId: 7130,
    Tier: "Uncommon",
  },
  {
    tokenId: 7131,
    Tier: "Basic",
  },
  {
    tokenId: 7132,
    Tier: "Basic",
  },
  {
    tokenId: 7133,
    Tier: "Basic",
  },
  {
    tokenId: 7134,
    Tier: "Epic",
  },
  {
    tokenId: 7135,
    Tier: "Basic",
  },
  {
    tokenId: 7136,
    Tier: "Basic",
  },
  {
    tokenId: 7137,
    Tier: "Rare",
  },
  {
    tokenId: 7138,
    Tier: "Basic",
  },
  {
    tokenId: 7139,
    Tier: "Basic",
  },
  {
    tokenId: 7140,
    Tier: "Rare",
  },
  {
    tokenId: 7141,
    Tier: "Uncommon",
  },
  {
    tokenId: 7142,
    Tier: "Legendary",
  },
  {
    tokenId: 7143,
    Tier: "Legendary",
  },
  {
    tokenId: 7144,
    Tier: "Basic",
  },
  {
    tokenId: 7145,
    Tier: "Basic",
  },
  {
    tokenId: 7146,
    Tier: "Exotic",
  },
  {
    tokenId: 7147,
    Tier: "Epic",
  },
  {
    tokenId: 7148,
    Tier: "Uncommon",
  },
  {
    tokenId: 7149,
    Tier: "Basic",
  },
  {
    tokenId: 7150,
    Tier: "Basic",
  },
  {
    tokenId: 7151,
    Tier: "Rare",
  },
  {
    tokenId: 7152,
    Tier: "Uncommon",
  },
  {
    tokenId: 7153,
    Tier: "Common",
  },
  {
    tokenId: 7154,
    Tier: "Basic",
  },
  {
    tokenId: 7155,
    Tier: "Uncommon",
  },
  {
    tokenId: 7156,
    Tier: "Basic",
  },
  {
    tokenId: 7157,
    Tier: "Basic",
  },
  {
    tokenId: 7158,
    Tier: "Rare",
  },
  {
    tokenId: 7159,
    Tier: "Basic",
  },
  {
    tokenId: 7160,
    Tier: "Basic",
  },
  {
    tokenId: 7161,
    Tier: "Common",
  },
  {
    tokenId: 7162,
    Tier: "Basic",
  },
  {
    tokenId: 7163,
    Tier: "Basic",
  },
  {
    tokenId: 7164,
    Tier: "Basic",
  },
  {
    tokenId: 7165,
    Tier: "Basic",
  },
  {
    tokenId: 7166,
    Tier: "Uncommon",
  },
  {
    tokenId: 7167,
    Tier: "Rare",
  },
  {
    tokenId: 7168,
    Tier: "Legendary",
  },
  {
    tokenId: 7169,
    Tier: "Basic",
  },
  {
    tokenId: 7170,
    Tier: "Basic",
  },
  {
    tokenId: 7171,
    Tier: "Basic",
  },
  {
    tokenId: 7172,
    Tier: "Basic",
  },
  {
    tokenId: 7173,
    Tier: "Legendary",
  },
  {
    tokenId: 7174,
    Tier: "Basic",
  },
  {
    tokenId: 7175,
    Tier: "Basic",
  },
  {
    tokenId: 7176,
    Tier: "Common",
  },
  {
    tokenId: 7177,
    Tier: "Mythic",
  },
  {
    tokenId: 7178,
    Tier: "Common",
  },
  {
    tokenId: 7179,
    Tier: "Legendary",
  },
  {
    tokenId: 7180,
    Tier: "Common",
  },
  {
    tokenId: 7181,
    Tier: "Uncommon",
  },
  {
    tokenId: 7182,
    Tier: "Basic",
  },
  {
    tokenId: 7183,
    Tier: "Basic",
  },
  {
    tokenId: 7184,
    Tier: "Basic",
  },
  {
    tokenId: 7185,
    Tier: "Basic",
  },
  {
    tokenId: 7186,
    Tier: "Basic",
  },
  {
    tokenId: 7187,
    Tier: "Basic",
  },
  {
    tokenId: 7188,
    Tier: "Basic",
  },
  {
    tokenId: 7189,
    Tier: "Basic",
  },
  {
    tokenId: 7190,
    Tier: "Basic",
  },
  {
    tokenId: 7191,
    Tier: "Epic",
  },
  {
    tokenId: 7192,
    Tier: "Rare",
  },
  {
    tokenId: 7193,
    Tier: "Basic",
  },
  {
    tokenId: 7194,
    Tier: "Basic",
  },
  {
    tokenId: 7195,
    Tier: "Basic",
  },
  {
    tokenId: 7196,
    Tier: "Rare",
  },
  {
    tokenId: 7197,
    Tier: "Basic",
  },
  {
    tokenId: 7198,
    Tier: "Basic",
  },
  {
    tokenId: 7199,
    Tier: "Legendary",
  },
  {
    tokenId: 7200,
    Tier: "Exotic",
  },
  {
    tokenId: 7201,
    Tier: "Rare",
  },
  {
    tokenId: 7202,
    Tier: "Basic",
  },
  {
    tokenId: 7203,
    Tier: "Basic",
  },
  {
    tokenId: 7204,
    Tier: "Uncommon",
  },
  {
    tokenId: 7205,
    Tier: "Basic",
  },
  {
    tokenId: 7206,
    Tier: "Basic",
  },
  {
    tokenId: 7207,
    Tier: "Uncommon",
  },
  {
    tokenId: 7208,
    Tier: "Basic",
  },
  {
    tokenId: 7209,
    Tier: "Uncommon",
  },
  {
    tokenId: 7210,
    Tier: "Uncommon",
  },
  {
    tokenId: 7211,
    Tier: "Basic",
  },
  {
    tokenId: 7212,
    Tier: "Basic",
  },
  {
    tokenId: 7213,
    Tier: "Epic",
  },
  {
    tokenId: 7214,
    Tier: "Legendary",
  },
  {
    tokenId: 7215,
    Tier: "Uncommon",
  },
  {
    tokenId: 7216,
    Tier: "Basic",
  },
  {
    tokenId: 7217,
    Tier: "Common",
  },
  {
    tokenId: 7218,
    Tier: "Basic",
  },
  {
    tokenId: 7219,
    Tier: "Basic",
  },
  {
    tokenId: 7220,
    Tier: "Uncommon",
  },
  {
    tokenId: 7221,
    Tier: "Basic",
  },
  {
    tokenId: 7222,
    Tier: "Common",
  },
  {
    tokenId: 7223,
    Tier: "Basic",
  },
  {
    tokenId: 7224,
    Tier: "Uncommon",
  },
  {
    tokenId: 7225,
    Tier: "Uncommon",
  },
  {
    tokenId: 7226,
    Tier: "Common",
  },
  {
    tokenId: 7227,
    Tier: "Basic",
  },
  {
    tokenId: 7228,
    Tier: "Common",
  },
  {
    tokenId: 7229,
    Tier: "Basic",
  },
  {
    tokenId: 7230,
    Tier: "Basic",
  },
  {
    tokenId: 7231,
    Tier: "Basic",
  },
  {
    tokenId: 7232,
    Tier: "Epic",
  },
  {
    tokenId: 7233,
    Tier: "Basic",
  },
  {
    tokenId: 7234,
    Tier: "Common",
  },
  {
    tokenId: 7235,
    Tier: "Basic",
  },
  {
    tokenId: 7236,
    Tier: "Basic",
  },
  {
    tokenId: 7237,
    Tier: "Basic",
  },
  {
    tokenId: 7238,
    Tier: "Basic",
  },
  {
    tokenId: 7239,
    Tier: "Basic",
  },
  {
    tokenId: 7240,
    Tier: "Common",
  },
  {
    tokenId: 7241,
    Tier: "Basic",
  },
  {
    tokenId: 7242,
    Tier: "Basic",
  },
  {
    tokenId: 7243,
    Tier: "Common",
  },
  {
    tokenId: 7244,
    Tier: "Basic",
  },
  {
    tokenId: 7245,
    Tier: "Rare",
  },
  {
    tokenId: 7246,
    Tier: "Rare",
  },
  {
    tokenId: 7247,
    Tier: "Basic",
  },
  {
    tokenId: 7248,
    Tier: "Rare",
  },
  {
    tokenId: 7249,
    Tier: "Basic",
  },
  {
    tokenId: 7250,
    Tier: "Uncommon",
  },
  {
    tokenId: 7251,
    Tier: "Basic",
  },
  {
    tokenId: 7252,
    Tier: "Uncommon",
  },
  {
    tokenId: 7253,
    Tier: "Basic",
  },
  {
    tokenId: 7254,
    Tier: "Mythic",
  },
  {
    tokenId: 7255,
    Tier: "Basic",
  },
  {
    tokenId: 7256,
    Tier: "Basic",
  },
  {
    tokenId: 7257,
    Tier: "Basic",
  },
  {
    tokenId: 7258,
    Tier: "Basic",
  },
  {
    tokenId: 7259,
    Tier: "Rare",
  },
  {
    tokenId: 7260,
    Tier: "Uncommon",
  },
  {
    tokenId: 7261,
    Tier: "Basic",
  },
  {
    tokenId: 7262,
    Tier: "Basic",
  },
  {
    tokenId: 7263,
    Tier: "Common",
  },
  {
    tokenId: 7264,
    Tier: "Uncommon",
  },
  {
    tokenId: 7265,
    Tier: "Common",
  },
  {
    tokenId: 7266,
    Tier: "Uncommon",
  },
  {
    tokenId: 7267,
    Tier: "Common",
  },
  {
    tokenId: 7268,
    Tier: "Uncommon",
  },
  {
    tokenId: 7269,
    Tier: "Legendary",
  },
  {
    tokenId: 7270,
    Tier: "Uncommon",
  },
  {
    tokenId: 7271,
    Tier: "Basic",
  },
  {
    tokenId: 7272,
    Tier: "Basic",
  },
  {
    tokenId: 7273,
    Tier: "Basic",
  },
  {
    tokenId: 7274,
    Tier: "Basic",
  },
  {
    tokenId: 7275,
    Tier: "Basic",
  },
  {
    tokenId: 7276,
    Tier: "Common",
  },
  {
    tokenId: 7277,
    Tier: "Legendary",
  },
  {
    tokenId: 7278,
    Tier: "Uncommon",
  },
  {
    tokenId: 7279,
    Tier: "Rare",
  },
  {
    tokenId: 7280,
    Tier: "Rare",
  },
  {
    tokenId: 7281,
    Tier: "Common",
  },
  {
    tokenId: 7282,
    Tier: "Basic",
  },
  {
    tokenId: 7283,
    Tier: "Basic",
  },
  {
    tokenId: 7284,
    Tier: "Basic",
  },
  {
    tokenId: 7285,
    Tier: "Legendary",
  },
  {
    tokenId: 7286,
    Tier: "Basic",
  },
  {
    tokenId: 7287,
    Tier: "Basic",
  },
  {
    tokenId: 7288,
    Tier: "Legendary",
  },
  {
    tokenId: 7289,
    Tier: "Mythic",
  },
  {
    tokenId: 7290,
    Tier: "Basic",
  },
  {
    tokenId: 7291,
    Tier: "Basic",
  },
  {
    tokenId: 7292,
    Tier: "Basic",
  },
  {
    tokenId: 7293,
    Tier: "Basic",
  },
  {
    tokenId: 7294,
    Tier: "Common",
  },
  {
    tokenId: 7295,
    Tier: "Basic",
  },
  {
    tokenId: 7296,
    Tier: "Basic",
  },
  {
    tokenId: 7297,
    Tier: "Basic",
  },
  {
    tokenId: 7298,
    Tier: "Common",
  },
  {
    tokenId: 7299,
    Tier: "Legendary",
  },
  {
    tokenId: 7300,
    Tier: "Epic",
  },
  {
    tokenId: 7301,
    Tier: "Basic",
  },
  {
    tokenId: 7302,
    Tier: "Basic",
  },
  {
    tokenId: 7303,
    Tier: "Rare",
  },
  {
    tokenId: 7304,
    Tier: "Rare",
  },
  {
    tokenId: 7305,
    Tier: "Basic",
  },
  {
    tokenId: 7306,
    Tier: "Basic",
  },
  {
    tokenId: 7307,
    Tier: "Basic",
  },
  {
    tokenId: 7308,
    Tier: "Basic",
  },
  {
    tokenId: 7309,
    Tier: "Rare",
  },
  {
    tokenId: 7310,
    Tier: "Basic",
  },
  {
    tokenId: 7311,
    Tier: "Basic",
  },
  {
    tokenId: 7312,
    Tier: "Common",
  },
  {
    tokenId: 7313,
    Tier: "Basic",
  },
  {
    tokenId: 7314,
    Tier: "Uncommon",
  },
  {
    tokenId: 7315,
    Tier: "Basic",
  },
  {
    tokenId: 7316,
    Tier: "Basic",
  },
  {
    tokenId: 7317,
    Tier: "Exotic",
  },
  {
    tokenId: 7318,
    Tier: "Basic",
  },
  {
    tokenId: 7319,
    Tier: "Basic",
  },
  {
    tokenId: 7320,
    Tier: "Common",
  },
  {
    tokenId: 7321,
    Tier: "Basic",
  },
  {
    tokenId: 7322,
    Tier: "Rare",
  },
  {
    tokenId: 7323,
    Tier: "Epic",
  },
  {
    tokenId: 7324,
    Tier: "Basic",
  },
  {
    tokenId: 7325,
    Tier: "Basic",
  },
  {
    tokenId: 7326,
    Tier: "Basic",
  },
  {
    tokenId: 7327,
    Tier: "Legendary",
  },
  {
    tokenId: 7328,
    Tier: "Mythic",
  },
  {
    tokenId: 7329,
    Tier: "Exotic",
  },
  {
    tokenId: 7330,
    Tier: "Epic",
  },
  {
    tokenId: 7331,
    Tier: "Rare",
  },
  {
    tokenId: 7332,
    Tier: "Basic",
  },
  {
    tokenId: 7333,
    Tier: "Basic",
  },
  {
    tokenId: 7334,
    Tier: "Mythic",
  },
  {
    tokenId: 7335,
    Tier: "Basic",
  },
  {
    tokenId: 7336,
    Tier: "Basic",
  },
  {
    tokenId: 7337,
    Tier: "Legendary",
  },
  {
    tokenId: 7338,
    Tier: "Uncommon",
  },
  {
    tokenId: 7339,
    Tier: "Rare",
  },
  {
    tokenId: 7340,
    Tier: "Basic",
  },
  {
    tokenId: 7341,
    Tier: "Uncommon",
  },
  {
    tokenId: 7342,
    Tier: "Uncommon",
  },
  {
    tokenId: 7343,
    Tier: "Basic",
  },
  {
    tokenId: 7344,
    Tier: "Basic",
  },
  {
    tokenId: 7345,
    Tier: "Common",
  },
  {
    tokenId: 7346,
    Tier: "Basic",
  },
  {
    tokenId: 7347,
    Tier: "Basic",
  },
  {
    tokenId: 7348,
    Tier: "Basic",
  },
  {
    tokenId: 7349,
    Tier: "Rare",
  },
  {
    tokenId: 7350,
    Tier: "Epic",
  },
  {
    tokenId: 7351,
    Tier: "Epic",
  },
  {
    tokenId: 7352,
    Tier: "Basic",
  },
  {
    tokenId: 7353,
    Tier: "Legendary",
  },
  {
    tokenId: 7354,
    Tier: "Rare",
  },
  {
    tokenId: 7355,
    Tier: "Common",
  },
  {
    tokenId: 7356,
    Tier: "Legendary",
  },
  {
    tokenId: 7357,
    Tier: "Basic",
  },
  {
    tokenId: 7358,
    Tier: "Common",
  },
  {
    tokenId: 7359,
    Tier: "Uncommon",
  },
  {
    tokenId: 7360,
    Tier: "Basic",
  },
  {
    tokenId: 7361,
    Tier: "Basic",
  },
  {
    tokenId: 7362,
    Tier: "Common",
  },
  {
    tokenId: 7363,
    Tier: "Basic",
  },
  {
    tokenId: 7364,
    Tier: "Basic",
  },
  {
    tokenId: 7365,
    Tier: "Basic",
  },
  {
    tokenId: 7366,
    Tier: "Rare",
  },
  {
    tokenId: 7367,
    Tier: "Common",
  },
  {
    tokenId: 7368,
    Tier: "Basic",
  },
  {
    tokenId: 7369,
    Tier: "Basic",
  },
  {
    tokenId: 7370,
    Tier: "Common",
  },
  {
    tokenId: 7371,
    Tier: "Common",
  },
  {
    tokenId: 7372,
    Tier: "Legendary",
  },
  {
    tokenId: 7373,
    Tier: "Epic",
  },
  {
    tokenId: 7374,
    Tier: "Basic",
  },
  {
    tokenId: 7375,
    Tier: "Rare",
  },
  {
    tokenId: 7376,
    Tier: "Mythic",
  },
  {
    tokenId: 7377,
    Tier: "Basic",
  },
  {
    tokenId: 7378,
    Tier: "Epic",
  },
  {
    tokenId: 7379,
    Tier: "Basic",
  },
  {
    tokenId: 7380,
    Tier: "Basic",
  },
  {
    tokenId: 7381,
    Tier: "Basic",
  },
  {
    tokenId: 7382,
    Tier: "Uncommon",
  },
  {
    tokenId: 7383,
    Tier: "Uncommon",
  },
  {
    tokenId: 7384,
    Tier: "Basic",
  },
  {
    tokenId: 7385,
    Tier: "Common",
  },
  {
    tokenId: 7386,
    Tier: "Basic",
  },
  {
    tokenId: 7387,
    Tier: "Basic",
  },
  {
    tokenId: 7388,
    Tier: "Uncommon",
  },
  {
    tokenId: 7389,
    Tier: "Basic",
  },
  {
    tokenId: 7390,
    Tier: "Uncommon",
  },
  {
    tokenId: 7391,
    Tier: "Basic",
  },
  {
    tokenId: 7392,
    Tier: "Common",
  },
  {
    tokenId: 7393,
    Tier: "Epic",
  },
  {
    tokenId: 7394,
    Tier: "Basic",
  },
  {
    tokenId: 7395,
    Tier: "Basic",
  },
  {
    tokenId: 7396,
    Tier: "Uncommon",
  },
  {
    tokenId: 7397,
    Tier: "Basic",
  },
  {
    tokenId: 7398,
    Tier: "Basic",
  },
  {
    tokenId: 7399,
    Tier: "Common",
  },
  {
    tokenId: 7400,
    Tier: "Uncommon",
  },
  {
    tokenId: 7401,
    Tier: "Basic",
  },
  {
    tokenId: 7402,
    Tier: "Epic",
  },
  {
    tokenId: 7403,
    Tier: "Rare",
  },
  {
    tokenId: 7404,
    Tier: "Basic",
  },
  {
    tokenId: 7405,
    Tier: "Legendary",
  },
  {
    tokenId: 7406,
    Tier: "Basic",
  },
  {
    tokenId: 7407,
    Tier: "Rare",
  },
  {
    tokenId: 7408,
    Tier: "Basic",
  },
  {
    tokenId: 7409,
    Tier: "Basic",
  },
  {
    tokenId: 7410,
    Tier: "Uncommon",
  },
  {
    tokenId: 7411,
    Tier: "Basic",
  },
  {
    tokenId: 7412,
    Tier: "Basic",
  },
  {
    tokenId: 7413,
    Tier: "Basic",
  },
  {
    tokenId: 7414,
    Tier: "Basic",
  },
  {
    tokenId: 7415,
    Tier: "Basic",
  },
  {
    tokenId: 7416,
    Tier: "Basic",
  },
  {
    tokenId: 7417,
    Tier: "Basic",
  },
  {
    tokenId: 7418,
    Tier: "Common",
  },
  {
    tokenId: 7419,
    Tier: "Epic",
  },
  {
    tokenId: 7420,
    Tier: "Common",
  },
  {
    tokenId: 7421,
    Tier: "Common",
  },
  {
    tokenId: 7422,
    Tier: "Basic",
  },
  {
    tokenId: 7423,
    Tier: "Basic",
  },
  {
    tokenId: 7424,
    Tier: "Common",
  },
  {
    tokenId: 7425,
    Tier: "Uncommon",
  },
  {
    tokenId: 7426,
    Tier: "Common",
  },
  {
    tokenId: 7427,
    Tier: "Basic",
  },
  {
    tokenId: 7428,
    Tier: "Rare",
  },
  {
    tokenId: 7429,
    Tier: "Basic",
  },
  {
    tokenId: 7430,
    Tier: "Uncommon",
  },
  {
    tokenId: 7431,
    Tier: "Uncommon",
  },
  {
    tokenId: 7432,
    Tier: "Basic",
  },
  {
    tokenId: 7433,
    Tier: "Legendary",
  },
  {
    tokenId: 7434,
    Tier: "Basic",
  },
  {
    tokenId: 7435,
    Tier: "Uncommon",
  },
  {
    tokenId: 7436,
    Tier: "Basic",
  },
  {
    tokenId: 7437,
    Tier: "Mythic",
  },
  {
    tokenId: 7438,
    Tier: "Rare",
  },
  {
    tokenId: 7439,
    Tier: "Basic",
  },
  {
    tokenId: 7440,
    Tier: "Basic",
  },
  {
    tokenId: 7441,
    Tier: "Basic",
  },
  {
    tokenId: 7442,
    Tier: "Rare",
  },
  {
    tokenId: 7443,
    Tier: "Basic",
  },
  {
    tokenId: 7444,
    Tier: "Basic",
  },
  {
    tokenId: 7445,
    Tier: "Basic",
  },
  {
    tokenId: 7446,
    Tier: "Basic",
  },
  {
    tokenId: 7447,
    Tier: "Basic",
  },
  {
    tokenId: 7448,
    Tier: "Rare",
  },
  {
    tokenId: 7449,
    Tier: "Rare",
  },
  {
    tokenId: 7450,
    Tier: "Common",
  },
  {
    tokenId: 7451,
    Tier: "Basic",
  },
  {
    tokenId: 7452,
    Tier: "Rare",
  },
  {
    tokenId: 7453,
    Tier: "Uncommon",
  },
  {
    tokenId: 7454,
    Tier: "Basic",
  },
  {
    tokenId: 7455,
    Tier: "Rare",
  },
  {
    tokenId: 7456,
    Tier: "Common",
  },
  {
    tokenId: 7457,
    Tier: "Common",
  },
  {
    tokenId: 7458,
    Tier: "Basic",
  },
  {
    tokenId: 7459,
    Tier: "Common",
  },
  {
    tokenId: 7460,
    Tier: "Basic",
  },
  {
    tokenId: 7461,
    Tier: "Common",
  },
  {
    tokenId: 7462,
    Tier: "Basic",
  },
  {
    tokenId: 7463,
    Tier: "Basic",
  },
  {
    tokenId: 7464,
    Tier: "Epic",
  },
  {
    tokenId: 7465,
    Tier: "Common",
  },
  {
    tokenId: 7466,
    Tier: "Legendary",
  },
  {
    tokenId: 7467,
    Tier: "Basic",
  },
  {
    tokenId: 7468,
    Tier: "Basic",
  },
  {
    tokenId: 7469,
    Tier: "Basic",
  },
  {
    tokenId: 7470,
    Tier: "Basic",
  },
  {
    tokenId: 7471,
    Tier: "Basic",
  },
  {
    tokenId: 7472,
    Tier: "Mythic",
  },
  {
    tokenId: 7473,
    Tier: "Uncommon",
  },
  {
    tokenId: 7474,
    Tier: "Basic",
  },
  {
    tokenId: 7475,
    Tier: "Mythic",
  },
  {
    tokenId: 7476,
    Tier: "Basic",
  },
  {
    tokenId: 7477,
    Tier: "Basic",
  },
  {
    tokenId: 7478,
    Tier: "Basic",
  },
  {
    tokenId: 7479,
    Tier: "Rare",
  },
  {
    tokenId: 7480,
    Tier: "Exotic",
  },
  {
    tokenId: 7481,
    Tier: "Rare",
  },
  {
    tokenId: 7482,
    Tier: "Basic",
  },
  {
    tokenId: 7483,
    Tier: "Basic",
  },
  {
    tokenId: 7484,
    Tier: "Rare",
  },
  {
    tokenId: 7485,
    Tier: "Uncommon",
  },
  {
    tokenId: 7486,
    Tier: "Basic",
  },
  {
    tokenId: 7487,
    Tier: "Epic",
  },
  {
    tokenId: 7488,
    Tier: "Basic",
  },
  {
    tokenId: 7489,
    Tier: "Mythic",
  },
  {
    tokenId: 7490,
    Tier: "Legendary",
  },
  {
    tokenId: 7491,
    Tier: "Epic",
  },
  {
    tokenId: 7492,
    Tier: "Rare",
  },
  {
    tokenId: 7493,
    Tier: "Basic",
  },
  {
    tokenId: 7494,
    Tier: "Common",
  },
  {
    tokenId: 7495,
    Tier: "Basic",
  },
  {
    tokenId: 7496,
    Tier: "Rare",
  },
  {
    tokenId: 7497,
    Tier: "Basic",
  },
  {
    tokenId: 7498,
    Tier: "Basic",
  },
  {
    tokenId: 7499,
    Tier: "Basic",
  },
  {
    tokenId: 7500,
    Tier: "Common",
  },
  {
    tokenId: 7501,
    Tier: "Basic",
  },
  {
    tokenId: 7502,
    Tier: "Basic",
  },
  {
    tokenId: 7503,
    Tier: "Mythic",
  },
  {
    tokenId: 7504,
    Tier: "Basic",
  },
  {
    tokenId: 7505,
    Tier: "Basic",
  },
  {
    tokenId: 7506,
    Tier: "Basic",
  },
  {
    tokenId: 7507,
    Tier: "Legendary",
  },
  {
    tokenId: 7508,
    Tier: "Uncommon",
  },
  {
    tokenId: 7509,
    Tier: "Epic",
  },
  {
    tokenId: 7510,
    Tier: "Basic",
  },
  {
    tokenId: 7511,
    Tier: "Basic",
  },
  {
    tokenId: 7512,
    Tier: "Basic",
  },
  {
    tokenId: 7513,
    Tier: "Basic",
  },
  {
    tokenId: 7514,
    Tier: "Uncommon",
  },
  {
    tokenId: 7515,
    Tier: "Common",
  },
  {
    tokenId: 7516,
    Tier: "Epic",
  },
  {
    tokenId: 7517,
    Tier: "Basic",
  },
  {
    tokenId: 7518,
    Tier: "Rare",
  },
  {
    tokenId: 7519,
    Tier: "Basic",
  },
  {
    tokenId: 7520,
    Tier: "Uncommon",
  },
  {
    tokenId: 7521,
    Tier: "Common",
  },
  {
    tokenId: 7522,
    Tier: "Basic",
  },
  {
    tokenId: 7523,
    Tier: "Rare",
  },
  {
    tokenId: 7524,
    Tier: "Common",
  },
  {
    tokenId: 7525,
    Tier: "Epic",
  },
  {
    tokenId: 7526,
    Tier: "Rare",
  },
  {
    tokenId: 7527,
    Tier: "Basic",
  },
  {
    tokenId: 7528,
    Tier: "Rare",
  },
  {
    tokenId: 7529,
    Tier: "Basic",
  },
  {
    tokenId: 7530,
    Tier: "Rare",
  },
  {
    tokenId: 7531,
    Tier: "Basic",
  },
  {
    tokenId: 7532,
    Tier: "Common",
  },
  {
    tokenId: 7533,
    Tier: "Basic",
  },
  {
    tokenId: 7534,
    Tier: "Rare",
  },
  {
    tokenId: 7535,
    Tier: "Basic",
  },
  {
    tokenId: 7536,
    Tier: "Legendary",
  },
  {
    tokenId: 7537,
    Tier: "Uncommon",
  },
  {
    tokenId: 7538,
    Tier: "Uncommon",
  },
  {
    tokenId: 7539,
    Tier: "Mythic",
  },
  {
    tokenId: 7540,
    Tier: "Common",
  },
  {
    tokenId: 7541,
    Tier: "Epic",
  },
  {
    tokenId: 7542,
    Tier: "Rare",
  },
  {
    tokenId: 7543,
    Tier: "Common",
  },
  {
    tokenId: 7544,
    Tier: "Rare",
  },
  {
    tokenId: 7545,
    Tier: "Common",
  },
  {
    tokenId: 7546,
    Tier: "Basic",
  },
  {
    tokenId: 7547,
    Tier: "Rare",
  },
  {
    tokenId: 7548,
    Tier: "Common",
  },
  {
    tokenId: 7549,
    Tier: "Epic",
  },
  {
    tokenId: 7550,
    Tier: "Legendary",
  },
  {
    tokenId: 7551,
    Tier: "Basic",
  },
  {
    tokenId: 7552,
    Tier: "Basic",
  },
  {
    tokenId: 7553,
    Tier: "Basic",
  },
  {
    tokenId: 7554,
    Tier: "Basic",
  },
  {
    tokenId: 7555,
    Tier: "Uncommon",
  },
  {
    tokenId: 7556,
    Tier: "Basic",
  },
  {
    tokenId: 7557,
    Tier: "Uncommon",
  },
  {
    tokenId: 7558,
    Tier: "Basic",
  },
  {
    tokenId: 7559,
    Tier: "Epic",
  },
  {
    tokenId: 7560,
    Tier: "Basic",
  },
  {
    tokenId: 7561,
    Tier: "Basic",
  },
  {
    tokenId: 7562,
    Tier: "Basic",
  },
  {
    tokenId: 7563,
    Tier: "Mythic",
  },
  {
    tokenId: 7564,
    Tier: "Epic",
  },
  {
    tokenId: 7565,
    Tier: "Epic",
  },
  {
    tokenId: 7566,
    Tier: "Basic",
  },
  {
    tokenId: 7567,
    Tier: "Basic",
  },
  {
    tokenId: 7568,
    Tier: "Common",
  },
  {
    tokenId: 7569,
    Tier: "Basic",
  },
  {
    tokenId: 7570,
    Tier: "Basic",
  },
  {
    tokenId: 7571,
    Tier: "Basic",
  },
  {
    tokenId: 7572,
    Tier: "Epic",
  },
  {
    tokenId: 7573,
    Tier: "Uncommon",
  },
  {
    tokenId: 7574,
    Tier: "Basic",
  },
  {
    tokenId: 7575,
    Tier: "Mythic",
  },
  {
    tokenId: 7576,
    Tier: "Uncommon",
  },
  {
    tokenId: 7577,
    Tier: "Rare",
  },
  {
    tokenId: 7578,
    Tier: "Basic",
  },
  {
    tokenId: 7579,
    Tier: "Mythic",
  },
  {
    tokenId: 7580,
    Tier: "Common",
  },
  {
    tokenId: 7581,
    Tier: "Uncommon",
  },
  {
    tokenId: 7582,
    Tier: "Common",
  },
  {
    tokenId: 7583,
    Tier: "Basic",
  },
  {
    tokenId: 7584,
    Tier: "Common",
  },
  {
    tokenId: 7585,
    Tier: "Basic",
  },
  {
    tokenId: 7586,
    Tier: "Basic",
  },
  {
    tokenId: 7587,
    Tier: "Basic",
  },
  {
    tokenId: 7588,
    Tier: "Common",
  },
  {
    tokenId: 7589,
    Tier: "Basic",
  },
  {
    tokenId: 7590,
    Tier: "Rare",
  },
  {
    tokenId: 7591,
    Tier: "Rare",
  },
  {
    tokenId: 7592,
    Tier: "Uncommon",
  },
  {
    tokenId: 7593,
    Tier: "Epic",
  },
  {
    tokenId: 7594,
    Tier: "Basic",
  },
  {
    tokenId: 7595,
    Tier: "Common",
  },
  {
    tokenId: 7596,
    Tier: "Mythic",
  },
  {
    tokenId: 7597,
    Tier: "Basic",
  },
  {
    tokenId: 7598,
    Tier: "Basic",
  },
  {
    tokenId: 7599,
    Tier: "Uncommon",
  },
  {
    tokenId: 7600,
    Tier: "Basic",
  },
  {
    tokenId: 7601,
    Tier: "Basic",
  },
  {
    tokenId: 7602,
    Tier: "Basic",
  },
  {
    tokenId: 7603,
    Tier: "Basic",
  },
  {
    tokenId: 7604,
    Tier: "Uncommon",
  },
  {
    tokenId: 7605,
    Tier: "Basic",
  },
  {
    tokenId: 7606,
    Tier: "Legendary",
  },
  {
    tokenId: 7607,
    Tier: "Basic",
  },
  {
    tokenId: 7608,
    Tier: "Basic",
  },
  {
    tokenId: 7609,
    Tier: "Epic",
  },
  {
    tokenId: 7610,
    Tier: "Basic",
  },
  {
    tokenId: 7611,
    Tier: "Basic",
  },
  {
    tokenId: 7612,
    Tier: "Basic",
  },
  {
    tokenId: 7613,
    Tier: "Rare",
  },
  {
    tokenId: 7614,
    Tier: "Basic",
  },
  {
    tokenId: 7615,
    Tier: "Basic",
  },
  {
    tokenId: 7616,
    Tier: "Basic",
  },
  {
    tokenId: 7617,
    Tier: "Common",
  },
  {
    tokenId: 7618,
    Tier: "Basic",
  },
  {
    tokenId: 7619,
    Tier: "Basic",
  },
  {
    tokenId: 7620,
    Tier: "Epic",
  },
  {
    tokenId: 7621,
    Tier: "Uncommon",
  },
  {
    tokenId: 7622,
    Tier: "Epic",
  },
  {
    tokenId: 7623,
    Tier: "Basic",
  },
  {
    tokenId: 7624,
    Tier: "Basic",
  },
  {
    tokenId: 7625,
    Tier: "Common",
  },
  {
    tokenId: 7626,
    Tier: "Rare",
  },
  {
    tokenId: 7627,
    Tier: "Rare",
  },
  {
    tokenId: 7628,
    Tier: "Basic",
  },
  {
    tokenId: 7629,
    Tier: "Basic",
  },
  {
    tokenId: 7630,
    Tier: "Rare",
  },
  {
    tokenId: 7631,
    Tier: "Common",
  },
  {
    tokenId: 7632,
    Tier: "Basic",
  },
  {
    tokenId: 7633,
    Tier: "Basic",
  },
  {
    tokenId: 7634,
    Tier: "Basic",
  },
  {
    tokenId: 7635,
    Tier: "Common",
  },
  {
    tokenId: 7636,
    Tier: "Basic",
  },
  {
    tokenId: 7637,
    Tier: "Basic",
  },
  {
    tokenId: 7638,
    Tier: "Rare",
  },
  {
    tokenId: 7639,
    Tier: "Basic",
  },
  {
    tokenId: 7640,
    Tier: "Basic",
  },
  {
    tokenId: 7641,
    Tier: "Basic",
  },
  {
    tokenId: 7642,
    Tier: "Basic",
  },
  {
    tokenId: 7643,
    Tier: "Basic",
  },
  {
    tokenId: 7644,
    Tier: "Basic",
  },
  {
    tokenId: 7645,
    Tier: "Basic",
  },
  {
    tokenId: 7646,
    Tier: "Basic",
  },
  {
    tokenId: 7647,
    Tier: "Basic",
  },
  {
    tokenId: 7648,
    Tier: "Basic",
  },
  {
    tokenId: 7649,
    Tier: "Basic",
  },
  {
    tokenId: 7650,
    Tier: "Rare",
  },
  {
    tokenId: 7651,
    Tier: "Epic",
  },
  {
    tokenId: 7652,
    Tier: "Basic",
  },
  {
    tokenId: 7653,
    Tier: "Basic",
  },
  {
    tokenId: 7654,
    Tier: "Basic",
  },
  {
    tokenId: 7655,
    Tier: "Common",
  },
  {
    tokenId: 7656,
    Tier: "Legendary",
  },
  {
    tokenId: 7657,
    Tier: "Mythic",
  },
  {
    tokenId: 7658,
    Tier: "Common",
  },
  {
    tokenId: 7659,
    Tier: "Basic",
  },
  {
    tokenId: 7660,
    Tier: "Basic",
  },
  {
    tokenId: 7661,
    Tier: "Uncommon",
  },
  {
    tokenId: 7662,
    Tier: "Basic",
  },
  {
    tokenId: 7663,
    Tier: "Uncommon",
  },
  {
    tokenId: 7664,
    Tier: "Rare",
  },
  {
    tokenId: 7665,
    Tier: "Uncommon",
  },
  {
    tokenId: 7666,
    Tier: "Common",
  },
  {
    tokenId: 7667,
    Tier: "Uncommon",
  },
  {
    tokenId: 7668,
    Tier: "Basic",
  },
  {
    tokenId: 7669,
    Tier: "Basic",
  },
  {
    tokenId: 7670,
    Tier: "Common",
  },
  {
    tokenId: 7671,
    Tier: "Basic",
  },
  {
    tokenId: 7672,
    Tier: "Rare",
  },
  {
    tokenId: 7673,
    Tier: "Epic",
  },
  {
    tokenId: 7674,
    Tier: "Basic",
  },
  {
    tokenId: 7675,
    Tier: "Basic",
  },
  {
    tokenId: 7676,
    Tier: "Basic",
  },
  {
    tokenId: 7677,
    Tier: "Epic",
  },
  {
    tokenId: 7678,
    Tier: "Basic",
  },
  {
    tokenId: 7679,
    Tier: "Epic",
  },
  {
    tokenId: 7680,
    Tier: "Basic",
  },
  {
    tokenId: 7681,
    Tier: "Basic",
  },
  {
    tokenId: 7682,
    Tier: "Basic",
  },
  {
    tokenId: 7683,
    Tier: "Legendary",
  },
  {
    tokenId: 7684,
    Tier: "Rare",
  },
  {
    tokenId: 7685,
    Tier: "Basic",
  },
  {
    tokenId: 7686,
    Tier: "Rare",
  },
  {
    tokenId: 7687,
    Tier: "Basic",
  },
  {
    tokenId: 7688,
    Tier: "Uncommon",
  },
  {
    tokenId: 7689,
    Tier: "Basic",
  },
  {
    tokenId: 7690,
    Tier: "Rare",
  },
  {
    tokenId: 7691,
    Tier: "Uncommon",
  },
  {
    tokenId: 7692,
    Tier: "Common",
  },
  {
    tokenId: 7693,
    Tier: "Basic",
  },
  {
    tokenId: 7694,
    Tier: "Basic",
  },
  {
    tokenId: 7695,
    Tier: "Basic",
  },
  {
    tokenId: 7696,
    Tier: "Rare",
  },
  {
    tokenId: 7697,
    Tier: "Basic",
  },
  {
    tokenId: 7698,
    Tier: "Basic",
  },
  {
    tokenId: 7699,
    Tier: "Mythic",
  },
  {
    tokenId: 7700,
    Tier: "Mythic",
  },
  {
    tokenId: 7701,
    Tier: "Basic",
  },
  {
    tokenId: 7702,
    Tier: "Epic",
  },
  {
    tokenId: 7703,
    Tier: "Uncommon",
  },
  {
    tokenId: 7704,
    Tier: "Basic",
  },
  {
    tokenId: 7705,
    Tier: "Basic",
  },
  {
    tokenId: 7706,
    Tier: "Basic",
  },
  {
    tokenId: 7707,
    Tier: "Legendary",
  },
  {
    tokenId: 7708,
    Tier: "Basic",
  },
  {
    tokenId: 7709,
    Tier: "Basic",
  },
  {
    tokenId: 7710,
    Tier: "Basic",
  },
  {
    tokenId: 7711,
    Tier: "Common",
  },
  {
    tokenId: 7712,
    Tier: "Basic",
  },
  {
    tokenId: 7713,
    Tier: "Common",
  },
  {
    tokenId: 7714,
    Tier: "Basic",
  },
  {
    tokenId: 7715,
    Tier: "Basic",
  },
  {
    tokenId: 7716,
    Tier: "Epic",
  },
  {
    tokenId: 7717,
    Tier: "Basic",
  },
  {
    tokenId: 7718,
    Tier: "Basic",
  },
  {
    tokenId: 7719,
    Tier: "Basic",
  },
  {
    tokenId: 7720,
    Tier: "Basic",
  },
  {
    tokenId: 7721,
    Tier: "Common",
  },
  {
    tokenId: 7722,
    Tier: "Common",
  },
  {
    tokenId: 7723,
    Tier: "Basic",
  },
  {
    tokenId: 7724,
    Tier: "Common",
  },
  {
    tokenId: 7725,
    Tier: "Epic",
  },
  {
    tokenId: 7726,
    Tier: "Basic",
  },
  {
    tokenId: 7727,
    Tier: "Basic",
  },
  {
    tokenId: 7728,
    Tier: "Common",
  },
  {
    tokenId: 7729,
    Tier: "Basic",
  },
  {
    tokenId: 7730,
    Tier: "Legendary",
  },
  {
    tokenId: 7731,
    Tier: "Basic",
  },
  {
    tokenId: 7732,
    Tier: "Basic",
  },
  {
    tokenId: 7733,
    Tier: "Basic",
  },
  {
    tokenId: 7734,
    Tier: "Basic",
  },
  {
    tokenId: 7735,
    Tier: "Basic",
  },
  {
    tokenId: 7736,
    Tier: "Basic",
  },
  {
    tokenId: 7737,
    Tier: "Basic",
  },
  {
    tokenId: 7738,
    Tier: "Uncommon",
  },
  {
    tokenId: 7739,
    Tier: "Uncommon",
  },
  {
    tokenId: 7740,
    Tier: "Uncommon",
  },
  {
    tokenId: 7741,
    Tier: "Common",
  },
  {
    tokenId: 7742,
    Tier: "Basic",
  },
  {
    tokenId: 7743,
    Tier: "Rare",
  },
  {
    tokenId: 7744,
    Tier: "Epic",
  },
  {
    tokenId: 7745,
    Tier: "Basic",
  },
  {
    tokenId: 7746,
    Tier: "Mythic",
  },
  {
    tokenId: 7747,
    Tier: "Basic",
  },
  {
    tokenId: 7748,
    Tier: "Basic",
  },
  {
    tokenId: 7749,
    Tier: "Basic",
  },
  {
    tokenId: 7750,
    Tier: "Basic",
  },
  {
    tokenId: 7751,
    Tier: "Basic",
  },
  {
    tokenId: 7752,
    Tier: "Basic",
  },
  {
    tokenId: 7753,
    Tier: "Common",
  },
  {
    tokenId: 7754,
    Tier: "Basic",
  },
  {
    tokenId: 7755,
    Tier: "Basic",
  },
  {
    tokenId: 7756,
    Tier: "Basic",
  },
  {
    tokenId: 7757,
    Tier: "Basic",
  },
  {
    tokenId: 7758,
    Tier: "Basic",
  },
  {
    tokenId: 7759,
    Tier: "Basic",
  },
  {
    tokenId: 7760,
    Tier: "Epic",
  },
  {
    tokenId: 7761,
    Tier: "Basic",
  },
  {
    tokenId: 7762,
    Tier: "Basic",
  },
  {
    tokenId: 7763,
    Tier: "Basic",
  },
  {
    tokenId: 7764,
    Tier: "Rare",
  },
  {
    tokenId: 7765,
    Tier: "Common",
  },
  {
    tokenId: 7766,
    Tier: "Basic",
  },
  {
    tokenId: 7767,
    Tier: "Rare",
  },
  {
    tokenId: 7768,
    Tier: "Legendary",
  },
  {
    tokenId: 7769,
    Tier: "Basic",
  },
  {
    tokenId: 7770,
    Tier: "Rare",
  },
  {
    tokenId: 7771,
    Tier: "Basic",
  },
  {
    tokenId: 7772,
    Tier: "Uncommon",
  },
  {
    tokenId: 7773,
    Tier: "Basic",
  },
  {
    tokenId: 7774,
    Tier: "Basic",
  },
  {
    tokenId: 7775,
    Tier: "Basic",
  },
  {
    tokenId: 7776,
    Tier: "Epic",
  },
  {
    tokenId: 7777,
    Tier: "Common",
  },
  {
    tokenId: 7778,
    Tier: "Uncommon",
  },
  {
    tokenId: 7779,
    Tier: "Basic",
  },
  {
    tokenId: 7780,
    Tier: "Basic",
  },
  {
    tokenId: 7781,
    Tier: "Basic",
  },
  {
    tokenId: 7782,
    Tier: "Basic",
  },
  {
    tokenId: 7783,
    Tier: "Epic",
  },
  {
    tokenId: 7784,
    Tier: "Basic",
  },
  {
    tokenId: 7785,
    Tier: "Basic",
  },
  {
    tokenId: 7786,
    Tier: "Basic",
  },
  {
    tokenId: 7787,
    Tier: "Basic",
  },
  {
    tokenId: 7788,
    Tier: "Epic",
  },
  {
    tokenId: 7789,
    Tier: "Common",
  },
  {
    tokenId: 7790,
    Tier: "Basic",
  },
  {
    tokenId: 7791,
    Tier: "Epic",
  },
  {
    tokenId: 7792,
    Tier: "Basic",
  },
  {
    tokenId: 7793,
    Tier: "Basic",
  },
  {
    tokenId: 7794,
    Tier: "Basic",
  },
  {
    tokenId: 7795,
    Tier: "Common",
  },
  {
    tokenId: 7796,
    Tier: "Basic",
  },
  {
    tokenId: 7797,
    Tier: "Epic",
  },
  {
    tokenId: 7798,
    Tier: "Legendary",
  },
  {
    tokenId: 7799,
    Tier: "Basic",
  },
  {
    tokenId: 7800,
    Tier: "Common",
  },
  {
    tokenId: 7801,
    Tier: "Mythic",
  },
  {
    tokenId: 7802,
    Tier: "Uncommon",
  },
  {
    tokenId: 7803,
    Tier: "Basic",
  },
  {
    tokenId: 7804,
    Tier: "Basic",
  },
  {
    tokenId: 7805,
    Tier: "Uncommon",
  },
  {
    tokenId: 7806,
    Tier: "Common",
  },
  {
    tokenId: 7807,
    Tier: "Basic",
  },
  {
    tokenId: 7808,
    Tier: "Uncommon",
  },
  {
    tokenId: 7809,
    Tier: "Uncommon",
  },
  {
    tokenId: 7810,
    Tier: "Exotic",
  },
  {
    tokenId: 7811,
    Tier: "Basic",
  },
  {
    tokenId: 7812,
    Tier: "Basic",
  },
  {
    tokenId: 7813,
    Tier: "Common",
  },
  {
    tokenId: 7814,
    Tier: "Basic",
  },
  {
    tokenId: 7815,
    Tier: "Common",
  },
  {
    tokenId: 7816,
    Tier: "Rare",
  },
  {
    tokenId: 7817,
    Tier: "Common",
  },
  {
    tokenId: 7818,
    Tier: "Epic",
  },
  {
    tokenId: 7819,
    Tier: "Mythic",
  },
  {
    tokenId: 7820,
    Tier: "Basic",
  },
  {
    tokenId: 7821,
    Tier: "Rare",
  },
  {
    tokenId: 7822,
    Tier: "Basic",
  },
  {
    tokenId: 7823,
    Tier: "Basic",
  },
  {
    tokenId: 7824,
    Tier: "Common",
  },
  {
    tokenId: 7825,
    Tier: "Basic",
  },
  {
    tokenId: 7826,
    Tier: "Common",
  },
  {
    tokenId: 7827,
    Tier: "Basic",
  },
  {
    tokenId: 7828,
    Tier: "Basic",
  },
  {
    tokenId: 7829,
    Tier: "Uncommon",
  },
  {
    tokenId: 7830,
    Tier: "Common",
  },
  {
    tokenId: 7831,
    Tier: "Basic",
  },
  {
    tokenId: 7832,
    Tier: "Legendary",
  },
  {
    tokenId: 7833,
    Tier: "Basic",
  },
  {
    tokenId: 7834,
    Tier: "Basic",
  },
  {
    tokenId: 7835,
    Tier: "Uncommon",
  },
  {
    tokenId: 7836,
    Tier: "Common",
  },
  {
    tokenId: 7837,
    Tier: "Common",
  },
  {
    tokenId: 7838,
    Tier: "Basic",
  },
  {
    tokenId: 7839,
    Tier: "Uncommon",
  },
  {
    tokenId: 7840,
    Tier: "Basic",
  },
  {
    tokenId: 7841,
    Tier: "Rare",
  },
  {
    tokenId: 7842,
    Tier: "Basic",
  },
  {
    tokenId: 7843,
    Tier: "Legendary",
  },
  {
    tokenId: 7844,
    Tier: "Common",
  },
  {
    tokenId: 7845,
    Tier: "Basic",
  },
  {
    tokenId: 7846,
    Tier: "Basic",
  },
  {
    tokenId: 7847,
    Tier: "Rare",
  },
  {
    tokenId: 7848,
    Tier: "Common",
  },
  {
    tokenId: 7849,
    Tier: "Basic",
  },
  {
    tokenId: 7850,
    Tier: "Basic",
  },
  {
    tokenId: 7851,
    Tier: "Rare",
  },
  {
    tokenId: 7852,
    Tier: "Basic",
  },
  {
    tokenId: 7853,
    Tier: "Basic",
  },
  {
    tokenId: 7854,
    Tier: "Uncommon",
  },
  {
    tokenId: 7855,
    Tier: "Basic",
  },
  {
    tokenId: 7856,
    Tier: "Common",
  },
  {
    tokenId: 7857,
    Tier: "Basic",
  },
  {
    tokenId: 7858,
    Tier: "Basic",
  },
  {
    tokenId: 7859,
    Tier: "Uncommon",
  },
  {
    tokenId: 7860,
    Tier: "Epic",
  },
  {
    tokenId: 7861,
    Tier: "Basic",
  },
  {
    tokenId: 7862,
    Tier: "Basic",
  },
  {
    tokenId: 7863,
    Tier: "Basic",
  },
  {
    tokenId: 7864,
    Tier: "Rare",
  },
  {
    tokenId: 7865,
    Tier: "Basic",
  },
  {
    tokenId: 7866,
    Tier: "Basic",
  },
  {
    tokenId: 7867,
    Tier: "Uncommon",
  },
  {
    tokenId: 7868,
    Tier: "Basic",
  },
  {
    tokenId: 7869,
    Tier: "Uncommon",
  },
  {
    tokenId: 7870,
    Tier: "Rare",
  },
  {
    tokenId: 7871,
    Tier: "Epic",
  },
  {
    tokenId: 7872,
    Tier: "Basic",
  },
  {
    tokenId: 7873,
    Tier: "Basic",
  },
  {
    tokenId: 7874,
    Tier: "Basic",
  },
  {
    tokenId: 7875,
    Tier: "Basic",
  },
  {
    tokenId: 7876,
    Tier: "Rare",
  },
  {
    tokenId: 7877,
    Tier: "Rare",
  },
  {
    tokenId: 7878,
    Tier: "Basic",
  },
  {
    tokenId: 7879,
    Tier: "Uncommon",
  },
  {
    tokenId: 7880,
    Tier: "Basic",
  },
  {
    tokenId: 7881,
    Tier: "Basic",
  },
  {
    tokenId: 7882,
    Tier: "Common",
  },
  {
    tokenId: 7883,
    Tier: "Basic",
  },
  {
    tokenId: 7884,
    Tier: "Common",
  },
  {
    tokenId: 7885,
    Tier: "Common",
  },
  {
    tokenId: 7886,
    Tier: "Basic",
  },
  {
    tokenId: 7887,
    Tier: "Rare",
  },
  {
    tokenId: 7888,
    Tier: "Uncommon",
  },
  {
    tokenId: 7889,
    Tier: "Mythic",
  },
  {
    tokenId: 7890,
    Tier: "Uncommon",
  },
  {
    tokenId: 7891,
    Tier: "Legendary",
  },
  {
    tokenId: 7892,
    Tier: "Basic",
  },
  {
    tokenId: 7893,
    Tier: "Common",
  },
  {
    tokenId: 7894,
    Tier: "Rare",
  },
  {
    tokenId: 7895,
    Tier: "Basic",
  },
  {
    tokenId: 7896,
    Tier: "Basic",
  },
  {
    tokenId: 7897,
    Tier: "Basic",
  },
  {
    tokenId: 7898,
    Tier: "Epic",
  },
  {
    tokenId: 7899,
    Tier: "Epic",
  },
  {
    tokenId: 7900,
    Tier: "Common",
  },
  {
    tokenId: 7901,
    Tier: "Basic",
  },
  {
    tokenId: 7902,
    Tier: "Basic",
  },
  {
    tokenId: 7903,
    Tier: "Basic",
  },
  {
    tokenId: 7904,
    Tier: "Basic",
  },
  {
    tokenId: 7905,
    Tier: "Basic",
  },
  {
    tokenId: 7906,
    Tier: "Mythic",
  },
  {
    tokenId: 7907,
    Tier: "Basic",
  },
  {
    tokenId: 7908,
    Tier: "Basic",
  },
  {
    tokenId: 7909,
    Tier: "Rare",
  },
  {
    tokenId: 7910,
    Tier: "Epic",
  },
  {
    tokenId: 7911,
    Tier: "Mythic",
  },
  {
    tokenId: 7912,
    Tier: "Basic",
  },
  {
    tokenId: 7913,
    Tier: "Common",
  },
  {
    tokenId: 7914,
    Tier: "Uncommon",
  },
  {
    tokenId: 7915,
    Tier: "Basic",
  },
  {
    tokenId: 7916,
    Tier: "Basic",
  },
  {
    tokenId: 7917,
    Tier: "Basic",
  },
  {
    tokenId: 7918,
    Tier: "Mythic",
  },
  {
    tokenId: 7919,
    Tier: "Basic",
  },
  {
    tokenId: 7920,
    Tier: "Basic",
  },
  {
    tokenId: 7921,
    Tier: "Rare",
  },
  {
    tokenId: 7922,
    Tier: "Common",
  },
  {
    tokenId: 7923,
    Tier: "Basic",
  },
  {
    tokenId: 7924,
    Tier: "Common",
  },
  {
    tokenId: 7925,
    Tier: "Rare",
  },
  {
    tokenId: 7926,
    Tier: "Exotic",
  },
  {
    tokenId: 7927,
    Tier: "Common",
  },
  {
    tokenId: 7928,
    Tier: "Uncommon",
  },
  {
    tokenId: 7929,
    Tier: "Common",
  },
  {
    tokenId: 7930,
    Tier: "Basic",
  },
  {
    tokenId: 7931,
    Tier: "Uncommon",
  },
  {
    tokenId: 7932,
    Tier: "Basic",
  },
  {
    tokenId: 7933,
    Tier: "Basic",
  },
  {
    tokenId: 7934,
    Tier: "Rare",
  },
  {
    tokenId: 7935,
    Tier: "Basic",
  },
  {
    tokenId: 7936,
    Tier: "Legendary",
  },
  {
    tokenId: 7937,
    Tier: "Basic",
  },
  {
    tokenId: 7938,
    Tier: "Basic",
  },
  {
    tokenId: 7939,
    Tier: "Basic",
  },
  {
    tokenId: 7940,
    Tier: "Common",
  },
  {
    tokenId: 7941,
    Tier: "Basic",
  },
  {
    tokenId: 7942,
    Tier: "Common",
  },
  {
    tokenId: 7943,
    Tier: "Basic",
  },
  {
    tokenId: 7944,
    Tier: "Uncommon",
  },
  {
    tokenId: 7945,
    Tier: "Common",
  },
  {
    tokenId: 7946,
    Tier: "Legendary",
  },
  {
    tokenId: 7947,
    Tier: "Basic",
  },
  {
    tokenId: 7948,
    Tier: "Basic",
  },
  {
    tokenId: 7949,
    Tier: "Basic",
  },
  {
    tokenId: 7950,
    Tier: "Basic",
  },
  {
    tokenId: 7951,
    Tier: "Uncommon",
  },
  {
    tokenId: 7952,
    Tier: "Common",
  },
  {
    tokenId: 7953,
    Tier: "Basic",
  },
  {
    tokenId: 7954,
    Tier: "Exotic",
  },
  {
    tokenId: 7955,
    Tier: "Legendary",
  },
  {
    tokenId: 7956,
    Tier: "Legendary",
  },
  {
    tokenId: 7957,
    Tier: "Basic",
  },
  {
    tokenId: 7958,
    Tier: "Common",
  },
  {
    tokenId: 7959,
    Tier: "Basic",
  },
  {
    tokenId: 7960,
    Tier: "Basic",
  },
  {
    tokenId: 7961,
    Tier: "Common",
  },
  {
    tokenId: 7962,
    Tier: "Basic",
  },
  {
    tokenId: 7963,
    Tier: "Epic",
  },
  {
    tokenId: 7964,
    Tier: "Uncommon",
  },
  {
    tokenId: 7965,
    Tier: "Basic",
  },
  {
    tokenId: 7966,
    Tier: "Rare",
  },
  {
    tokenId: 7967,
    Tier: "Basic",
  },
  {
    tokenId: 7968,
    Tier: "Common",
  },
  {
    tokenId: 7969,
    Tier: "Basic",
  },
  {
    tokenId: 7970,
    Tier: "Uncommon",
  },
  {
    tokenId: 7971,
    Tier: "Basic",
  },
  {
    tokenId: 7972,
    Tier: "Basic",
  },
  {
    tokenId: 7973,
    Tier: "Basic",
  },
  {
    tokenId: 7974,
    Tier: "Basic",
  },
  {
    tokenId: 7975,
    Tier: "Basic",
  },
  {
    tokenId: 7976,
    Tier: "Rare",
  },
  {
    tokenId: 7977,
    Tier: "Basic",
  },
  {
    tokenId: 7978,
    Tier: "Basic",
  },
  {
    tokenId: 7979,
    Tier: "Mythic",
  },
  {
    tokenId: 7980,
    Tier: "Basic",
  },
  {
    tokenId: 7981,
    Tier: "Uncommon",
  },
  {
    tokenId: 7982,
    Tier: "Basic",
  },
  {
    tokenId: 7983,
    Tier: "Basic",
  },
  {
    tokenId: 7984,
    Tier: "Uncommon",
  },
  {
    tokenId: 7985,
    Tier: "Basic",
  },
  {
    tokenId: 7986,
    Tier: "Common",
  },
  {
    tokenId: 7987,
    Tier: "Basic",
  },
  {
    tokenId: 7988,
    Tier: "Common",
  },
  {
    tokenId: 7989,
    Tier: "Basic",
  },
  {
    tokenId: 7990,
    Tier: "Basic",
  },
  {
    tokenId: 7991,
    Tier: "Basic",
  },
  {
    tokenId: 7992,
    Tier: "Legendary",
  },
  {
    tokenId: 7993,
    Tier: "Basic",
  },
  {
    tokenId: 7994,
    Tier: "Basic",
  },
  {
    tokenId: 7995,
    Tier: "Epic",
  },
  {
    tokenId: 7996,
    Tier: "Mythic",
  },
  {
    tokenId: 7997,
    Tier: "Basic",
  },
  {
    tokenId: 7998,
    Tier: "Basic",
  },
  {
    tokenId: 7999,
    Tier: "Uncommon",
  },
  {
    tokenId: 8000,
    Tier: "Common",
  },
  {
    tokenId: 8001,
    Tier: "Epic",
  },
  {
    tokenId: 8002,
    Tier: "Basic",
  },
  {
    tokenId: 8003,
    Tier: "Legendary",
  },
  {
    tokenId: 8004,
    Tier: "Basic",
  },
  {
    tokenId: 8005,
    Tier: "Mythic",
  },
  {
    tokenId: 8006,
    Tier: "Basic",
  },
  {
    tokenId: 8007,
    Tier: "Basic",
  },
  {
    tokenId: 8008,
    Tier: "Uncommon",
  },
  {
    tokenId: 8009,
    Tier: "Basic",
  },
  {
    tokenId: 8010,
    Tier: "Basic",
  },
  {
    tokenId: 8011,
    Tier: "Common",
  },
  {
    tokenId: 8012,
    Tier: "Rare",
  },
  {
    tokenId: 8013,
    Tier: "Basic",
  },
  {
    tokenId: 8014,
    Tier: "Basic",
  },
  {
    tokenId: 8015,
    Tier: "Rare",
  },
  {
    tokenId: 8016,
    Tier: "Rare",
  },
  {
    tokenId: 8017,
    Tier: "Basic",
  },
  {
    tokenId: 8018,
    Tier: "Uncommon",
  },
  {
    tokenId: 8019,
    Tier: "Uncommon",
  },
  {
    tokenId: 8020,
    Tier: "Basic",
  },
  {
    tokenId: 8021,
    Tier: "Common",
  },
  {
    tokenId: 8022,
    Tier: "Uncommon",
  },
  {
    tokenId: 8023,
    Tier: "Common",
  },
  {
    tokenId: 8024,
    Tier: "Basic",
  },
  {
    tokenId: 8025,
    Tier: "Basic",
  },
  {
    tokenId: 8026,
    Tier: "Basic",
  },
  {
    tokenId: 8027,
    Tier: "Rare",
  },
  {
    tokenId: 8028,
    Tier: "Basic",
  },
  {
    tokenId: 8029,
    Tier: "Epic",
  },
  {
    tokenId: 8030,
    Tier: "Basic",
  },
  {
    tokenId: 8031,
    Tier: "Uncommon",
  },
  {
    tokenId: 8032,
    Tier: "Legendary",
  },
  {
    tokenId: 8033,
    Tier: "Basic",
  },
  {
    tokenId: 8034,
    Tier: "Common",
  },
  {
    tokenId: 8035,
    Tier: "Uncommon",
  },
  {
    tokenId: 8036,
    Tier: "Common",
  },
  {
    tokenId: 8037,
    Tier: "Common",
  },
  {
    tokenId: 8038,
    Tier: "Basic",
  },
  {
    tokenId: 8039,
    Tier: "Epic",
  },
  {
    tokenId: 8040,
    Tier: "Basic",
  },
  {
    tokenId: 8041,
    Tier: "Basic",
  },
  {
    tokenId: 8042,
    Tier: "Common",
  },
  {
    tokenId: 8043,
    Tier: "Uncommon",
  },
  {
    tokenId: 8044,
    Tier: "Common",
  },
  {
    tokenId: 8045,
    Tier: "Common",
  },
  {
    tokenId: 8046,
    Tier: "Basic",
  },
  {
    tokenId: 8047,
    Tier: "Rare",
  },
  {
    tokenId: 8048,
    Tier: "Basic",
  },
  {
    tokenId: 8049,
    Tier: "Epic",
  },
  {
    tokenId: 8050,
    Tier: "Common",
  },
  {
    tokenId: 8051,
    Tier: "Common",
  },
  {
    tokenId: 8052,
    Tier: "Uncommon",
  },
  {
    tokenId: 8053,
    Tier: "Basic",
  },
  {
    tokenId: 8054,
    Tier: "Basic",
  },
  {
    tokenId: 8055,
    Tier: "Uncommon",
  },
  {
    tokenId: 8056,
    Tier: "Rare",
  },
  {
    tokenId: 8057,
    Tier: "Uncommon",
  },
  {
    tokenId: 8058,
    Tier: "Epic",
  },
  {
    tokenId: 8059,
    Tier: "Common",
  },
  {
    tokenId: 8060,
    Tier: "Uncommon",
  },
  {
    tokenId: 8061,
    Tier: "Common",
  },
  {
    tokenId: 8062,
    Tier: "Epic",
  },
  {
    tokenId: 8063,
    Tier: "Uncommon",
  },
  {
    tokenId: 8064,
    Tier: "Basic",
  },
  {
    tokenId: 8065,
    Tier: "Epic",
  },
  {
    tokenId: 8066,
    Tier: "Basic",
  },
  {
    tokenId: 8067,
    Tier: "Exotic",
  },
  {
    tokenId: 8068,
    Tier: "Basic",
  },
  {
    tokenId: 8069,
    Tier: "Basic",
  },
  {
    tokenId: 8070,
    Tier: "Basic",
  },
  {
    tokenId: 8071,
    Tier: "Basic",
  },
  {
    tokenId: 8072,
    Tier: "Exotic",
  },
  {
    tokenId: 8073,
    Tier: "Basic",
  },
  {
    tokenId: 8074,
    Tier: "Basic",
  },
  {
    tokenId: 8075,
    Tier: "Rare",
  },
  {
    tokenId: 8076,
    Tier: "Exotic",
  },
  {
    tokenId: 8077,
    Tier: "Common",
  },
  {
    tokenId: 8078,
    Tier: "Basic",
  },
  {
    tokenId: 8079,
    Tier: "Basic",
  },
  {
    tokenId: 8080,
    Tier: "Common",
  },
  {
    tokenId: 8081,
    Tier: "Basic",
  },
  {
    tokenId: 8082,
    Tier: "Basic",
  },
  {
    tokenId: 8083,
    Tier: "Basic",
  },
  {
    tokenId: 8084,
    Tier: "Basic",
  },
  {
    tokenId: 8085,
    Tier: "Uncommon",
  },
  {
    tokenId: 8086,
    Tier: "Common",
  },
  {
    tokenId: 8087,
    Tier: "Basic",
  },
  {
    tokenId: 8088,
    Tier: "Uncommon",
  },
  {
    tokenId: 8089,
    Tier: "Basic",
  },
  {
    tokenId: 8090,
    Tier: "Basic",
  },
  {
    tokenId: 8091,
    Tier: "Uncommon",
  },
  {
    tokenId: 8092,
    Tier: "Epic",
  },
  {
    tokenId: 8093,
    Tier: "Basic",
  },
  {
    tokenId: 8094,
    Tier: "Basic",
  },
  {
    tokenId: 8095,
    Tier: "Legendary",
  },
  {
    tokenId: 8096,
    Tier: "Basic",
  },
  {
    tokenId: 8097,
    Tier: "Basic",
  },
  {
    tokenId: 8098,
    Tier: "Legendary",
  },
  {
    tokenId: 8099,
    Tier: "Uncommon",
  },
  {
    tokenId: 8100,
    Tier: "Basic",
  },
  {
    tokenId: 8101,
    Tier: "Common",
  },
  {
    tokenId: 8102,
    Tier: "Common",
  },
  {
    tokenId: 8103,
    Tier: "Basic",
  },
  {
    tokenId: 8104,
    Tier: "Basic",
  },
  {
    tokenId: 8105,
    Tier: "Basic",
  },
  {
    tokenId: 8106,
    Tier: "Basic",
  },
  {
    tokenId: 8107,
    Tier: "Rare",
  },
  {
    tokenId: 8108,
    Tier: "Uncommon",
  },
  {
    tokenId: 8109,
    Tier: "Basic",
  },
  {
    tokenId: 8110,
    Tier: "Basic",
  },
  {
    tokenId: 8111,
    Tier: "Basic",
  },
  {
    tokenId: 8112,
    Tier: "Basic",
  },
  {
    tokenId: 8113,
    Tier: "Rare",
  },
  {
    tokenId: 8114,
    Tier: "Basic",
  },
  {
    tokenId: 8115,
    Tier: "Uncommon",
  },
  {
    tokenId: 8116,
    Tier: "Common",
  },
  {
    tokenId: 8117,
    Tier: "Uncommon",
  },
  {
    tokenId: 8118,
    Tier: "Basic",
  },
  {
    tokenId: 8119,
    Tier: "Common",
  },
  {
    tokenId: 8120,
    Tier: "Basic",
  },
  {
    tokenId: 8121,
    Tier: "Basic",
  },
  {
    tokenId: 8122,
    Tier: "Basic",
  },
  {
    tokenId: 8123,
    Tier: "Uncommon",
  },
  {
    tokenId: 8124,
    Tier: "Uncommon",
  },
  {
    tokenId: 8125,
    Tier: "Basic",
  },
  {
    tokenId: 8126,
    Tier: "Epic",
  },
  {
    tokenId: 8127,
    Tier: "Uncommon",
  },
  {
    tokenId: 8128,
    Tier: "Basic",
  },
  {
    tokenId: 8129,
    Tier: "Uncommon",
  },
  {
    tokenId: 8130,
    Tier: "Rare",
  },
  {
    tokenId: 8131,
    Tier: "Rare",
  },
  {
    tokenId: 8132,
    Tier: "Basic",
  },
  {
    tokenId: 8133,
    Tier: "Basic",
  },
  {
    tokenId: 8134,
    Tier: "Rare",
  },
  {
    tokenId: 8135,
    Tier: "Common",
  },
  {
    tokenId: 8136,
    Tier: "Epic",
  },
  {
    tokenId: 8137,
    Tier: "Basic",
  },
  {
    tokenId: 8138,
    Tier: "Uncommon",
  },
  {
    tokenId: 8139,
    Tier: "Rare",
  },
  {
    tokenId: 8140,
    Tier: "Basic",
  },
  {
    tokenId: 8141,
    Tier: "Legendary",
  },
  {
    tokenId: 8142,
    Tier: "Common",
  },
  {
    tokenId: 8143,
    Tier: "Basic",
  },
  {
    tokenId: 8144,
    Tier: "Basic",
  },
  {
    tokenId: 8145,
    Tier: "Basic",
  },
  {
    tokenId: 8146,
    Tier: "Basic",
  },
  {
    tokenId: 8147,
    Tier: "Epic",
  },
  {
    tokenId: 8148,
    Tier: "Legendary",
  },
  {
    tokenId: 8149,
    Tier: "Basic",
  },
  {
    tokenId: 8150,
    Tier: "Rare",
  },
  {
    tokenId: 8151,
    Tier: "Mythic",
  },
  {
    tokenId: 8152,
    Tier: "Rare",
  },
  {
    tokenId: 8153,
    Tier: "Basic",
  },
  {
    tokenId: 8154,
    Tier: "Basic",
  },
  {
    tokenId: 8155,
    Tier: "Uncommon",
  },
  {
    tokenId: 8156,
    Tier: "Common",
  },
  {
    tokenId: 8157,
    Tier: "Mythic",
  },
  {
    tokenId: 8158,
    Tier: "Epic",
  },
  {
    tokenId: 8159,
    Tier: "Basic",
  },
  {
    tokenId: 8160,
    Tier: "Basic",
  },
  {
    tokenId: 8161,
    Tier: "Common",
  },
  {
    tokenId: 8162,
    Tier: "Common",
  },
  {
    tokenId: 8163,
    Tier: "Basic",
  },
  {
    tokenId: 8164,
    Tier: "Uncommon",
  },
  {
    tokenId: 8165,
    Tier: "Basic",
  },
  {
    tokenId: 8166,
    Tier: "Uncommon",
  },
  {
    tokenId: 8167,
    Tier: "Basic",
  },
  {
    tokenId: 8168,
    Tier: "Uncommon",
  },
  {
    tokenId: 8169,
    Tier: "Basic",
  },
  {
    tokenId: 8170,
    Tier: "Basic",
  },
  {
    tokenId: 8171,
    Tier: "Basic",
  },
  {
    tokenId: 8172,
    Tier: "Basic",
  },
  {
    tokenId: 8173,
    Tier: "Basic",
  },
  {
    tokenId: 8174,
    Tier: "Uncommon",
  },
  {
    tokenId: 8175,
    Tier: "Basic",
  },
  {
    tokenId: 8176,
    Tier: "Rare",
  },
  {
    tokenId: 8177,
    Tier: "Epic",
  },
  {
    tokenId: 8178,
    Tier: "Common",
  },
  {
    tokenId: 8179,
    Tier: "Basic",
  },
  {
    tokenId: 8180,
    Tier: "Uncommon",
  },
  {
    tokenId: 8181,
    Tier: "Basic",
  },
  {
    tokenId: 8182,
    Tier: "Common",
  },
  {
    tokenId: 8183,
    Tier: "Epic",
  },
  {
    tokenId: 8184,
    Tier: "Basic",
  },
  {
    tokenId: 8185,
    Tier: "Exotic",
  },
  {
    tokenId: 8186,
    Tier: "Basic",
  },
  {
    tokenId: 8187,
    Tier: "Basic",
  },
  {
    tokenId: 8188,
    Tier: "Uncommon",
  },
  {
    tokenId: 8189,
    Tier: "Epic",
  },
  {
    tokenId: 8190,
    Tier: "Basic",
  },
  {
    tokenId: 8191,
    Tier: "Basic",
  },
  {
    tokenId: 8192,
    Tier: "Uncommon",
  },
  {
    tokenId: 8193,
    Tier: "Legendary",
  },
  {
    tokenId: 8194,
    Tier: "Uncommon",
  },
  {
    tokenId: 8195,
    Tier: "Common",
  },
  {
    tokenId: 8196,
    Tier: "Basic",
  },
  {
    tokenId: 8197,
    Tier: "Common",
  },
  {
    tokenId: 8198,
    Tier: "Common",
  },
  {
    tokenId: 8199,
    Tier: "Basic",
  },
  {
    tokenId: 8200,
    Tier: "Uncommon",
  },
  {
    tokenId: 8201,
    Tier: "Basic",
  },
  {
    tokenId: 8202,
    Tier: "Rare",
  },
  {
    tokenId: 8203,
    Tier: "Epic",
  },
  {
    tokenId: 8204,
    Tier: "Basic",
  },
  {
    tokenId: 8205,
    Tier: "Epic",
  },
  {
    tokenId: 8206,
    Tier: "Basic",
  },
  {
    tokenId: 8207,
    Tier: "Rare",
  },
  {
    tokenId: 8208,
    Tier: "Basic",
  },
  {
    tokenId: 8209,
    Tier: "Common",
  },
  {
    tokenId: 8210,
    Tier: "Basic",
  },
  {
    tokenId: 8211,
    Tier: "Basic",
  },
  {
    tokenId: 8212,
    Tier: "Basic",
  },
  {
    tokenId: 8213,
    Tier: "Basic",
  },
  {
    tokenId: 8214,
    Tier: "Basic",
  },
  {
    tokenId: 8215,
    Tier: "Basic",
  },
  {
    tokenId: 8216,
    Tier: "Mythic",
  },
  {
    tokenId: 8217,
    Tier: "Epic",
  },
  {
    tokenId: 8218,
    Tier: "Uncommon",
  },
  {
    tokenId: 8219,
    Tier: "Basic",
  },
  {
    tokenId: 8220,
    Tier: "Basic",
  },
  {
    tokenId: 8221,
    Tier: "Epic",
  },
  {
    tokenId: 8222,
    Tier: "Common",
  },
  {
    tokenId: 8223,
    Tier: "Common",
  },
  {
    tokenId: 8224,
    Tier: "Common",
  },
  {
    tokenId: 8225,
    Tier: "Uncommon",
  },
  {
    tokenId: 8226,
    Tier: "Basic",
  },
  {
    tokenId: 8227,
    Tier: "Basic",
  },
  {
    tokenId: 8228,
    Tier: "Basic",
  },
  {
    tokenId: 8229,
    Tier: "Basic",
  },
  {
    tokenId: 8230,
    Tier: "Basic",
  },
  {
    tokenId: 8231,
    Tier: "Basic",
  },
  {
    tokenId: 8232,
    Tier: "Basic",
  },
  {
    tokenId: 8233,
    Tier: "Uncommon",
  },
  {
    tokenId: 8234,
    Tier: "Basic",
  },
  {
    tokenId: 8235,
    Tier: "Common",
  },
  {
    tokenId: 8236,
    Tier: "Basic",
  },
  {
    tokenId: 8237,
    Tier: "Rare",
  },
  {
    tokenId: 8238,
    Tier: "Uncommon",
  },
  {
    tokenId: 8239,
    Tier: "Basic",
  },
  {
    tokenId: 8240,
    Tier: "Common",
  },
  {
    tokenId: 8241,
    Tier: "Uncommon",
  },
  {
    tokenId: 8242,
    Tier: "Basic",
  },
  {
    tokenId: 8243,
    Tier: "Basic",
  },
  {
    tokenId: 8244,
    Tier: "Basic",
  },
  {
    tokenId: 8245,
    Tier: "Uncommon",
  },
  {
    tokenId: 8246,
    Tier: "Basic",
  },
  {
    tokenId: 8247,
    Tier: "Uncommon",
  },
  {
    tokenId: 8248,
    Tier: "Common",
  },
  {
    tokenId: 8249,
    Tier: "Basic",
  },
  {
    tokenId: 8250,
    Tier: "Uncommon",
  },
  {
    tokenId: 8251,
    Tier: "Basic",
  },
  {
    tokenId: 8252,
    Tier: "Mythic",
  },
  {
    tokenId: 8253,
    Tier: "Basic",
  },
  {
    tokenId: 8254,
    Tier: "Basic",
  },
  {
    tokenId: 8255,
    Tier: "Epic",
  },
  {
    tokenId: 8256,
    Tier: "Uncommon",
  },
  {
    tokenId: 8257,
    Tier: "Mythic",
  },
  {
    tokenId: 8258,
    Tier: "Legendary",
  },
  {
    tokenId: 8259,
    Tier: "Epic",
  },
  {
    tokenId: 8260,
    Tier: "Basic",
  },
  {
    tokenId: 8261,
    Tier: "Basic",
  },
  {
    tokenId: 8262,
    Tier: "Basic",
  },
  {
    tokenId: 8263,
    Tier: "Basic",
  },
  {
    tokenId: 8264,
    Tier: "Basic",
  },
  {
    tokenId: 8265,
    Tier: "Rare",
  },
  {
    tokenId: 8266,
    Tier: "Basic",
  },
  {
    tokenId: 8267,
    Tier: "Mythic",
  },
  {
    tokenId: 8268,
    Tier: "Legendary",
  },
  {
    tokenId: 8269,
    Tier: "Mythic",
  },
  {
    tokenId: 8270,
    Tier: "Rare",
  },
  {
    tokenId: 8271,
    Tier: "Basic",
  },
  {
    tokenId: 8272,
    Tier: "Basic",
  },
  {
    tokenId: 8273,
    Tier: "Basic",
  },
  {
    tokenId: 8274,
    Tier: "Common",
  },
  {
    tokenId: 8275,
    Tier: "Rare",
  },
  {
    tokenId: 8276,
    Tier: "Basic",
  },
  {
    tokenId: 8277,
    Tier: "Basic",
  },
  {
    tokenId: 8278,
    Tier: "Common",
  },
  {
    tokenId: 8279,
    Tier: "Rare",
  },
  {
    tokenId: 8280,
    Tier: "Uncommon",
  },
  {
    tokenId: 8281,
    Tier: "Basic",
  },
  {
    tokenId: 8282,
    Tier: "Basic",
  },
  {
    tokenId: 8283,
    Tier: "Legendary",
  },
  {
    tokenId: 8284,
    Tier: "Basic",
  },
  {
    tokenId: 8285,
    Tier: "Uncommon",
  },
  {
    tokenId: 8286,
    Tier: "Basic",
  },
  {
    tokenId: 8287,
    Tier: "Basic",
  },
  {
    tokenId: 8288,
    Tier: "Epic",
  },
  {
    tokenId: 8289,
    Tier: "Common",
  },
  {
    tokenId: 8290,
    Tier: "Basic",
  },
  {
    tokenId: 8291,
    Tier: "Basic",
  },
  {
    tokenId: 8292,
    Tier: "Basic",
  },
  {
    tokenId: 8293,
    Tier: "Basic",
  },
  {
    tokenId: 8294,
    Tier: "Basic",
  },
  {
    tokenId: 8295,
    Tier: "Common",
  },
  {
    tokenId: 8296,
    Tier: "Basic",
  },
  {
    tokenId: 8297,
    Tier: "Basic",
  },
  {
    tokenId: 8298,
    Tier: "Uncommon",
  },
  {
    tokenId: 8299,
    Tier: "Basic",
  },
  {
    tokenId: 8300,
    Tier: "Basic",
  },
  {
    tokenId: 8301,
    Tier: "Basic",
  },
  {
    tokenId: 8302,
    Tier: "Basic",
  },
  {
    tokenId: 8303,
    Tier: "Basic",
  },
  {
    tokenId: 8304,
    Tier: "Rare",
  },
  {
    tokenId: 8305,
    Tier: "Basic",
  },
  {
    tokenId: 8306,
    Tier: "Basic",
  },
  {
    tokenId: 8307,
    Tier: "Basic",
  },
  {
    tokenId: 8308,
    Tier: "Basic",
  },
  {
    tokenId: 8309,
    Tier: "Uncommon",
  },
  {
    tokenId: 8310,
    Tier: "Common",
  },
  {
    tokenId: 8311,
    Tier: "Basic",
  },
  {
    tokenId: 8312,
    Tier: "Basic",
  },
  {
    tokenId: 8313,
    Tier: "Legendary",
  },
  {
    tokenId: 8314,
    Tier: "Basic",
  },
  {
    tokenId: 8315,
    Tier: "Legendary",
  },
  {
    tokenId: 8316,
    Tier: "Basic",
  },
  {
    tokenId: 8317,
    Tier: "Basic",
  },
  {
    tokenId: 8318,
    Tier: "Basic",
  },
  {
    tokenId: 8319,
    Tier: "Basic",
  },
  {
    tokenId: 8320,
    Tier: "Uncommon",
  },
  {
    tokenId: 8321,
    Tier: "Common",
  },
  {
    tokenId: 8322,
    Tier: "Basic",
  },
  {
    tokenId: 8323,
    Tier: "Common",
  },
  {
    tokenId: 8324,
    Tier: "Basic",
  },
  {
    tokenId: 8325,
    Tier: "Basic",
  },
  {
    tokenId: 8326,
    Tier: "Basic",
  },
  {
    tokenId: 8327,
    Tier: "Basic",
  },
  {
    tokenId: 8328,
    Tier: "Common",
  },
  {
    tokenId: 8329,
    Tier: "Uncommon",
  },
  {
    tokenId: 8330,
    Tier: "Basic",
  },
  {
    tokenId: 8331,
    Tier: "Basic",
  },
  {
    tokenId: 8332,
    Tier: "Epic",
  },
  {
    tokenId: 8333,
    Tier: "Basic",
  },
  {
    tokenId: 8334,
    Tier: "Basic",
  },
  {
    tokenId: 8335,
    Tier: "Legendary",
  },
  {
    tokenId: 8336,
    Tier: "Basic",
  },
  {
    tokenId: 8337,
    Tier: "Uncommon",
  },
  {
    tokenId: 8338,
    Tier: "Mythic",
  },
  {
    tokenId: 8339,
    Tier: "Basic",
  },
  {
    tokenId: 8340,
    Tier: "Legendary",
  },
  {
    tokenId: 8341,
    Tier: "Basic",
  },
  {
    tokenId: 8342,
    Tier: "Common",
  },
  {
    tokenId: 8343,
    Tier: "Basic",
  },
  {
    tokenId: 8344,
    Tier: "Basic",
  },
  {
    tokenId: 8345,
    Tier: "Basic",
  },
  {
    tokenId: 8346,
    Tier: "Basic",
  },
  {
    tokenId: 8347,
    Tier: "Uncommon",
  },
  {
    tokenId: 8348,
    Tier: "Mythic",
  },
  {
    tokenId: 8349,
    Tier: "Legendary",
  },
  {
    tokenId: 8350,
    Tier: "Common",
  },
  {
    tokenId: 8351,
    Tier: "Basic",
  },
  {
    tokenId: 8352,
    Tier: "Uncommon",
  },
  {
    tokenId: 8353,
    Tier: "Basic",
  },
  {
    tokenId: 8354,
    Tier: "Basic",
  },
  {
    tokenId: 8355,
    Tier: "Basic",
  },
  {
    tokenId: 8356,
    Tier: "Basic",
  },
  {
    tokenId: 8357,
    Tier: "Basic",
  },
  {
    tokenId: 8358,
    Tier: "Common",
  },
  {
    tokenId: 8359,
    Tier: "Rare",
  },
  {
    tokenId: 8360,
    Tier: "Uncommon",
  },
  {
    tokenId: 8361,
    Tier: "Common",
  },
  {
    tokenId: 8362,
    Tier: "Basic",
  },
  {
    tokenId: 8363,
    Tier: "Basic",
  },
  {
    tokenId: 8364,
    Tier: "Basic",
  },
  {
    tokenId: 8365,
    Tier: "Basic",
  },
  {
    tokenId: 8366,
    Tier: "Common",
  },
  {
    tokenId: 8367,
    Tier: "Uncommon",
  },
  {
    tokenId: 8368,
    Tier: "Mythic",
  },
  {
    tokenId: 8369,
    Tier: "Rare",
  },
  {
    tokenId: 8370,
    Tier: "Basic",
  },
  {
    tokenId: 8371,
    Tier: "Common",
  },
  {
    tokenId: 8372,
    Tier: "Basic",
  },
  {
    tokenId: 8373,
    Tier: "Basic",
  },
  {
    tokenId: 8374,
    Tier: "Mythic",
  },
  {
    tokenId: 8375,
    Tier: "Rare",
  },
  {
    tokenId: 8376,
    Tier: "Basic",
  },
  {
    tokenId: 8377,
    Tier: "Basic",
  },
  {
    tokenId: 8378,
    Tier: "Basic",
  },
  {
    tokenId: 8379,
    Tier: "Epic",
  },
  {
    tokenId: 8380,
    Tier: "Basic",
  },
  {
    tokenId: 8381,
    Tier: "Basic",
  },
  {
    tokenId: 8382,
    Tier: "Basic",
  },
  {
    tokenId: 8383,
    Tier: "Rare",
  },
  {
    tokenId: 8384,
    Tier: "Basic",
  },
  {
    tokenId: 8385,
    Tier: "Basic",
  },
  {
    tokenId: 8386,
    Tier: "Common",
  },
  {
    tokenId: 8387,
    Tier: "Uncommon",
  },
  {
    tokenId: 8388,
    Tier: "Uncommon",
  },
  {
    tokenId: 8389,
    Tier: "Epic",
  },
  {
    tokenId: 8390,
    Tier: "Basic",
  },
  {
    tokenId: 8391,
    Tier: "Basic",
  },
  {
    tokenId: 8392,
    Tier: "Rare",
  },
  {
    tokenId: 8393,
    Tier: "Basic",
  },
  {
    tokenId: 8394,
    Tier: "Epic",
  },
  {
    tokenId: 8395,
    Tier: "Basic",
  },
  {
    tokenId: 8396,
    Tier: "Rare",
  },
  {
    tokenId: 8397,
    Tier: "Common",
  },
  {
    tokenId: 8398,
    Tier: "Basic",
  },
  {
    tokenId: 8399,
    Tier: "Basic",
  },
  {
    tokenId: 8400,
    Tier: "Basic",
  },
  {
    tokenId: 8401,
    Tier: "Common",
  },
  {
    tokenId: 8402,
    Tier: "Basic",
  },
  {
    tokenId: 8403,
    Tier: "Basic",
  },
  {
    tokenId: 8404,
    Tier: "Common",
  },
  {
    tokenId: 8405,
    Tier: "Exotic",
  },
  {
    tokenId: 8406,
    Tier: "Basic",
  },
  {
    tokenId: 8407,
    Tier: "Mythic",
  },
  {
    tokenId: 8408,
    Tier: "Rare",
  },
  {
    tokenId: 8409,
    Tier: "Common",
  },
  {
    tokenId: 8410,
    Tier: "Basic",
  },
  {
    tokenId: 8411,
    Tier: "Rare",
  },
  {
    tokenId: 8412,
    Tier: "Rare",
  },
  {
    tokenId: 8413,
    Tier: "Basic",
  },
  {
    tokenId: 8414,
    Tier: "Basic",
  },
  {
    tokenId: 8415,
    Tier: "Basic",
  },
  {
    tokenId: 8416,
    Tier: "Basic",
  },
  {
    tokenId: 8417,
    Tier: "Uncommon",
  },
  {
    tokenId: 8418,
    Tier: "Common",
  },
  {
    tokenId: 8419,
    Tier: "Common",
  },
  {
    tokenId: 8420,
    Tier: "Basic",
  },
  {
    tokenId: 8421,
    Tier: "Uncommon",
  },
  {
    tokenId: 8422,
    Tier: "Basic",
  },
  {
    tokenId: 8423,
    Tier: "Basic",
  },
  {
    tokenId: 8424,
    Tier: "Legendary",
  },
  {
    tokenId: 8425,
    Tier: "Common",
  },
  {
    tokenId: 8426,
    Tier: "Common",
  },
  {
    tokenId: 8427,
    Tier: "Basic",
  },
  {
    tokenId: 8428,
    Tier: "Common",
  },
  {
    tokenId: 8429,
    Tier: "Basic",
  },
  {
    tokenId: 8430,
    Tier: "Common",
  },
  {
    tokenId: 8431,
    Tier: "Basic",
  },
  {
    tokenId: 8432,
    Tier: "Basic",
  },
  {
    tokenId: 8433,
    Tier: "Basic",
  },
  {
    tokenId: 8434,
    Tier: "Basic",
  },
  {
    tokenId: 8435,
    Tier: "Basic",
  },
  {
    tokenId: 8436,
    Tier: "Basic",
  },
  {
    tokenId: 8437,
    Tier: "Basic",
  },
  {
    tokenId: 8438,
    Tier: "Legendary",
  },
  {
    tokenId: 8439,
    Tier: "Epic",
  },
  {
    tokenId: 8440,
    Tier: "Basic",
  },
  {
    tokenId: 8441,
    Tier: "Legendary",
  },
  {
    tokenId: 8442,
    Tier: "Common",
  },
  {
    tokenId: 8443,
    Tier: "Basic",
  },
  {
    tokenId: 8444,
    Tier: "Exotic",
  },
  {
    tokenId: 8445,
    Tier: "Basic",
  },
  {
    tokenId: 8446,
    Tier: "Basic",
  },
  {
    tokenId: 8447,
    Tier: "Basic",
  },
  {
    tokenId: 8448,
    Tier: "Common",
  },
  {
    tokenId: 8449,
    Tier: "Rare",
  },
  {
    tokenId: 8450,
    Tier: "Common",
  },
  {
    tokenId: 8451,
    Tier: "Rare",
  },
  {
    tokenId: 8452,
    Tier: "Common",
  },
  {
    tokenId: 8453,
    Tier: "Basic",
  },
  {
    tokenId: 8454,
    Tier: "Basic",
  },
  {
    tokenId: 8455,
    Tier: "Common",
  },
  {
    tokenId: 8456,
    Tier: "Uncommon",
  },
  {
    tokenId: 8457,
    Tier: "Basic",
  },
  {
    tokenId: 8458,
    Tier: "Mythic",
  },
  {
    tokenId: 8459,
    Tier: "Epic",
  },
  {
    tokenId: 8460,
    Tier: "Epic",
  },
  {
    tokenId: 8461,
    Tier: "Basic",
  },
  {
    tokenId: 8462,
    Tier: "Basic",
  },
  {
    tokenId: 8463,
    Tier: "Basic",
  },
  {
    tokenId: 8464,
    Tier: "Common",
  },
  {
    tokenId: 8465,
    Tier: "Epic",
  },
  {
    tokenId: 8466,
    Tier: "Basic",
  },
  {
    tokenId: 8467,
    Tier: "Common",
  },
  {
    tokenId: 8468,
    Tier: "Common",
  },
  {
    tokenId: 8469,
    Tier: "Basic",
  },
  {
    tokenId: 8470,
    Tier: "Uncommon",
  },
  {
    tokenId: 8471,
    Tier: "Basic",
  },
  {
    tokenId: 8472,
    Tier: "Epic",
  },
  {
    tokenId: 8473,
    Tier: "Basic",
  },
  {
    tokenId: 8474,
    Tier: "Mythic",
  },
  {
    tokenId: 8475,
    Tier: "Uncommon",
  },
  {
    tokenId: 8476,
    Tier: "Epic",
  },
  {
    tokenId: 8477,
    Tier: "Common",
  },
  {
    tokenId: 8478,
    Tier: "Rare",
  },
  {
    tokenId: 8479,
    Tier: "Basic",
  },
  {
    tokenId: 8480,
    Tier: "Basic",
  },
  {
    tokenId: 8481,
    Tier: "Common",
  },
  {
    tokenId: 8482,
    Tier: "Uncommon",
  },
  {
    tokenId: 8483,
    Tier: "Basic",
  },
  {
    tokenId: 8484,
    Tier: "Uncommon",
  },
  {
    tokenId: 8485,
    Tier: "Basic",
  },
  {
    tokenId: 8486,
    Tier: "Epic",
  },
  {
    tokenId: 8487,
    Tier: "Epic",
  },
  {
    tokenId: 8488,
    Tier: "Mythic",
  },
  {
    tokenId: 8489,
    Tier: "Basic",
  },
  {
    tokenId: 8490,
    Tier: "Basic",
  },
  {
    tokenId: 8491,
    Tier: "Basic",
  },
  {
    tokenId: 8492,
    Tier: "Basic",
  },
  {
    tokenId: 8493,
    Tier: "Basic",
  },
  {
    tokenId: 8494,
    Tier: "Basic",
  },
  {
    tokenId: 8495,
    Tier: "Common",
  },
  {
    tokenId: 8496,
    Tier: "Epic",
  },
  {
    tokenId: 8497,
    Tier: "Basic",
  },
  {
    tokenId: 8498,
    Tier: "Legendary",
  },
  {
    tokenId: 8499,
    Tier: "Exotic",
  },
  {
    tokenId: 8500,
    Tier: "Basic",
  },
  {
    tokenId: 8501,
    Tier: "Common",
  },
  {
    tokenId: 8502,
    Tier: "Epic",
  },
  {
    tokenId: 8503,
    Tier: "Rare",
  },
  {
    tokenId: 8504,
    Tier: "Rare",
  },
  {
    tokenId: 8505,
    Tier: "Basic",
  },
  {
    tokenId: 8506,
    Tier: "Basic",
  },
  {
    tokenId: 8507,
    Tier: "Basic",
  },
  {
    tokenId: 8508,
    Tier: "Exotic",
  },
  {
    tokenId: 8509,
    Tier: "Basic",
  },
  {
    tokenId: 8510,
    Tier: "Uncommon",
  },
  {
    tokenId: 8511,
    Tier: "Basic",
  },
  {
    tokenId: 8512,
    Tier: "Basic",
  },
  {
    tokenId: 8513,
    Tier: "Rare",
  },
  {
    tokenId: 8514,
    Tier: "Epic",
  },
  {
    tokenId: 8515,
    Tier: "Basic",
  },
  {
    tokenId: 8516,
    Tier: "Basic",
  },
  {
    tokenId: 8517,
    Tier: "Mythic",
  },
  {
    tokenId: 8518,
    Tier: "Basic",
  },
  {
    tokenId: 8519,
    Tier: "Common",
  },
  {
    tokenId: 8520,
    Tier: "Legendary",
  },
  {
    tokenId: 8521,
    Tier: "Basic",
  },
  {
    tokenId: 8522,
    Tier: "Uncommon",
  },
  {
    tokenId: 8523,
    Tier: "Common",
  },
  {
    tokenId: 8524,
    Tier: "Basic",
  },
  {
    tokenId: 8525,
    Tier: "Rare",
  },
  {
    tokenId: 8526,
    Tier: "Basic",
  },
  {
    tokenId: 8527,
    Tier: "Basic",
  },
  {
    tokenId: 8528,
    Tier: "Basic",
  },
  {
    tokenId: 8529,
    Tier: "Basic",
  },
  {
    tokenId: 8530,
    Tier: "Legendary",
  },
  {
    tokenId: 8531,
    Tier: "Basic",
  },
  {
    tokenId: 8532,
    Tier: "Basic",
  },
  {
    tokenId: 8533,
    Tier: "Basic",
  },
  {
    tokenId: 8534,
    Tier: "Rare",
  },
  {
    tokenId: 8535,
    Tier: "Basic",
  },
  {
    tokenId: 8536,
    Tier: "Basic",
  },
  {
    tokenId: 8537,
    Tier: "Epic",
  },
  {
    tokenId: 8538,
    Tier: "Uncommon",
  },
  {
    tokenId: 8539,
    Tier: "Common",
  },
  {
    tokenId: 8540,
    Tier: "Epic",
  },
  {
    tokenId: 8541,
    Tier: "Common",
  },
  {
    tokenId: 8542,
    Tier: "Uncommon",
  },
  {
    tokenId: 8543,
    Tier: "Uncommon",
  },
  {
    tokenId: 8544,
    Tier: "Basic",
  },
  {
    tokenId: 8545,
    Tier: "Uncommon",
  },
  {
    tokenId: 8546,
    Tier: "Basic",
  },
  {
    tokenId: 8547,
    Tier: "Basic",
  },
  {
    tokenId: 8548,
    Tier: "Rare",
  },
  {
    tokenId: 8549,
    Tier: "Basic",
  },
  {
    tokenId: 8550,
    Tier: "Basic",
  },
  {
    tokenId: 8551,
    Tier: "Basic",
  },
  {
    tokenId: 8552,
    Tier: "Rare",
  },
  {
    tokenId: 8553,
    Tier: "Rare",
  },
  {
    tokenId: 8554,
    Tier: "Basic",
  },
  {
    tokenId: 8555,
    Tier: "Basic",
  },
  {
    tokenId: 8556,
    Tier: "Basic",
  },
  {
    tokenId: 8557,
    Tier: "Basic",
  },
  {
    tokenId: 8558,
    Tier: "Common",
  },
  {
    tokenId: 8559,
    Tier: "Uncommon",
  },
  {
    tokenId: 8560,
    Tier: "Uncommon",
  },
  {
    tokenId: 8561,
    Tier: "Rare",
  },
  {
    tokenId: 8562,
    Tier: "Epic",
  },
  {
    tokenId: 8563,
    Tier: "Basic",
  },
  {
    tokenId: 8564,
    Tier: "Epic",
  },
  {
    tokenId: 8565,
    Tier: "Basic",
  },
  {
    tokenId: 8566,
    Tier: "Rare",
  },
  {
    tokenId: 8567,
    Tier: "Rare",
  },
  {
    tokenId: 8568,
    Tier: "Basic",
  },
  {
    tokenId: 8569,
    Tier: "Basic",
  },
  {
    tokenId: 8570,
    Tier: "Basic",
  },
  {
    tokenId: 8571,
    Tier: "Common",
  },
  {
    tokenId: 8572,
    Tier: "Basic",
  },
  {
    tokenId: 8573,
    Tier: "Basic",
  },
  {
    tokenId: 8574,
    Tier: "Epic",
  },
  {
    tokenId: 8575,
    Tier: "Basic",
  },
  {
    tokenId: 8576,
    Tier: "Uncommon",
  },
  {
    tokenId: 8577,
    Tier: "Uncommon",
  },
  {
    tokenId: 8578,
    Tier: "Uncommon",
  },
  {
    tokenId: 8579,
    Tier: "Basic",
  },
  {
    tokenId: 8580,
    Tier: "Basic",
  },
  {
    tokenId: 8581,
    Tier: "Basic",
  },
  {
    tokenId: 8582,
    Tier: "Basic",
  },
  {
    tokenId: 8583,
    Tier: "Mythic",
  },
  {
    tokenId: 8584,
    Tier: "Basic",
  },
  {
    tokenId: 8585,
    Tier: "Uncommon",
  },
  {
    tokenId: 8586,
    Tier: "Common",
  },
  {
    tokenId: 8587,
    Tier: "Basic",
  },
  {
    tokenId: 8588,
    Tier: "Rare",
  },
  {
    tokenId: 8589,
    Tier: "Common",
  },
  {
    tokenId: 8590,
    Tier: "Basic",
  },
  {
    tokenId: 8591,
    Tier: "Rare",
  },
  {
    tokenId: 8592,
    Tier: "Basic",
  },
  {
    tokenId: 8593,
    Tier: "Basic",
  },
  {
    tokenId: 8594,
    Tier: "Common",
  },
  {
    tokenId: 8595,
    Tier: "Basic",
  },
  {
    tokenId: 8596,
    Tier: "Basic",
  },
  {
    tokenId: 8597,
    Tier: "Mythic",
  },
  {
    tokenId: 8598,
    Tier: "Rare",
  },
  {
    tokenId: 8599,
    Tier: "Basic",
  },
  {
    tokenId: 8600,
    Tier: "Basic",
  },
  {
    tokenId: 8601,
    Tier: "Uncommon",
  },
  {
    tokenId: 8602,
    Tier: "Legendary",
  },
  {
    tokenId: 8603,
    Tier: "Common",
  },
  {
    tokenId: 8604,
    Tier: "Basic",
  },
  {
    tokenId: 8605,
    Tier: "Basic",
  },
  {
    tokenId: 8606,
    Tier: "Basic",
  },
  {
    tokenId: 8607,
    Tier: "Basic",
  },
  {
    tokenId: 8608,
    Tier: "Basic",
  },
  {
    tokenId: 8609,
    Tier: "Basic",
  },
  {
    tokenId: 8610,
    Tier: "Common",
  },
  {
    tokenId: 8611,
    Tier: "Basic",
  },
  {
    tokenId: 8612,
    Tier: "Basic",
  },
  {
    tokenId: 8613,
    Tier: "Uncommon",
  },
  {
    tokenId: 8614,
    Tier: "Uncommon",
  },
  {
    tokenId: 8615,
    Tier: "Rare",
  },
  {
    tokenId: 8616,
    Tier: "Basic",
  },
  {
    tokenId: 8617,
    Tier: "Uncommon",
  },
  {
    tokenId: 8618,
    Tier: "Basic",
  },
  {
    tokenId: 8619,
    Tier: "Common",
  },
  {
    tokenId: 8620,
    Tier: "Basic",
  },
  {
    tokenId: 8621,
    Tier: "Basic",
  },
  {
    tokenId: 8622,
    Tier: "Uncommon",
  },
  {
    tokenId: 8623,
    Tier: "Basic",
  },
  {
    tokenId: 8624,
    Tier: "Uncommon",
  },
  {
    tokenId: 8625,
    Tier: "Basic",
  },
  {
    tokenId: 8626,
    Tier: "Basic",
  },
  {
    tokenId: 8627,
    Tier: "Mythic",
  },
  {
    tokenId: 8628,
    Tier: "Basic",
  },
  {
    tokenId: 8629,
    Tier: "Uncommon",
  },
  {
    tokenId: 8630,
    Tier: "Rare",
  },
  {
    tokenId: 8631,
    Tier: "Basic",
  },
  {
    tokenId: 8632,
    Tier: "Rare",
  },
  {
    tokenId: 8633,
    Tier: "Mythic",
  },
  {
    tokenId: 8634,
    Tier: "Epic",
  },
  {
    tokenId: 8635,
    Tier: "Epic",
  },
  {
    tokenId: 8636,
    Tier: "Common",
  },
  {
    tokenId: 8637,
    Tier: "Common",
  },
  {
    tokenId: 8638,
    Tier: "Basic",
  },
  {
    tokenId: 8639,
    Tier: "Legendary",
  },
  {
    tokenId: 8640,
    Tier: "Mythic",
  },
  {
    tokenId: 8641,
    Tier: "Basic",
  },
  {
    tokenId: 8642,
    Tier: "Uncommon",
  },
  {
    tokenId: 8643,
    Tier: "Basic",
  },
  {
    tokenId: 8644,
    Tier: "Uncommon",
  },
  {
    tokenId: 8645,
    Tier: "Uncommon",
  },
  {
    tokenId: 8646,
    Tier: "Basic",
  },
  {
    tokenId: 8647,
    Tier: "Basic",
  },
  {
    tokenId: 8648,
    Tier: "Mythic",
  },
  {
    tokenId: 8649,
    Tier: "Basic",
  },
  {
    tokenId: 8650,
    Tier: "Basic",
  },
  {
    tokenId: 8651,
    Tier: "Rare",
  },
  {
    tokenId: 8652,
    Tier: "Common",
  },
  {
    tokenId: 8653,
    Tier: "Basic",
  },
  {
    tokenId: 8654,
    Tier: "Basic",
  },
  {
    tokenId: 8655,
    Tier: "Epic",
  },
  {
    tokenId: 8656,
    Tier: "Common",
  },
  {
    tokenId: 8657,
    Tier: "Legendary",
  },
  {
    tokenId: 8658,
    Tier: "Basic",
  },
  {
    tokenId: 8659,
    Tier: "Basic",
  },
  {
    tokenId: 8660,
    Tier: "Common",
  },
  {
    tokenId: 8661,
    Tier: "Uncommon",
  },
  {
    tokenId: 8662,
    Tier: "Basic",
  },
  {
    tokenId: 8663,
    Tier: "Basic",
  },
  {
    tokenId: 8664,
    Tier: "Uncommon",
  },
  {
    tokenId: 8665,
    Tier: "Epic",
  },
  {
    tokenId: 8666,
    Tier: "Common",
  },
  {
    tokenId: 8667,
    Tier: "Uncommon",
  },
  {
    tokenId: 8668,
    Tier: "Basic",
  },
  {
    tokenId: 8669,
    Tier: "Legendary",
  },
  {
    tokenId: 8670,
    Tier: "Common",
  },
  {
    tokenId: 8671,
    Tier: "Uncommon",
  },
  {
    tokenId: 8672,
    Tier: "Basic",
  },
  {
    tokenId: 8673,
    Tier: "Basic",
  },
  {
    tokenId: 8674,
    Tier: "Common",
  },
  {
    tokenId: 8675,
    Tier: "Basic",
  },
  {
    tokenId: 8676,
    Tier: "Epic",
  },
  {
    tokenId: 8677,
    Tier: "Basic",
  },
  {
    tokenId: 8678,
    Tier: "Basic",
  },
  {
    tokenId: 8679,
    Tier: "Basic",
  },
  {
    tokenId: 8680,
    Tier: "Basic",
  },
  {
    tokenId: 8681,
    Tier: "Epic",
  },
  {
    tokenId: 8682,
    Tier: "Basic",
  },
  {
    tokenId: 8683,
    Tier: "Rare",
  },
  {
    tokenId: 8684,
    Tier: "Basic",
  },
  {
    tokenId: 8685,
    Tier: "Basic",
  },
  {
    tokenId: 8686,
    Tier: "Basic",
  },
  {
    tokenId: 8687,
    Tier: "Basic",
  },
  {
    tokenId: 8688,
    Tier: "Epic",
  },
  {
    tokenId: 8689,
    Tier: "Common",
  },
  {
    tokenId: 8690,
    Tier: "Common",
  },
  {
    tokenId: 8691,
    Tier: "Basic",
  },
  {
    tokenId: 8692,
    Tier: "Basic",
  },
  {
    tokenId: 8693,
    Tier: "Rare",
  },
  {
    tokenId: 8694,
    Tier: "Uncommon",
  },
  {
    tokenId: 8695,
    Tier: "Common",
  },
  {
    tokenId: 8696,
    Tier: "Basic",
  },
  {
    tokenId: 8697,
    Tier: "Basic",
  },
  {
    tokenId: 8698,
    Tier: "Common",
  },
  {
    tokenId: 8699,
    Tier: "Basic",
  },
  {
    tokenId: 8700,
    Tier: "Basic",
  },
  {
    tokenId: 8701,
    Tier: "Legendary",
  },
  {
    tokenId: 8702,
    Tier: "Mythic",
  },
  {
    tokenId: 8703,
    Tier: "Basic",
  },
  {
    tokenId: 8704,
    Tier: "Basic",
  },
  {
    tokenId: 8705,
    Tier: "Basic",
  },
  {
    tokenId: 8706,
    Tier: "Basic",
  },
  {
    tokenId: 8707,
    Tier: "Basic",
  },
  {
    tokenId: 8708,
    Tier: "Common",
  },
  {
    tokenId: 8709,
    Tier: "Basic",
  },
  {
    tokenId: 8710,
    Tier: "Rare",
  },
  {
    tokenId: 8711,
    Tier: "Common",
  },
  {
    tokenId: 8712,
    Tier: "Basic",
  },
  {
    tokenId: 8713,
    Tier: "Basic",
  },
  {
    tokenId: 8714,
    Tier: "Common",
  },
  {
    tokenId: 8715,
    Tier: "Basic",
  },
  {
    tokenId: 8716,
    Tier: "Epic",
  },
  {
    tokenId: 8717,
    Tier: "Basic",
  },
  {
    tokenId: 8718,
    Tier: "Uncommon",
  },
  {
    tokenId: 8719,
    Tier: "Basic",
  },
  {
    tokenId: 8720,
    Tier: "Legendary",
  },
  {
    tokenId: 8721,
    Tier: "Uncommon",
  },
  {
    tokenId: 8722,
    Tier: "Basic",
  },
  {
    tokenId: 8723,
    Tier: "Common",
  },
  {
    tokenId: 8724,
    Tier: "Uncommon",
  },
  {
    tokenId: 8725,
    Tier: "Basic",
  },
  {
    tokenId: 8726,
    Tier: "Mythic",
  },
  {
    tokenId: 8727,
    Tier: "Basic",
  },
  {
    tokenId: 8728,
    Tier: "Basic",
  },
  {
    tokenId: 8729,
    Tier: "Basic",
  },
  {
    tokenId: 8730,
    Tier: "Basic",
  },
  {
    tokenId: 8731,
    Tier: "Basic",
  },
  {
    tokenId: 8732,
    Tier: "Mythic",
  },
  {
    tokenId: 8733,
    Tier: "Uncommon",
  },
  {
    tokenId: 8734,
    Tier: "Basic",
  },
  {
    tokenId: 8735,
    Tier: "Basic",
  },
  {
    tokenId: 8736,
    Tier: "Basic",
  },
  {
    tokenId: 8737,
    Tier: "Basic",
  },
  {
    tokenId: 8738,
    Tier: "Basic",
  },
  {
    tokenId: 8739,
    Tier: "Rare",
  },
  {
    tokenId: 8740,
    Tier: "Basic",
  },
  {
    tokenId: 8741,
    Tier: "Common",
  },
  {
    tokenId: 8742,
    Tier: "Basic",
  },
  {
    tokenId: 8743,
    Tier: "Epic",
  },
  {
    tokenId: 8744,
    Tier: "Common",
  },
  {
    tokenId: 8745,
    Tier: "Basic",
  },
  {
    tokenId: 8746,
    Tier: "Basic",
  },
  {
    tokenId: 8747,
    Tier: "Basic",
  },
  {
    tokenId: 8748,
    Tier: "Common",
  },
  {
    tokenId: 8749,
    Tier: "Epic",
  },
  {
    tokenId: 8750,
    Tier: "Rare",
  },
  {
    tokenId: 8751,
    Tier: "Common",
  },
  {
    tokenId: 8752,
    Tier: "Basic",
  },
  {
    tokenId: 8753,
    Tier: "Basic",
  },
  {
    tokenId: 8754,
    Tier: "Rare",
  },
  {
    tokenId: 8755,
    Tier: "Common",
  },
  {
    tokenId: 8756,
    Tier: "Epic",
  },
  {
    tokenId: 8757,
    Tier: "Epic",
  },
  {
    tokenId: 8758,
    Tier: "Basic",
  },
  {
    tokenId: 8759,
    Tier: "Basic",
  },
  {
    tokenId: 8760,
    Tier: "Basic",
  },
  {
    tokenId: 8761,
    Tier: "Common",
  },
  {
    tokenId: 8762,
    Tier: "Uncommon",
  },
  {
    tokenId: 8763,
    Tier: "Basic",
  },
  {
    tokenId: 8764,
    Tier: "Uncommon",
  },
  {
    tokenId: 8765,
    Tier: "Rare",
  },
  {
    tokenId: 8766,
    Tier: "Rare",
  },
  {
    tokenId: 8767,
    Tier: "Basic",
  },
  {
    tokenId: 8768,
    Tier: "Basic",
  },
  {
    tokenId: 8769,
    Tier: "Uncommon",
  },
  {
    tokenId: 8770,
    Tier: "Common",
  },
  {
    tokenId: 8771,
    Tier: "Basic",
  },
  {
    tokenId: 8772,
    Tier: "Basic",
  },
  {
    tokenId: 8773,
    Tier: "Epic",
  },
  {
    tokenId: 8774,
    Tier: "Uncommon",
  },
  {
    tokenId: 8775,
    Tier: "Common",
  },
  {
    tokenId: 8776,
    Tier: "Common",
  },
  {
    tokenId: 8777,
    Tier: "Basic",
  },
  {
    tokenId: 8778,
    Tier: "Epic",
  },
  {
    tokenId: 8779,
    Tier: "Mythic",
  },
  {
    tokenId: 8780,
    Tier: "Basic",
  },
  {
    tokenId: 8781,
    Tier: "Basic",
  },
  {
    tokenId: 8782,
    Tier: "Basic",
  },
  {
    tokenId: 8783,
    Tier: "Basic",
  },
  {
    tokenId: 8784,
    Tier: "Common",
  },
  {
    tokenId: 8785,
    Tier: "Legendary",
  },
  {
    tokenId: 8786,
    Tier: "Uncommon",
  },
  {
    tokenId: 8787,
    Tier: "Basic",
  },
  {
    tokenId: 8788,
    Tier: "Common",
  },
  {
    tokenId: 8789,
    Tier: "Basic",
  },
  {
    tokenId: 8790,
    Tier: "Basic",
  },
  {
    tokenId: 8791,
    Tier: "Basic",
  },
  {
    tokenId: 8792,
    Tier: "Basic",
  },
  {
    tokenId: 8793,
    Tier: "Basic",
  },
  {
    tokenId: 8794,
    Tier: "Basic",
  },
  {
    tokenId: 8795,
    Tier: "Uncommon",
  },
  {
    tokenId: 8796,
    Tier: "Common",
  },
  {
    tokenId: 8797,
    Tier: "Rare",
  },
  {
    tokenId: 8798,
    Tier: "Rare",
  },
  {
    tokenId: 8799,
    Tier: "Uncommon",
  },
  {
    tokenId: 8800,
    Tier: "Uncommon",
  },
  {
    tokenId: 8801,
    Tier: "Rare",
  },
  {
    tokenId: 8802,
    Tier: "Rare",
  },
  {
    tokenId: 8803,
    Tier: "Epic",
  },
  {
    tokenId: 8804,
    Tier: "Basic",
  },
  {
    tokenId: 8805,
    Tier: "Basic",
  },
  {
    tokenId: 8806,
    Tier: "Basic",
  },
  {
    tokenId: 8807,
    Tier: "Epic",
  },
  {
    tokenId: 8808,
    Tier: "Uncommon",
  },
  {
    tokenId: 8809,
    Tier: "Basic",
  },
  {
    tokenId: 8810,
    Tier: "Basic",
  },
  {
    tokenId: 8811,
    Tier: "Basic",
  },
  {
    tokenId: 8812,
    Tier: "Uncommon",
  },
  {
    tokenId: 8813,
    Tier: "Basic",
  },
  {
    tokenId: 8814,
    Tier: "Basic",
  },
  {
    tokenId: 8815,
    Tier: "Common",
  },
  {
    tokenId: 8816,
    Tier: "Basic",
  },
  {
    tokenId: 8817,
    Tier: "Basic",
  },
  {
    tokenId: 8818,
    Tier: "Rare",
  },
  {
    tokenId: 8819,
    Tier: "Uncommon",
  },
  {
    tokenId: 8820,
    Tier: "Uncommon",
  },
  {
    tokenId: 8821,
    Tier: "Epic",
  },
  {
    tokenId: 8822,
    Tier: "Basic",
  },
  {
    tokenId: 8823,
    Tier: "Basic",
  },
  {
    tokenId: 8824,
    Tier: "Basic",
  },
  {
    tokenId: 8825,
    Tier: "Legendary",
  },
  {
    tokenId: 8826,
    Tier: "Legendary",
  },
  {
    tokenId: 8827,
    Tier: "Common",
  },
  {
    tokenId: 8828,
    Tier: "Epic",
  },
  {
    tokenId: 8829,
    Tier: "Common",
  },
  {
    tokenId: 8830,
    Tier: "Common",
  },
  {
    tokenId: 8831,
    Tier: "Basic",
  },
  {
    tokenId: 8832,
    Tier: "Basic",
  },
  {
    tokenId: 8833,
    Tier: "Basic",
  },
  {
    tokenId: 8834,
    Tier: "Rare",
  },
  {
    tokenId: 8835,
    Tier: "Uncommon",
  },
  {
    tokenId: 8836,
    Tier: "Basic",
  },
  {
    tokenId: 8837,
    Tier: "Basic",
  },
  {
    tokenId: 8838,
    Tier: "Basic",
  },
  {
    tokenId: 8839,
    Tier: "Rare",
  },
  {
    tokenId: 8840,
    Tier: "Uncommon",
  },
  {
    tokenId: 8841,
    Tier: "Basic",
  },
  {
    tokenId: 8842,
    Tier: "Uncommon",
  },
  {
    tokenId: 8843,
    Tier: "Basic",
  },
  {
    tokenId: 8844,
    Tier: "Epic",
  },
  {
    tokenId: 8845,
    Tier: "Basic",
  },
  {
    tokenId: 8846,
    Tier: "Basic",
  },
  {
    tokenId: 8847,
    Tier: "Basic",
  },
  {
    tokenId: 8848,
    Tier: "Common",
  },
  {
    tokenId: 8849,
    Tier: "Rare",
  },
  {
    tokenId: 8850,
    Tier: "Basic",
  },
  {
    tokenId: 8851,
    Tier: "Basic",
  },
  {
    tokenId: 8852,
    Tier: "Uncommon",
  },
  {
    tokenId: 8853,
    Tier: "Rare",
  },
  {
    tokenId: 8854,
    Tier: "Uncommon",
  },
  {
    tokenId: 8855,
    Tier: "Basic",
  },
  {
    tokenId: 8856,
    Tier: "Basic",
  },
  {
    tokenId: 8857,
    Tier: "Basic",
  },
  {
    tokenId: 8858,
    Tier: "Basic",
  },
  {
    tokenId: 8859,
    Tier: "Legendary",
  },
  {
    tokenId: 8860,
    Tier: "Basic",
  },
  {
    tokenId: 8861,
    Tier: "Basic",
  },
  {
    tokenId: 8862,
    Tier: "Basic",
  },
  {
    tokenId: 8863,
    Tier: "Uncommon",
  },
  {
    tokenId: 8864,
    Tier: "Basic",
  },
  {
    tokenId: 8865,
    Tier: "Basic",
  },
  {
    tokenId: 8866,
    Tier: "Basic",
  },
  {
    tokenId: 8867,
    Tier: "Basic",
  },
  {
    tokenId: 8868,
    Tier: "Epic",
  },
  {
    tokenId: 8869,
    Tier: "Basic",
  },
  {
    tokenId: 8870,
    Tier: "Common",
  },
  {
    tokenId: 8871,
    Tier: "Basic",
  },
  {
    tokenId: 8872,
    Tier: "Basic",
  },
  {
    tokenId: 8873,
    Tier: "Common",
  },
  {
    tokenId: 8874,
    Tier: "Basic",
  },
  {
    tokenId: 8875,
    Tier: "Basic",
  },
  {
    tokenId: 8876,
    Tier: "Common",
  },
  {
    tokenId: 8877,
    Tier: "Basic",
  },
  {
    tokenId: 8878,
    Tier: "Epic",
  },
  {
    tokenId: 8879,
    Tier: "Common",
  },
  {
    tokenId: 8880,
    Tier: "Common",
  },
  {
    tokenId: 8881,
    Tier: "Basic",
  },
  {
    tokenId: 8882,
    Tier: "Basic",
  },
  {
    tokenId: 8883,
    Tier: "Legendary",
  },
  {
    tokenId: 8884,
    Tier: "Common",
  },
  {
    tokenId: 8885,
    Tier: "Basic",
  },
  {
    tokenId: 8886,
    Tier: "Basic",
  },
  {
    tokenId: 8887,
    Tier: "Rare",
  },
  {
    tokenId: 8888,
    Tier: "Basic",
  },
  {
    tokenId: 8889,
    Tier: "Common",
  },
  {
    tokenId: 8890,
    Tier: "Basic",
  },
  {
    tokenId: 8891,
    Tier: "Uncommon",
  },
  {
    tokenId: 8892,
    Tier: "Common",
  },
  {
    tokenId: 8893,
    Tier: "Basic",
  },
  {
    tokenId: 8894,
    Tier: "Common",
  },
  {
    tokenId: 8895,
    Tier: "Legendary",
  },
  {
    tokenId: 8896,
    Tier: "Basic",
  },
  {
    tokenId: 8897,
    Tier: "Basic",
  },
  {
    tokenId: 8898,
    Tier: "Basic",
  },
  {
    tokenId: 8899,
    Tier: "Basic",
  },
  {
    tokenId: 8900,
    Tier: "Mythic",
  },
  {
    tokenId: 8901,
    Tier: "Basic",
  },
  {
    tokenId: 8902,
    Tier: "Basic",
  },
  {
    tokenId: 8903,
    Tier: "Rare",
  },
  {
    tokenId: 8904,
    Tier: "Uncommon",
  },
  {
    tokenId: 8905,
    Tier: "Mythic",
  },
  {
    tokenId: 8906,
    Tier: "Basic",
  },
  {
    tokenId: 8907,
    Tier: "Mythic",
  },
  {
    tokenId: 8908,
    Tier: "Common",
  },
  {
    tokenId: 8909,
    Tier: "Epic",
  },
  {
    tokenId: 8910,
    Tier: "Legendary",
  },
  {
    tokenId: 8911,
    Tier: "Basic",
  },
  {
    tokenId: 8912,
    Tier: "Epic",
  },
  {
    tokenId: 8913,
    Tier: "Basic",
  },
  {
    tokenId: 8914,
    Tier: "Common",
  },
  {
    tokenId: 8915,
    Tier: "Epic",
  },
  {
    tokenId: 8916,
    Tier: "Epic",
  },
  {
    tokenId: 8917,
    Tier: "Basic",
  },
  {
    tokenId: 8918,
    Tier: "Basic",
  },
  {
    tokenId: 8919,
    Tier: "Epic",
  },
  {
    tokenId: 8920,
    Tier: "Basic",
  },
  {
    tokenId: 8921,
    Tier: "Basic",
  },
  {
    tokenId: 8922,
    Tier: "Uncommon",
  },
  {
    tokenId: 8923,
    Tier: "Rare",
  },
  {
    tokenId: 8924,
    Tier: "Epic",
  },
  {
    tokenId: 8925,
    Tier: "Common",
  },
  {
    tokenId: 8926,
    Tier: "Basic",
  },
  {
    tokenId: 8927,
    Tier: "Basic",
  },
  {
    tokenId: 8928,
    Tier: "Basic",
  },
  {
    tokenId: 8929,
    Tier: "Basic",
  },
  {
    tokenId: 8930,
    Tier: "Basic",
  },
  {
    tokenId: 8931,
    Tier: "Legendary",
  },
  {
    tokenId: 8932,
    Tier: "Basic",
  },
  {
    tokenId: 8933,
    Tier: "Epic",
  },
  {
    tokenId: 8934,
    Tier: "Legendary",
  },
  {
    tokenId: 8935,
    Tier: "Basic",
  },
  {
    tokenId: 8936,
    Tier: "Mythic",
  },
  {
    tokenId: 8937,
    Tier: "Common",
  },
  {
    tokenId: 8938,
    Tier: "Basic",
  },
  {
    tokenId: 8939,
    Tier: "Basic",
  },
  {
    tokenId: 8940,
    Tier: "Common",
  },
  {
    tokenId: 8941,
    Tier: "Rare",
  },
  {
    tokenId: 8942,
    Tier: "Basic",
  },
  {
    tokenId: 8943,
    Tier: "Basic",
  },
  {
    tokenId: 8944,
    Tier: "Common",
  },
  {
    tokenId: 8945,
    Tier: "Uncommon",
  },
  {
    tokenId: 8946,
    Tier: "Basic",
  },
  {
    tokenId: 8947,
    Tier: "Epic",
  },
  {
    tokenId: 8948,
    Tier: "Common",
  },
  {
    tokenId: 8949,
    Tier: "Common",
  },
  {
    tokenId: 8950,
    Tier: "Basic",
  },
  {
    tokenId: 8951,
    Tier: "Basic",
  },
  {
    tokenId: 8952,
    Tier: "Basic",
  },
  {
    tokenId: 8953,
    Tier: "Basic",
  },
  {
    tokenId: 8954,
    Tier: "Uncommon",
  },
  {
    tokenId: 8955,
    Tier: "Legendary",
  },
  {
    tokenId: 8956,
    Tier: "Mythic",
  },
  {
    tokenId: 8957,
    Tier: "Rare",
  },
  {
    tokenId: 8958,
    Tier: "Basic",
  },
  {
    tokenId: 8959,
    Tier: "Basic",
  },
  {
    tokenId: 8960,
    Tier: "Basic",
  },
  {
    tokenId: 8961,
    Tier: "Epic",
  },
  {
    tokenId: 8962,
    Tier: "Rare",
  },
  {
    tokenId: 8963,
    Tier: "Basic",
  },
  {
    tokenId: 8964,
    Tier: "Basic",
  },
  {
    tokenId: 8965,
    Tier: "Basic",
  },
  {
    tokenId: 8966,
    Tier: "Basic",
  },
  {
    tokenId: 8967,
    Tier: "Epic",
  },
  {
    tokenId: 8968,
    Tier: "Mythic",
  },
  {
    tokenId: 8969,
    Tier: "Common",
  },
  {
    tokenId: 8970,
    Tier: "Basic",
  },
  {
    tokenId: 8971,
    Tier: "Basic",
  },
  {
    tokenId: 8972,
    Tier: "Uncommon",
  },
  {
    tokenId: 8973,
    Tier: "Basic",
  },
  {
    tokenId: 8974,
    Tier: "Common",
  },
  {
    tokenId: 8975,
    Tier: "Basic",
  },
  {
    tokenId: 8976,
    Tier: "Basic",
  },
  {
    tokenId: 8977,
    Tier: "Rare",
  },
  {
    tokenId: 8978,
    Tier: "Basic",
  },
  {
    tokenId: 8979,
    Tier: "Basic",
  },
  {
    tokenId: 8980,
    Tier: "Common",
  },
  {
    tokenId: 8981,
    Tier: "Basic",
  },
  {
    tokenId: 8982,
    Tier: "Common",
  },
  {
    tokenId: 8983,
    Tier: "Basic",
  },
  {
    tokenId: 8984,
    Tier: "Basic",
  },
  {
    tokenId: 8985,
    Tier: "Common",
  },
  {
    tokenId: 8986,
    Tier: "Basic",
  },
  {
    tokenId: 8987,
    Tier: "Legendary",
  },
  {
    tokenId: 8988,
    Tier: "Basic",
  },
  {
    tokenId: 8989,
    Tier: "Basic",
  },
  {
    tokenId: 8990,
    Tier: "Rare",
  },
  {
    tokenId: 8991,
    Tier: "Basic",
  },
  {
    tokenId: 8992,
    Tier: "Basic",
  },
  {
    tokenId: 8993,
    Tier: "Common",
  },
  {
    tokenId: 8994,
    Tier: "Basic",
  },
  {
    tokenId: 8995,
    Tier: "Basic",
  },
  {
    tokenId: 8996,
    Tier: "Basic",
  },
  {
    tokenId: 8997,
    Tier: "Basic",
  },
  {
    tokenId: 8998,
    Tier: "Basic",
  },
  {
    tokenId: 8999,
    Tier: "Common",
  },
  {
    tokenId: 9000,
    Tier: "Basic",
  },
  {
    tokenId: 9001,
    Tier: "Epic",
  },
  {
    tokenId: 9002,
    Tier: "Basic",
  },
  {
    tokenId: 9003,
    Tier: "Rare",
  },
  {
    tokenId: 9004,
    Tier: "Common",
  },
  {
    tokenId: 9005,
    Tier: "Rare",
  },
  {
    tokenId: 9006,
    Tier: "Basic",
  },
  {
    tokenId: 9007,
    Tier: "Basic",
  },
  {
    tokenId: 9008,
    Tier: "Common",
  },
  {
    tokenId: 9009,
    Tier: "Common",
  },
  {
    tokenId: 9010,
    Tier: "Common",
  },
  {
    tokenId: 9011,
    Tier: "Common",
  },
  {
    tokenId: 9012,
    Tier: "Rare",
  },
  {
    tokenId: 9013,
    Tier: "Uncommon",
  },
  {
    tokenId: 9014,
    Tier: "Uncommon",
  },
  {
    tokenId: 9015,
    Tier: "Common",
  },
  {
    tokenId: 9016,
    Tier: "Legendary",
  },
  {
    tokenId: 9017,
    Tier: "Basic",
  },
  {
    tokenId: 9018,
    Tier: "Rare",
  },
  {
    tokenId: 9019,
    Tier: "Uncommon",
  },
  {
    tokenId: 9020,
    Tier: "Basic",
  },
  {
    tokenId: 9021,
    Tier: "Basic",
  },
  {
    tokenId: 9022,
    Tier: "Epic",
  },
  {
    tokenId: 9023,
    Tier: "Basic",
  },
  {
    tokenId: 9024,
    Tier: "Basic",
  },
  {
    tokenId: 9025,
    Tier: "Common",
  },
  {
    tokenId: 9026,
    Tier: "Common",
  },
  {
    tokenId: 9027,
    Tier: "Basic",
  },
  {
    tokenId: 9028,
    Tier: "Basic",
  },
  {
    tokenId: 9029,
    Tier: "Basic",
  },
  {
    tokenId: 9030,
    Tier: "Basic",
  },
  {
    tokenId: 9031,
    Tier: "Rare",
  },
  {
    tokenId: 9032,
    Tier: "Rare",
  },
  {
    tokenId: 9033,
    Tier: "Uncommon",
  },
  {
    tokenId: 9034,
    Tier: "Basic",
  },
  {
    tokenId: 9035,
    Tier: "Epic",
  },
  {
    tokenId: 9036,
    Tier: "Basic",
  },
  {
    tokenId: 9037,
    Tier: "Basic",
  },
  {
    tokenId: 9038,
    Tier: "Basic",
  },
  {
    tokenId: 9039,
    Tier: "Common",
  },
  {
    tokenId: 9040,
    Tier: "Uncommon",
  },
  {
    tokenId: 9041,
    Tier: "Rare",
  },
  {
    tokenId: 9042,
    Tier: "Basic",
  },
  {
    tokenId: 9043,
    Tier: "Basic",
  },
  {
    tokenId: 9044,
    Tier: "Epic",
  },
  {
    tokenId: 9045,
    Tier: "Legendary",
  },
  {
    tokenId: 9046,
    Tier: "Basic",
  },
  {
    tokenId: 9047,
    Tier: "Basic",
  },
  {
    tokenId: 9048,
    Tier: "Common",
  },
  {
    tokenId: 9049,
    Tier: "Basic",
  },
  {
    tokenId: 9050,
    Tier: "Basic",
  },
  {
    tokenId: 9051,
    Tier: "Basic",
  },
  {
    tokenId: 9052,
    Tier: "Rare",
  },
  {
    tokenId: 9053,
    Tier: "Uncommon",
  },
  {
    tokenId: 9054,
    Tier: "Basic",
  },
  {
    tokenId: 9055,
    Tier: "Basic",
  },
  {
    tokenId: 9056,
    Tier: "Rare",
  },
  {
    tokenId: 9057,
    Tier: "Uncommon",
  },
  {
    tokenId: 9058,
    Tier: "Common",
  },
  {
    tokenId: 9059,
    Tier: "Mythic",
  },
  {
    tokenId: 9060,
    Tier: "Common",
  },
  {
    tokenId: 9061,
    Tier: "Epic",
  },
  {
    tokenId: 9062,
    Tier: "Common",
  },
  {
    tokenId: 9063,
    Tier: "Common",
  },
  {
    tokenId: 9064,
    Tier: "Basic",
  },
  {
    tokenId: 9065,
    Tier: "Epic",
  },
  {
    tokenId: 9066,
    Tier: "Basic",
  },
  {
    tokenId: 9067,
    Tier: "Basic",
  },
  {
    tokenId: 9068,
    Tier: "Epic",
  },
  {
    tokenId: 9069,
    Tier: "Basic",
  },
  {
    tokenId: 9070,
    Tier: "Common",
  },
  {
    tokenId: 9071,
    Tier: "Common",
  },
  {
    tokenId: 9072,
    Tier: "Epic",
  },
  {
    tokenId: 9073,
    Tier: "Common",
  },
  {
    tokenId: 9074,
    Tier: "Epic",
  },
  {
    tokenId: 9075,
    Tier: "Mythic",
  },
  {
    tokenId: 9076,
    Tier: "Basic",
  },
  {
    tokenId: 9077,
    Tier: "Basic",
  },
  {
    tokenId: 9078,
    Tier: "Rare",
  },
  {
    tokenId: 9079,
    Tier: "Epic",
  },
  {
    tokenId: 9080,
    Tier: "Basic",
  },
  {
    tokenId: 9081,
    Tier: "Uncommon",
  },
  {
    tokenId: 9082,
    Tier: "Common",
  },
  {
    tokenId: 9083,
    Tier: "Uncommon",
  },
  {
    tokenId: 9084,
    Tier: "Rare",
  },
  {
    tokenId: 9085,
    Tier: "Uncommon",
  },
  {
    tokenId: 9086,
    Tier: "Basic",
  },
  {
    tokenId: 9087,
    Tier: "Basic",
  },
  {
    tokenId: 9088,
    Tier: "Basic",
  },
  {
    tokenId: 9089,
    Tier: "Uncommon",
  },
  {
    tokenId: 9090,
    Tier: "Epic",
  },
  {
    tokenId: 9091,
    Tier: "Basic",
  },
  {
    tokenId: 9092,
    Tier: "Basic",
  },
  {
    tokenId: 9093,
    Tier: "Common",
  },
  {
    tokenId: 9094,
    Tier: "Basic",
  },
  {
    tokenId: 9095,
    Tier: "Basic",
  },
  {
    tokenId: 9096,
    Tier: "Basic",
  },
  {
    tokenId: 9097,
    Tier: "Rare",
  },
  {
    tokenId: 9098,
    Tier: "Rare",
  },
  {
    tokenId: 9099,
    Tier: "Basic",
  },
  {
    tokenId: 9100,
    Tier: "Basic",
  },
  {
    tokenId: 9101,
    Tier: "Basic",
  },
  {
    tokenId: 9102,
    Tier: "Legendary",
  },
  {
    tokenId: 9103,
    Tier: "Basic",
  },
  {
    tokenId: 9104,
    Tier: "Common",
  },
  {
    tokenId: 9105,
    Tier: "Basic",
  },
  {
    tokenId: 9106,
    Tier: "Basic",
  },
  {
    tokenId: 9107,
    Tier: "Basic",
  },
  {
    tokenId: 9108,
    Tier: "Basic",
  },
  {
    tokenId: 9109,
    Tier: "Basic",
  },
  {
    tokenId: 9110,
    Tier: "Rare",
  },
  {
    tokenId: 9111,
    Tier: "Rare",
  },
  {
    tokenId: 9112,
    Tier: "Common",
  },
  {
    tokenId: 9113,
    Tier: "Common",
  },
  {
    tokenId: 9114,
    Tier: "Uncommon",
  },
  {
    tokenId: 9115,
    Tier: "Legendary",
  },
  {
    tokenId: 9116,
    Tier: "Legendary",
  },
  {
    tokenId: 9117,
    Tier: "Epic",
  },
  {
    tokenId: 9118,
    Tier: "Basic",
  },
  {
    tokenId: 9119,
    Tier: "Basic",
  },
  {
    tokenId: 9120,
    Tier: "Common",
  },
  {
    tokenId: 9121,
    Tier: "Basic",
  },
  {
    tokenId: 9122,
    Tier: "Basic",
  },
  {
    tokenId: 9123,
    Tier: "Basic",
  },
  {
    tokenId: 9124,
    Tier: "Epic",
  },
  {
    tokenId: 9125,
    Tier: "Rare",
  },
  {
    tokenId: 9126,
    Tier: "Basic",
  },
  {
    tokenId: 9127,
    Tier: "Common",
  },
  {
    tokenId: 9128,
    Tier: "Rare",
  },
  {
    tokenId: 9129,
    Tier: "Common",
  },
  {
    tokenId: 9130,
    Tier: "Basic",
  },
  {
    tokenId: 9131,
    Tier: "Epic",
  },
  {
    tokenId: 9132,
    Tier: "Basic",
  },
  {
    tokenId: 9133,
    Tier: "Basic",
  },
  {
    tokenId: 9134,
    Tier: "Uncommon",
  },
  {
    tokenId: 9135,
    Tier: "Basic",
  },
  {
    tokenId: 9136,
    Tier: "Common",
  },
  {
    tokenId: 9137,
    Tier: "Basic",
  },
  {
    tokenId: 9138,
    Tier: "Basic",
  },
  {
    tokenId: 9139,
    Tier: "Basic",
  },
  {
    tokenId: 9140,
    Tier: "Legendary",
  },
  {
    tokenId: 9141,
    Tier: "Epic",
  },
  {
    tokenId: 9142,
    Tier: "Common",
  },
  {
    tokenId: 9143,
    Tier: "Mythic",
  },
  {
    tokenId: 9144,
    Tier: "Uncommon",
  },
  {
    tokenId: 9145,
    Tier: "Rare",
  },
  {
    tokenId: 9146,
    Tier: "Legendary",
  },
  {
    tokenId: 9147,
    Tier: "Exotic",
  },
  {
    tokenId: 9148,
    Tier: "Basic",
  },
  {
    tokenId: 9149,
    Tier: "Common",
  },
  {
    tokenId: 9150,
    Tier: "Legendary",
  },
  {
    tokenId: 9151,
    Tier: "Basic",
  },
  {
    tokenId: 9152,
    Tier: "Legendary",
  },
  {
    tokenId: 9153,
    Tier: "Common",
  },
  {
    tokenId: 9154,
    Tier: "Uncommon",
  },
  {
    tokenId: 9155,
    Tier: "Basic",
  },
  {
    tokenId: 9156,
    Tier: "Basic",
  },
  {
    tokenId: 9157,
    Tier: "Basic",
  },
  {
    tokenId: 9158,
    Tier: "Common",
  },
  {
    tokenId: 9159,
    Tier: "Basic",
  },
  {
    tokenId: 9160,
    Tier: "Uncommon",
  },
  {
    tokenId: 9161,
    Tier: "Basic",
  },
  {
    tokenId: 9162,
    Tier: "Mythic",
  },
  {
    tokenId: 9163,
    Tier: "Uncommon",
  },
  {
    tokenId: 9164,
    Tier: "Mythic",
  },
  {
    tokenId: 9165,
    Tier: "Basic",
  },
  {
    tokenId: 9166,
    Tier: "Common",
  },
  {
    tokenId: 9167,
    Tier: "Basic",
  },
  {
    tokenId: 9168,
    Tier: "Basic",
  },
  {
    tokenId: 9169,
    Tier: "Basic",
  },
  {
    tokenId: 9170,
    Tier: "Uncommon",
  },
  {
    tokenId: 9171,
    Tier: "Uncommon",
  },
  {
    tokenId: 9172,
    Tier: "Legendary",
  },
  {
    tokenId: 9173,
    Tier: "Rare",
  },
  {
    tokenId: 9174,
    Tier: "Rare",
  },
  {
    tokenId: 9175,
    Tier: "Basic",
  },
  {
    tokenId: 9176,
    Tier: "Basic",
  },
  {
    tokenId: 9177,
    Tier: "Common",
  },
  {
    tokenId: 9178,
    Tier: "Legendary",
  },
  {
    tokenId: 9179,
    Tier: "Basic",
  },
  {
    tokenId: 9180,
    Tier: "Basic",
  },
  {
    tokenId: 9181,
    Tier: "Basic",
  },
  {
    tokenId: 9182,
    Tier: "Basic",
  },
  {
    tokenId: 9183,
    Tier: "Basic",
  },
  {
    tokenId: 9184,
    Tier: "Rare",
  },
  {
    tokenId: 9185,
    Tier: "Common",
  },
  {
    tokenId: 9186,
    Tier: "Basic",
  },
  {
    tokenId: 9187,
    Tier: "Common",
  },
  {
    tokenId: 9188,
    Tier: "Basic",
  },
  {
    tokenId: 9189,
    Tier: "Common",
  },
  {
    tokenId: 9190,
    Tier: "Basic",
  },
  {
    tokenId: 9191,
    Tier: "Basic",
  },
  {
    tokenId: 9192,
    Tier: "Basic",
  },
  {
    tokenId: 9193,
    Tier: "Rare",
  },
  {
    tokenId: 9194,
    Tier: "Basic",
  },
  {
    tokenId: 9195,
    Tier: "Basic",
  },
  {
    tokenId: 9196,
    Tier: "Common",
  },
  {
    tokenId: 9197,
    Tier: "Uncommon",
  },
  {
    tokenId: 9198,
    Tier: "Uncommon",
  },
  {
    tokenId: 9199,
    Tier: "Basic",
  },
  {
    tokenId: 9200,
    Tier: "Basic",
  },
  {
    tokenId: 9201,
    Tier: "Basic",
  },
  {
    tokenId: 9202,
    Tier: "Basic",
  },
  {
    tokenId: 9203,
    Tier: "Rare",
  },
  {
    tokenId: 9204,
    Tier: "Basic",
  },
  {
    tokenId: 9205,
    Tier: "Basic",
  },
  {
    tokenId: 9206,
    Tier: "Common",
  },
  {
    tokenId: 9207,
    Tier: "Rare",
  },
  {
    tokenId: 9208,
    Tier: "Rare",
  },
  {
    tokenId: 9209,
    Tier: "Basic",
  },
  {
    tokenId: 9210,
    Tier: "Basic",
  },
  {
    tokenId: 9211,
    Tier: "Basic",
  },
  {
    tokenId: 9212,
    Tier: "Basic",
  },
  {
    tokenId: 9213,
    Tier: "Uncommon",
  },
  {
    tokenId: 9214,
    Tier: "Rare",
  },
  {
    tokenId: 9215,
    Tier: "Uncommon",
  },
  {
    tokenId: 9216,
    Tier: "Common",
  },
  {
    tokenId: 9217,
    Tier: "Basic",
  },
  {
    tokenId: 9218,
    Tier: "Basic",
  },
  {
    tokenId: 9219,
    Tier: "Basic",
  },
  {
    tokenId: 9220,
    Tier: "Basic",
  },
  {
    tokenId: 9221,
    Tier: "Common",
  },
  {
    tokenId: 9222,
    Tier: "Rare",
  },
  {
    tokenId: 9223,
    Tier: "Basic",
  },
  {
    tokenId: 9224,
    Tier: "Basic",
  },
  {
    tokenId: 9225,
    Tier: "Common",
  },
  {
    tokenId: 9226,
    Tier: "Basic",
  },
  {
    tokenId: 9227,
    Tier: "Basic",
  },
  {
    tokenId: 9228,
    Tier: "Epic",
  },
  {
    tokenId: 9229,
    Tier: "Legendary",
  },
  {
    tokenId: 9230,
    Tier: "Basic",
  },
  {
    tokenId: 9231,
    Tier: "Exotic",
  },
  {
    tokenId: 9232,
    Tier: "Uncommon",
  },
  {
    tokenId: 9233,
    Tier: "Legendary",
  },
  {
    tokenId: 9234,
    Tier: "Basic",
  },
  {
    tokenId: 9235,
    Tier: "Common",
  },
  {
    tokenId: 9236,
    Tier: "Basic",
  },
  {
    tokenId: 9237,
    Tier: "Basic",
  },
  {
    tokenId: 9238,
    Tier: "Uncommon",
  },
  {
    tokenId: 9239,
    Tier: "Basic",
  },
  {
    tokenId: 9240,
    Tier: "Basic",
  },
  {
    tokenId: 9241,
    Tier: "Common",
  },
  {
    tokenId: 9242,
    Tier: "Rare",
  },
  {
    tokenId: 9243,
    Tier: "Common",
  },
  {
    tokenId: 9244,
    Tier: "Common",
  },
  {
    tokenId: 9245,
    Tier: "Basic",
  },
  {
    tokenId: 9246,
    Tier: "Rare",
  },
  {
    tokenId: 9247,
    Tier: "Rare",
  },
  {
    tokenId: 9248,
    Tier: "Rare",
  },
  {
    tokenId: 9249,
    Tier: "Mythic",
  },
  {
    tokenId: 9250,
    Tier: "Common",
  },
  {
    tokenId: 9251,
    Tier: "Uncommon",
  },
  {
    tokenId: 9252,
    Tier: "Basic",
  },
  {
    tokenId: 9253,
    Tier: "Basic",
  },
  {
    tokenId: 9254,
    Tier: "Basic",
  },
  {
    tokenId: 9255,
    Tier: "Basic",
  },
  {
    tokenId: 9256,
    Tier: "Basic",
  },
  {
    tokenId: 9257,
    Tier: "Basic",
  },
  {
    tokenId: 9258,
    Tier: "Basic",
  },
  {
    tokenId: 9259,
    Tier: "Basic",
  },
  {
    tokenId: 9260,
    Tier: "Basic",
  },
  {
    tokenId: 9261,
    Tier: "Mythic",
  },
  {
    tokenId: 9262,
    Tier: "Basic",
  },
  {
    tokenId: 9263,
    Tier: "Common",
  },
  {
    tokenId: 9264,
    Tier: "Common",
  },
  {
    tokenId: 9265,
    Tier: "Basic",
  },
  {
    tokenId: 9266,
    Tier: "Basic",
  },
  {
    tokenId: 9267,
    Tier: "Common",
  },
  {
    tokenId: 9268,
    Tier: "Basic",
  },
  {
    tokenId: 9269,
    Tier: "Uncommon",
  },
  {
    tokenId: 9270,
    Tier: "Basic",
  },
  {
    tokenId: 9271,
    Tier: "Basic",
  },
  {
    tokenId: 9272,
    Tier: "Basic",
  },
  {
    tokenId: 9273,
    Tier: "Legendary",
  },
  {
    tokenId: 9274,
    Tier: "Basic",
  },
  {
    tokenId: 9275,
    Tier: "Basic",
  },
  {
    tokenId: 9276,
    Tier: "Common",
  },
  {
    tokenId: 9277,
    Tier: "Mythic",
  },
  {
    tokenId: 9278,
    Tier: "Basic",
  },
  {
    tokenId: 9279,
    Tier: "Basic",
  },
  {
    tokenId: 9280,
    Tier: "Rare",
  },
  {
    tokenId: 9281,
    Tier: "Basic",
  },
  {
    tokenId: 9282,
    Tier: "Basic",
  },
  {
    tokenId: 9283,
    Tier: "Basic",
  },
  {
    tokenId: 9284,
    Tier: "Legendary",
  },
  {
    tokenId: 9285,
    Tier: "Legendary",
  },
  {
    tokenId: 9286,
    Tier: "Basic",
  },
  {
    tokenId: 9287,
    Tier: "Rare",
  },
  {
    tokenId: 9288,
    Tier: "Basic",
  },
  {
    tokenId: 9289,
    Tier: "Mythic",
  },
  {
    tokenId: 9290,
    Tier: "Uncommon",
  },
  {
    tokenId: 9291,
    Tier: "Common",
  },
  {
    tokenId: 9292,
    Tier: "Rare",
  },
  {
    tokenId: 9293,
    Tier: "Basic",
  },
  {
    tokenId: 9294,
    Tier: "Basic",
  },
  {
    tokenId: 9295,
    Tier: "Epic",
  },
  {
    tokenId: 9296,
    Tier: "Uncommon",
  },
  {
    tokenId: 9297,
    Tier: "Basic",
  },
  {
    tokenId: 9298,
    Tier: "Common",
  },
  {
    tokenId: 9299,
    Tier: "Epic",
  },
  {
    tokenId: 9300,
    Tier: "Common",
  },
  {
    tokenId: 9301,
    Tier: "Common",
  },
  {
    tokenId: 9302,
    Tier: "Basic",
  },
  {
    tokenId: 9303,
    Tier: "Basic",
  },
  {
    tokenId: 9304,
    Tier: "Basic",
  },
  {
    tokenId: 9305,
    Tier: "Basic",
  },
  {
    tokenId: 9306,
    Tier: "Basic",
  },
  {
    tokenId: 9307,
    Tier: "Basic",
  },
  {
    tokenId: 9308,
    Tier: "Common",
  },
  {
    tokenId: 9309,
    Tier: "Basic",
  },
  {
    tokenId: 9310,
    Tier: "Rare",
  },
  {
    tokenId: 9311,
    Tier: "Basic",
  },
  {
    tokenId: 9312,
    Tier: "Basic",
  },
  {
    tokenId: 9313,
    Tier: "Uncommon",
  },
  {
    tokenId: 9314,
    Tier: "Uncommon",
  },
  {
    tokenId: 9315,
    Tier: "Basic",
  },
  {
    tokenId: 9316,
    Tier: "Rare",
  },
  {
    tokenId: 9317,
    Tier: "Common",
  },
  {
    tokenId: 9318,
    Tier: "Basic",
  },
  {
    tokenId: 9319,
    Tier: "Basic",
  },
  {
    tokenId: 9320,
    Tier: "Basic",
  },
  {
    tokenId: 9321,
    Tier: "Uncommon",
  },
  {
    tokenId: 9322,
    Tier: "Epic",
  },
  {
    tokenId: 9323,
    Tier: "Basic",
  },
  {
    tokenId: 9324,
    Tier: "Epic",
  },
  {
    tokenId: 9325,
    Tier: "Basic",
  },
  {
    tokenId: 9326,
    Tier: "Uncommon",
  },
  {
    tokenId: 9327,
    Tier: "Basic",
  },
  {
    tokenId: 9328,
    Tier: "Basic",
  },
  {
    tokenId: 9329,
    Tier: "Common",
  },
  {
    tokenId: 9330,
    Tier: "Basic",
  },
  {
    tokenId: 9331,
    Tier: "Common",
  },
  {
    tokenId: 9332,
    Tier: "Common",
  },
  {
    tokenId: 9333,
    Tier: "Uncommon",
  },
  {
    tokenId: 9334,
    Tier: "Basic",
  },
  {
    tokenId: 9335,
    Tier: "Basic",
  },
  {
    tokenId: 9336,
    Tier: "Uncommon",
  },
  {
    tokenId: 9337,
    Tier: "Uncommon",
  },
  {
    tokenId: 9338,
    Tier: "Basic",
  },
  {
    tokenId: 9339,
    Tier: "Epic",
  },
  {
    tokenId: 9340,
    Tier: "Basic",
  },
  {
    tokenId: 9341,
    Tier: "Basic",
  },
  {
    tokenId: 9342,
    Tier: "Epic",
  },
  {
    tokenId: 9343,
    Tier: "Basic",
  },
  {
    tokenId: 9344,
    Tier: "Mythic",
  },
  {
    tokenId: 9345,
    Tier: "Basic",
  },
  {
    tokenId: 9346,
    Tier: "Basic",
  },
  {
    tokenId: 9347,
    Tier: "Basic",
  },
  {
    tokenId: 9348,
    Tier: "Epic",
  },
  {
    tokenId: 9349,
    Tier: "Basic",
  },
  {
    tokenId: 9350,
    Tier: "Common",
  },
  {
    tokenId: 9351,
    Tier: "Basic",
  },
  {
    tokenId: 9352,
    Tier: "Basic",
  },
  {
    tokenId: 9353,
    Tier: "Legendary",
  },
  {
    tokenId: 9354,
    Tier: "Common",
  },
  {
    tokenId: 9355,
    Tier: "Basic",
  },
  {
    tokenId: 9356,
    Tier: "Common",
  },
  {
    tokenId: 9357,
    Tier: "Basic",
  },
  {
    tokenId: 9358,
    Tier: "Exotic",
  },
  {
    tokenId: 9359,
    Tier: "Basic",
  },
  {
    tokenId: 9360,
    Tier: "Basic",
  },
  {
    tokenId: 9361,
    Tier: "Common",
  },
  {
    tokenId: 9362,
    Tier: "Basic",
  },
  {
    tokenId: 9363,
    Tier: "Basic",
  },
  {
    tokenId: 9364,
    Tier: "Rare",
  },
  {
    tokenId: 9365,
    Tier: "Uncommon",
  },
  {
    tokenId: 9366,
    Tier: "Uncommon",
  },
  {
    tokenId: 9367,
    Tier: "Basic",
  },
  {
    tokenId: 9368,
    Tier: "Uncommon",
  },
  {
    tokenId: 9369,
    Tier: "Basic",
  },
  {
    tokenId: 9370,
    Tier: "Basic",
  },
  {
    tokenId: 9371,
    Tier: "Basic",
  },
  {
    tokenId: 9372,
    Tier: "Common",
  },
  {
    tokenId: 9373,
    Tier: "Rare",
  },
  {
    tokenId: 9374,
    Tier: "Uncommon",
  },
  {
    tokenId: 9375,
    Tier: "Rare",
  },
  {
    tokenId: 9376,
    Tier: "Basic",
  },
  {
    tokenId: 9377,
    Tier: "Common",
  },
  {
    tokenId: 9378,
    Tier: "Basic",
  },
  {
    tokenId: 9379,
    Tier: "Uncommon",
  },
  {
    tokenId: 9380,
    Tier: "Basic",
  },
  {
    tokenId: 9381,
    Tier: "Rare",
  },
  {
    tokenId: 9382,
    Tier: "Mythic",
  },
  {
    tokenId: 9383,
    Tier: "Basic",
  },
  {
    tokenId: 9384,
    Tier: "Rare",
  },
  {
    tokenId: 9385,
    Tier: "Uncommon",
  },
  {
    tokenId: 9386,
    Tier: "Legendary",
  },
  {
    tokenId: 9387,
    Tier: "Basic",
  },
  {
    tokenId: 9388,
    Tier: "Uncommon",
  },
  {
    tokenId: 9389,
    Tier: "Common",
  },
  {
    tokenId: 9390,
    Tier: "Basic",
  },
  {
    tokenId: 9391,
    Tier: "Basic",
  },
  {
    tokenId: 9392,
    Tier: "Common",
  },
  {
    tokenId: 9393,
    Tier: "Basic",
  },
  {
    tokenId: 9394,
    Tier: "Legendary",
  },
  {
    tokenId: 9395,
    Tier: "Basic",
  },
  {
    tokenId: 9396,
    Tier: "Basic",
  },
  {
    tokenId: 9397,
    Tier: "Basic",
  },
  {
    tokenId: 9398,
    Tier: "Basic",
  },
  {
    tokenId: 9399,
    Tier: "Rare",
  },
  {
    tokenId: 9400,
    Tier: "Epic",
  },
  {
    tokenId: 9401,
    Tier: "Epic",
  },
  {
    tokenId: 9402,
    Tier: "Rare",
  },
  {
    tokenId: 9403,
    Tier: "Basic",
  },
  {
    tokenId: 9404,
    Tier: "Basic",
  },
  {
    tokenId: 9405,
    Tier: "Epic",
  },
  {
    tokenId: 9406,
    Tier: "Basic",
  },
  {
    tokenId: 9407,
    Tier: "Basic",
  },
  {
    tokenId: 9408,
    Tier: "Common",
  },
  {
    tokenId: 9409,
    Tier: "Basic",
  },
  {
    tokenId: 9410,
    Tier: "Basic",
  },
  {
    tokenId: 9411,
    Tier: "Basic",
  },
  {
    tokenId: 9412,
    Tier: "Rare",
  },
  {
    tokenId: 9413,
    Tier: "Uncommon",
  },
  {
    tokenId: 9414,
    Tier: "Basic",
  },
  {
    tokenId: 9415,
    Tier: "Epic",
  },
  {
    tokenId: 9416,
    Tier: "Mythic",
  },
  {
    tokenId: 9417,
    Tier: "Basic",
  },
  {
    tokenId: 9418,
    Tier: "Basic",
  },
  {
    tokenId: 9419,
    Tier: "Basic",
  },
  {
    tokenId: 9420,
    Tier: "Basic",
  },
  {
    tokenId: 9421,
    Tier: "Rare",
  },
  {
    tokenId: 9422,
    Tier: "Basic",
  },
  {
    tokenId: 9423,
    Tier: "Basic",
  },
  {
    tokenId: 9424,
    Tier: "Basic",
  },
  {
    tokenId: 9425,
    Tier: "Basic",
  },
  {
    tokenId: 9426,
    Tier: "Uncommon",
  },
  {
    tokenId: 9427,
    Tier: "Mythic",
  },
  {
    tokenId: 9428,
    Tier: "Basic",
  },
  {
    tokenId: 9429,
    Tier: "Mythic",
  },
  {
    tokenId: 9430,
    Tier: "Basic",
  },
  {
    tokenId: 9431,
    Tier: "Uncommon",
  },
  {
    tokenId: 9432,
    Tier: "Legendary",
  },
  {
    tokenId: 9433,
    Tier: "Basic",
  },
  {
    tokenId: 9434,
    Tier: "Legendary",
  },
  {
    tokenId: 9435,
    Tier: "Rare",
  },
  {
    tokenId: 9436,
    Tier: "Basic",
  },
  {
    tokenId: 9437,
    Tier: "Basic",
  },
  {
    tokenId: 9438,
    Tier: "Uncommon",
  },
  {
    tokenId: 9439,
    Tier: "Common",
  },
  {
    tokenId: 9440,
    Tier: "Basic",
  },
  {
    tokenId: 9441,
    Tier: "Basic",
  },
  {
    tokenId: 9442,
    Tier: "Basic",
  },
  {
    tokenId: 9443,
    Tier: "Uncommon",
  },
  {
    tokenId: 9444,
    Tier: "Legendary",
  },
  {
    tokenId: 9445,
    Tier: "Basic",
  },
  {
    tokenId: 9446,
    Tier: "Basic",
  },
  {
    tokenId: 9447,
    Tier: "Common",
  },
  {
    tokenId: 9448,
    Tier: "Basic",
  },
  {
    tokenId: 9449,
    Tier: "Basic",
  },
  {
    tokenId: 9450,
    Tier: "Epic",
  },
  {
    tokenId: 9451,
    Tier: "Basic",
  },
  {
    tokenId: 9452,
    Tier: "Basic",
  },
  {
    tokenId: 9453,
    Tier: "Basic",
  },
  {
    tokenId: 9454,
    Tier: "Basic",
  },
  {
    tokenId: 9455,
    Tier: "Basic",
  },
  {
    tokenId: 9456,
    Tier: "Common",
  },
  {
    tokenId: 9457,
    Tier: "Basic",
  },
  {
    tokenId: 9458,
    Tier: "Basic",
  },
  {
    tokenId: 9459,
    Tier: "Rare",
  },
  {
    tokenId: 9460,
    Tier: "Basic",
  },
  {
    tokenId: 9461,
    Tier: "Basic",
  },
  {
    tokenId: 9462,
    Tier: "Epic",
  },
  {
    tokenId: 9463,
    Tier: "Basic",
  },
  {
    tokenId: 9464,
    Tier: "Uncommon",
  },
  {
    tokenId: 9465,
    Tier: "Common",
  },
  {
    tokenId: 9466,
    Tier: "Basic",
  },
  {
    tokenId: 9467,
    Tier: "Legendary",
  },
  {
    tokenId: 9468,
    Tier: "Epic",
  },
  {
    tokenId: 9469,
    Tier: "Common",
  },
  {
    tokenId: 9470,
    Tier: "Legendary",
  },
  {
    tokenId: 9471,
    Tier: "Uncommon",
  },
  {
    tokenId: 9472,
    Tier: "Common",
  },
  {
    tokenId: 9473,
    Tier: "Mythic",
  },
  {
    tokenId: 9474,
    Tier: "Basic",
  },
  {
    tokenId: 9475,
    Tier: "Basic",
  },
  {
    tokenId: 9476,
    Tier: "Basic",
  },
  {
    tokenId: 9477,
    Tier: "Basic",
  },
  {
    tokenId: 9478,
    Tier: "Rare",
  },
  {
    tokenId: 9479,
    Tier: "Basic",
  },
  {
    tokenId: 9480,
    Tier: "Basic",
  },
  {
    tokenId: 9481,
    Tier: "Common",
  },
  {
    tokenId: 9482,
    Tier: "Basic",
  },
  {
    tokenId: 9483,
    Tier: "Common",
  },
  {
    tokenId: 9484,
    Tier: "Basic",
  },
  {
    tokenId: 9485,
    Tier: "Mythic",
  },
  {
    tokenId: 9486,
    Tier: "Basic",
  },
  {
    tokenId: 9487,
    Tier: "Basic",
  },
  {
    tokenId: 9488,
    Tier: "Basic",
  },
  {
    tokenId: 9489,
    Tier: "Basic",
  },
  {
    tokenId: 9490,
    Tier: "Epic",
  },
  {
    tokenId: 9491,
    Tier: "Common",
  },
  {
    tokenId: 9492,
    Tier: "Basic",
  },
  {
    tokenId: 9493,
    Tier: "Legendary",
  },
  {
    tokenId: 9494,
    Tier: "Epic",
  },
  {
    tokenId: 9495,
    Tier: "Basic",
  },
  {
    tokenId: 9496,
    Tier: "Basic",
  },
  {
    tokenId: 9497,
    Tier: "Rare",
  },
  {
    tokenId: 9498,
    Tier: "Basic",
  },
  {
    tokenId: 9499,
    Tier: "Uncommon",
  },
  {
    tokenId: 9500,
    Tier: "Basic",
  },
  {
    tokenId: 9501,
    Tier: "Basic",
  },
  {
    tokenId: 9502,
    Tier: "Rare",
  },
  {
    tokenId: 9503,
    Tier: "Basic",
  },
  {
    tokenId: 9504,
    Tier: "Rare",
  },
  {
    tokenId: 9505,
    Tier: "Basic",
  },
  {
    tokenId: 9506,
    Tier: "Basic",
  },
  {
    tokenId: 9507,
    Tier: "Exotic",
  },
  {
    tokenId: 9508,
    Tier: "Basic",
  },
  {
    tokenId: 9509,
    Tier: "Exotic",
  },
  {
    tokenId: 9510,
    Tier: "Basic",
  },
  {
    tokenId: 9511,
    Tier: "Basic",
  },
  {
    tokenId: 9512,
    Tier: "Basic",
  },
  {
    tokenId: 9513,
    Tier: "Mythic",
  },
  {
    tokenId: 9514,
    Tier: "Rare",
  },
  {
    tokenId: 9515,
    Tier: "Rare",
  },
  {
    tokenId: 9516,
    Tier: "Basic",
  },
  {
    tokenId: 9517,
    Tier: "Rare",
  },
  {
    tokenId: 9518,
    Tier: "Basic",
  },
  {
    tokenId: 9519,
    Tier: "Basic",
  },
  {
    tokenId: 9520,
    Tier: "Basic",
  },
  {
    tokenId: 9521,
    Tier: "Common",
  },
  {
    tokenId: 9522,
    Tier: "Basic",
  },
  {
    tokenId: 9523,
    Tier: "Basic",
  },
  {
    tokenId: 9524,
    Tier: "Common",
  },
  {
    tokenId: 9525,
    Tier: "Basic",
  },
  {
    tokenId: 9526,
    Tier: "Rare",
  },
  {
    tokenId: 9527,
    Tier: "Rare",
  },
  {
    tokenId: 9528,
    Tier: "Uncommon",
  },
  {
    tokenId: 9529,
    Tier: "Common",
  },
  {
    tokenId: 9530,
    Tier: "Rare",
  },
  {
    tokenId: 9531,
    Tier: "Basic",
  },
  {
    tokenId: 9532,
    Tier: "Basic",
  },
  {
    tokenId: 9533,
    Tier: "Legendary",
  },
  {
    tokenId: 9534,
    Tier: "Rare",
  },
  {
    tokenId: 9535,
    Tier: "Basic",
  },
  {
    tokenId: 9536,
    Tier: "Basic",
  },
  {
    tokenId: 9537,
    Tier: "Basic",
  },
  {
    tokenId: 9538,
    Tier: "Common",
  },
  {
    tokenId: 9539,
    Tier: "Basic",
  },
  {
    tokenId: 9540,
    Tier: "Epic",
  },
  {
    tokenId: 9541,
    Tier: "Common",
  },
  {
    tokenId: 9542,
    Tier: "Basic",
  },
  {
    tokenId: 9543,
    Tier: "Basic",
  },
  {
    tokenId: 9544,
    Tier: "Basic",
  },
  {
    tokenId: 9545,
    Tier: "Uncommon",
  },
  {
    tokenId: 9546,
    Tier: "Legendary",
  },
  {
    tokenId: 9547,
    Tier: "Basic",
  },
  {
    tokenId: 9548,
    Tier: "Basic",
  },
  {
    tokenId: 9549,
    Tier: "Basic",
  },
  {
    tokenId: 9550,
    Tier: "Rare",
  },
  {
    tokenId: 9551,
    Tier: "Basic",
  },
  {
    tokenId: 9552,
    Tier: "Basic",
  },
  {
    tokenId: 9553,
    Tier: "Epic",
  },
  {
    tokenId: 9554,
    Tier: "Basic",
  },
  {
    tokenId: 9555,
    Tier: "Common",
  },
  {
    tokenId: 9556,
    Tier: "Basic",
  },
  {
    tokenId: 9557,
    Tier: "Basic",
  },
  {
    tokenId: 9558,
    Tier: "Basic",
  },
  {
    tokenId: 9559,
    Tier: "Basic",
  },
  {
    tokenId: 9560,
    Tier: "Uncommon",
  },
  {
    tokenId: 9561,
    Tier: "Basic",
  },
  {
    tokenId: 9562,
    Tier: "Basic",
  },
  {
    tokenId: 9563,
    Tier: "Basic",
  },
  {
    tokenId: 9564,
    Tier: "Basic",
  },
  {
    tokenId: 9565,
    Tier: "Basic",
  },
  {
    tokenId: 9566,
    Tier: "Epic",
  },
  {
    tokenId: 9567,
    Tier: "Basic",
  },
  {
    tokenId: 9568,
    Tier: "Epic",
  },
  {
    tokenId: 9569,
    Tier: "Legendary",
  },
  {
    tokenId: 9570,
    Tier: "Rare",
  },
  {
    tokenId: 9571,
    Tier: "Common",
  },
  {
    tokenId: 9572,
    Tier: "Common",
  },
  {
    tokenId: 9573,
    Tier: "Basic",
  },
  {
    tokenId: 9574,
    Tier: "Epic",
  },
  {
    tokenId: 9575,
    Tier: "Basic",
  },
  {
    tokenId: 9576,
    Tier: "Common",
  },
  {
    tokenId: 9577,
    Tier: "Basic",
  },
  {
    tokenId: 9578,
    Tier: "Basic",
  },
  {
    tokenId: 9579,
    Tier: "Legendary",
  },
  {
    tokenId: 9580,
    Tier: "Basic",
  },
  {
    tokenId: 9581,
    Tier: "Rare",
  },
  {
    tokenId: 9582,
    Tier: "Basic",
  },
  {
    tokenId: 9583,
    Tier: "Basic",
  },
  {
    tokenId: 9584,
    Tier: "Epic",
  },
  {
    tokenId: 9585,
    Tier: "Basic",
  },
  {
    tokenId: 9586,
    Tier: "Common",
  },
  {
    tokenId: 9587,
    Tier: "Uncommon",
  },
  {
    tokenId: 9588,
    Tier: "Basic",
  },
  {
    tokenId: 9589,
    Tier: "Common",
  },
  {
    tokenId: 9590,
    Tier: "Basic",
  },
  {
    tokenId: 9591,
    Tier: "Common",
  },
  {
    tokenId: 9592,
    Tier: "Common",
  },
  {
    tokenId: 9593,
    Tier: "Uncommon",
  },
  {
    tokenId: 9594,
    Tier: "Basic",
  },
  {
    tokenId: 9595,
    Tier: "Legendary",
  },
  {
    tokenId: 9596,
    Tier: "Uncommon",
  },
  {
    tokenId: 9597,
    Tier: "Basic",
  },
  {
    tokenId: 9598,
    Tier: "Epic",
  },
  {
    tokenId: 9599,
    Tier: "Uncommon",
  },
  {
    tokenId: 9600,
    Tier: "Basic",
  },
  {
    tokenId: 9601,
    Tier: "Basic",
  },
  {
    tokenId: 9602,
    Tier: "Basic",
  },
  {
    tokenId: 9603,
    Tier: "Uncommon",
  },
  {
    tokenId: 9604,
    Tier: "Common",
  },
  {
    tokenId: 9605,
    Tier: "Basic",
  },
  {
    tokenId: 9606,
    Tier: "Basic",
  },
  {
    tokenId: 9607,
    Tier: "Uncommon",
  },
  {
    tokenId: 9608,
    Tier: "Epic",
  },
  {
    tokenId: 9609,
    Tier: "Legendary",
  },
  {
    tokenId: 9610,
    Tier: "Basic",
  },
  {
    tokenId: 9611,
    Tier: "Epic",
  },
  {
    tokenId: 9612,
    Tier: "Basic",
  },
  {
    tokenId: 9613,
    Tier: "Common",
  },
  {
    tokenId: 9614,
    Tier: "Basic",
  },
  {
    tokenId: 9615,
    Tier: "Epic",
  },
  {
    tokenId: 9616,
    Tier: "Common",
  },
  {
    tokenId: 9617,
    Tier: "Basic",
  },
  {
    tokenId: 9618,
    Tier: "Basic",
  },
  {
    tokenId: 9619,
    Tier: "Epic",
  },
  {
    tokenId: 9620,
    Tier: "Basic",
  },
  {
    tokenId: 9621,
    Tier: "Uncommon",
  },
  {
    tokenId: 9622,
    Tier: "Basic",
  },
  {
    tokenId: 9623,
    Tier: "Basic",
  },
  {
    tokenId: 9624,
    Tier: "Basic",
  },
  {
    tokenId: 9625,
    Tier: "Common",
  },
  {
    tokenId: 9626,
    Tier: "Basic",
  },
  {
    tokenId: 9627,
    Tier: "Basic",
  },
  {
    tokenId: 9628,
    Tier: "Mythic",
  },
  {
    tokenId: 9629,
    Tier: "Basic",
  },
  {
    tokenId: 9630,
    Tier: "Basic",
  },
  {
    tokenId: 9631,
    Tier: "Uncommon",
  },
  {
    tokenId: 9632,
    Tier: "Common",
  },
  {
    tokenId: 9633,
    Tier: "Basic",
  },
  {
    tokenId: 9634,
    Tier: "Basic",
  },
  {
    tokenId: 9635,
    Tier: "Common",
  },
  {
    tokenId: 9636,
    Tier: "Uncommon",
  },
  {
    tokenId: 9637,
    Tier: "Legendary",
  },
  {
    tokenId: 9638,
    Tier: "Basic",
  },
  {
    tokenId: 9639,
    Tier: "Basic",
  },
  {
    tokenId: 9640,
    Tier: "Basic",
  },
  {
    tokenId: 9641,
    Tier: "Basic",
  },
  {
    tokenId: 9642,
    Tier: "Epic",
  },
  {
    tokenId: 9643,
    Tier: "Exotic",
  },
  {
    tokenId: 9644,
    Tier: "Basic",
  },
  {
    tokenId: 9645,
    Tier: "Epic",
  },
  {
    tokenId: 9646,
    Tier: "Basic",
  },
  {
    tokenId: 9647,
    Tier: "Mythic",
  },
  {
    tokenId: 9648,
    Tier: "Common",
  },
  {
    tokenId: 9649,
    Tier: "Epic",
  },
  {
    tokenId: 9650,
    Tier: "Basic",
  },
  {
    tokenId: 9651,
    Tier: "Basic",
  },
  {
    tokenId: 9652,
    Tier: "Uncommon",
  },
  {
    tokenId: 9653,
    Tier: "Rare",
  },
  {
    tokenId: 9654,
    Tier: "Basic",
  },
  {
    tokenId: 9655,
    Tier: "Basic",
  },
  {
    tokenId: 9656,
    Tier: "Epic",
  },
  {
    tokenId: 9657,
    Tier: "Basic",
  },
  {
    tokenId: 9658,
    Tier: "Uncommon",
  },
  {
    tokenId: 9659,
    Tier: "Mythic",
  },
  {
    tokenId: 9660,
    Tier: "Basic",
  },
  {
    tokenId: 9661,
    Tier: "Rare",
  },
  {
    tokenId: 9662,
    Tier: "Rare",
  },
  {
    tokenId: 9663,
    Tier: "Basic",
  },
  {
    tokenId: 9664,
    Tier: "Basic",
  },
  {
    tokenId: 9665,
    Tier: "Basic",
  },
  {
    tokenId: 9666,
    Tier: "Rare",
  },
  {
    tokenId: 9667,
    Tier: "Uncommon",
  },
  {
    tokenId: 9668,
    Tier: "Uncommon",
  },
  {
    tokenId: 9669,
    Tier: "Rare",
  },
  {
    tokenId: 9670,
    Tier: "Basic",
  },
  {
    tokenId: 9671,
    Tier: "Basic",
  },
  {
    tokenId: 9672,
    Tier: "Rare",
  },
  {
    tokenId: 9673,
    Tier: "Rare",
  },
  {
    tokenId: 9674,
    Tier: "Rare",
  },
  {
    tokenId: 9675,
    Tier: "Basic",
  },
  {
    tokenId: 9676,
    Tier: "Basic",
  },
  {
    tokenId: 9677,
    Tier: "Legendary",
  },
  {
    tokenId: 9678,
    Tier: "Common",
  },
  {
    tokenId: 9679,
    Tier: "Rare",
  },
  {
    tokenId: 9680,
    Tier: "Basic",
  },
  {
    tokenId: 9681,
    Tier: "Rare",
  },
  {
    tokenId: 9682,
    Tier: "Uncommon",
  },
  {
    tokenId: 9683,
    Tier: "Basic",
  },
  {
    tokenId: 9684,
    Tier: "Mythic",
  },
  {
    tokenId: 9685,
    Tier: "Rare",
  },
  {
    tokenId: 9686,
    Tier: "Uncommon",
  },
  {
    tokenId: 9687,
    Tier: "Basic",
  },
  {
    tokenId: 9688,
    Tier: "Rare",
  },
  {
    tokenId: 9689,
    Tier: "Uncommon",
  },
  {
    tokenId: 9690,
    Tier: "Uncommon",
  },
  {
    tokenId: 9691,
    Tier: "Basic",
  },
  {
    tokenId: 9692,
    Tier: "Basic",
  },
  {
    tokenId: 9693,
    Tier: "Basic",
  },
  {
    tokenId: 9694,
    Tier: "Basic",
  },
  {
    tokenId: 9695,
    Tier: "Basic",
  },
  {
    tokenId: 9696,
    Tier: "Common",
  },
  {
    tokenId: 9697,
    Tier: "Uncommon",
  },
  {
    tokenId: 9698,
    Tier: "Common",
  },
  {
    tokenId: 9699,
    Tier: "Basic",
  },
  {
    tokenId: 9700,
    Tier: "Uncommon",
  },
  {
    tokenId: 9701,
    Tier: "Legendary",
  },
  {
    tokenId: 9702,
    Tier: "Basic",
  },
  {
    tokenId: 9703,
    Tier: "Common",
  },
  {
    tokenId: 9704,
    Tier: "Basic",
  },
  {
    tokenId: 9705,
    Tier: "Epic",
  },
  {
    tokenId: 9706,
    Tier: "Basic",
  },
  {
    tokenId: 9707,
    Tier: "Basic",
  },
  {
    tokenId: 9708,
    Tier: "Basic",
  },
  {
    tokenId: 9709,
    Tier: "Common",
  },
  {
    tokenId: 9710,
    Tier: "Basic",
  },
  {
    tokenId: 9711,
    Tier: "Basic",
  },
  {
    tokenId: 9712,
    Tier: "Uncommon",
  },
  {
    tokenId: 9713,
    Tier: "Basic",
  },
  {
    tokenId: 9714,
    Tier: "Rare",
  },
  {
    tokenId: 9715,
    Tier: "Basic",
  },
  {
    tokenId: 9716,
    Tier: "Mythic",
  },
  {
    tokenId: 9717,
    Tier: "Rare",
  },
  {
    tokenId: 9718,
    Tier: "Epic",
  },
  {
    tokenId: 9719,
    Tier: "Common",
  },
  {
    tokenId: 9720,
    Tier: "Epic",
  },
  {
    tokenId: 9721,
    Tier: "Basic",
  },
  {
    tokenId: 9722,
    Tier: "Uncommon",
  },
  {
    tokenId: 9723,
    Tier: "Common",
  },
  {
    tokenId: 9724,
    Tier: "Uncommon",
  },
  {
    tokenId: 9725,
    Tier: "Basic",
  },
  {
    tokenId: 9726,
    Tier: "Basic",
  },
  {
    tokenId: 9727,
    Tier: "Mythic",
  },
  {
    tokenId: 9728,
    Tier: "Common",
  },
  {
    tokenId: 9729,
    Tier: "Uncommon",
  },
  {
    tokenId: 9730,
    Tier: "Basic",
  },
  {
    tokenId: 9731,
    Tier: "Basic",
  },
  {
    tokenId: 9732,
    Tier: "Common",
  },
  {
    tokenId: 9733,
    Tier: "Rare",
  },
  {
    tokenId: 9734,
    Tier: "Common",
  },
  {
    tokenId: 9735,
    Tier: "Legendary",
  },
  {
    tokenId: 9736,
    Tier: "Mythic",
  },
  {
    tokenId: 9737,
    Tier: "Basic",
  },
  {
    tokenId: 9738,
    Tier: "Basic",
  },
  {
    tokenId: 9739,
    Tier: "Basic",
  },
  {
    tokenId: 9740,
    Tier: "Common",
  },
  {
    tokenId: 9741,
    Tier: "Basic",
  },
  {
    tokenId: 9742,
    Tier: "Basic",
  },
  {
    tokenId: 9743,
    Tier: "Common",
  },
  {
    tokenId: 9744,
    Tier: "Basic",
  },
  {
    tokenId: 9745,
    Tier: "Basic",
  },
  {
    tokenId: 9746,
    Tier: "Basic",
  },
  {
    tokenId: 9747,
    Tier: "Epic",
  },
  {
    tokenId: 9748,
    Tier: "Basic",
  },
  {
    tokenId: 9749,
    Tier: "Common",
  },
  {
    tokenId: 9750,
    Tier: "Rare",
  },
  {
    tokenId: 9751,
    Tier: "Basic",
  },
  {
    tokenId: 9752,
    Tier: "Basic",
  },
  {
    tokenId: 9753,
    Tier: "Uncommon",
  },
  {
    tokenId: 9754,
    Tier: "Common",
  },
  {
    tokenId: 9755,
    Tier: "Mythic",
  },
  {
    tokenId: 9756,
    Tier: "Common",
  },
  {
    tokenId: 9757,
    Tier: "Basic",
  },
  {
    tokenId: 9758,
    Tier: "Basic",
  },
  {
    tokenId: 9759,
    Tier: "Basic",
  },
  {
    tokenId: 9760,
    Tier: "Basic",
  },
  {
    tokenId: 9761,
    Tier: "Legendary",
  },
  {
    tokenId: 9762,
    Tier: "Basic",
  },
  {
    tokenId: 9763,
    Tier: "Legendary",
  },
  {
    tokenId: 9764,
    Tier: "Basic",
  },
  {
    tokenId: 9765,
    Tier: "Basic",
  },
  {
    tokenId: 9766,
    Tier: "Common",
  },
  {
    tokenId: 9767,
    Tier: "Common",
  },
  {
    tokenId: 9768,
    Tier: "Basic",
  },
  {
    tokenId: 9769,
    Tier: "Uncommon",
  },
  {
    tokenId: 9770,
    Tier: "Rare",
  },
  {
    tokenId: 9771,
    Tier: "Basic",
  },
  {
    tokenId: 9772,
    Tier: "Common",
  },
  {
    tokenId: 9773,
    Tier: "Basic",
  },
  {
    tokenId: 9774,
    Tier: "Basic",
  },
  {
    tokenId: 9775,
    Tier: "Rare",
  },
  {
    tokenId: 9776,
    Tier: "Epic",
  },
  {
    tokenId: 9777,
    Tier: "Epic",
  },
  {
    tokenId: 9778,
    Tier: "Common",
  },
  {
    tokenId: 9779,
    Tier: "Basic",
  },
  {
    tokenId: 9780,
    Tier: "Basic",
  },
  {
    tokenId: 9781,
    Tier: "Mythic",
  },
  {
    tokenId: 9782,
    Tier: "Basic",
  },
  {
    tokenId: 9783,
    Tier: "Basic",
  },
  {
    tokenId: 9784,
    Tier: "Basic",
  },
  {
    tokenId: 9785,
    Tier: "Basic",
  },
  {
    tokenId: 9786,
    Tier: "Basic",
  },
  {
    tokenId: 9787,
    Tier: "Mythic",
  },
  {
    tokenId: 9788,
    Tier: "Basic",
  },
  {
    tokenId: 9789,
    Tier: "Basic",
  },
  {
    tokenId: 9790,
    Tier: "Common",
  },
  {
    tokenId: 9791,
    Tier: "Epic",
  },
  {
    tokenId: 9792,
    Tier: "Basic",
  },
  {
    tokenId: 9793,
    Tier: "Basic",
  },
  {
    tokenId: 9794,
    Tier: "Common",
  },
  {
    tokenId: 9795,
    Tier: "Epic",
  },
  {
    tokenId: 9796,
    Tier: "Exotic",
  },
  {
    tokenId: 9797,
    Tier: "Basic",
  },
  {
    tokenId: 9798,
    Tier: "Basic",
  },
  {
    tokenId: 9799,
    Tier: "Basic",
  },
  {
    tokenId: 9800,
    Tier: "Basic",
  },
  {
    tokenId: 9801,
    Tier: "Basic",
  },
  {
    tokenId: 9802,
    Tier: "Epic",
  },
  {
    tokenId: 9803,
    Tier: "Basic",
  },
  {
    tokenId: 9804,
    Tier: "Basic",
  },
  {
    tokenId: 9805,
    Tier: "Basic",
  },
  {
    tokenId: 9806,
    Tier: "Common",
  },
  {
    tokenId: 9807,
    Tier: "Basic",
  },
  {
    tokenId: 9808,
    Tier: "Basic",
  },
  {
    tokenId: 9809,
    Tier: "Basic",
  },
  {
    tokenId: 9810,
    Tier: "Basic",
  },
  {
    tokenId: 9811,
    Tier: "Uncommon",
  },
  {
    tokenId: 9812,
    Tier: "Uncommon",
  },
  {
    tokenId: 9813,
    Tier: "Basic",
  },
  {
    tokenId: 9814,
    Tier: "Basic",
  },
  {
    tokenId: 9815,
    Tier: "Common",
  },
  {
    tokenId: 9816,
    Tier: "Exotic",
  },
  {
    tokenId: 9817,
    Tier: "Basic",
  },
  {
    tokenId: 9818,
    Tier: "Basic",
  },
  {
    tokenId: 9819,
    Tier: "Basic",
  },
  {
    tokenId: 9820,
    Tier: "Basic",
  },
  {
    tokenId: 9821,
    Tier: "Epic",
  },
  {
    tokenId: 9822,
    Tier: "Uncommon",
  },
  {
    tokenId: 9823,
    Tier: "Uncommon",
  },
  {
    tokenId: 9824,
    Tier: "Basic",
  },
  {
    tokenId: 9825,
    Tier: "Basic",
  },
  {
    tokenId: 9826,
    Tier: "Rare",
  },
  {
    tokenId: 9827,
    Tier: "Epic",
  },
  {
    tokenId: 9828,
    Tier: "Uncommon",
  },
  {
    tokenId: 9829,
    Tier: "Basic",
  },
  {
    tokenId: 9830,
    Tier: "Basic",
  },
  {
    tokenId: 9831,
    Tier: "Basic",
  },
  {
    tokenId: 9832,
    Tier: "Basic",
  },
  {
    tokenId: 9833,
    Tier: "Rare",
  },
  {
    tokenId: 9834,
    Tier: "Common",
  },
  {
    tokenId: 9835,
    Tier: "Basic",
  },
  {
    tokenId: 9836,
    Tier: "Common",
  },
  {
    tokenId: 9837,
    Tier: "Common",
  },
  {
    tokenId: 9838,
    Tier: "Uncommon",
  },
  {
    tokenId: 9839,
    Tier: "Mythic",
  },
  {
    tokenId: 9840,
    Tier: "Common",
  },
  {
    tokenId: 9841,
    Tier: "Basic",
  },
  {
    tokenId: 9842,
    Tier: "Basic",
  },
  {
    tokenId: 9843,
    Tier: "Basic",
  },
  {
    tokenId: 9844,
    Tier: "Basic",
  },
  {
    tokenId: 9845,
    Tier: "Rare",
  },
  {
    tokenId: 9846,
    Tier: "Basic",
  },
  {
    tokenId: 9847,
    Tier: "Basic",
  },
  {
    tokenId: 9848,
    Tier: "Rare",
  },
  {
    tokenId: 9849,
    Tier: "Rare",
  },
  {
    tokenId: 9850,
    Tier: "Common",
  },
  {
    tokenId: 9851,
    Tier: "Uncommon",
  },
  {
    tokenId: 9852,
    Tier: "Rare",
  },
  {
    tokenId: 9853,
    Tier: "Basic",
  },
  {
    tokenId: 9854,
    Tier: "Common",
  },
  {
    tokenId: 9855,
    Tier: "Uncommon",
  },
  {
    tokenId: 9856,
    Tier: "Basic",
  },
  {
    tokenId: 9857,
    Tier: "Common",
  },
  {
    tokenId: 9858,
    Tier: "Basic",
  },
  {
    tokenId: 9859,
    Tier: "Uncommon",
  },
  {
    tokenId: 9860,
    Tier: "Exotic",
  },
  {
    tokenId: 9861,
    Tier: "Uncommon",
  },
  {
    tokenId: 9862,
    Tier: "Common",
  },
  {
    tokenId: 9863,
    Tier: "Epic",
  },
  {
    tokenId: 9864,
    Tier: "Common",
  },
  {
    tokenId: 9865,
    Tier: "Basic",
  },
  {
    tokenId: 9866,
    Tier: "Basic",
  },
  {
    tokenId: 9867,
    Tier: "Basic",
  },
  {
    tokenId: 9868,
    Tier: "Epic",
  },
  {
    tokenId: 9869,
    Tier: "Rare",
  },
  {
    tokenId: 9870,
    Tier: "Legendary",
  },
  {
    tokenId: 9871,
    Tier: "Common",
  },
  {
    tokenId: 9872,
    Tier: "Legendary",
  },
  {
    tokenId: 9873,
    Tier: "Basic",
  },
  {
    tokenId: 9874,
    Tier: "Basic",
  },
  {
    tokenId: 9875,
    Tier: "Uncommon",
  },
  {
    tokenId: 9876,
    Tier: "Common",
  },
  {
    tokenId: 9877,
    Tier: "Basic",
  },
  {
    tokenId: 9878,
    Tier: "Common",
  },
  {
    tokenId: 9879,
    Tier: "Basic",
  },
  {
    tokenId: 9880,
    Tier: "Uncommon",
  },
  {
    tokenId: 9881,
    Tier: "Basic",
  },
  {
    tokenId: 9882,
    Tier: "Uncommon",
  },
  {
    tokenId: 9883,
    Tier: "Exotic",
  },
  {
    tokenId: 9884,
    Tier: "Legendary",
  },
  {
    tokenId: 9885,
    Tier: "Uncommon",
  },
  {
    tokenId: 9886,
    Tier: "Basic",
  },
  {
    tokenId: 9887,
    Tier: "Uncommon",
  },
  {
    tokenId: 9888,
    Tier: "Basic",
  },
  {
    tokenId: 9889,
    Tier: "Uncommon",
  },
  {
    tokenId: 9890,
    Tier: "Common",
  },
  {
    tokenId: 9891,
    Tier: "Basic",
  },
  {
    tokenId: 9892,
    Tier: "Basic",
  },
  {
    tokenId: 9893,
    Tier: "Basic",
  },
  {
    tokenId: 9894,
    Tier: "Legendary",
  },
  {
    tokenId: 9895,
    Tier: "Epic",
  },
  {
    tokenId: 9896,
    Tier: "Basic",
  },
  {
    tokenId: 9897,
    Tier: "Exotic",
  },
  {
    tokenId: 9898,
    Tier: "Basic",
  },
  {
    tokenId: 9899,
    Tier: "Uncommon",
  },
  {
    tokenId: 9900,
    Tier: "Common",
  },
  {
    tokenId: 9901,
    Tier: "Basic",
  },
  {
    tokenId: 9902,
    Tier: "Basic",
  },
  {
    tokenId: 9903,
    Tier: "Rare",
  },
  {
    tokenId: 9904,
    Tier: "Uncommon",
  },
  {
    tokenId: 9905,
    Tier: "Common",
  },
  {
    tokenId: 9906,
    Tier: "Rare",
  },
  {
    tokenId: 9907,
    Tier: "Basic",
  },
  {
    tokenId: 9908,
    Tier: "Common",
  },
  {
    tokenId: 9909,
    Tier: "Basic",
  },
  {
    tokenId: 9910,
    Tier: "Basic",
  },
  {
    tokenId: 9911,
    Tier: "Epic",
  },
  {
    tokenId: 9912,
    Tier: "Basic",
  },
  {
    tokenId: 9913,
    Tier: "Uncommon",
  },
  {
    tokenId: 9914,
    Tier: "Basic",
  },
  {
    tokenId: 9915,
    Tier: "Basic",
  },
  {
    tokenId: 9916,
    Tier: "Basic",
  },
  {
    tokenId: 9917,
    Tier: "Uncommon",
  },
  {
    tokenId: 9918,
    Tier: "Epic",
  },
  {
    tokenId: 9919,
    Tier: "Common",
  },
  {
    tokenId: 9920,
    Tier: "Rare",
  },
  {
    tokenId: 9921,
    Tier: "Legendary",
  },
  {
    tokenId: 9922,
    Tier: "Uncommon",
  },
  {
    tokenId: 9923,
    Tier: "Basic",
  },
  {
    tokenId: 9924,
    Tier: "Common",
  },
  {
    tokenId: 9925,
    Tier: "Rare",
  },
  {
    tokenId: 9926,
    Tier: "Common",
  },
  {
    tokenId: 9927,
    Tier: "Basic",
  },
  {
    tokenId: 9928,
    Tier: "Basic",
  },
  {
    tokenId: 9929,
    Tier: "Basic",
  },
  {
    tokenId: 9930,
    Tier: "Basic",
  },
  {
    tokenId: 9931,
    Tier: "Epic",
  },
  {
    tokenId: 9932,
    Tier: "Basic",
  },
  {
    tokenId: 9933,
    Tier: "Epic",
  },
  {
    tokenId: 9934,
    Tier: "Rare",
  },
  {
    tokenId: 9935,
    Tier: "Rare",
  },
  {
    tokenId: 9936,
    Tier: "Legendary",
  },
  {
    tokenId: 9937,
    Tier: "Basic",
  },
  {
    tokenId: 9938,
    Tier: "Basic",
  },
  {
    tokenId: 9939,
    Tier: "Basic",
  },
  {
    tokenId: 9940,
    Tier: "Epic",
  },
  {
    tokenId: 9941,
    Tier: "Basic",
  },
  {
    tokenId: 9942,
    Tier: "Uncommon",
  },
  {
    tokenId: 9943,
    Tier: "Mythic",
  },
  {
    tokenId: 9944,
    Tier: "Uncommon",
  },
  {
    tokenId: 9945,
    Tier: "Epic",
  },
  {
    tokenId: 9946,
    Tier: "Rare",
  },
  {
    tokenId: 9947,
    Tier: "Basic",
  },
  {
    tokenId: 9948,
    Tier: "Rare",
  },
  {
    tokenId: 9949,
    Tier: "Basic",
  },
  {
    tokenId: 9950,
    Tier: "Uncommon",
  },
  {
    tokenId: 9951,
    Tier: "Uncommon",
  },
  {
    tokenId: 9952,
    Tier: "Common",
  },
  {
    tokenId: 9953,
    Tier: "Epic",
  },
  {
    tokenId: 9954,
    Tier: "Common",
  },
  {
    tokenId: 9955,
    Tier: "Rare",
  },
  {
    tokenId: 9956,
    Tier: "Uncommon",
  },
  {
    tokenId: 9957,
    Tier: "Uncommon",
  },
  {
    tokenId: 9958,
    Tier: "Uncommon",
  },
  {
    tokenId: 9959,
    Tier: "Basic",
  },
  {
    tokenId: 9960,
    Tier: "Basic",
  },
  {
    tokenId: 9961,
    Tier: "Basic",
  },
  {
    tokenId: 9962,
    Tier: "Uncommon",
  },
  {
    tokenId: 9963,
    Tier: "Basic",
  },
  {
    tokenId: 9964,
    Tier: "Basic",
  },
  {
    tokenId: 9965,
    Tier: "Basic",
  },
  {
    tokenId: 9966,
    Tier: "Uncommon",
  },
  {
    tokenId: 9967,
    Tier: "Legendary",
  },
  {
    tokenId: 9968,
    Tier: "Uncommon",
  },
  {
    tokenId: 9969,
    Tier: "Basic",
  },
  {
    tokenId: 9970,
    Tier: "Rare",
  },
  {
    tokenId: 9971,
    Tier: "Basic",
  },
  {
    tokenId: 9972,
    Tier: "Uncommon",
  },
  {
    tokenId: 9973,
    Tier: "Basic",
  },
  {
    tokenId: 9974,
    Tier: "Common",
  },
  {
    tokenId: 9975,
    Tier: "Basic",
  },
  {
    tokenId: 9976,
    Tier: "Mythic",
  },
  {
    tokenId: 9977,
    Tier: "Basic",
  },
  {
    tokenId: 9978,
    Tier: "Common",
  },
  {
    tokenId: 9979,
    Tier: "Rare",
  },
  {
    tokenId: 9980,
    Tier: "Mythic",
  },
  {
    tokenId: 9981,
    Tier: "Common",
  },
  {
    tokenId: 9982,
    Tier: "Basic",
  },
  {
    tokenId: 9983,
    Tier: "Rare",
  },
  {
    tokenId: 9984,
    Tier: "Basic",
  },
  {
    tokenId: 9985,
    Tier: "Basic",
  },
  {
    tokenId: 9986,
    Tier: "Basic",
  },
  {
    tokenId: 9987,
    Tier: "Basic",
  },
  {
    tokenId: 9988,
    Tier: "Legendary",
  },
  {
    tokenId: 9989,
    Tier: "Basic",
  },
  {
    tokenId: 9990,
    Tier: "Basic",
  },
  {
    tokenId: 9991,
    Tier: "Basic",
  },
  {
    tokenId: 9992,
    Tier: "Uncommon",
  },
  {
    tokenId: 9993,
    Tier: "Basic",
  },
];

const markings = [
  {
    name: "Basic",
    value: 5,
  },
  {
    name: "Common",
    value: 5,
  },
  {
    name: "Uncommon",
    value: 6,
  },
  {
    name: "Rare",
    value: 7,
  },
  {
    name: "Epic",
    value: 8,
  },
  {
    name: "Legendary",
    value: 10,
  },
  {
    name: "Mythic",
    value: 12,
  },
  {
    name: "Exotic",
    value: 15,
  },
  {
    name: "Winning Deathmatches",
    value: 5,
  },
  {
    name: "Winning Team Tournaments",
    value: 5,
  },
];

module.exports.markings = markings;
module.exports.chart = chart;
