import { Provider as StateProvider } from "react-redux";
import { BrowserRouter, Switch } from "react-router-dom";
import Dashboard from "./component/dashboard/Dashboard";
import Landing from "./component/landingPage/LandingPage";
import Login from "./component/login/Login";
import ProtectedRoute from "./component/routes/ProtectedRoute";
import PublicRoute from "./component/routes/PublicRoute";
import SignUp from "./component/signup/SignUp";
import EmailConfirmation from "./component/support-components/EmailConfirmation";
import store from "./state";

import { FractalProvider } from "@fractalwagmi/react-sdk";
import coinbaseWalletModule from "@web3-onboard/coinbase";
import injectedModule from "@web3-onboard/injected-wallets";
import { Web3OnboardProvider, init } from "@web3-onboard/react";
import uauthModule from "@web3-onboard/uauth";
import walletConnectModule from "@web3-onboard/walletconnect";
import "./App.scss";
import "./assets/fonts/fonts.css";

const injected = injectedModule();
const coinbase = coinbaseWalletModule();
const walletConnect = walletConnectModule({
  projectId: "3b4d714703ff7df81b69ee48173b682f",
});
const uauth = uauthModule({
  walletConnectProjectId: "3b4d714703ff7df81b69ee48173b682f",
  clientID: "cc1b3c90-18f1-4f83-9eb0-dc90f50a6f09",
  redirectUri: "https://galaxyfightclub.com/dashboard",
  scope: "openid wallet",
});

const chains = [
  {
    id: 137,
    token: "MATIC",
    label: "Matic Mainnet",
    rpcUrl: "https://matic-mainnet.chainstacklabs.com",
  },
];
const wallets = [injected, walletConnect, coinbase, uauth];

const web3Onboard = init({
  wallets,
  chains,
  appMetadata: {
    name: "Galaxy Fight Club",
    description: "Galaxy Fight Club wallet",
    recommendedInjectedWallets: [
      { name: "MetaMask", url: "https://metamask.io" },
      { name: "Coinbase", url: "https://wallet.coinbase.com/" },
    ],
  },
});

function App() {
  return (
    <Web3OnboardProvider web3Onboard={web3Onboard}>
      <BrowserRouter>
        <FractalProvider clientId="vryZrPgyG0uoDnvrnsZzzcvNffVlJRF1">
          <StateProvider store={store}>
            <Switch>
              {/* <PublicRoute exact path="/signup" component={SignUp} /> */}
              {/* <ProtectedRoute exact path="/dashboard" component={Dashboard} /> */}
              {/* <PublicRoute exact path="/login" component={Login} /> */}
              <PublicRoute exact path="/" component={Landing} />
              <PublicRoute
                exact
                path="/email/:token"
                component={EmailConfirmation}
              />
            </Switch>
          </StateProvider>
        </FractalProvider>
      </BrowserRouter>
    </Web3OnboardProvider>
  );
}

export default App;
