import React from "react";
import { Redirect, Route } from "react-router-dom";

function PublicRoute(props) {
  const isToken = localStorage.getItem("playFabId") ? true : false;

  const { path, component } = props;
  const toRender = () => {
    if (isToken) {
      if (path === "/login" || path === "/signup") {
        return <Redirect to="/dashboard" />;
      }
    }
    return <Route exact path={path} component={component} />;
  };
  return toRender();
}

export default PublicRoute;
