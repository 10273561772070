import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useWeb3 } from "../../state/application/hooks";
import axios from "../support-components/axios";

function WalletCard(props) {
  const { name, contract, val } = props;
  const { account } = useWeb3();
  const [ids, setIds] = useState("");

  const isDarkMode = useSelector((state) => state.theme.isDark);

  useEffect(() => {
    if (name === "Claimable $GCOIN") {
      if (val !== 0) setIds(val);
      else setIds(0);
    } else if (name === "Fighters") {
      if (account) {
        axios
          .get(`ethers/fighters/gcoins/${account}`)
          .then((res) => {
            setIds(res.data.totalFighters);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else if (name === "Weapons") {
      if (account) {
        axios
          .get(`ethers/weapons/${account}`)
          .then((res) => {
            setIds(res.data.total);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else if (name === "Consumables") {
      if (account) {
        axios
          .get(`ethers/consumables/${account}`)
          .then((res) => {
            setIds(res.data.total);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else if (name === "Compatible NFTs") {
      if (account) {
        axios
          .get(`https://gfc-metadata.azurewebsites.net/collections/${account}`)
          .then((res) => {
            setIds(
              _.sum(
                res.data.map((nft) => {
                  if (
                    nft.type !== "Death Robots" &&
                    nft.type !== "Fighters" &&
                    nft.type !== "Genesis Weapon" &&
                    nft.type !== "Gen2" &&
                    nft.type !== "P2E Weapon"
                  )
                    return nft.tokenIds.length;
                  return 0;
                })
              )
            );
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      if (account && contract) {
        axios
          .post(`ethers`, {
            publicAddress: account,
            contract: contract,
          })
          .then((res) => {
            if (res.data.arr) {
              setIds(res.data.arr.length);
            } else {
              setIds(0);
            }
          })
          .catch((err) => {
            console.log(err);
            setIds(0);
          });
      }
    }
  }, [contract, name, val, account]);

  return (
    <div
      className={`d-flex align-items-center walletAside ${
        isDarkMode && "walletAside-dark"
      }`}
    >
      <div className={`gCoin-bg ${isDarkMode && "bg-white"}`}>
        {props.img && <img src={props.img} alt="icon" />}
      </div>
      <div style={{ marginLeft: "20px" }}>
        <h4 className={`${isDarkMode ? "text-light" : "text-dark"}`}>
          {" "}
          {ids}{" "}
        </h4>
        <p className={`${isDarkMode ? "text-light" : "text-dark"}`}>{name}</p>
      </div>
    </div>
  );
}

export default WalletCard;
