import { createReducer } from "@reduxjs/toolkit";

import { setWeb3Settings } from "./actions";

export const initialState = {
  type: undefined,
  account: "",
  playFabId: undefined,
};

export default createReducer(initialState, (builder) =>
  builder.addCase(setWeb3Settings, (state, { payload }) => {
    const { type, playFabId, account } = payload;

    state.type = type || state.type;
    state.playFabId = playFabId || state.playFabId;

    if (account) {
      state.account = account || state.account;
    }
  })
);
