import { createReducer } from "@reduxjs/toolkit";

import { toggleTheme } from "./actions";

export const initialState = {
  isDark: true,
};

export default createReducer(initialState, (builder) =>
  builder.addCase(toggleTheme, (state, { payload }) => {
    state.isDark = !state.isDark;
  })
);
