import { Contract } from "@ethersproject/contracts";
import { JsonRpcProvider } from "@ethersproject/providers";

import { ethers } from "ethers";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Web3 from "web3";
import darkLogo from "../../assets/img/dark-logo.png";
import lightLogo from "../../assets/img/light_logo.png";
import { toggleTheme } from "../../state/application/actions";
import { useDisconnect, useWeb3 } from "../../state/application/hooks";
import axios from "../support-components/axios";

import DisclaimerModal from "./DisclaimerModal";
import EmailCredentials from "./EmailCredentials";
import ResetPasswordModal from "./ResetPasswordModal";
import UpdateEmailModal from "./UpdateEmailModal";
import Wallets from "./Wallets";

import darkcategory from "../../assets/Icons/category.svg";
import darkfragment from "../../assets/Icons/fragment.svg";
import darkGen2 from "../../assets/Icons/gen2.svg";
import hamburgerDark from "../../assets/Icons/hamburger.svg";
import darkKey from "../../assets/Icons/key.svg";
import darkLock from "../../assets/Icons/lock.svg";
import darkBox from "../../assets/Icons/lottBox.svg";
import darkweaponForge from "../../assets/Icons/weaponForge.svg";

import lightcategory from "../../assets/Icons/lightIcons/category.svg";
import lightfragment from "../../assets/Icons/lightIcons/fragment.svg";
import lightGen2 from "../../assets/Icons/lightIcons/gen2.svg";
import hamburgerLight from "../../assets/Icons/lightIcons/hamburger.svg";
import lightKey from "../../assets/Icons/lightIcons/key.svg";
import lightLock from "../../assets/Icons/lightIcons/lock.svg";
import lightBox from "../../assets/Icons/lightIcons/lottBox.svg";
import lightweaponForge from "../../assets/Icons/lightIcons/weaponForge.svg";

import { useSignOut } from "@fractalwagmi/react-sdk";
import { chart } from "../../constants/weight";
import "./Dashboard.scss";

import {
  useConnectWallet,
  useNotifications,
  useSetChain,
} from "@web3-onboard/react";
import LogoutIcon from "../../Icons/LogoutIcon";
import MoonIcon from "../../Icons/MoonIcon";
import SunIcon from "../../Icons/SunIcon";
import useScreenSize from "../../hooks/useScreenSize";
import { setWeb3Settings } from "../../state/application/actions";
import Drawer from "./Drawer";

const RARITIES = {
  Basic: 1,
  Common: 2,
  Uncommon: 3,
  Rare: 4,
  Epic: 5,
  Legendary: 6,
  Mythic: 7,
  Exotic: 8,
};

function stringToBytes32(text) {
  const result = ethers.utils.hexlify(text);
  return result;
}

function Dashboard() {
  let history = useHistory();
  const dispatch = useDispatch();
  const screenSize = useScreenSize();
  const [{ wallet }, connect] = useConnectWallet();
  const chainId = wallet?.chains[0]?.id;
  const [, setChain] = useSetChain();
  const [, customNotification] = useNotifications();
  const { playFabId, type, account } = useWeb3();
  const { signOut } = useSignOut();
  const isDarkMode = useSelector((state) => state.theme.isDark);
  const disconnect = useDisconnect();
  const [show, setShow] = useState(false);
  const [disclaimerShow, setDisclaimerShow] = useState(false);
  const [verified, setVerified] = useState(false);
  const [email, setEmail] = useState("");
  const [totalYield, setTotalYield] = useState(0);
  const [walletValue, setWalletValue] = useState("");
  const [claimLoader, setClaimLoader] = useState(false);
  const [amountGCOIN, setAmountGCOIN] = useState(0);
  const [showUpdateEmail, setShowUpdateEmail] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [isDisableClaimBtn, setIsDisableClaimBtn] = useState(false);
  const [weapons, setWeapons] = useState([]);
  const [fighters, setFighters] = useState([]);
  const [totalYieldLoading, setTotalYieldLoading] = useState(false);
  const [fighterLoading, setFighterLoading] = useState(false);
  const [weaponLoading, setWeaponLoading] = useState(false);
  const [walletValueLoading, setWalletValueLoading] = useState(false);
  const [iframebox, setIframebox] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    if (playFabId) {
      axios
        .get(`user/account?id=${playFabId}`)
        .then((res) => {
          dispatch(
            setWeb3Settings({
              account: _.get(res, "data.data.Data.publicaddress.Value", ""),
            })
          );
          setVerified(_.get(res, "data.data.Data.verification.Value", "false"));
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .get(`user/email/${playFabId}`)
        .then((res) => {
          setEmail(res.data.Email);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [playFabId]);

  useEffect(() => {
    const prevPlayFabId = localStorage.getItem("playFabId");

    if (prevPlayFabId) {
      dispatch(
        setWeb3Settings({
          playFabId: prevPlayFabId,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (account) {
      setTotalYieldLoading(true);
      setFighterLoading(true);
      setWeaponLoading(true);
      setWalletValueLoading(true);
      axios
        .get(`https://gfc-metadata.azurewebsites.net/collections/${account}`)
        .then(async (res) => {
          setFighters(
            _.get(
              res.data.find((item) => item.type === "Fighters"),
              "tokenIds",
              []
            )
          );
          const weapons = [
            ..._.get(
              res.data.find((item) => item.type === "P2E Weapon"),
              "tokenIds",
              []
            ),
            ..._.get(
              res.data.find((item) => item.type === "Genesis Weapon"),
              "tokenIds",
              []
            ),
          ];

          const rarities = await Promise.all(
            weapons.map((weaponId) =>
              axios.get(
                `https://gfcmetaimage.blob.core.windows.net/weapons/metadata/${weaponId}`
              )
            )
          );
          setWeapons(
            _.map(rarities, "data.attributes.1.value").map((tier, index) => {
              return {
                weaponId: weapons[index],
                tier,
              };
            })
          );
          setWeaponLoading(false);
          setFighterLoading(false);
        });

      axios
        .post(`/ethers/value`, {
          publicAddress: account,
        })
        .then((res) => {
          setWalletValue("balance", res);
          firstLoad();
          if (res.data.convertBalance > 0) {
            setWalletValue(res.data.convertBalance);
            setWalletValueLoading(false);
          } else {
            setWalletValueLoading(false);
            setWalletValue(0);
          }
        })
        .catch((err) => {
          console.log(err);
          setWalletValue(0);
          setWalletValueLoading(false);
        });

      axios
        .get(`ethers/fighters/gcoins/${account}`)
        .then((res) => {
          setTotalYield(res.data.totalYield);
          setTotalYieldLoading(false);
        })
        .catch((err) => {
          setTotalYield(0);
        });
    }

    // eslint-disable-next-line
  }, [account]);

  const checkVerificationStatus = (playfabid) => {
    if (playFabId) {
      axios
        .get(`user/account?id=${playFabId}`)
        .then((res) => {
          if (res.data.data.Data.verification) {
            setVerified(res.data.data.Data.verification.Value);
          } else {
            setVerified("false");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const logout = (e) => {
    e.preventDefault();
    if (type === "wallet") {
      localStorage.removeItem("walletconnect");
    }

    signOut();
    disconnect();
    history.push("/");
  };

  function swtichToPolygon() {
    setChain({
      chainId: "0x89",
    });
  }

  const firstLoad = async (e) => {
    setClaimLoader(true);
    setIsDisableClaimBtn(false);

    if (chainId !== "0x89" && !!chainId) {
      customNotification({
        eventCode: "dbUpdate",
        type: "hint",
        message: "Please switch to Polygon network",
        onClick: () => {
          swtichToPolygon();
        },
      });
    }

    if (account) {
      axios
        .get(`/ethers/claim/gcoins/${account}`)
        .then(async (res) => {
          if (!res.data.totalBalance) {
            setIsDisableClaimBtn(true);
            setClaimLoader(false);
            return;
          }

          const HodlerBridgeMerkleClaim_CONTRACT = require("../../ABI/HODLER_BRIDGE_MERKLE_CLAIM_CONTRACT.json");
          const HodlerBridgeMerkleClaim_ContractAddress =
            HodlerBridgeMerkleClaim_CONTRACT.MainNetAddress;
          const HodlerBridgeMerkleClaimContract = new Contract(
            HodlerBridgeMerkleClaim_ContractAddress,
            HodlerBridgeMerkleClaim_CONTRACT.ABI,
            new JsonRpcProvider(
              "https://polygon-mainnet.g.alchemy.com/v2/fcC_PZ51Vn6TyinLAiGYYxPGI68Ufqqt"
            )
          );

          HodlerBridgeMerkleClaimContract.claimedAmount(account)
            .then((result) => {
              let x = ethers.BigNumber.from(res.data.totalBalance);
              let y = ethers.BigNumber.from(result);
              let t1 = x.sub(y);

              let t2 = Web3.utils.fromWei(t1.toString(), "ether");
              let GCOIN_val = Math.round(t2 * 100) / 100;
              setAmountGCOIN(GCOIN_val);
              if (GCOIN_val === 0 || chainId !== "0x89") {
                setIsDisableClaimBtn(true);
              }
            })
            .catch((err) => {
              alert("The error occured when call claimedAmount()");
            });
          setClaimLoader(false);
        })
        .catch((err) => {
          setClaimLoader(false);
          customNotification({
            eventCode: "dbUpdate",
            type: "error",
            message: "Sorry an Error Occured",
          });
        });
    }
  };

  const claimGcoins = async (e) => {
    setClaimLoader(true);
    setIsDisableClaimBtn(false);

    if (chainId !== "0x89" && !!chainId) {
      customNotification({
        eventCode: "dbUpdate",
        type: "hint",
        message: "Please switch to Polygon network",
        onClick: () => {
          swtichToPolygon();
        },
      });
    }

    if (account) {
      axios
        .get("/ethers/claim/gcoins/" + account)
        .then(async (res) => {
          if (!res.data.totalBalance) {
            setIsDisableClaimBtn(true);
            setClaimLoader(false);
            return;
          }

          const HodlerBridgeMerkleClaim_CONTRACT = require("../../ABI/HODLER_BRIDGE_MERKLE_CLAIM_CONTRACT.json");
          const HodlerBridgeMerkleClaim_ContractAddress =
            HodlerBridgeMerkleClaim_CONTRACT.MainNetAddress;
          const HodlerBridgeMerkleClaimContract = new Contract(
            HodlerBridgeMerkleClaim_ContractAddress,
            HodlerBridgeMerkleClaim_CONTRACT.ABI,
            new JsonRpcProvider(
              "https://polygon-mainnet.g.alchemy.com/v2/fcC_PZ51Vn6TyinLAiGYYxPGI68Ufqqt"
            )
          );

          HodlerBridgeMerkleClaimContract.claimedAmount(account)
            .then((result) => {
              let x = ethers.BigNumber.from(res.data.totalBalance);
              let y = ethers.BigNumber.from(result);
              let t1 = x.sub(y);

              let t2 = Web3.utils.fromWei(t1.toString(), "ether");
              let GCOIN_val = Math.round(t2 * 100) / 100;
              setAmountGCOIN(GCOIN_val);
              if (GCOIN_val === 0 || chainId !== "0x89") {
                setIsDisableClaimBtn(true);
              }
            })
            .catch((err) => {
              console.error(err);
              alert("The error occured when call claimedAmount()");
            });

          let proof = res.data.proof.map((x) => stringToBytes32(x));
          let payload = new ethers.utils.AbiCoder().encode(
            ["uint256", "bytes32[]"],
            [res.data.totalBalance, proof]
          );
          const tx = {
            to: "0x1C28Da3F19F4FFd84218d374dbA0eE42A52EC52e",
            from: account,
            data: "0x2f52ebb7" + payload.substring(2),
          };
          console.log(tx);
          //debugger;
          let val = await new Web3(wallet.provider).eth.sendTransaction(tx);
          console.log(val);
          setClaimLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setClaimLoader(false);
          //debugger
          customNotification({
            eventCode: "dbUpdate",
            type: "error",
            message: "Sorry an Error Occured",
          });
        });
    }
  };

  const ClaimButtonStyle = `${
    isDisableClaimBtn ? "disable-btn " : ""
  }option-li`;

  const rarestFighter = fighters.sort((fighter1, fighter2) => {
    const weight1 =
      RARITIES[
        _.get(
          chart.find((fighter) => fighter["tokenId"] === Number(fighter1)),
          "Tier",
          "Basic"
        )
      ];
    const weight2 =
      RARITIES[
        _.get(
          chart.find((fighter) => fighter["tokenId"] === Number(fighter2)),
          "Tier",
          "Basic"
        )
      ];

    return weight2 - weight1;
  })[0];

  const rarestWeapon = _.get(
    weapons.sort((weapon1, weapon2) => {
      const weight1 = RARITIES[weapon1.tier];
      const weight2 = RARITIES[weapon2.tier];

      return weight2 - weight1;
    }),
    "0.weaponId",
    undefined
  );

  const DashboardSideBar = () => {
    return (
      <div className="option-sidebar">
        <ul className={`option-ul ${isDarkMode ? "text-light" : "text-dark"}`}>
          <li className="d-none d-md-block option-li">
            <Link
              to="/"
              className="option-a"
              onClick={() => {
                setIframebox("");
              }}
            >
              <img
                className={"option-header"}
                src={isDarkMode ? lightLogo : darkLogo}
                alt="logo"
              />
            </Link>
          </li>
          <li
            onClick={() => setIframebox("")}
            className="option-li option-a"
          ></li>
          {type === "meta" ? (
            <li
              onClick={() => {
                const isDisclaimerChecked = window.localStorage.getItem(
                  "isDisclaimerChecked"
                );

                if (!isDisableClaimBtn) {
                  if (isDisclaimerChecked) {
                    claimGcoins();
                  } else {
                    setDisclaimerShow(true);
                  }
                }
              }}
              className={ClaimButtonStyle}
            >
              {claimLoader ? (
                <Spinner
                  style={{ color: "white", margin: "auto" }}
                  animation="border"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                <div className="option-a">
                  <img
                    className="option-icon"
                    src={isDarkMode ? lightcategory : darkcategory}
                    alt="icon"
                  />
                  <span className="option-text">Claim GCOIN</span>
                </div>
              )}
            </li>
          ) : (
            <></>
          )}
          {type === "meta" && (
            <li
              onClick={() => setShowResetPassword(true)}
              className="option-li option-a"
            >
              <img
                className="option-icon"
                src={isDarkMode ? lightLock : darkLock}
                alt="icon"
              />
              <span className="option-text">Reset Password</span>
            </li>
          )}
          {!account && (
            <li
              className="option-li option-a"
              onClick={async () => {
                try {
                  localStorage.setItem("linkWallet", "CONNECT_WALLET");
                  const [{ accounts }] = await connect();

                  if (accounts[0]) {
                    const account = accounts[0].address;
                    await axios.post(
                      "user/connect/wallet",
                      {
                        playfabid: localStorage.getItem("playFabId"),
                        publicAddress: account,
                      },
                      {
                        headers: {
                          "X-Authorization":
                            localStorage.getItem("session_ticket"),
                        },
                      }
                    );
                  }
                } catch (err) {}
              }}
            >
              <img
                className="option-icon"
                src={isDarkMode ? lightcategory : darkcategory}
                alt="icon"
              />
              <span className="option-text"> Connect Wallet </span>
            </li>
          )}

          <li
            className="option-li option-a"
            onClick={() => {
              setIframebox("https://lootbox.galaxyfightclub.com");
            }}
          >
            <img
              className={"option-icon"}
              src={isDarkMode ? lightBox : darkBox}
              alt="logo"
            />
            <span className="option-text"> Loot Box </span>
          </li>
          <li
            className="option-li option-a"
            onClick={() => {
              setIframebox("https://forge.galaxyfightclub.com");
            }}
          >
            <img
              className={"option-icon"}
              src={isDarkMode ? lightweaponForge : darkweaponForge}
              alt="logo"
            />
            <span className="option-text"> Weapon Forge </span>
          </li>
          <li
            className="option-li option-a"
            onClick={() => {
              setIframebox(
                "https://training.galaxyfightclub.com?isSidebarHidden=true"
              );
            }}
          >
            <img
              className={`option-icon ${
                isDarkMode ? "dark-mode" : "light-mode"
              }`}
              src={isDarkMode ? lightGen2 : darkGen2}
              alt="logo"
            />
            <span className="option-text"> Gen 2 Training </span>
          </li>
          <li
            className="option-li option-a"
            onClick={() => {
              setIframebox(
                "https://p2e.galaxyfightclub.com?isSidebarHidden=true"
              );
            }}
          >
            <img
              className={"option-icon"}
              src={isDarkMode ? lightfragment : darkfragment}
              alt="logo"
            />
            <span className="option-text"> Fragment Redeem </span>
          </li>
          <li
            className="option-li option-a"
            onClick={() => {
              setIframebox(
                "https://p2e.galaxyfightclub.com/key-craft?isSidebarHidden=true"
              );
            }}
          >
            <img
              className={`option-icon ${
                isDarkMode ? "dark-mode" : "light-mode"
              }`}
              src={isDarkMode ? lightKey : darkKey}
              alt="logo"
            />
            <span className="option-text"> Key Craft </span>
          </li>
          <li className="option-li toggleBtn">
            <div className="d-flex justify-content-end justify-content-md-center">
              <>
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={isDarkMode}
                  id="checkbox"
                  onChange={() => {
                    dispatch(toggleTheme());
                  }}
                />
                <label
                  htmlFor="checkbox"
                  className={`${
                    isDarkMode ? "bg-white" : "bg-dark"
                  } checkbox-label`}
                >
                  <SunIcon color={"black"} />
                  <MoonIcon />
                  <span className=" d-flex align-items-center justify-content-center ball">
                    {isDarkMode ? (
                      <MoonIcon color="black" />
                    ) : (
                      <SunIcon color="black" />
                    )}
                  </span>
                </label>
              </>
            </div>
          </li>
          <li onClick={logout} className="option-li logout-btn">
            <Link to="/" className="option-a">
              <span className="option-icon">
                <LogoutIcon
                  color={
                    !isDarkMode && screenSize === "large" ? "#231D5D" : "white"
                  }
                />
              </span>
              <span
                className={`option-text ${
                  !isDarkMode && screenSize === "large"
                    ? "text-dark"
                    : "text-white"
                }`}
              >
                Logout
              </span>
            </Link>
          </li>
        </ul>
      </div>
    );
  };

  return (
    <div>
      <div
        className={`${
          isDarkMode ? "background-dark" : "background-light"
        } dasboard-wrapper`}
        style={{
          color: isDarkMode ? "#FFF" : "#000000",
        }}
      >
        <nav
          className={`justify-content-between container-fluid nav-mobile ${
            isDarkMode ? "sidebar-dark" : "bg-white"
          }`}
        >
          <img
            style={{ width: "147px", height: "59px" }}
            src={isDarkMode ? lightLogo : darkLogo}
            alt="logo"
          />
          <img
            onClick={() => setOpenDrawer(true)}
            className={"cursor-pointer"}
            src={isDarkMode ? hamburgerLight : hamburgerDark}
            alt="logo"
          />
        </nav>

        <div
          className={`${isDarkMode ? "sidebar-dark" : "bg-white"} left-sidebar`}
        >
          <div className="sidebar-dashboard">
            <DashboardSideBar />
          </div>
        </div>

        <Drawer open={openDrawer} setOpen={setOpenDrawer}>
          <div
            className={`${
              isDarkMode ? "sidebar-dark" : "bg-white"
            } left-sidebar-mob`}
          >
            <div className="sidebar-dashboard">
              <DashboardSideBar />
            </div>
          </div>
        </Drawer>

        <div className="content-wrapper">
          <div
            className={`nav-bar  ${isDarkMode ? "text-light" : "text-dark"}`}
          >
            Dashboard
          </div>
          {/* <ResendEmail
            verified={verified}
            resendShow={resendShow}
            setResendShow={setResendShow}
          /> */}
          {!iframebox && (
            <>
              {true && (
                <EmailCredentials
                  verified={verified}
                  setShow={setShow}
                  checkVerificationStatus={checkVerificationStatus}
                  show={show}
                  email={email}
                />
              )}
              <Wallets
                fighterLoading={fighterLoading}
                totalYieldLoading={totalYieldLoading}
                rarestFighter={rarestFighter}
                weaponLoading={weaponLoading}
                rarestWeapon={rarestWeapon}
                totalYield={totalYield}
                email={email}
                walletValue={walletValue}
                address={account}
                loading={walletValueLoading}
                amountGCOIN={amountGCOIN}
                unstoppableDomain={""}
              />
            </>
          )}
          {iframebox && (
            <div className="py-5 d-flex px-5">
              <iframe
                className="m-auto"
                src={iframebox}
                title="iframe"
                width="100%"
                height="700px"
              />
            </div>
          )}
          <DisclaimerModal
            setShow={setDisclaimerShow}
            show={disclaimerShow}
            proceed={claimGcoins}
          />
          <UpdateEmailModal
            setShow={setShowUpdateEmail}
            show={showUpdateEmail}
          />
          <ResetPasswordModal
            setShow={setShowResetPassword}
            show={showResetPassword}
            email={email}
          />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
